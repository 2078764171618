import { CLIENT_ID, API_KEY, DISCOVERY_DOC, SCOPES }  from './constants';

async function initializeGapiClient() {
  await gapi.client.init({
    apiKey: API_KEY,
    discoveryDocs: [DISCOVERY_DOC],
  });
}

export default {
  gapiLoaded() {
    gapi.load('client', initializeGapiClient);
  },
  gisLoaded() {
    let tokenClient = google.accounts.oauth2.initTokenClient({
      client_id: CLIENT_ID,
      scope: SCOPES,
      callback: ''
    });
    return tokenClient;
  },
  newSheet(params, title) {
    return gapi.client.sheets.spreadsheets.batchUpdate(params, {
      requests: [{
        addSheet: {
          properties: {
            title: title
          }
        }
      }]
    });
  },
  loadData(sheetId, body) {
    return gapi.client.sheets.spreadsheets.values.batchUpdate({
      spreadsheetId: sheetId,
      resource: body
    });
  }
};

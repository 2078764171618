<template>
  <div
    class="confirmation"
  >
    <p
      v-html="confirmMessage"
    ></p>

    <base-button
      v-if="confirmationNeeded
        && (confirmationNeeded.deactivate && confirmationNeeded.deactivate.length
        || confirmationNeeded.reactivate && confirmationNeeded.reactivate.length)"
      @click="disable()"
    >
      {{ $t('yes') }}
    </base-button>

    <base-button
      v-else-if="confirmationNeeded
        && selected === 'add-policy'"
      @click="editPolicy()"
    >
      {{ $t('yes') }}
    </base-button>

    <base-button
      v-else-if="confirmationNeeded
        && selected === 'add-playcard'"
      @click="editPlayCard()"
    >
      {{ $t('yes') }}
    </base-button>

    <base-button
      v-else-if="confirmationNeeded
        && selected === 'edit-project'"
      @click="editProject()"
    >
      {{ $t('yes') }}
    </base-button>

    <base-button
      v-else-if="userManagement && userManagement.map(item=> item.text).includes(selected)"
      @click="addUser()"
    >
      {{ $t('yes') }}
    </base-button>

    <base-button
      v-else-if="confirmationNeeded
        && selected === 'general'"
      @click="yesClicked"
    >
      {{ $t('yes') }}
    </base-button>

    <base-button
      @click="hideConfirmation()"
    >
      {{ $t('no') }}
    </base-button>
  </div>
</template>

<script>

export default {
  props: {
    userManagement: {
      required: true,
    },
    confirmationNeeded: {
      required: true,
    },
    confirmMessage: {
      required: true,
    },
    selected: {
      required: true,
    },
  },
  methods: {
    hideConfirmation() {
      this.$emit('hideConfirmation');
    },
    disable() {
      this.$emit('disable');
    },
    addUser() {
      this.$emit('addUser');
    },
    editPolicy() {
      this.hideConfirmation();
      this.$emit('editPolicy');
    },
    editPlayCard() {
      this.hideConfirmation();
      this.$emit('editPlayCard');
    },
    editProject() {
      this.$emit('editProject');
    },
    yesClicked() {
      this.hideConfirmation();
      this.$emit('onYesClicked');
    },
  },
};
</script>

<style lang="scss" scoped>
.row {
  display: flex;
  align-items: center;

  label {
    margin: 0;
    display: flex;
    align-items: center;
    margin-top: 8px;

    &[for='eval_balanced'] {
      width: 100%;
    }

    .label-text {
      font-weight: 400;
      color: #3C4866;
      margin-right: 10px;
      margin-bottom: 0;

      &.name {
        font-weight: bold;
      }
    }

    .form-group {
      margin-top: 0;

      &.smaller {
        width: 60px;
      }
    }

    textarea {
      height: 70px;
      width: 250px;
      margin-top: 0;
      resize: both;
    }
  }

  textarea {
    padding: 10px 12px;
  }

  &:not(:first-child) {
    margin-top: 0.8rem;
  }

  .custom-control.custom-checkbox {
    margin-right: 30px;
  }

  .form-group, textarea {
    margin-top: 0.8rem;
    margin-right: 30px;
    margin-bottom: 0;

    &:first-child {
      margin-top: 0;
    }
  }

  .form-group input, textarea {
    height: 43px;
    border: 1px solid #CAD1D7;
    border-radius: 0.25rem;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.5;
    color: #3C4866;

    &::placeholder {
      color: #8898AA;
      opacity: 0.8;
    }
  }
}

.selection-panel {
  .project .project-options .row {
    &:not(:first-child) button {
      margin-top: 0.8rem;
    }

    button:last-child {
      margin-right: 0;
    }
  }

  hr {
    position: absolute;
    width: 100%;
    margin-top: 0.75rem;
    margin-left: -45px;
  }
}

.confirmation {
  text-align: center;
}
</style>

<template>
    <div class="raw_div">
        <LoadingRing v-if="loading" class="pagination-wrapper"></LoadingRing>
        <pre v-if ="!loading && rawData">{{ `${rawData}` }}</pre>
    </div>
</template>
<script>
import api from '../common/api';
import LoadingRing from '../components/base-components/LoadingRing';

export default {
    components: {
        LoadingRing,
    },
    data() {
        return {
            rawData: null,
            url: '',
            uuid: null,
            loading: true,
        };
    },
    methods: {
        getRawDataGameplay(uuid) {
            api.getRawDataGameplay(uuid)
                .then((res) => {
                    this.rawData = JSON.stringify(res.data, null, "\t");
                    this.resultMessage = '';
                })
                .catch((err) => {
                    if (err.response !== undefined) {
                        this.resultMessage = err.response.data.detail;
                    } else {
                        this.resultMessage = err;
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
    mounted() {
        if (this.$route.params.rawGP) {
            this.uuid = this.$route.params.rawGP;
            this.getRawDataGameplay(this.uuid);
        }
    },
};
</script>
<style>
.raw_div {
    margin: 25px;
}
</style>
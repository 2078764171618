<template>
  <div
    class="aggregator-options row calculators"
  >
    <div
      v-if="(selectedAction === 'edit'
        || selectedAction === 'add')
        && aggregator"
    >
      <div
        v-for="(calculator, i) in aggregator.profile_fit_calculators"
        :key="i"
        class="calculator"
      >
        <Dropdown
          :options="calculators"
          @selected="setCalculator"
          @index="selectCalculator(i)"
          :defaultValue="getDefaultCalculator(aggregator.profile_fit_calculators[i].id, i)"
          :disabled="false"
          :name="`calc-${i}`"
          :maxItem="calculators.length"
          :placeholder="$t('aggregator.select_calculator')"
          :key="`calculators-${i}`"
        />

        <base-button
          class="remove"
          @click="removeCalculator(i)"
        >
          X
        </base-button>

        <base-button
          v-if="i === 0"
          class="add-calculator"
          @click.prevent="addCalculator()"
        >
          {{ $t('aggregator.add_calculator') }}
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import Dropdown from "./Dropdown.vue";

export default {
  components: {
    Dropdown
  },
  props: {
    selectedAction: {
      required: true
    },
    aggregator: {
      required: true
    },
    calculators: {
      required: true
    }
  },
  data() {
    return {
      selectedCalculatorId: null,
      selectedCalculatorName: ''
    }
  },
  methods: {
    handleErrors(err) {
      this.$emit('handleErrors', err);
    },
    setCalculator(calculator) {
      this.selectedCalculatorId = calculator.id;
      this.selectedCalculatorName = calculator.name;
    },
    selectCalculator(i) {
      this.aggregator.profile_fit_calculators[i].id = this.selectedCalculatorId;
      this.aggregator.profile_fit_calculators[i].name = this.selectedCalculatorName;
    },
    removeCalculator(i) {
      // empty list values when removing the only calculator
      if (this.aggregator.profile_fit_calculators.length === 1) {
         this.aggregator.profile_fit_calculators = [];
        this.aggregator.profile_fit_calculators[0]= {
           id: null,
           name: ''
         }
      } else {
        this.aggregator.profile_fit_calculators.splice(i, 1);
      }
    },
    addCalculator() {
      this.aggregator.profile_fit_calculators.push({
        id: null,
        name: ''
      })
    },
    getDefaultCalculator(calculator, i) {
      let arr = this.calculators.filter(obj => {
        return obj.id === calculator;
      }).map(calculator => calculator.name);

      return arr[0] || '';
    },
  }
}
</script>

<style lang="scss" scoped>
.row.calculators {
  position: relative;
  display: flex;
  width: 1030px;
  margin-right: 10px;
  padding-bottom: 5px;

  .calculator {
    display: flex;
  }

  &:not(:first-child) {
    margin-top: 0.8rem;
  }

  .form-group {
    margin-top: 0.8rem;
    margin-right: 30px;
    margin-bottom: 0;
  }

  .form-group input {
    height: 43px;
    border: 1px solid #CAD1D7;
    border-radius: 0.25rem;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.5;
    color: #3C4866;

    &::placeholder {
      color: #8898AA;
      opacity: 0.8;
    }
  }
}

.selection-panel {
  .aggregator-options.row {
    &:not(:first-child) button {
      margin-top: 0.8rem;
    }

    button:last-child {
      margin-right: 0;
    }
  }
}
</style>

<template>
  <div
    class="report"
  >
    <div class="report-options row">
      <base-button
        v-if="!selectedAction"
        @click="selectAction('add')"
      >
        {{ $t('add_layout') }}
      </base-button>

      <base-button
        v-if="!selectedAction"
        @click="selectAction('edit')"
      >
        {{ $t('edit_layout') }}
      </base-button>

      <div
        v-if="selectedAction === 'edit'"
        class="edit-report"
      >
        <Dropdown
          :options="reportLayouts"
          @selected="sendLayoutId"
          :disabled="false"
          name="layouts"
          :maxItem="reportLayouts.length"
          :placeholder="$t('select_layout')"
          :key="`layout-${key}`"
        />
      </div>

      <div
        v-else-if="selectedAction === 'add'
          && newLayout"
        class="edit-report"
      >
        <base-input
          class="name-input"
          :placeholder="$t('name')"
          name="name"
          type="text"
          v-model="newLayout.name"
        ></base-input>
      </div>

      <base-button
        v-if="selectedAction"
        @click="selectAction(null)"
      >
        {{ $t('back') }}
      </base-button>
    </div>

    <div
      v-if="selectedAction === 'edit'
        && layout"
      class="report-options row"
    >
      <label
        style="flex: 100%"
        for="name"
      >
        <p
          class="label-text name"
        >
          {{ $t('name') }}:
        </p>

        <base-input
          v-if="layout"
          class="name-input"
          :placeholder="$t('name')"
          name="name"
          type="text"
          v-model="layout.name"
        ></base-input>
      </label>

      <label
        v-for="([key, value], i) in Object.entries(layout).filter(([key, value]) => typeof(value) === 'boolean')"
        :key="`layout-${i}`"
        style="flex: 50%"
        :for="key"
      >
        <p
          class="label-text"
        >
          {{ $t(`report_editor.${key}`) }}:
        </p>

        <base-checkbox
          class="check-input"
          :name="key"
          v-model="layout[key]"
        ></base-checkbox>
      </label>
    </div>

    <div
      v-else-if="selectedAction === 'add'
        && newLayout"
      class="report-options row"
    >
      <label
        v-for="([key, value], i) in Object.entries(newLayout).filter(([key, value]) => typeof(value) === 'boolean')"
        :key="`layout-${i}`"
        style="flex: 50%"
        :for="key"
      >
        <p
          class="label-text"
        >
          {{ $t(`report_editor.${key}`) }}:
        </p>

        <base-checkbox
          class="check-input"
          :name="key"
          v-model="newLayout[key]"
        ></base-checkbox>
      </label>
    </div>

    <hr
      v-if="(selectedAction === 'edit'
        && layout)
        || (selectedAction === 'add'
        && newLayout)"
    >

    <div
      v-if="selectedAction === 'edit'
        && layout"
      class="report-options row"
    >
      <div style="flex: 100%" >
        <Dropdown
          class="lang"
          :options="langs"
          @selected="selectLang"
          :disabled="false"
          name="lang"
          :maxItem="langs.length"
          :placeholder="$t('select_lang')"
          :key="`lang`"
          :language="true" 
        />
      </div>

      <label
        v-for="(key, i) in Object.keys(layout.translations).filter(key => key.includes('methods'))"
        :key="`layout-${i}`"
        :for="key"
      >
        <p
          class="label-text"
        >
          {{ $t(`report_editor.${key}`) }}:
        </p>

        <base-input
          v-if="selectedLang
            && key.includes('title')"
          class="title-input"
          :placeholder="selectedLang"
          :name="key"
          type="text"
          v-model="layout.translations[key][selectedLang]"
        ></base-input>
        
        <textarea
          v-if="selectedLang
            && key.includes('text')"
          class="text-input"
          :placeholder="selectedLang"
          :name="key"
          v-model="layout.translations[key][selectedLang]"
        ></textarea>
      </label>
    </div>

    <div
      v-else-if="selectedAction === 'add'
        && newLayout"
      class="report-options row"
    >
      <div style="flex: 100%" >
        <Dropdown
          class="lang"
          :options="langs"
          @selected="selectLang"
          :disabled="false"
          name="lang"
          :maxItem="langs.length"
          :placeholder="$t('select_lang')"
          :key="`lang`"
          :language="true"
        />
      </div>

      <label
        v-for="(key, i) in Object.keys(newLayout.translations)"
        :key="`layout-${i}`"
        :for="key"
      >
        <p
          class="label-text"
        >
          {{ $t(`report_editor.${key}`) }}:
        </p>

        <base-input
          v-if="selectedLang
            && key.includes('title')"
          class="title-input"
          :placeholder="selectedLang"
          :name="key"
          type="text"
          v-model="newLayout.translations[key][selectedLang]"
        ></base-input>
        
        <textarea
          v-if="selectedLang
            && key.includes('text')"
          class="text-input"
          :placeholder="selectedLang"
          :name="key"
          v-model="newLayout.translations[key][selectedLang]"
        ></textarea>
      </label>
    </div>

    <hr
      v-if="(selectedAction === 'edit'
        && layout)
        || (selectedAction === 'add'
        && newLayout)"
    >

    <ModuleSelector
      v-if="(selectedAction === 'edit'
        && layout)
        || (selectedAction === 'add'
        && newLayout)"
      ref="moduleSelector"
      :reportLayouts="reportLayouts"
      :layout="layout"
      :newLayout="newLayout"
      :reportModules="reportModules"
      :selectedAction="selectedAction"
      :moduleTypes="moduleTypes"
      @handleErrors="handleErrors"
    />

    <hr
      v-if="(selectedAction === 'edit'
        && layout)
        || (selectedAction === 'add'
        && newLayout)"
    >

    <div
      v-if="selectedAction === 'edit'
        && layout"
      class="report-options row"
    >
      <base-button
        @click="editReportLayout"
      >
        {{ $t('edit') }}
      </base-button>
    </div>

    <div
      v-else-if="selectedAction === 'add'"
      class="report-options row"
    >
      <base-button
        @click="addReportLayout"
      >
        {{ $t('add') }}
      </base-button>
    </div>

    <hr
      v-if="(selectedAction === 'edit'
        && layout)
        || (selectedAction === 'add'
        && newLayout)"
    >
  </div>
</template>

<script>
import Dropdown from "./Dropdown.vue";
import api from "../common/api";
import ModuleSelector from "./ModuleSelector.vue";

export default {
  components: {
    Dropdown,
    ModuleSelector
},
  props: {
    allLang: {
      required: true,
    },
    langs: {
      required: true,
    },
    reportLayouts: {
      required: true,
    },
    reportModules: {
      required: true,
    },
    moduleTypes: {
      required: true,
    },
  },
  data() {
    return {
      key: 0,
      selectedAction: null,
      selectedLang: null,
      layout: null,
      reportLayoutId: null,
      layoutScheme: {
        name: "",
        show_gameplay_data: true,
        show_game_data: true,
        expose_benchmark: false,
        expose_profile_fit: false,
        modules: [],
        translations: {
          methods_title: {},
          methods_text: {}
        }
      },
      newLayout: null,
    }
  },
  methods: {
    setMsg(msg) {
      this.$emit('setMsg', msg);
    },
    sendAction(action) {
      this.$emit('sendAction', action);
    },
    setDefaultLang() {
      this.$children.forEach((child, i) => {
        if ( child.$el.className === 'dropdown lang' ) {
          this.$children[i].setDefault('English');
        }
      })
      this.selectedLang = 'en';
    },
    selectLang(lang) {
      this.selectedLang = lang.code;
    },
    deleteValues() {
      this.layout = null;
      this.selectedLang = null;
      this.$emit('sendLang', this.selectedLang);
      this.$emit('hideResult', 'edit-report');
    },
    handleErrors(err) {
      this.$emit('handleErrors', err);
    },
    selectAction(action) {
      if ( !action ) {
        this.deleteValues();
      } else if ( action === 'add' ) {
        this.newLayout = structuredClone(this.layoutScheme);
        Object.keys(this.newLayout.translations).forEach((key, i) => {
          this.newLayout.translations[key] = structuredClone(this.allLang);
          this.newLayout.modules.push({
            module_type: "",
            module_id: null,
            order: i
          })
        });

        if ( !this.selectedLang ) {
          setTimeout(() => {
            this.setDefaultLang();
          }, 200)
        }
      }

      this.selectedAction = action;
      this.sendAction(action);
    },
    sendLayoutId(layout) {
      if ( !this.selectedLang ) {
        setTimeout(() => {
          this.setDefaultLang();
        }, 200)
      }

      this.reportLayoutId = layout.id;

      api.getReporLayout(layout.id)
      .then(res => {
        this.layout = res.data;

        if (this.$refs.moduleSelector) {
          this.$refs.moduleSelector.rerender();
        }
      })
      .catch(err => {
        this.layout = null;

        if ( err.response !== undefined ) {
          this.handleErrors(err.response);
        } else {
          this.handleErrors(err);
        }
      })
    },
    addReportLayout() {
      api.addLayout(this.newLayout)
      .then(res => {
        this.setMsg(this.$t('result_message.layout_added'));
      })
      .catch(err => {
        if ( err.response !== undefined ) {
          this.handleErrors(err.response);
        } else {
          this.handleErrors(err);
        }
      })
    },
    editReportLayout() {
      api.editReporLayout(this.reportLayoutId, this.layout)
      .then(res => {
        this.setMsg(this.$t('result_message.layout_edited'));
      })
      .catch(err => {
        if ( err.response !== undefined ) {
          this.handleErrors(err.response);
        } else {
          this.handleErrors(err);
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.row {
  display: flex;
  align-items: baseline;

  label {
    display: flex;
    align-items: center;
    margin-top: 8px;

    &[for='name'] {
      flex: 100%;
    }

    .label-text {
      font-weight: 400;
      color: #3C4866;
      margin-right: 10px;
      margin-bottom: 0;

      &.name {
        font-weight: bold;
      }
    }

    textarea {
      height: 70px;
      width: 250px;
      margin-top: 0;
      resize: both;
    }
  }

  textarea {
    padding: 10px 12px;
  }

  &:not(:first-child) {
    margin-top: 0.8rem;
  }

  .custom-control.custom-checkbox {
    margin-right: 30px;
  }

  .form-group, textarea {
    margin-right: 30px;
    margin-bottom: 0;
  }

  .form-group input, textarea {
    height: 43px;
    border: 1px solid #CAD1D7;
    border-radius: 0.25rem;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.5;
    color: #3C4866;

    &::placeholder {
      color: #8898AA;
      opacity: 0.8;
    }
  }

  .edit-report {
    display: flex;
  }
}

.selection-panel {
  .report .row {
    &:not(:first-child) button {
      margin-top: 0.8rem;
    }

    button:last-child {
      margin-right: 0;
    }
  }

  hr {
    position: absolute;
    width: 100%;
    margin-top: 0.75rem;
    margin-left: 0;
  }
}
</style>

<template>
  <div class="wrapper">
    <div>
      <Aggregator
        :langs="$store.state.admin.langs"
        :clients="clients"
        :policies="policies"
        :reportLayouts="reportLayouts"
        :projects="projects"
        :notMemberProjects="notMemberProjects"
        :playCards="playCards"
        :videoCards="videoCards"
        :registerCards="registerCards"
        :calculators="calculators"
        :emailTemplates="emailTemplates"
        :customfields="customfields"
        @handleErrors="handleErrors"
        @setMsg="setMsg"
      />
    </div>
  </div>
</template>
<script>
import Aggregator from '../components/Aggregator.vue';
import api from '../common/api.js';
import sharedMethods from '../mixins/sharedMethods';

export default {
  components: {
    Aggregator,
  },
  mixins: [sharedMethods],
  data() {
    return {
      notMemberProjectDataSize: 0,
      notMemberProjects: [],
      calculatorDataSize: 0,
      calculators: [],
      emailTemplateDataSize: 0,
      emailTemplates: [],
      playCardDataSize: 0,
      playCards: [],
      layoutDataSize: 0,
      reportLayouts: [],
      policyDataSize: 0,
      policies: [],
      projectDataSize: 0,
      projects: [],
      clientDataSize: 0,
      clients: [],
      videoCards: [],
      registerCards: [],
      customfields: [],
    };
  },
  computed: {
    userLang() {
      return this.$store.state.user.user_lang;
    },
  },

  methods: {
    getNotMemberProjectList(page) {
      api
        .listProjects(page, true)
        .then((res) => {
          this.notMemberProjectDataSize = res.data.total / 100;
          res.data.items.forEach((item) => {
            this.notMemberProjects.unshift({
              id: item.id,
              name: `${item.id} - ${item.name}`,
            });
          });
          if (page < this.notMemberProjectDataSize) {
            page++;
            this.getNotMemberProjectList(page);
          }
        })
        .catch((err) => {
          if (err.response !== undefined) {
            this.handleErrors(err.response);
          } else {
            this.handleErrors(err);
          }
        });
    },
    getCalculatorList(page) {
      api
        .listCalculators(page)
        .then((res) => {
          this.calculatorDataSize = res.data.total / 5000;
          res.data.items.forEach((item) => {
            this.calculators.unshift({
              id: item.id,
              name: `${item.id} - ${item.name}`,
            });
          });
          if (page < this.calculatorDataSize) {
            page++;
            this.getCalculatorList(page);
          }
        })
        .catch((err) => {
          if (err.response !== undefined) {
            this.handleErrors(err.response);
          } else {
            this.handleErrors(err);
          }
        });
    },
    getEmailTemplateList(page) {
      api
        .listEmailTemplates(page)
        .then((res) => {
          this.emailTemplateDataSize = res.data.total / 5000;
          res.data.items.forEach((item) => {
            this.emailTemplates.unshift({
              id: item.id,
              name: item.name,
            });
          });
          if (page < this.emailTemplateDataSize) {
            page++;
            this.getEmailTemplateList(page);
          }
        })
        .catch((err) => {
          if (err.response !== undefined) {
            this.handleErrors(err.response);
          } else {
            this.handleErrors(err);
          }
        });
    },
    getProjectList(page) {
      api
        .listProjects(page)
        .then((res) => {
          this.projectDataSize = res.data.total / 100;
          res.data.items.forEach((item) => {
            this.projects.unshift({
              id: item.id,
              name: `${item.id} - ${item.name}`,
            });
          });
          if (page < this.projectDataSize) {
            page++;
            this.getProjectList(page);
          }
        })
        .catch((err) => {
          if (err.response !== undefined) {
            this.handleErrors(err.response);
          } else {
            this.handleErrors(err);
          }
        });
    },
    getReportLayoutList(page) {
      api
        .listReportLayouts(page)
        .then((res) => {
          this.layoutDataSize = res.data.total / 5000;
          res.data.items.forEach((item) => {
            this.reportLayouts.unshift({
              id: item.id,
              name: `${item.id} - ${item.name}`,
            });
          });
          if (page < this.layoutDataSize) {
            page++;
            this.getReportLayoutList(page);
          }
        })
        .catch((err) => {
          if (err.response !== undefined) {
            this.handleErrors(err.response);
          } else {
            this.handleErrors(err);
          }
        });
    },
    getPlayCardList(page) {
      api
        .listPlayCards(page)
        .then((res) => {
          this.playCardDataSize = res.data.total / 100;
          res.data.items.forEach((item) => {
            this.playCards.unshift({
              id: item.id,
              name: `${item.id} - ${item.name}`,
              aggregator_count: item.aggregator_count,
            });
          });
          if (page < this.playCardDataSize) {
            page++;
            this.getPlayCardList(page);
          }
        })
        .catch((err) => {
          if (err.response !== undefined) {
            this.handleErrors(err.response);
          } else {
            this.handleErrors(err);
          }
        });
    },
    getClientList(page) {
      api
        .listClients(page, 1000)
        .then((res) => {
          this.clientDataSize = res.data.total / 100;
          res.data.items.forEach((item) => {
            this.clients.unshift(item);
          });
          if (page < this.clientDataSize) {
            page++;
            this.getClientList(page);
          }
        })
        .catch((err) => {
          if (err.response !== undefined) {
            this.handleErrors(err.response);
          } else {
            this.handleErrors(err);
          }
        });
    },
    getPolicyList(page) {
      api
        .listPolicies(page)
        .then((res) => {
          this.policyDataSize = res.data.total / 100;
          res.data.items.forEach((item) => {
            this.policies.unshift(item);
          });
          if (page < this.policyDataSize) {
            page++;
            this.getPolicyList(page);
          }
        })
        .catch((err) => {
          if (err.response !== undefined) {
            this.handleErrors(err.response);
          } else {
            this.handleErrors(err);
          }
        });
    },
    getVideoCardsList(page) {
      api.listVideoCards(page)
        .then((res) => {
          res.data.items.forEach((item) => {
            this.videoCards.unshift({
              id: item.id,
              displayName: `${item.id} - ${item.name}`,
              name: item.name,
            });
          });
        })
        .catch((err) => {
          if (err.response !== undefined) {
            this.handleErrors(err.response);
          } else {
            this.handleErrors(err);
          }
        });
      api.listRegistationCards()
        .then((res) => {
          res.data.items.forEach((item) => {
            this.registerCards.unshift({
              id: item.id,
              displayName: `${item.id} - ${item.name}`,
              name: item.name,
            });
          });
        })
        .catch((err) => {
          if (err.response !== undefined) {
            this.handleErrors(err.response);
          } else {
            this.handleErrors(err);
          }
        });
    },
    getCustomfieldsList() {
      api.listCustomFields()
        .then((res) => {
          this.customfields = res.data.items;
        })
        .catch((err) => {
          if (err.response !== undefined) {
            this.handleErrors(err.response);
          } else {
            this.handleErrors(err);
          }
        });
    },
  },
  mounted() {
    if (this.notMemberProjectDataSize === 0) {
      this.getNotMemberProjectList(1);
    }
    if (this.calculatorDataSize === 0) {
      this.getCalculatorList(1);
    }
    if (this.emailTemplateDataSize === 0) {
      this.getEmailTemplateList(1);
    }
    if (this.playCardDataSize === 0) {
      this.getPlayCardList(1);
    }

    this.getVideoCardsList(1);
    this.getCustomfieldsList();

    if (this.layoutDataSize === 0) {
      this.getReportLayoutList(1);
    }
    if (this.policyDataSize === 0) {
      this.getPolicyList(1);
    }
    if (this.projectDataSize === 0) {
      this.getProjectList(1);
    }
    if (this.clientDataSize === 0) {
      this.getClientList(1);
    }
  },
};
</script>

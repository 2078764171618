<template>
  <div v-if="game" class="wrapper">
    <div class="row">
      <label for="id">
        <p class="label-text">ID:</p>
        <p class="result">{{ `${game.id}` }}</p>
      </label>
      <label for="name">
        <p class="label-text">{{ $t('name') }}:</p>
        <base-input class="name-input" :placeholder="$t('name')" :name="'name'" type="text"
          v-model="game.name"></base-input>
      </label>
    </div>
    <div class="row">
      <label for="name">
        <p class="label-text">{{ $t('game.identifier') }}:</p>
        <p class="result">{{ `${game.identifier}` }}</p>
      </label>
      <label for="state">
        <p class="label-text">{{ $t('game.state') }}:</p>
        <Dropdown :options="states" :defaultValue="game.state" />
      </label>
    </div>
    <hr />
    <div class="row">
      <label for="max_play_time">
        <p class="label-text">{{ $t('game.max_play_time') }}:</p>
        <base-input class="name-input" :placeholder="$t('max_play_time')" :name="'max_play_time'" type="text"
          v-model="game.max_play_time"></base-input>
      </label>
      <label for="field_width">
        <p class="label-text">{{ $t('game.field_width') }}:</p>
        <base-input class="name-input" :placeholder="$t('field_width')" :name="'field_width'" type="text"
          v-model="game.field_width"></base-input>
      </label>
      <label for="field_height">
        <p class="label-text">{{ $t('game.field_height') }}:</p>
        <base-input class="name-input" :placeholder="$t('field_height')" :name="'field_height'" type="text"
          v-model="game.field_height"></base-input>
      </label>
    </div>
    <hr />
    <div class="row config">
      <div class="skins_lvl" for="name">
        <p class="label-text">{{ $t('game.skins') }}:</p>
        <ul class="game-skins">
          <li v-for="(skin, index) in game.skins" :key="index">
            {{ skin }}
          </li>
        </ul>
       <p class="label-text skins-info">{{ $t('game.skins_info') }}</p>
      </div>
      <div class="skins_lvl">
        <p class="label-text">
          {{ $t('game.levelpack') }}:
        </p>
        <div class="table-container">
          <table>
            <thead>
              <tr>
                <th @click="sortTable('name')">
                  {{ $t('name') }}
                </th>
                <th @click="sortTable('state')">
                  {{ $t('game.state') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="lvlp in sortedLevelpacks" :key="lvlp.id" @click="handleTableRowClick(lvlp)">
                <td class="lvl_name">
                  {{ lvlp.name }}
                </td>
                <td>
                  {{ lvlp.state }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <hr />
    <div class="row">
      <label for="default_level_pack">
        <p class="label-text">{{ $t('game.default_level_pack') }}:</p>
        <p class="result">{{ `${game.default_level_pack}` }}</p>
      </label>
      <label for="name">
        <p class="label-text">{{ $t('game.created_at') }}:</p>
        <p class="result">{{ `${game.created_at}` | moment }}</p>
      </label>
      <label class="responsive" for="allow_csv">
        <p class="label-text">{{ $t('game.responsive') }}:</p>
        <span class="allow_csv allow_csv-2">
          <input type="checkbox" id="allow_csv-2" v-model="game.responsive" />
          <label for="allow_csv-2"></label>
        </span>
      </label>
    </div>
    <div class="row">
      <base-button @click="editGame">
        {{ $t('edit') }}
      </base-button>
      <base-button @click="newLevelPack()">
        {{ $t('New LevelPack') }}
      </base-button>
      <base-button @click="backGame">
        {{ $t('back') }}
      </base-button>
    </div>
    <Message v-if="resultMessage !== ''" :classes="''" :message="resultMessage" @hideResult="resultMessage = ''">
    </Message>
    <hr />
  </div>
</template>

<script>
import Filters from '../mixins/filters';
import sharedMethods from '../mixins/sharedMethods';
import api from '../common/api';
import Dropdown from '../components/Dropdown.vue';
import Message from '../components/Message.vue';

export default {
  components: {
    Dropdown,
    Message,
  },
  mixins: [sharedMethods, Filters],
  data() {
    return {
      game: null,
      states: [
        { id: 'active', name: 'active' },
        { id: 'obsolete', name: 'obsolete' },
      ],
      levelpacks: null,
      sortedLevelpacks: null,
    };
  },
  methods: {
    sortTable(column) {
      this.sortedLevelpacks = [...this.levelpacks].sort((a, b) => {
        const nameA = a[column].toLowerCase();
        const nameB = b[column].toLowerCase();

        if (column === 'state') {
          return nameB.localeCompare(nameA, 'en-US');
        }
        return nameA.localeCompare(nameB, 'en-US');
      });
    },
    counterPush(editorsave) {
      const timeoutSeconds = 4;
      let countDown = timeoutSeconds;

      const message = `${this.$t(editorsave)}`;
      this.setMsg(message);

      const intervalId = setInterval(() => {
        countDown--;
        if (countDown > 0) {
          this.setMsg(`${message} ... ${countDown}`);
        } else {
          clearInterval(intervalId);
          this.$router.push('/games');
        }
      }, 1000);
    },
    newLevelPack() {
      this.$router.push(`/game/${this.game.id}/new-levelpack`);
    },
    handleTableRowClick(lvlp) {
      this.$router.push(`/game/${this.game.id}/levelpack/${lvlp.identifier}`);
    },
    backGame() {
      this.$router.push('/games');
    },
    getGame(gameId) {
      api.getGame(gameId, true)
        .then((res) => {
          this.game = res.data;
        })
        .catch((err) => {
          if (err.response !== undefined) {
            this.handleErrors(err.response);
          } else {
            this.handleErrors(err);
          }
        });
    },
    editGame() {
      api.editGame(this.game.id, this.game)
        .then(() => {
          this.counterPush('result_message.game_edited');
        })
        .catch((err) => {
          if (err.response !== undefined) {
            this.handleErrors(err.response);
          } else {
            this.handleErrors(err);
          }
        });
    },
    listLevelPack(gameId) {
      api.listLevelPacks(1, gameId, '')
        .then((res) => {
          this.levelpacks = res.data.items;
          this.sortedLevelpacks = res.data.items;
        })
        .catch((err) => {
          if (err.response !== undefined) {
            this.handleErrors(err.response);
          } else {
            this.handleErrors(err);
          }
        });
    },
  },
  mounted() {
    if (this.$route.params.gameId) {
      this.getGame(this.$route.params.gameId);
      this.listLevelPack(this.$route.params.gameId);
    }
  },
};
</script>
<style scoped lang="scss">
.row {
  position: relative;
  display: flex;
  width: 1030px;
  margin-right: 10px;
  padding-bottom: 5px;

  &:not(:first-child) {
    margin-top: 0.8rem;
  }

  button {
    margin: 20px 0 20px 20px;
  }

  label {
    margin: 0;
    display: flex;
    align-items: center;
    margin-top: 0.8rem;
  }
}

.result {
  font-weight: bold;
  margin: 0;
  margin-right: 50px;
}

.table-container {
  max-height: 300px;
  width: auto;
  overflow-y: auto;

  td {
    padding-top: 10px;
    padding-right: 50px;
    padding-bottom: 10px;
  }

  thead {
    position: sticky;
    top: 0;
    background-color: #525f7f;
    color: white;

    tr th:hover {
      background-color: #394258;
      color: rgb(255, 255, 255);
    }
  }

  tbody tr {
    cursor: pointer;
  }

  tbody tr:hover {
    background-color: rgb(206, 211, 219);
  }

  .lvl_name {
    color: rgb(86, 86, 231);
  }

  .lvl_name:hover {
    color: rgb(122, 122, 236);
  }
}

$purple: #A245FF;
$white: #FFFFFF;

span.allow_csv {
  box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
  position: relative;
  width: 120px;
  height: 43px;
  border-radius: 40px;
  overflow: hidden;
  border: 1.2px solid $purple;

  input {
    appearance: none;
    position: relative;
    width: 120px;
    height: 43px;
    border-radius: 40px;
    background-color: $white;
    outline: none;
    font-family: "Open Sans", sans-serif;
    text-transform: uppercase;
    font-size: 0.9rem;
    font-weight: 600;
    letter-spacing: 0.025em;

    &:before,
    &:after {
      z-index: 2;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      color: $purple;
    }

    &:before {
      content: 'ON';
      left: 20px;
    }

    &:after {
      content: 'OFF';
      right: 20px;
    }
  }

  label {
    z-index: 1;
    position: absolute;
    bottom: 10px;
    margin: 0;
  }

  &.allow_csv-2 {
    input {
      transition: background-color 0s .5s;

      &:before {
        color: $white;
      }

      &:after {
        color: $purple;
      }

      &:checked {
        background-color: $purple;

        &+label {
          background: $purple;
          animation: turn-on .5s ease-out;

          @keyframes turn-on {
            0% {
              left: 100%;
            }

            100% {
              left: 0%;
            }
          }
        }
      }

      &:not(:checked) {
        background: $white;

        &+label {
          background: $white;
          animation: turn-off .5s ease-out;

          @keyframes turn-off {
            0% {
              right: 100%;
            }

            100% {
              right: 0%;
            }
          }
        }
      }
    }

    label {
      top: 0px;
      width: 120px;
      height: 43px;
      border-radius: 40px;
      max-height: 43px;
    }
  }
}

.row.config {
  display:flex;
  column-gap: 100px;
  flex-wrap: nowrap;
}

.skins_lvl {
  font-size: 14px;
  font-weight: 400;
  max-width: 50%;
  margin: 0 !important;
  display: grid !important;

  p {
    margin-bottom: 20px !important;
  }
  .skins-info {
    color: red;
    font-style: italic;
  }
}

.game-skins {
  list-style-type: none;
  padding: 0;

  li {
    margin-left: 30px;
  }
}
</style>

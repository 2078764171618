<template>
    <div v-if="players && active === 'player'">
        <div v-for="(player, i) in players" :key="player.id" class="player">
            <div class="row2">
                <h3 class="search_h3">{{ `${player.name || $t('anonymus')}` }}</h3>
                <router-link class="uuid" :to="`/player/${player.uuid}`">P-{{ `${player.uuid}` }}</router-link>
            </div>
            <p class="email" v-tooltip=" $t('copy_link')" @click="copyEmail(player.email)">{{ `${player.email || $t('anonymus')}` }}</p>
        </div>
        <hr />
    </div>
</template>
<script>

export default {
    props: {
        players: {
            required: true,
        },
        active: {
            required: true,
        },
    },
    methods: {
        copyEmail(email) {
            navigator.clipboard.writeText(email);
        },
    },
};
</script>
  
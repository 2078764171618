<template>
  <div class="export">
    <div class="btns">
      <base-button
        v-if="gapiInited
          && gisInited"
        id="authorize_button"
        @click="handleAuthClick()"
      >
        {{ auth }}
      </base-button>

      <base-button
        v-if="link"
        @click="openSheet()"
      >
        {{ link }}
      </base-button>
    </div>
  </div>
</template>

<script>

import gs_api from "../common/gsheets-api.js";

export default {
  name: 'export',
  props: {
    exportData: {
      required: true,
    },
  },
  data() {
    return {
      sheetId: '1bXVVS2TmovmKE0mrAiQuBNRg_avyXrgTFz78uWVfeIA',
      sheetUrl: '',
      tokenClient: '',
      gapiInited: false,
      gisInited: false,
      signOutVisible: false,
      auth: this.$t('export.auth'),
      link: '',
    };
  },
  methods: {
    setMsg(msg) {
      this.$emit('setMsg', msg);
    },

    openSheet() {
      window.open(this.sheetUrl,'_blank');
    },

    exportTable() {
      this.$emit('exportTable', 'export');
    },

    gapiInit() {
      gs_api.gapiLoaded();
      this.gapiInited = true;
    },

    gisLoad() {
      this.tokenClient = gs_api.gisLoaded();
      this.gisInited = true;
    },

    handleAuthClick() {
      this.tokenClient.callback = async (resp) => {
        if ( resp.error !== undefined ) {
          throw (resp);
        }
        this.signOutVisible = true;
        this.auth = this.$t('export.refresh');
        await this.saveExportData();
      };

      if ( gapi.client.getToken() === null ) {
        // Prompt the user to select a Google Account and ask for consent to share their data when establishing a new session.
        this.tokenClient.requestAccessToken({prompt: 'consent'});
      } else {
        // Skip display of account chooser and consent dialog for an existing session.
        this.tokenClient.requestAccessToken({prompt: ''});
      }
    },

    async saveExportData() {
      this.exportTable();
      try {
        // Create new sheet
        let params = {
          spreadsheetId: this.sheetId
        }
        let title = new Date().toLocaleDateString();
        await gs_api.newSheet(params, title)
        .then((res) => {
          this.sheetUrl = `https://docs.google.com/spreadsheets/d/${this.sheetId}/edit#gid=${res.result.replies[0].addSheet.properties.sheetId}`;
        });
        // Load data into the new sheet
        let data = [];
        data.push({
          range: `${title}!A1`,
          values: this.exportData
        });
        let body = {
          data: data,
          valueInputOption: 'RAW'
        };
        gs_api.loadData(this.sheetId, body)
        .then((res) => {
          this.link = this.$t('export.show_list');
        });
      } catch (err) {
        this.setMsg(err.message || err.result.error.message);
        return;
      }
    },
  },
  mounted() {
    this.gapiInit();
    this.gisLoad();
  }
};
</script>

<style lang="scss" scoped>
button, button:hover {
  float: right;
  margin: 10px 30px 10px 0;
  color: #A245FF;
  border: 1px solid #A245FF;
  border-radius: 40px;
  max-height: 43px;

  &:not(:last-child) {
    margin-right: 30px;
  }
}

p {
  margin-top: 16px;
  font-weight: 400;
}
</style>

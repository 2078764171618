import Vue from 'vue';
import Router from 'vue-router';
import store from './store';
import AppHeader from '../components/base-components/AppHeader';
import BGHome from '../views/BGHome.vue';
import Aggregators from '../views/Aggregators.vue';
import AggregatorPage from '../views/AggregatorPage.vue';
import CustomFields from '../views/CustomFields.vue';
import CustomFieldPage from '../views/CustomFieldPage.vue';
import Playcards from '../views/Playcards.vue';
import PlaycardPage from '../views/PlaycardPage.vue';
import ReportWarningPage from '../views/ReportWarningPage.vue';
import ReportWarnings from '../views/ReportWarnings.vue';
import Videocards from '../views/Videocards.vue';
import VideocardPage from '../views/VideocardPage.vue';
import PrivacyPolicies from '../views/PrivacyPolicies.vue';
import PrivacyPolicyPage from '../views/PrivacyPolicyPage.vue';
import Projects from '../views/Projects.vue';
import ProjectPage from '../views/ProjectPage.vue';
import ReportModulePage from '../views/ReportModulePage.vue';
import ReportModules from '../views/ReportModules.vue';
import Clients from '../views/Clients.vue';
import ClientPage from '../views/ClientPage.vue';
import Users from '../views/Users.vue';
import UserPage from '../views/UserPage.vue';
import Error from '../views/Error.vue';
import Games from '../views/Games.vue';
import GamePage from '../views/GamePage.vue';
import LevelPackPage from '../views/LevelPackPage.vue';
import PlayerPage from '../views/PlayerPage.vue';
import RawDataPage from '../views/RawDataPage.vue';
import RawAggregatorPage from '../views/RawAggregatorPage.vue';
import AuditLogsPage from '../views/AuditLogsPage.vue';
import PlayerState from '../views/PlayerStatePage.vue';
import AggregatorPlayers from '../views/AggregatorPlayers.vue';
import ProfileCalculators from '../views/ProfileCalculators.vue';
import ProfileCalculatorPage from '../views/ProfileCalculatorPage.vue';
import Benchmarks from '../views/Benchmarks.vue';
import BenchmarkPage from '../views/BenchmarkPage.vue';
import StatusPage from '../views/StatusPage.vue';
import PMCalculatorListPage from '../views/PMCalculatorList.vue';
import PMCalculatorPage from '../views/PMCalculatorPage.vue';
import PMcalculatorNew from '../views/PMCalculatorNew.vue';
import { AUTH_PATH } from './constants';
import { setFallbackLocale, getUserLang } from '../i18n';

Vue.use(Router);

export const router = new Router({
  base: '/',
  mode: 'history',
  routes: [
    {
      path: '/auth-handler',
      name: 'AuthHandler',
      beforeEnter: (to, from, next) => {
        const { hash } = to;
        const separators = ['#', '=', '&'];
        const parts = hash.split(new RegExp(separators.join('|'), 'g'));
        const position = parts.indexOf('access_token');
        const token = parts[position + 1];
        window.localStorage.setItem('access_token', token);
        store.commit('auth/setToken', token);
        if (!window.localStorage.getItem('userinfo')) {
          getUserLang();
          setFallbackLocale();
        }
        next({
          path: window.localStorage.getItem('path') || '/',
        });
      },
    },
    {
      path: '/',
      name: 'Home',
      components: {
        default: BGHome,
        header: AppHeader,
      },
    },
    {
      path: '/player/:playerId/state/:aggregatorId',
      name: 'PlayerState',
      components: {
        default: PlayerState,
        header: AppHeader,
      },
    },
    {
      path: '/player/:playerId',
      name: 'PlayerPage',
      components: {
        default: PlayerPage,
        header: AppHeader,
      },
    },
    {
      path: '/gameplay/:rawGP/raw-data',
      name: 'RawDataPage',
      components: {
        default: RawDataPage,
        header: AppHeader,
      },
    },
    {
      path: '/aggregator/:aggregatorId/player/:playerId/raw-report',
      name: 'RawAggregatorPage',
      components: {
        default: RawAggregatorPage,
        header: AppHeader,
      },
    },
    {
      path: '/aggregators',
      name: 'Aggregators',
      components: {
        default: Aggregators,
        header: AppHeader,
      },
    },
    {
      path: '/aggregator/:aggregatorId',
      name: 'AggregatorPage',
      components: {
        default: AggregatorPage,
        header: AppHeader,
      },
    },
    {
      path: '/aggregator/:aggregatorId/candidates',
      name: 'AggregatorPage',
      components: {
        default: AggregatorPlayers,
        header: AppHeader,
      },
    },
    {
      path: '/new-aggregator',
      name: 'NewAggregator',
      components: {
        default: AggregatorPage,
        header: AppHeader,
      },
    },
    {
      path: '/clients',
      name: 'Clients',
      components: {
        default: Clients,
        header: AppHeader,
      },
    },
    {
      path: '/client/:clientId',
      name: 'ClientPage',
      components: {
        default: ClientPage,
        header: AppHeader,
      },
    },
    {
      path: '/new-client',
      name: 'NewClient',
      components: {
        default: ClientPage,
        header: AppHeader,
      },
    },
    {
      path: '/users',
      name: 'Users',
      components: {
        default: Users,
        header: AppHeader,
      },
    },
    {
      path: '/user/:userId',
      name: 'UserPage',
      components: {
        default: UserPage,
        header: AppHeader,
      },
    },
    {
      path: '/user/:userId/audit-log',
      name: 'AuditLogPage',
      components: {
        default: AuditLogsPage,
        header: AppHeader,
      },
    },
    {
      path: '/new-user',
      name: 'NewUser',
      components: {
        default: UserPage,
        header: AppHeader,
      },
    },
    {
      path: '/projects',
      name: 'Projects',
      components: {
        default: Projects,
        header: AppHeader,
      },
    },
    {
      path: '/new-project',
      name: 'NewProject',
      components: {
        default: ProjectPage,
        header: AppHeader,
      },
    },
    {
      path: '/project/:projectId',
      name: 'ProjectPage',
      components: {
        default: ProjectPage,
        header: AppHeader,
      },
    },
    {
      path: '/modules',
      name: 'ReportModules',
      components: {
        default: ReportModules,
        header: AppHeader,
      },
    },
    {
      path: '/new-module',
      name: 'NewModule',
      components: {
        default: ReportModulePage,
        header: AppHeader,
      },
    },
    {
      path: '/module/:type/:id',
      name: 'ReportModule',
      components: {
        default: ReportModulePage,
        header: AppHeader,
      },
    },
    {
      path: '/custom-fields',
      name: 'CustomFields',
      components: {
        default: CustomFields,
        header: AppHeader,
      },
    },
    {
      path: '/custom-field/:id',
      name: 'CustomFieldPage',
      components: {
        default: CustomFieldPage,
        header: AppHeader,
      },
    },
    {
      path: '/new-custom-field',
      name: 'NewCustomField',
      components: {
        default: CustomFieldPage,
        header: AppHeader,
      },
    },
    {
      path: '/playcards',
      name: 'Playcards',
      components: {
        default: Playcards,
        header: AppHeader,
      },
    },
    {
      path: '/playcard/:id',
      name: 'PlaycardPage',
      components: {
        default: PlaycardPage,
        header: AppHeader,
      },
    },
    {
      path: '/new-playcard',
      name: 'NewPlaycard',
      components: {
        default: PlaycardPage,
        header: AppHeader,
      },
    },
    {
      path: '/report-warnings',
      name: 'Report Warnings',
      components: {
        default: ReportWarnings,
        header: AppHeader,
      },
    },
    {
      path: '/report-warning/:id',
      name: 'ReportWarnigPage',
      components: {
        default: ReportWarningPage,
        header: AppHeader,
      },
    },
    {
      path: '/new-report-warning',
      name: 'NewReportWarning',
      components: {
        default: ReportWarningPage,
        header: AppHeader,
      },
    },
    {
      path: '/videocards',
      name: 'Videocards',
      components: {
        default: Videocards,
        header: AppHeader,
      },
    },
    {
      path: '/videocard/:id',
      name: 'Video Cards',
      components: {
        default: VideocardPage,
        header: AppHeader,
      },
    },
    {
      path: '/new-videocard',
      name: 'New Video Card',
      components: {
        default: VideocardPage,
        header: AppHeader,
      },
    },
    {
      path: '/privacy-policies',
      name: 'Privacy Policies',
      components: {
        default: PrivacyPolicies,
        header: AppHeader,
      },
    },
    {
      path: '/privacy-policy/:id',
      name: 'Privacy Policy Page',
      components: {
        default: PrivacyPolicyPage,
        header: AppHeader,
      },
    },
    {
      path: '/new-policy',
      name: 'New Privacy Policy',
      components: {
        default: PrivacyPolicyPage,
        header: AppHeader,
      },
    },
    {
      path: '/games',
      name: 'Games',
      components: {
        default: Games,
        header: AppHeader,
      },
    },
    {
      path: '/game/:gameId',
      name: 'GamePage',
      components: {
        default: GamePage,
        header: AppHeader,
      },
    },
    {
      path: '/game/:gameId/levelpack/:levelpackId',
      name: 'LevelPackPage',
      components: {
        default: LevelPackPage,
        header: AppHeader,
      },
    },
    {
      path: '/game/:gameId/new-levelpack',
      name: 'NewLevelPack',
      components: {
        default: LevelPackPage,
        header: AppHeader,
      },
    },
    {
      path: '/profile-calculators',
      name: 'ProfileCalculators',
      components: {
        default: ProfileCalculators,
        header: AppHeader,
      },
    },
    {
      path: '/profile-calculator/:profilecalculatorId',
      name: 'ProfileCalculatorPage',
      components: {
        default: ProfileCalculatorPage,
        header: AppHeader,
      },
    },
    {
      path: '/new-profile-calculator/',
      name: 'NewProfileCalculatorPage',
      components: {
        default: ProfileCalculatorPage,
        header: AppHeader,
      },
    },
    {
      path: '/benchmarks',
      name: 'Benchmarks',
      components: {
        default: Benchmarks,
        header: AppHeader,
      },
    },
    {
      path: '/benchmark/:benchmarkId',
      name: 'BenchmarkPage',
      components: {
        default: BenchmarkPage,
        header: AppHeader,
      },
    },
    {
      path: '/new-benchmark/',
      name: 'NewBenchmarkPage',
      components: {
        default: BenchmarkPage,
        header: AppHeader,
      },
    },
    {
      path: '/status/',
      name: 'Status',
      components: {
        default: StatusPage,
        header: AppHeader,
      },
    },
    {
      path: '/pm-calculator-list/',
      name: 'ProfileMatchCalculatorList',
      components: {
        default: PMCalculatorListPage,
        header: AppHeader,
      },
    },
    {
      path: '/pm-calculator/:pmcalculator',
      name: 'ProfileMatchCalculatorPage',
      components: {
        default: PMCalculatorPage,
        header: AppHeader,
      },
    },
    {
      path: '/pm-calculator-new',
      name: 'NewProfileMatchCalculatorPage',
      components: {
        default: PMcalculatorNew,
        header: AppHeader,
      },
    },
    {
      path: '*',
      components: {
        default: Error,
      },
    },
  ],
  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const access_token = window.localStorage.getItem('access_token');
  if (!access_token) {
    if (to.name !== 'AuthHandler') {
      // At the beginning before settings json is requested, in the absence of auth path avoid /null path to be saved into local storage
      if (to.path !== '/null') {
        window.localStorage.setItem('path', to.path);
      }
      location.href = AUTH_PATH;
      return;
    }
  }
  next();
});

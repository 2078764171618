var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"kpi-editor"},[_c('div',{staticClass:"row"},[(!_vm.selectedAction)?_c('base-button',{on:{"click":function($event){return _vm.selectAction('add')}}},[_vm._v(" "+_vm._s(_vm.$t('report_editor.add_kpi'))+" ")]):_vm._e(),(!_vm.selectedAction)?_c('base-button',{on:{"click":function($event){return _vm.selectAction('edit')}}},[_vm._v(" "+_vm._s(_vm.$t('report_editor.edit_kpi'))+" ")]):_vm._e(),(_vm.selectedAction === 'add')?_c('base-input',{staticClass:"identifier-input",attrs:{"name":"identifier","placeholder":_vm.$t('report_editor.identifier'),"type":"text"},model:{value:(_vm.newKpi.identifier),callback:function ($$v) {_vm.$set(_vm.newKpi, "identifier", $$v)},expression:"newKpi.identifier"}}):(_vm.selectedAction === 'edit')?_c('Dropdown',{key:`kpis`,staticClass:"edit",attrs:{"name":"kpis","options":_vm.kpis,"disabled":false,"maxItem":_vm.kpis.length,"placeholder":_vm.$t('report_editor.select_kpi')},on:{"selected":_vm.getKpi}}):_vm._e(),(_vm.selectedAction)?_c('base-button',{on:{"click":function($event){return _vm.selectAction(null)}}},[_vm._v(" "+_vm._s(_vm.$t('back'))+" ")]):_vm._e()],1),(_vm.selectedAction === 'add' && _vm.newKpi)?_c('div',{staticClass:"row"},[_c('Dropdown',{key:`lang`,staticClass:"lang",attrs:{"options":_vm.langs,"disabled":false,"name":"lang","maxItem":_vm.langs.length,"language":true,"placeholder":_vm.$t('select_lang')},on:{"selected":_vm.selectLang}}),_vm._l((Object.keys(_vm.newKpi.translations).filter(key =>
        key === 'name'
        || key === 'description'
      )),function(key,i){return _c('label',{key:`kpi-${i}`,attrs:{"for":key}},[(_vm.selectedLang)?_c('p',{staticClass:"label-text"},[_vm._v(" "+_vm._s(_vm.$t(`report_editor.${key}`))+": ")]):_vm._e(),(_vm.selectedLang
          && key === 'name')?_c('base-input',{class:`${key}-input`,attrs:{"name":key,"placeholder":_vm.selectedLang,"type":"text"},model:{value:(_vm.newKpi.translations[key][_vm.selectedLang]),callback:function ($$v) {_vm.$set(_vm.newKpi.translations[key], _vm.selectedLang, $$v)},expression:"newKpi.translations[key][selectedLang]"}}):(_vm.selectedLang)?_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.newKpi.translations[key][_vm.selectedLang]),expression:"newKpi.translations[key][selectedLang]"}],class:`${key}-input`,attrs:{"name":key,"placeholder":_vm.selectedLang},domProps:{"value":(_vm.newKpi.translations[key][_vm.selectedLang])},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.newKpi.translations[key], _vm.selectedLang, $event.target.value)}}}):_vm._e()],1)})],2):(_vm.selectedAction === 'edit'
      && _vm.selectedKpi)?_c('div',{staticClass:"row"},[_c('Dropdown',{key:`lang`,staticClass:"lang",attrs:{"options":_vm.langs,"disabled":false,"name":"lang","maxItem":_vm.langs.length,"placeholder":_vm.$t('select_lang'),"language":true},on:{"selected":_vm.selectLang}}),_vm._l((Object.keys(_vm.selectedKpi.translations).filter(key =>
        key === 'name'
        || key === 'description'
      )),function(key,i){return _c('label',{key:`kpi-${i}`,attrs:{"for":key}},[(_vm.selectedLang)?_c('p',{staticClass:"label-text",class:{ name: key === 'name' }},[_vm._v(" "+_vm._s(_vm.$t(`report_editor.${key}`))+": ")]):_vm._e(),(_vm.selectedLang
          && key === 'name')?_c('base-input',{class:`${key}-input`,attrs:{"name":key,"placeholder":_vm.selectedLang,"type":"text"},model:{value:(_vm.selectedKpi.translations[key][_vm.selectedLang]),callback:function ($$v) {_vm.$set(_vm.selectedKpi.translations[key], _vm.selectedLang, $$v)},expression:"selectedKpi.translations[key][selectedLang]"}}):(_vm.selectedLang)?_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedKpi.translations[key][_vm.selectedLang]),expression:"selectedKpi.translations[key][selectedLang]"}],class:`${key}-input`,attrs:{"name":key,"placeholder":_vm.selectedLang},domProps:{"value":(_vm.selectedKpi.translations[key][_vm.selectedLang])},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.selectedKpi.translations[key], _vm.selectedLang, $event.target.value)}}}):_vm._e()],1)})],2):_vm._e(),(_vm.selectedAction === 'add'
      || (_vm.selectedAction === 'edit'
      && _vm.selectedKpi))?_c('hr'):_vm._e(),(_vm.selectedAction === 'add')?_c('div',{staticClass:"row"},_vm._l((Object.keys(_vm.newKpi.translations).filter(key =>
        key.includes('eval')
      )),function(key,i){return _c('label',{key:`kpi-${i}`,attrs:{"for":key}},[(_vm.selectedLang)?_c('p',{staticClass:"label-text"},[_vm._v(" "+_vm._s(_vm.$t(`report_editor.${key}`))+": ")]):_vm._e(),(_vm.selectedLang)?_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.newKpi.translations[key][_vm.selectedLang]),expression:"newKpi.translations[key][selectedLang]"}],class:`${key}-input`,attrs:{"name":key,"placeholder":_vm.selectedLang},domProps:{"value":(_vm.newKpi.translations[key][_vm.selectedLang])},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.newKpi.translations[key], _vm.selectedLang, $event.target.value)}}}):_vm._e()])}),0):(_vm.selectedAction === 'edit'
      && _vm.selectedKpi)?_c('div',{staticClass:"row"},_vm._l((Object.keys(_vm.selectedKpi.translations).filter(key =>
        key.includes('eval')
      )),function(key,i){return _c('label',{key:`kpi-${i}`,attrs:{"for":key}},[(_vm.selectedLang)?_c('p',{staticClass:"label-text"},[_vm._v(" "+_vm._s(_vm.$t(`report_editor.${key}`))+": ")]):_vm._e(),(_vm.selectedLang)?_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedKpi.translations[key][_vm.selectedLang]),expression:"selectedKpi.translations[key][selectedLang]"}],class:`${key}-input`,attrs:{"name":key,"placeholder":_vm.selectedLang},domProps:{"value":(_vm.selectedKpi.translations[key][_vm.selectedLang])},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.selectedKpi.translations[key], _vm.selectedLang, $event.target.value)}}}):_vm._e()])}),0):_vm._e(),(_vm.selectedAction === 'add'
      || (_vm.selectedAction === 'edit'
      && _vm.selectedKpi))?_c('hr'):_vm._e(),(_vm.selectedAction === 'add')?_c('div',{staticClass:"row"},_vm._l((Object.keys(_vm.newKpi.translations).filter(key =>
        key.includes('adj')
      )),function(key,i){return _c('label',{key:`kpi-${i}`,attrs:{"for":key}},[(_vm.selectedLang)?_c('p',{staticClass:"label-text"},[_vm._v(" "+_vm._s(_vm.$t(`report_editor.${key}`))+": ")]):_vm._e(),(_vm.selectedLang)?_c('base-input',{class:`${key}-input`,attrs:{"name":key,"placeholder":_vm.selectedLang,"type":"text"},model:{value:(_vm.newKpi.translations[key][_vm.selectedLang]),callback:function ($$v) {_vm.$set(_vm.newKpi.translations[key], _vm.selectedLang, $$v)},expression:"newKpi.translations[key][selectedLang]"}}):_vm._e()],1)}),0):(_vm.selectedAction === 'edit'
      && _vm.selectedKpi)?_c('div',{staticClass:"row"},_vm._l((Object.keys(_vm.selectedKpi.translations).filter(key =>
        key.includes('adj')
      )),function(key,i){return _c('label',{key:`kpi-${i}`,attrs:{"for":key}},[(_vm.selectedLang)?_c('p',{staticClass:"label-text"},[_vm._v(" "+_vm._s(_vm.$t(`report_editor.${key}`))+": ")]):_vm._e(),(_vm.selectedLang)?_c('base-input',{class:`${key}-input`,attrs:{"name":key,"placeholder":_vm.selectedLang,"type":"text"},model:{value:(_vm.selectedKpi.translations[key][_vm.selectedLang]),callback:function ($$v) {_vm.$set(_vm.selectedKpi.translations[key], _vm.selectedLang, $$v)},expression:"selectedKpi.translations[key][selectedLang]"}}):_vm._e()],1)}),0):_vm._e(),(_vm.selectedAction === 'add'
      || (_vm.selectedAction === 'edit'
      && _vm.selectedKpi))?_c('hr'):_vm._e(),(_vm.selectedAction === 'add')?_c('div',{staticClass:"row"},_vm._l((Object.keys(_vm.newKpi)),function(key,i){return _c('label',{key:`kpi-${i}`,attrs:{"for":key}},[(key !== 'identifier'
          && key !== 'translations')?_c('p',{staticClass:"label-text"},[_vm._v(" "+_vm._s(_vm.$t(`report_editor.${key}`))+": ")]):_vm._e(),(key === 'excluded'
          || key === 'obsolete')?_c('base-checkbox',{class:`${key}-input`,attrs:{"name":key},model:{value:(_vm.newKpi[key]),callback:function ($$v) {_vm.$set(_vm.newKpi, key, $$v)},expression:"newKpi[key]"}}):_vm._e(),(key === 'order')?_c('base-input',{staticClass:"smaller",class:`${key}-input`,attrs:{"name":key,"type":"number"},model:{value:(_vm.newKpi[key]),callback:function ($$v) {_vm.$set(_vm.newKpi, key, _vm._n($$v))},expression:"newKpi[key]"}}):_vm._e()],1)}),0):_vm._e(),(_vm.selectedAction === 'edit'
      && _vm.selectedKpi)?_c('div',{staticClass:"row"},_vm._l((Object.keys(_vm.selectedKpi)),function(key,i){return _c('label',{key:`kpi-${i}`,attrs:{"for":key}},[(key !== 'identifier'
          && key !== 'translations'
          && key !== 'id'
          && key !== 'name')?_c('p',{staticClass:"label-text"},[_vm._v(" "+_vm._s(_vm.$t(`report_editor.${key}`))+": ")]):_vm._e(),(key === 'excluded'
          || key === 'obsolete')?_c('base-checkbox',{class:`${key}-input`,attrs:{"name":key},model:{value:(_vm.selectedKpi[key]),callback:function ($$v) {_vm.$set(_vm.selectedKpi, key, $$v)},expression:"selectedKpi[key]"}}):_vm._e(),(key === 'order')?_c('base-input',{staticClass:"smaller",class:`${key}-input`,attrs:{"name":key,"type":"number"},model:{value:(_vm.selectedKpi[key]),callback:function ($$v) {_vm.$set(_vm.selectedKpi, key, _vm._n($$v))},expression:"selectedKpi[key]"}}):_vm._e()],1)}),0):_vm._e(),(_vm.selectedAction === 'add'
      || (_vm.selectedAction === 'edit'
      && _vm.selectedKpi))?_c('hr'):_vm._e(),(_vm.selectedAction === 'add'
      || (_vm.selectedAction === 'edit'
      && _vm.selectedKpi))?_c('div',{staticClass:"row"},[(_vm.selectedAction === 'add')?_c('base-button',{on:{"click":function($event){return _vm.addKpi()}}},[_vm._v(" "+_vm._s(_vm.$t('report_editor.add_kpi'))+" ")]):_vm._e(),(_vm.selectedAction === 'edit')?_c('base-button',{on:{"click":function($event){return _vm.editKpi()}}},[_vm._v(" "+_vm._s(_vm.$t('report_editor.edit_kpi'))+" ")]):_vm._e()],1):_vm._e(),(_vm.selectedAction === 'add'
      || (_vm.selectedAction === 'edit'
      && _vm.selectedKpi))?_c('hr'):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <Manage />
    <SearchPage v-if="!$store.state.admin.selectedOption"/>
  </div>
</template>

<script>
import Manage from '../components/Manage.vue';
import SearchPage from '../components/SearchPage.vue';

export default {
  components: {
    Manage,
    SearchPage
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  min-width: 1280px;
}
</style>

<template>
  <div v-if="playcard && allLangs" class="wrapper">
    <div class="row">
      <Dropdown
        :options="allLangs"
        :language="true"
        @selected="setCurrentLang"
        :defaultValue="allLangs[0].name"
        :disabled="false"
        name="lang"
        :maxItem="allLangs.length"
        :placeholder="$t('select_lang')"
      />

      <base-button @click="$router.push('/playcards')">
        {{ $t("back") }}
      </base-button>
    </div>
    <div class="policy-options row">
      <label for="name">
        <p class="label-text">{{ $t("name") }}:</p>
        <base-input
          class="name-input"
          :placeholder="$t('name')"
          name="name"
          type="text"
          v-model="playcard.name"
        ></base-input>
      </label>

      <base-button v-if="$route.name === 'NewPlaycard'" @click="addPlayCard">
        {{ $t("add") }}
      </base-button>
      <base-button v-else @click="showConfirmation = true">
        {{ $t("edit") }}
      </base-button>
    </div>

    <hr />
    <Message
      v-if="resultMessage !== ''"
      :classes="''"
      :message="resultMessage"
      @hideResult="resultMessage = ''"
    >
    </Message>
    <Confirmation
      v-if="showConfirmation"
      :userManagement="false"
      :confirmationNeeded="true"
      :confirmMessage="confirmMessage"
      selected="add-playcard"
      @hideConfirmation="showConfirmation = false"
      @editPlayCard="editPlayCard"
    />
    <div>
      <ckeditor
        v-if="playcard && currentLang"
        ref="ckeditor"
        class="text-editor"
        v-model="playcard.translations.content[currentLang]"
        :config="editorConfig"
        @ready="onEditorReady"
      >
      </ckeditor>
    </div>
  </div>
</template>

<script>
import sharedMethods from "../mixins/sharedMethods";
import api from "../common/api";
import Dropdown from "../components/Dropdown.vue";
import Message from "../components/Message.vue";

export default {
  components: {
    Dropdown,
    Message,
  },
  mixins: [sharedMethods],
  data() {
    return {
      playcard: null,
      currentLang: "en",
      newCard: {
        name: null,
        translations: {
          content: {},
        },
      },
      showConfirmation: false,
      confirmMessage: this.$t("confirm_message.edit_card"),
    };
  },
  computed: {
    allLangs() {
      return this.$store.state.admin.langs;
    },
    editorConfig() {
      const isRtl = this.currentLang === "ar";
      return {
        extraPlugins: "justify,sourcearea,bidi,docprops",
        resize_dir: "both",
        resize_minWidth: 300,
        width: 800,
        height: 280,
        font_names: 'Noto Naskh Arabic/Noto Naskh Arabic, Arial, sans-serif;',
        entities_latin: false,
        entities_greek: false,
        contentsLangDirection: isRtl ? "rtl" : "ltr",
        contentsLanguage: isRtl ? "ar" : this.currentLang,
        language: isRtl ? "ar" : this.currentLang,
        allowedContent: true,
        defaultLanguage: isRtl ? "ar" : "en",
        contentsCSS: isRtl
          ? "body { direction: rtl; }"
          : "body { direction: ltr; }",
      };
    },
  },
  watch: {
    currentLang(newLang) {
      if (this.$refs.ckeditor && this.$refs.ckeditor.instance) {
        const isRtl = newLang === "ar";
        this.$refs.ckeditor.instance.config.contentsLangDirection = isRtl
          ? "rtl"
          : "ltr";
        this.$refs.ckeditor.instance.config.contentsLanguage = newLang;
        this.$refs.ckeditor.instance.updateElement();
      }
    },
  },
  methods: {
    onEditorReady(editor) {
      editor.on("instanceReady", () => {
        const isRtl = this.currentLang === "ar";
        editor.document
          .getBody()
          .setStyle("direction", isRtl ? "rtl" : "ltr");
      });
    },
    getplaycard(id) {
      api
        .getPlayCard(id)
        .then((res) => {
          this.playcard = res.data;
        })
        .catch((err) => {
          if (err.response !== undefined) {
            this.handleErrors(err.response);
          } else {
            this.handleErrors(err);
          }
        });
    },
    addPlayCard() {
      api
        .addPlayCard(this.newCard)
        .then(() => {
          this.setMsg(this.$t("result_message.card_added"));
        })
        .catch((err) => {
          if (err.response !== undefined) {
            this.handleErrors(err.response);
          } else {
            this.handleErrors(err);
          }
        });
    },
    editPlayCard() {
      api
        .editPlayCard(this.playcard.id, this.playcard)
        .then(() => {
          this.setMsg(this.$t("result_message.card_edited"));
        })
        .catch((err) => {
          if (err.response !== undefined) {
            this.handleErrors(err.response);
          } else {
            this.handleErrors(err);
          }
        });
    },
    setCurrentLang(lang) {
      this.currentLang = lang.code;
    },
    rerender() {
      this.render++;
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.getplaycard(this.$route.params.id);
    } else if (!this.$route.params.id && this.allLangs) {
      this.allLangs.forEach((element) => {
        this.$set(this.newCard.translations.content, `${element.code}`, null);
      });
      this.playcard = this.newCard;
    }
  },
};
</script>
<style scoped lang="scss">
.row {
  position: relative;
  display: flex;
  width: 1030px;
  margin-right: 10px;
  padding-bottom: 5px;

  &:not(:first-child) {
    margin-top: 0.8rem;
  }

  label {
    margin: 0;
    display: flex;
    align-items: center;
  }
}

.text-editor {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
}

.text-editor :deep(.cke_contents) {
  direction: inherit;
}
</style>

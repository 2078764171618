<template>
  <div v-if="moduleType && editableModule" class="report-module">
    <div class="row">
      <!-- NAME -->
      <label for="name">
        <p class="label-text">
          {{ $t(`report_editor.name`) }}:
        </p>
        <base-input
          class="name-input flex-full"
          :placeholder="$t('report_editor.name')"
          name="name"
          type="text"
          v-model="editableModule.name"
        ></base-input>
      </label>
      </div>
      <div class="row">

      <Dropdown
        class="lang"
        style="margin-bottom: 0"
        :options="allLangs"
        @selected="selectLang"
        :disabled="false"
        name="lang"
        :maxItem="allLangs.length"
        :placeholder="$t('select_lang')"
        key="lang"
        :language="true"
      />

      <label for="title">
      <p class="label-text">
        {{ $t(`report_editor.title`) }}:
      </p>
      <base-input
        v-if="selectedLang"
        class="title-input"
        :placeholder="$t('report_editor.title')"
        name="title"
        type="text"
        v-model="editableModule.translations.title[selectedLang]"
      ></base-input>
      </label>
    </div>
    <hr>

    <div class="row">
    <!-- SAVE BUTTON -->
    <base-button @click="saveModule">
      {{ $t(`report_editor.${selectedAction}_module`) }}
    </base-button>
    </div>
  </div>
</template>

<script>

import Dropdown from '../Dropdown.vue';
import '../../assets/scss/modules.scss';
import BaseButton from '../base-components/BaseButton.vue';

export default {
  components: { Dropdown, BaseButton },
  data() {
    return {
      editableModule: null,
      selectedLang: null,
      newModule: {
        name: null,
        translations: {
          title: {},
        },
      },
    };
  },
  props: {
    moduleType: {
      required: true,
    },
    module: {
      required: true,
    },
    allLangs: {
      required: true,
    },
    selectedAction: {
      required: true,
    },
  },
  methods: {
    selectLang(lang) {
      this.selectedLang = lang.code;
    },
    saveModule() {
      this.$emit('saveModule', this.moduleType, this.editableModule);
    },
    setDefaultLang() {
      this.$children.forEach((child, i) => {
        if (child.$el.className === 'dropdown lang') {
          this.$children[i].setDefault('English');
        }
      });
      this.selectedLang = 'en';
    },
  },
  beforeMount() {
    this.editableModule = JSON.parse(JSON.stringify(this.module));
  },
  mounted() {
    this.setDefaultLang();
  },
};
</script>

<template>
    <div v-if="profile" class="profile_calculator">
        <h3 v-if="selectedAction === 'edit'" class="calculator_title">{{ $t('profile-calculator.editor') }}</h3>
        <h3 v-if="selectedAction === 'add'" class="calculator_title">{{ $t('profile-calculator.creator') }}</h3>
        <div class="row">
            <label v-if="selectedAction == 'edit'" class="id" for="identifier">
                <p class="label-text">{{ $t('ID') }}: {{ `${profile.id}` }}</p>
            </label>
            <label for="name">
                <p class="label-text">{{ $t('name') }}:</p>
                <base-input class="name-input" :placeholder="$t('name')" :name="'name'" type="text"
                    v-model="profile.name"></base-input>
            </label>
            <label for="state">
                <p class="label-text">{{ $t('profile-calculator.state') }}:</p>
                <Dropdown :options="state" @selected="setState" :defaultValue="profile.state" />
            </label>
            <label for="benchmark">
                <p class="label-text">{{ $t('profile-calculator.benchmark') }}:</p>
                <Dropdown v-if="benchmark" :options="benchmark" @selected="setCurrentBenchmark"
                    :defaultValue="profile.benchmark ? profile.benchmark.name : null" :maxItem="benchmark.length"
                    :placeholder="$t('select_benchmark')" :disabled="false"
                    :key="profile.benchmark ? profile.benchmark.name : null" />
                <base-button class="delete_button" @click="deleteBenchmark">{{ $t('profile-calculator.delete') }}</base-button>
            </label>
            <label for="config">
                <p class="label-text">{{ $t('profile-calculator.config') }}:</p>
                <Dropdown class="dropdown_config" :options="formattedConfigNameList" @selected="setCurrentConfigName"
                    :defaultValue="profile.config_name" :maxItem="configList.length" :placeholder="$t('select_config_name')"
                    :disabled="false" />
            </label>
        </div>
        <hr />
        <div class="row3">
            <label v-if="allLangs" for="state">
                <p class="label-text">{{ $t('languages') }}:</p>
                <Dropdown :options="allLangs" @selected="setCurrentLang" :defaultValue="allLangs[0].name"
                    name="lang" :maxItem="allLangs.length" :placeholder="$t('select_lang')" :language="true" :key="allLangs ? allLangs.name : null"/>
            </label>
            <label for="display_name" class="display_name">
                <p class="label-text">{{ $t('profile-calculator.display_name') }}:</p>
                <textarea v-model="profile.translations.display_name[currentLang]" cols="60">
                </textarea>
            </label>
            <label for="description" class="description">
                <p class="label-text">{{ $t('profile-calculator.description') }}:</p>
                <textarea v-model="profile.translations.description[currentLang]" cols="80" rows="10">
                </textarea>
            </label>
        </div>
        <div>
            <base-button v-if="selectedAction === 'edit'" @click="editCalculator">
                {{ $t('edit') }}
            </base-button>
            <base-button v-if="selectedAction === 'add'" @click="addCalculator">
                {{ $t('add') }}
            </base-button>
            <div class="ring_massage">
                <Message v-if="responseMsg !== ''" :classes="''" :message="responseMsg" @hideResult="responseMsg = ''">
                </Message>
                <LoadingRing v-if="loading" class="pagination-wrapper"></LoadingRing>
            </div>
        </div>
    </div>
</template>
<script>
import api from '../common/api.js';
import Dropdown from '../components/Dropdown.vue';
import Message from '../components/Message.vue';
import LoadingRing from '../components/base-components/LoadingRing';
import sharedMethods from '../mixins/sharedMethods';

export default {
    components: {
        Dropdown,
        Message,
        LoadingRing,
    },
    data() {
        return {
            configList: [],
            profile: null,
            loading: false,
            selectedAction: null,
            responseMsg: '',
            state: [
                { id: 'stable', name: 'stable' },
                { id: 'obsolete', name: 'obsolete' },
            ],
            currentLang: 'en',
            benchmark_id: null,
            benchmark: null,
            selectedAction: null,
            profileSchema: {
                name: null,
                state: null,
                benchmark: {},
                translations: {
                    display_name: {},
                    description: {},
                }
            }
        };
    },
    mixins: [sharedMethods],
    computed: {
        allLangs() {
            return this.$store.state.admin.langs;
        },
        formattedConfigNameList() {
            if (this.configList) {
                return this.configList.map((config) => ({
                    displayName: `${config}`,
                }));
            }
        },
    },
    methods: {
        deleteBenchmark() {
            this.profile.benchmark = null;
            this.benchmark_id = null;
            this.$forceUpdate(); 
        },
        counterPush(editorsave) {
            const timeoutSeconds = 3;
            let countDown = timeoutSeconds;

            const message = `${this.$t(editorsave)}`;
            this.setMsg(message);

            const intervalId = setInterval(() => {
                countDown--;
                if (countDown < 0) {
                    clearInterval(intervalId);
                    this.$router.push('/profile-calculators');
                }
            }, 1000);
        },
        setMsg(msg) {
            this.responseMsg = msg;
        },
        setState(state) {
            if (!this.profile.state || this.profile.state !== state.id) {
                this.profile.state = state.id;
            }
        },
        getCalculator() {
            api
                .getCalculator(this.$route.params.profilecalculatorId)
                .then((res) => {
                    this.profile = res.data;
                    if (res.data.benchmark !== null) {
                        this.benchmark_id = res.data.benchmark.id;
                    }
                })
                .catch((err) => {
                    if (err.response !== undefined) {
                        this.handleErrors(err.response);
                    } else {
                        this.handleErrors(err);
                    }
                });
        },
        editCalculator() {
            this.profile.benchmark_id = this.benchmark_id;

            if (this.isValidCalculator(this.profile)) {
                this.loading = true;
                api
                    .editCalculator(this.$route.params.profilecalculatorId, this.profile)
                    .then(() => {
                        this.counterPush('result_message.calculator_edited');
                    })
                    .catch((err) => {
                        if (err.response !== undefined) {
                            this.handleErrors(err.response);
                        } else {
                            this.handleErrors(err);
                        }
                        this.loading = false;
                    });
            } else {
                this.setMsg(`${this.$t('result_message.data_incomplet')}`);
            }
        },
        addCalculator() {
            this.profile.benchmark_id = this.benchmark_id;

            if (this.isValidCalculator(this.profile)) {
                this.loading = true;
                api
                    .addCalculator(this.profile)
                    .then(() => {
                        this.counterPush('result_message.calculator_added');
                    })
                    .catch((err) => {
                        if (err.response !== undefined) {
                            this.handleErrors(err.response);
                        } else {
                            this.handleErrors(err);
                        }
                        this.loading = false;
                    });
            } else {
                this.setMsg(`${this.$t('result_message.data_incomplet')}`);
            }
        },
        isValidCalculator(profile) {
            const translations = profile.translations;
            const nonEmptyTranslationsExist = Object.values(translations.description).some(value => value !== null && value !== "");
            const nonEmptyDisplayNamesExist = Object.values(translations.display_name).some(value => value !== null && value !== "");

            if (profile.name && profile.state && profile.config_name && nonEmptyTranslationsExist && nonEmptyDisplayNamesExist) {
                return true;
            }
            return false;
        },
        listBenchmarks() {
            api
                .listBenchmarks()
                .then((res) => {
                    this.benchmark = res.data.items;
                })
                .catch((err) => {
                    if (err.response !== undefined) {
                        this.handleErrors(err.response);
                    } else {
                        this.handleErrors(err);
                    }
                });
        },
        listPmEvulators() {
            api
                .listPMEvulator()
                .then((res) => {
                    this.configList = res.data;
                })
                .catch((err) => {
                    if (err.response !== undefined) {
                        this.handleErrors(err.response);
                    } else {
                        this.handleErrors(err);
                    }
                });
        },
        setCurrentBenchmark(benchmark) {
            this.profile.benchmark = benchmark;
            this.benchmark_id = benchmark.id;
        },
        setCurrentLang(lang) {
            this.currentLang = lang.code;
        },
        setCurrentConfigName(config_name) {
            this.profile.config_name = config_name.displayName;
        },
    },
    mounted() {
        this.listBenchmarks();
        this.listPmEvulators();
        if (this.$route.params.profilecalculatorId) {
            this.getCalculator();
            this.selectedAction = "edit";
        }
        else {
            this.profile = this.profileSchema;
            this.selectedAction = "add";
        }
    }
}

</script>
<style lang="scss">
.profile_calculator {
    margin: 31px 30px 25px 40px !important;

    .calculator_title {
        margin-bottom: 2rem;
    }

    .row label {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .id {
        margin-right: 20px;
    }

    .delete_button {
        margin-left: -20px;
        margin-right: 30px !important;
    }

    .dropdown_config {
        .dropdown-content {
            max-height: 300px;
        }
    }

    .row3 {
        display: flex;
        align-items: flex-start;
        flex-direction: column;

        label {
            margin-bottom: 1.7rem;

            p {
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.7;
                color: #3c4866;
            }
        }

        .label-text {
            margin-bottom: 5px !important;
        }
    }

    .ring_massage {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}
</style>
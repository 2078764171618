<template>
    <div class="checkbox allow_csv allow_csv-2">
        <input type="checkbox" :id="checkboxId" :checked="selectedCheck" @change="$emit('update:selectedCheck', $event.target.checked)" />
        <label :for="checkboxId"></label>
    </div>
</template>
  
<script>
export default {
    props: {
        selectedCheck: {
            required: true,
            type: Boolean,
        },
        checkboxId: {
            required: true,
            type: String,
        },
    },
    methods: {
        handleCheckboxChange(event) {
            this.$emit('update:checked', event.target.checked);
        },
    },
};
</script>
  
<style lang="scss" scoped>
$purple: #A245FF;
$white: #FFFFFF;

.checkbox {
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    position: relative;
    width: 120px;
    height: 43px;
    border-radius: 40px;
    overflow: hidden;
    border: 1.2px solid $purple;

    input {
        appearance: none;
        position: relative;
        width: 120px;
        height: 43px;
        border-radius: 40px;
        background-color: $white;
        outline: none;
        font-family: "Open Sans", sans-serif;
        text-transform: uppercase;
        font-size: 0.9rem;
        font-weight: 600;
        letter-spacing: 0.025em;

        &:before,
        &:after {
            z-index: 2;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            color: $purple;
        }

        &:before {
            content: 'ON';
            left: 20px;
        }

        &:after {
            content: 'OFF';
            right: 20px;
        }
    }

    label {
        z-index: 1;
        position: absolute;
        bottom: 10px;
        margin: 0;
    }

    &.allow_csv-2 {
        input {
            transition: background-color 0s 0.5s;

            &:before {
                color: $white;
            }

            &:after {
                color: $purple;
            }

            &:checked {
                background-color: $purple;

                &+label {
                    background: $purple;
                    animation: turn-on 0.5s ease-out;

                    @keyframes turn-on {
                        0% {
                            left: 100%;
                        }

                        100% {
                            left: 0%;
                        }
                    }
                }
            }

            &:not(:checked) {
                background: $white;

                &+label {
                    background: $white;
                    animation: turn-off 0.5s ease-out;

                    @keyframes turn-off {
                        0% {
                            right: 100%;
                        }

                        100% {
                            right: 0%;
                        }
                    }
                }
            }
        }

        label {
            top: 0px;
            width: 120px;
            height: 43px;
            border-radius: 40px;
            max-height: 43px;
        }
    }
}
</style>

<template>
  <div class="result-table">
  <base-button @click="newPlayCard()">
    {{ $t('add-card') }}
  </base-button>
    <div class="results">
       <h3>{{$route.name}}</h3>
        <!-- pagination -->
      <div v-if="pages > 1" class="pagination-wrapper">
        <ul class="pagination justify-content-center">
          <li
            v-for="p in pages"
            :key="p"
            :class="`page-item ${p === pagination.page ? 'active' : ''}`"
          >
            <a class="page-link" @click="setPage(p)">{{ p }}</a>
          </li>
        </ul>
      </div>
      <table v-if="playCards">
        <thead>
          <tr>
            <th>{{ $t('result_table.playcards.id') }}</th>
            <th>{{ $t('result_table.playcards.name') }}</th>
            <th>
              {{ $t('result_table.playcards.aggregator_count') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="card in playCards" :key="card.id">
            <td>
              {{ card.id }}
            </td>
            <td>
              <router-link :to="`/playcard/${card.id}`">
                {{ card.name}}
              </router-link>
            </td>
            <td>
              {{ card.aggregator_count }}
            </td>
          </tr>
        </tbody>
      </table>
      <Message
        v-if="resultMessage !== ''"
        :classes="''"
        :message="resultMessage"
        @hideResult="resultMessage = ''">
      </Message>
    </div>
  </div>
</template>
<script>
import api from '../common/api';
import sharedMethods from '../mixins/sharedMethods';
import Message from '../components/Message.vue';

export default {
  components: {
    Message,
  },
  mixins: [sharedMethods],
  data() {
    return {
      playCards: [],
      pagination: {
        page: 1,
        total: 0,
        size: 100,
      },
      setPage(page) {
        if (this.pagination.page !== page) {
          this.pagination.page = page;
          this.getPlayCardList(page);
        }
      },
    };
  },
  computed: {
    pages() {
      return Math.ceil(this.pagination.total / this.pagination.size);
    },
  },
  methods: {
    newPlayCard() {
      this.$router.push('/new-playcard');
    },
    getPlayCardList(page) {
      api.listPlayCards(page, this.pagination.size)
        .then((res) => {
          this.playCardDataSize = res.data.total / 100;
          res.data.items.forEach((item) => {
            this.playCards.unshift({
              id: item.id,
              displayName: `${item.id} - ${item.name}`,
              name: item.name,
              aggregator_count: item.aggregator_count,
            });
            this.pagination.total = res.data.total;
            this.pagination.page = page;
          });
        })
        .catch((err) => {
          if (err.response !== undefined) {
            this.handleErrors(err.response);
          } else {
            this.handleErrors(err);
          }
        });
    },
  },
  mounted() {
    this.getPlayCardList(1);
  },
};
</script>

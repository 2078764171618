import Vue from "vue";
import App from "./App.vue";
import { i18n } from "./i18n";
import { router } from "./common/router";
import store from "./common/store";
import Argon from "./plugins/argon-kit";
import Confirmation from "./components/Confirmation.vue";
import axios from "axios";
import VueAxios from "vue-axios";
import { axios_no_auth } from "./common/axios-interceptor";
import dev_settings from "./common/dev-settings.json";
import CKEditor from "ckeditor4-vue";
import VTooltip from 'v-tooltip';
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false;
Vue.use(Argon);
Vue.use(VueAxios, axios);
Vue.use(CKEditor);
Vue.use(VTooltip);

// for global usage
Vue.component('Confirmation', Confirmation);

if (process.env.NODE_ENV === 'production') {
  // load /settings.json file
  axios_no_auth.get(`/settings.json?timestamp=${new Date().getTime()}`)
    .then(({ data }) => {
      // validate response
      if (!data) throw new Error('Missing settings.json.');
      if (!data.api_url) throw new Error('Missing api_url.');

      window.localStorage.setItem("settings",JSON.stringify(data));
      return new Vue({
        vuetify,
        i18n,
        router,
        store,
        render: h => h(App),
      }).$mount('#app');
    })
    .catch(err => new Vue({
      vuetify,
      i18n,
      router,
      store,
      render: h => h(MissingSettingsError, { props: { err, errMsg: "Could not download settings.json" } })
    }).$mount('#app'))
    
} else {
  window.localStorage.setItem("settings",JSON.stringify(dev_settings));
  new Vue({
    vuetify,
    i18n,
    router,
    store,
    render: h => h(App),
  }).$mount('#app')
}
<template>
    <div>
        <div v-if="aggregator">
            <div class="row2">
                <h3 class="aggregator_player_id" @click="RouteAggregator(aggregator.id)">{{ `${aggregator.name}` }}({{
                    `${aggregator.id}` }})</h3>
                <h3>| {{ $t('aggregator.candidates') }}</h3>
            </div>
            <div class="row2">
                <p class="label-text">{{ $t('aggregator.client') }}: </p>
                <p class="result aggregator_player_id" @click="RouteClient(aggregator.client.id)"> ({{
                    `${aggregator.client.id}` }}){{ `${aggregator.client.name}` }}</p>
            </div>
            <div class="row2">
                <p class="result">{{ `${aggregator.created_at}` | moment }}</p>
            </div>
            <base-button @click="showConfirmation = true" id="regenerate-all">Regenerate all reports</base-button>
            <Confirmation
                v-if="showConfirmation"
                :userManagement="false"
                :confirmationNeeded="true"
                :confirmMessage="$t('confirm_message.regenerate_all')"
                selected="general"
                @hideConfirmation="showConfirmation = false"
                @onYesClicked="regenerateAll"
            />
        <Message v-if="regenerateMessage !== ''" :classes="''" :message="regenerateMessage" @hideResult="regenerateMessage = ''">
        </Message>
        </div>
        <div class="player-category">
            <ul class="horizontal-list justify-content-center">
                <li v-for="filter in filters" :key="filter.value">
                    <a @click="setFilterSearch('completeness', filter.value)"
                        :class="{ 'active': activeFilter && activeFilter['completeness'] === filter.value, ['filter-' + filter.value]: true }">
                        {{ filter.label }}
                    </a>
                </li>
                <img @click="clearFilters" src="/img/clear-filter.png" alt="Filter Clear" class="clear_filter" />
            </ul>
        </div>
        <div v-if="pagination.total > pagination.size" class="pagination-wrapper">
            <BasePagination class="justify-content-center" :total="pagination.total" :value="pagination.page"
                :per-page="pagination.size" @input="setPage" />
        </div>
        <LoadingRing v-if="loading" class="pagination-wrapper"></LoadingRing>
        <Message v-if="resultMessage !== ''" :classes="''" :message="resultMessage" @hideResult="resultMessage = ''">
        </Message>
        <div v-else-if="sortedPlayers" class="aggregator-player">
            <div class="table-container-player">
                <table>
                    <thead>
                        <tr>
                            <th @click="setOrderBy('player_name')">{{ $t('name') }}
                                <img v-if="order_by === null || (order_by !== 'player_name' && order_by !== '-player_name')"
                                    class="sort_img" src="img/sort.png" alt="Both arrows">
                                <img v-else-if="order_by === 'player_name'" class="sort_img" src="img/up-arrow.png"
                                    alt="Up arrow">
                                <img v-else-if="order_by === '-player_name'" class="sort_img down-arrow"
                                    src="img/up-arrow.png" alt="Down arrow">
                            </th>
                            <th @click="setOrderBy('player_email')">{{ $t('email') }}
                                <img v-if="order_by === null || (order_by !== 'player_email' && order_by !== '-player_email')"
                                    class="sort_img" src="img/sort.png" alt="Both arrows">
                                <img v-else-if="order_by === 'player_email'" class="sort_img" src="img/up-arrow.png"
                                    alt="Up arrow">
                                <img v-else-if="order_by === '-player_email'" class="sort_img down-arrow"
                                    src="img/up-arrow.png" alt="Down arrow">
                            </th>
                            <th :rowspan="2">{{ $t('uuid') }}</th>
                            <th :rowspan="2">{{ $t('aggregator.completeness') }}</th>
                            <th :rowspan="2" @click="setOrderBy('start_time')">{{ $t('aggregator.start_time') }}
                                <img v-if="order_by === null || (order_by !== 'start_time' && order_by !== '-start_time')"
                                    class="sort_img" src="img/sort.png" alt="Both arrows">
                                <img v-else-if="order_by === 'start_time'" class="sort_img" src="img/up-arrow.png"
                                    alt="Up arrow">
                                <img v-else-if="order_by === '-start_time'" class="sort_img down-arrow"
                                    src="img/up-arrow.png" alt="Down arrow">
                            </th>
                        </tr>
                        <tr>
                            <th class="player_name_input">
                                <base-input :placeholder="$t('id')" v-model="playerId" ref="playerIdInput" type="text"
                                    @input="onPlayerIdInputChange"></base-input>
                            </th>
                            <th class="player_name_input">
                                <base-input :placeholder="$t('name')" v-model="playerName" ref="playerNameInput" type="text"
                                    @input="onPlayerNameInputChange"></base-input>
                            </th>
                            <th class="player_name_input">
                                <base-input :placeholder="$t('email')" v-model="playerEmail" ref="playerEmailInput"
                                    type="text" @input="onPlayerEmailInputChange"></base-input>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="player in sortedPlayers" :key="player.id"
                            @click="handleTableRowClick(player.player.uuid)"
                            :class="{ ['completeness-' + player.completeness]: true }">
                            <td>{{ player.player.id }}</td>
                            <td>{{ player.player.name }}</td>
                            <td>{{ player.player.email }}</td>
                            <td>{{ player.player.uuid }}</td>
                            <td class="player_center">{{ player.completeness }}</td>
                            <td class="player_center"
                                v-if="player.gameplay && player.gameplay[0] && player.gameplay[0].start_time">{{
                                    `${player.gameplay[0].start_time}` | moment }}</td>
                            <td class="player_center" v-else>-</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
  
<script>
import api from '../common/api.js';
import sharedMethods from '../mixins/sharedMethods';
import LoadingRing from '../components/base-components/LoadingRing';
import Filters from '../mixins/filters';
import Message from '../components/Message.vue';
import BasePagination from "../components/base-components/BasePagination";
export default {
    mixins: [sharedMethods, Filters],
    data() {
        return {
            aggregator: null,
            aggregatorPlayers: null,
            sortedPlayers: null,
            filter_by: null,
            order_by: null,
            activeFilter: null,
            filterType: null,
            playerName: '',
            playerId: '',
            playerEmail: '',
            pagination: {
                page: 1,
                total: 0,
                size: 20,
            },
            loading: false,
            searchTimer: null,
            ascendingOrder: true,
            showConfirmation: false,
            regenerateMessage: '',
        };
    },
    computed: {
        filters() {
            return [
                { label: this.$t('aggregator.filter.ok'), value: 'ok' },
                { label: this.$t('aggregator.filter.warning'), value: 'warning' },
                { label: this.$t('aggregator.filter.error'), value: 'error' },
                { label: this.$t('aggregator.filter.in_progress'), value: 'in-progress' },
                { label: this.$t('aggregator.filter.video_only'), value: 'video-only' },
                { label: this.$t('aggregator.filter.empty'), value: 'empty' },
                { label: this.$t('aggregator.filter.not_ready'), value: 'not-ready' },
            ];
        },
        pages() {
            return Math.ceil(this.pagination.total / this.pagination.size);
        },
    },
    components: {
        LoadingRing,
        Message,
        BasePagination,
    },
    methods: {
        setMsg(msg) {
            this.resultMessage = msg;
        },
        onPlayerEmailInputChange() {
            if (this.searchTimer) {
                clearTimeout(this.searchTimer);
            }
            this.searchTimer = setTimeout(() => {
                this.setFilterSearch('player_email', this.playerEmail);
            }, 1000);
        },
        onPlayerNameInputChange() {
            if (this.searchTimer) {
                clearTimeout(this.searchTimer);
            }
            this.searchTimer = setTimeout(() => {
                this.setFilterSearch('player_name', this.playerName);
            }, 1000);
        },
        onPlayerIdInputChange() {
            if (this.searchTimer) {
                clearTimeout(this.searchTimer);
            }
            this.searchTimer = setTimeout(() => {
                this.setFilterSearch('player_id', this.playerId);
            }, 1000);
        },
        setFilterSearch(filterType, filterInput) {
            if (!this.activeFilter) {
                this.activeFilter = {};
            }
            this.activeFilter[filterType] = filterInput;
            this.filter_by = this.activeFilter ? JSON.stringify(this.activeFilter) : null;
            this.getPlayers(1);
        },
        clearFilters() {
            this.activeFilter = {};
            this.playerId = '';
            this.playerName = '';
            this.playerEmail = '';
            this.filter_by = null;
            this.getPlayers(1);
        },
        setOrderBy(order_by) {
            if (this.order_by === order_by) {
                this.ascendingOrder = !this.ascendingOrder;
            } else {
                this.ascendingOrder = true;
            }
            this.order_by = this.ascendingOrder ? order_by : `-${order_by}`;
            this.getPlayers(1);
        },
        setPage(page) {
            if (this.pagination.page !== page) {
                this.pagination.page = page;
                this.getPlayers(page);
            }
        },
        handleTableRowClick(playerUuid) {
            this.$router.push(`/player/${playerUuid}`);
        },
        routeAggregator(id) {
            this.$router.push(`/aggregator/${id}`);
        },
        routeClient(id) {
            this.$router.push(`/client/${id}`);
        },
        getPlayers(page) {
            this.loading = true;
            api
                .listAssessmentsPlayers(this.aggregatorId, page, this.pagination.size, this.order_by, this.filter_by)
                .then((res) => {
                    this.aggregatorPlayers = res.data.items.map((item) => ({
                        player: item.player,
                        completeness: item.completeness,
                        gameplay: item.gameplays,
                    }));
                    this.sortedPlayers = this.aggregatorPlayers;
                    this.pagination.total = res.data.total;
                    this.pagination.page = page;
                })
                .catch((err) => {
                    if (err.response !== undefined) {
                        this.handleErrors(err.response);
                    } else {
                        this.handleErrors(err);
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        getAggregator() {
            api
                .getAggregator(this.aggregatorId)
                .then((res) => {
                    this.aggregator = res.data;
                })
                .catch((err) => {
                    if (err.response !== undefined) {
                        this.handleErrors(err.response);
                    } else {
                        this.handleErrors(err);
                    }
                });
        },
        regenerateAll() {
            api.regenerateAllReports(this.aggregatorId)
            .then(() => {
                this.regenerateMessage = 'Reports are being regenerated.';
            })
             .catch((err) => {
                if (err.response !== undefined) {
                    this.regenerateMessage = `status: ${err.response.status}, ${JSON.stringify(err.response.data.detail)}`;
                } else {
                    this.regenerateMessage = err;
                }
            });
        }
    },
    mounted() {
        if (this.$route.params.aggregatorId) {
            this.aggregatorId = this.$route.params.aggregatorId;
            this.getAggregator();
            this.getPlayers(1);
        }
    },
};
</script>
<style lang="scss">
#regenerate-all {
    margin-top: 10px;
    margin-bottom: 10px;
}

.sort_img {
    margin-left: 5px;
    height: 15px;
    width: auto;
}

.down-arrow {
    transform: rotate(180deg);
}

.player_name_input {
    padding: 16px 10px 0 10px !important;

    &:hover {
        cursor: default !important;
        background-color: transparent !important;
    }
}

.clear_filter {
    margin: auto 0;
    cursor: pointer;
    width: auto;
    max-height: 2rem;

    &:hover {
        filter: drop-shadow(0 0 0.75rem #a245ff);
    }
}

.aggregator_player_id {
    cursor: pointer;
    font-weight: 600;

    &:hover {
        color: #2b60ff;
    }
}

.player-category {
    display: flex;
    justify-content: center;
    margin: 1rem 0;
    margin-bottom: 2rem;

    .horizontal-list {
        display: flex;
        flex-wrap: wrap;
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
            margin: 10px 10px;

            &:last-child {
                margin-right: 0;
            }

            a {
                text-decoration: none;
                color: #333;
                cursor: pointer;
                padding: 5px 10px;
                border-bottom: 0.35rem solid #ff0000;

                &:hover {
                    font-weight: bold;
                }

                &.active {
                    font-weight: bold;
                }

                &.filter-ok {
                    border-color: green;
                }

                &.filter-warning {
                    border-color: #f2c80f;
                }

                &.filter-error {
                    border-color: #ed5464;
                }

                &.filter-in-progress {
                    border-color: #5f87ff;
                }

                &.filter-video-only {
                    border-color: #858796;
                }

                &.filter-empty {
                    border-color: #c4d1ff;
                }

                &.filter-not-ready {
                    border-color: #a245ff;
                }
            }
        }
    }
}

.player_center {
    text-align: center;
}

.aggregator-player {
    margin: 0;
}

.table-container-player {
    margin: 20px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    table {
        border-radius: 6px;
        overflow: hidden;

        thead {
            text-align: center;
            position: sticky;
            top: 0;
            background-color: #525f7f;
            color: white;

            tr th:hover {
                background-color: #394258;
                color: rgb(255, 255, 255);
            }

            th {
                padding: 15px;
                cursor: pointer;
            }
        }

        tbody tr td {
            padding: 10px;
            cursor: pointer;
        }

        tbody tr:nth-child(even) {
            background-color: rgb(206, 211, 219);
        }

        tbody tr {
            border-bottom: 1px solid;
            border-left: 5px solid;
        }

        tbody tr:hover {
            box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.192);
            transition: box-shadow 0.3s ease;
        }

        .completeness {
            border-left: 10px;
        }

        .completeness-ok {
            border-color: green;
        }

        .completeness-warning {
            border-color: #f2c80f;
        }

        .completeness-error {
            border-color: #ed5464;
        }

        .completeness-in-progress {
            border-color: #5f87ff;
        }

        .completeness-video-only {
            border-color: #858796;
        }

        .completeness-empty {
            border-color: #c4d1ff;
        }

        .completeness-not-ready {
            border-color: #a245ff;
        }
    }
}

@media (max-width: 1200px) {
    .player_center {
        text-align: inherit;
    }

    tbody,
    th,
    td,
    tr {
        display: block;
    }

    thead {
        display: none;
    }


    @media (max-width: 1105px) {
        .center {
            text-align: inherit;
        }

        tbody,
        th,
        td,
        tr {
            display: block;
        }

        thead {
            display: none;
        }


        .table-container-player {
            width: 90%;

            table {
                thead {
                    th {
                        padding: 15px;
                        cursor: pointer;
                    }
                }

                tbody td {
                    padding: 0;
                }
            }
        }
    }
}
</style>
import { render, staticRenderFns } from "./MemberSelector.vue?vue&type=template&id=70e3afb3&scoped=true"
import script from "./MemberSelector.vue?vue&type=script&lang=js"
export * from "./MemberSelector.vue?vue&type=script&lang=js"
import style0 from "./MemberSelector.vue?vue&type=style&index=0&id=70e3afb3&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70e3afb3",
  null
  
)

export default component.exports
<template>
  <div>
    <div class="aggregator-list">
      <table>
        <thead>
          <tr>
            <th v-for="(header, i) in headers" :key="i">
              {{ header }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" @click="setFilter('aggregator_id')"
                    ><i class="fa fa-search"></i
                  ></span>
                </div>
                <input
                  class="form-control"
                  :placeholder="$t('search')"
                  type="text"
                  v-model="filters.aggregator_id"
                />
              </div>
            </td>
            <td>
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" @click="setFilter('aggregator_name')"
                    ><i class="fa fa-search"></i
                  ></span>
                </div>
                <input
                  class="form-control"
                  :placeholder="$t('search')"
                  type="text"
                  v-model="filters.aggregator_name"
                />
              </div>
            </td>
            <td>
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" @click="setFilter('member_id')"
                    ><i class="fa fa-search"></i
                  ></span>
                </div>
                <input
                  class="form-control"
                  :placeholder="$t('search')"
                  type="text"
                  v-model="filters.member_id"
                />
              </div>
            </td>
            <td>
              <div class="input-group mb-3 calendar">
                <div class="input-group-prepend calendar">
                  <span class="input-group-text" @click="setFilter('dates')">
                    <i class="fa fa-search"></i>
                  </span>
                </div>

                <span class="calendar">
                  <base-input addon-left-icon="ni ni-calendar-grid-58">
                    <flat-picker slot-scope="{focus, blur}" @on-open="focus" @on-close="blur"
                        :config="{ allowInput: true, mode: 'range' }" class="form-control datepicker"
                        v-model="filters.dates">
                    </flat-picker>
                  </base-input>
                </span>
              </div>
            </td>
          </tr>
          <tr v-for="(obj, i) in aggregators" :key="i">
            <td :key="obj.id">{{ obj.id || '-' }}</td>
            <td>
              <router-link :to="`/aggregator/${obj.id}`">
                {{ obj.name || '-' }}
              </router-link>
            </td>
            <td>
              <span
                v-for="project in obj.members"
                :key="`${obj.id}_${obj.members.length > 0 ? project.entity.id : ''}`"
              >
                {{ project.entity.id || '-' }}
              </span>
            </td>
            <td>
              <base-button @click="openCloneModal(obj)">{{ $t('clone-aggregator') }}</base-button>
            </td>
            <td>
              <base-button @click="openAggregatorPlayers(obj.id)">{{ $t('aggregator.candidates') }}</base-button>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="aggregators.length === 0">{{ $t('no-results') }}</div>
    </div>
    <clone-aggregator
      v-if="cloneModalOpen"
      :cloneModalOpen="cloneModalOpen"
      :clients="clients"
      :selectedAggregator="selectedAggregator"
      @closeCloneModal="closeCloneModal"
      @unselectAggregstor="selectedAggregator = null"
    ></clone-aggregator>
  </div>
</template>

<script>
import CloneAggregator from './CloneAggregator.vue';
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import moment from 'moment-timezone';

export default {
  components: {
    CloneAggregator,
    flatPicker,
  },
  props: {
    aggregators: {
      required: true,
    },
    headers: {
      required: true,
    },
    clients: {
      required: true,
    },
  },
  data() {
    return {
      selectedAggregator: null,
      cloneModalOpen: false,
      filters: {
        aggregator_id: null,
        aggregator_name: null,
        member_id: null,
        dates: null,
      },
    };
  },
  methods: {
    openAggregatorPlayers(aggregatorid) {
      this.$router.push(`/aggregator/${aggregatorid}/candidates`);
    },
    closeCloneModal() {
      this.selectedAggregator = null;
      this.cloneModalOpen = false;
    },
    openCloneModal(assessment) {
      this.selectedAggregator = assessment;
      this.cloneModalOpen = true;
    },
    setFilter(type) {
      // Reset other filters
      Object.keys(this.filters).forEach((key) => {
          if (key !== type) this.filters[key] = null;
      });

      const val = this.filters[type];
      let filterParams;
      if (type == 'dates' && val !== null) {
            let [startDate, endDate] = val.split(' to ');
            if (endDate == undefined) {
              endDate = startDate;
            }

            if (startDate !== "null" && endDate !== "null") {
                const userTimezone = moment.tz.guess();
                const fromDateInUserTimezone = moment(startDate).tz(userTimezone);
                const toDateInUserTimezone = moment(endDate).tz(userTimezone);

                const activityUTC = fromDateInUserTimezone.utc().format();
                const beforeDateUTC = toDateInUserTimezone.endOf('day').utc().format();

                filterParams = `&activity_after=${activityUTC}&activity_before=${beforeDateUTC}`;
            } else {
                filterParams = null;
            }
      } else {
          filterParams = val ? `&${type}=${val}` : null;
      }

      this.$emit('setFilter', filterParams);
    },
  },
};
</script>

<style lang="scss" scoped>
.aggregator-list {
  i {
    cursor: pointer;
  }
}

.calendar {
    div {
      margin: 0 !important;
    }
  }
</style>

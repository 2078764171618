<template>
    <div v-if="profileCalculatorList" class="profile_calculator">
        <div class="title_button">
            <h3>{{ $t('profile-calculator.list') }}</h3>
            <base-button @click="setNew">
                {{ $t('add') }}
            </base-button>
        </div>
        <div v-for="(profile, i) in profileCalculatorList" :key="profile.id" class="player">
            <div class="row2">
                <h4 class="search_h3 pointer" @click="setProfile(profile.id)">{{ `${profile.name}` }}</h4>
            </div>
            <div class="row2">
                <p class="result">{{ formattedUsedText(profile.aggregator_count) }}</p>
            </div>
        </div>
    </div>
</template>
<script>
import api from '../common/api.js';

export default {
    data() {
        return {
            profileCalculatorList: null,
        };
    },
    methods: {
        formattedUsedText(aggregatorCount) {
            return this.$t('profile-calculator.used', { profileAggregatorCount: aggregatorCount });
        },
        setProfile(id) {
            this.$router.push(`/profile-calculator/${id}`);
        },
        setNew() {
            this.$router.push(`/new-profile-calculator/`);
        },
        listCalculators(page) {
            api
                .listCalculators(page)
                .then((res) => {
                    this.profileCalculatorList = res.data.items;
                })
                .catch((err) => {
                    if (err.response !== undefined) {
                        this.handleErrors(err.response);
                    } else {
                        this.handleErrors(err);
                    }
                });
        }
    },
    mounted() {
        this.listCalculators(1);
    }
}

</script>
<style lang="scss">
.profile_calculator {
    margin: 1.4rem;

    .player h4 {
        cursor: pointer;
    }

    .player h4:hover {
        color: rgb(118, 9, 128);
    }

    .title_button {
        display: flex;
        margin-bottom: 20px;

        button {
            margin-left: 20px ;
        }
    }
}
</style>
<template>
  <div class="aggregator">
    <div class="aggregator-options row">
      <base-button v-if="!selectedAction" @click="newAggregator()">
        {{ $t('add_aggregator') }}
      </base-button>

      <base-button v-if="selectedAction" @click="$router.go(-1)">
        {{ $t('back') }}
      </base-button>
    </div>

    <div
      v-if="(selectedAction === 'edit' || selectedAction === 'add') && aggregator && checked"
      class="aggregator-options row"
    >
      <div class="aggregator-row">
        <label for="name">
          <p class="label-text">{{ $t('aggregator.name') }}:</p>

          <base-input
            class="name-input"
            :placeholder="$t('name')"
            name="name"
            type="text"
            v-model="aggregator.name"
          ></base-input>
        </label>

        <label for="client">
          <p class="label-text">{{ $t('aggregator.client') }}:</p>

          <Dropdown
            :options="clients"
            @selected="selectClient"
            :defaultValue="getDefaultValue(aggregator.client, 'client')"
            :disabled="false"
             name="client"
            :maxItem="clients.length"
            :placeholder="$t('select_client')"
            :clientid="true"
          />
        </label>
      </div>

      <div class="aggregator-row">
        <label for="start_time">
          <p class="label-text">{{ $t('aggregator.start_time') }}:</p>

          <base-input
            name="start_time"
            type="datetime-local"
            step="1">
            <flat-picker
              type="datetime-local"
              :config="{ enableTime: true, allowInput: true, time_24hr: true }"
              class="form-control datepicker"
              v-model="localStartTime">
            </flat-picker>
          </base-input>
        </label>

         <label for="end_time">
          <p class="label-text">{{ $t('aggregator.end_time') }}:</p>
          <base-input
            name="end_time"
            type="datetime-local"
            step="1">
            <flat-picker slot-scope="{focus, blur}" @on-open="focus" @on-close="blur"
              type="datetime-local"
              :config="{ enableTime: true, allowInput: true, time_24hr: true }"
              class="form-control datepicker"
              v-model="localEndTime">
            </flat-picker>
          </base-input>

        </label>
        <p class="label-text">* when setting time, click into the calendar to save changes</p>
      </div>

      <div class="aggregator-row">
        <label for="privacy_policy">
          <p class="label-text">{{ $t('aggregator.privacy_policy') }}:</p>

          <Dropdown
            :options="policies"
            @selected="selectPolicy"
            :defaultValue="getDefaultValue(aggregator.privacy_policy, 'privacy_policy')"
            :disabled="false"
            name="privacy_policy"
            :maxItem="policies.length"
            :placeholder="$t('default')"
          />
        </label>

        <label for="redirection">
          <p class="label-text">{{ $t(`aggregator.redirect`) }}:</p>

          <base-input name="redirection" type="text" v-model="redirectionLink"></base-input>
        </label>
      </div>
      <div v-if="aggregator" class="aggregator-row">
      <label for="slug">
        <p class="label-text">{{ $t('add-slug') }}*:</p>
        <div class="slug " v-for="(slug, index) in aggregator.slugs" :key="'clug-' + index">
          <p class="slug-name">{{slug}}</p>
          <img class="delete-item" src="img/user/cancel.png" v-on:click="removeSlug(index)" />
        </div>
         <base-input
          class="slug-input"
          :placeholder="$t('select_slug')"
          name="slug"
          type="text"
          v-model="newSlug"
        ></base-input>
        <img
          v-if="newSlug && newSlug!=='' && !newSlug.includes(' ')"
          @click="addSlug(newSlug)"
          alt="plus-sign"
          class="add-item" src="img/user/plus.png"/>
      </label>
      <div class="client-errors">
        <p v-if="(newSlug && newSlug.includes(' ')) || slugformatError">Slug cannot contain spaces</p>
        <p v-if="!aggregator.slugs.length">* Slug is required</p>
      </div>
      </div>
    </div>

    <hr v-if="(selectedAction === 'edit' || selectedAction === 'add') && aggregator" />

    <div
      v-if="(selectedAction === 'edit' || selectedAction === 'add') && aggregator"
      class="aggregator-options row languages"
    >
      <h3>
        {{ $t('aggregator.enable_langs') }}
      </h3>
      <AggregatorLanguageSelector
        v-if="aggregator"
        :aggregator="aggregator"
        :aggregatorProps="props"
        :allLangs="langs"
        @languagesUpdated="updateLanguages"
      />
    </div>

    <hr v-if="(selectedAction === 'edit' || selectedAction === 'add') && aggregator" />

    <div
      v-if="(selectedAction === 'edit' || selectedAction === 'add') && aggregator"
      class="aggregator-options row"
    >
      <label for="report_theme" class="full-width">
        <p class="label-text">{{ $t(`aggregator.report_theme`) }}:</p>
        <base-input
          name="report_theme"
          type="text"
          v-model="aggregator.report_theme"
        ></base-input>
      </label>
      <label for="player_facing_report_layout" class="full-width">
        <p class="label-text">{{ $t(`aggregator.player_facing_report_layout`) }}:</p>
        <Dropdown
          :options="reportLayouts"
          @selected="playerFacing"
          :defaultValue="getDefaultValue(aggregator.player_facing_report_layout,
            'player_facing_report_layout')"
          :disabled="false"
          name= "player_facing_report_layout"
          :maxItem="reportLayouts.length"
          :placeholder="$t('select_layout')"
        />
      </label>
      <label for="user_facing_report_layout" class="full-width">
        <p class="label-text">{{ $t(`aggregator.user_facing_report_layout`) }}:</p>
        <Dropdown
          :options="reportLayouts"
          @selected="userFacing"
          :defaultValue="getDefaultValue(aggregator.user_facing_report_layout,
            'user_facing_report_layout')"
          :disabled="false"
          name="user_facing_report_layout"
          :maxItem="reportLayouts.length"
          :placeholder="$t('select_layout')"
        />
       </label>
       <AggregatorAdditionalLayout
        v-if="aggregatorId && aggregator"
        :assessmentId="this.aggregatorId"
        :reportLayouts="reportLayouts"
        :getDefaultValue="getDefaultValue"
        :assessmentAdditionalLayouts="aggregator.layouts"
        @layoutModifiedt="modifyLayouts"
        />
    </div>

    <hr v-if="(selectedAction === 'edit' || selectedAction === 'add') && aggregator" />

    <MemberSelector
      v-if="(selectedAction === 'edit' || selectedAction === 'add') && aggregator"
      ref="memberSelector"
      :selectedAction="selectedAction"
      :aggregator="aggregator"
      :projects="projects"
      :notMemberProjects="notMemberProjects"
      :playCards="playCards"
      :videoCards="videoCards"
      :registerCards="registerCards"
      @membersUpdated="updateMembers"
    />

    <div class="client-errors aggregator-options row" v-if="memberError">
      <p>{{memberError}}</p>
    </div>

    <hr v-if="(selectedAction === 'edit' || selectedAction === 'add') && aggregator" />

    <CalculatorSelector
      v-if="(selectedAction === 'edit' || selectedAction === 'add') && aggregator"
      ref="calculatorSelector"
      :selectedAction="selectedAction"
      :aggregator="aggregator"
      :calculators="calculators"
    />

    <hr v-if="(selectedAction === 'edit' || selectedAction === 'add') && aggregator" />

    <div
      v-if="(selectedAction === 'edit' || selectedAction === 'add') && aggregator"
      class="aggregator-options row"
    >
      <h3>
        {{ $t('aggregator.more_options') }}
      </h3>

      <label v-for="checkbox in Object.keys(checkboxes)" :for="checkbox" class="full-width">
        <p class="label-text">{{ $t(`aggregator.checkboxes.${checkbox}`) }}:</p>

        <base-checkbox
          :class="`${checkbox}-input`"
          :name="checkbox"
          v-model="checkboxes[checkbox]"
        ></base-checkbox>
      </label>
    </div>

    <hr v-if="(selectedAction === 'edit' || selectedAction === 'add') && aggregator" />

    <div
      v-if="(selectedAction === 'edit' || selectedAction === 'add') && aggregator"
      class="aggregator-options row"
    >
      <div class="full-width">
        <Dropdown
          class="lang"
          :options="langs"
          @selected="selectLang"
          :defaultValue="getDefaultLang()"
          :disabled="false"
          name="lang"
          :maxItem="langs.length"
          :placeholder="$t('select_lang')"
          :key="`lang`"
          :language="true"
        />
      </div>

      <label class="full-width" for="mail-template-user">
        <p class="label-text">{{ $t(`aggregator.mail_template_user`) }}:</p>

        <Dropdown
          :options="emailTemplates"
          @selected="setUserMailTemplate"
          :defaultValue="getDefaultUserTemplate()"
          :disabled="false"
          name="mail-template-user"
          :maxItem="emailTemplates.length"
          :placeholder="$t('aggregator.select_template')"
          :key="render"
        />
      </label>

      <label class="full-width" for="mail-template-player">
        <p class="label-text">{{ $t(`aggregator.mail_template_player`) }}:</p>

        <Dropdown
          :options="emailTemplates"
          @selected="setPlayerMailTemplate"
          :defaultValue="getDefaultPlayerTemplate()"
          :disabled="false"
          name="mail-template-player"
          :maxItem="emailTemplates.length"
          :placeholder="$t('aggregator.select_template')"
          :key="render"
        />
      </label>
    </div>
    <hr v-if="(selectedAction === 'edit' || selectedAction === 'add') && aggregator" />
    <div v-if="(selectedAction === 'edit' || selectedAction === 'add') && aggregator"
      class="aggregator-options row"
    >
      <h3>
        {{$t('custom-fields')}}
      </h3>
      <AggregatorCustomfieldSelector
        v-if="aggregator"
        :aggregator="aggregator"
        :customfields="customfields"
        @updated="updateCustomfields"
      />
    </div>
    <hr v-if="(selectedAction === 'edit' || selectedAction === 'add') && aggregator" />
    <div v-if="(selectedAction === 'edit' || selectedAction === 'add') && aggregator"
      class="aggregator-options row"
    >
      <h3>
        {{$t('aggregator.csv_settings')}}
      </h3>
    <textarea v-if="aggregator" name="csv" id="csv-settings" cols="30" rows="10" v-model="csvSettings.newValue">
    </textarea>
    </div>
    <div
      v-if="(selectedAction === 'edit' || selectedAction === 'add') && aggregator"
      class="aggregator-options row aggregator-save"
    >
      <base-button
        @click="saveAggregator"
        :disabled="memberError || customFieldError || !aggregator.slugs.length || slugformatError">
        {{ selectedAction === 'edit' ? $t('edit') : $t('add') }}
      </base-button>
      <div class="client-errors">
        <p  v-if="customFieldError">{{$t('aggregator.custom_field_export_key')}}</p>
        <p  v-if="memberError">{{memberError}}</p>
        <p  v-if="slugformatError">Slug cannot contain spaces</p>
        <p  v-if="!aggregator.slugs.length">Slug is required</p>
      </div>
      <Message
        v-if="resultMessage !== ''"
        :classes="''"
        :message="resultMessage"
        @hideResult="resultMessage = ''">
      </Message>
    </div>

    <hr v-if="(selectedAction === 'edit' || selectedAction === 'add') && aggregator" />
  </div>
</template>

<script>
import flatPicker from 'vue-flatpickr-component';
import moment from 'moment-timezone';
import Dropdown from './Dropdown.vue';
import api from '../common/api.js';
import CalculatorSelector from './CalculatorSelector.vue';
import MemberSelector from './MemberSelector.vue';
import Message from './Message.vue';
import AggregatorLanguageSelector from './AggregatorLanguageSelector.vue';
import AggregatorCustomfieldSelector from './AggregatorCustomfieldSelector.vue';
import AggregatorAdditionalLayout from './AggregatorAdditionalLayout.vue';
import sharedMethods from '../mixins/sharedMethods';
import BaseInput from './base-components/BaseInput.vue';

export default {
  components: {
    Dropdown,
    CalculatorSelector,
    MemberSelector,
    Message,
    AggregatorLanguageSelector,
    AggregatorCustomfieldSelector,
    AggregatorAdditionalLayout,
    BaseInput,
    flatPicker,
  },
  mixins: [sharedMethods],
  props: {
    langs: {
      required: true,
    },
    clients: {
      required: true,
    },
    policies: {
      required: true,
    },
    reportLayouts: {
      required: true,
    },
    projects: {
      required: true,
    },
    notMemberProjects: {
      required: true,
    },
    playCards: {
      required: true,
    },
    videoCards: {
      required: true,
    },
    registerCards: {
      required: true,
    },
    calculators: {
      required: true,
    },
    emailTemplates: {
      required: true,
    },
    customfields: {
      required: true,
    },
  },
  data() {
    return {
      newSlug: null,
      render: 0,
      errorHappened: false,
      selectedAction: null,
      selectedLang: 'en',
      aggregatorId: null,
      aggregator: null,
      checkboxes: {
        INVITE_ONLY: false,
        PLAYERS_GET_REPORT: false,
        ANONYMOUS: false,
        ANONYMOUS_ASK_EMAIL: false,
        ALLOW_EXPERIMENTAL_GAME: false,
      },
      redirectionLink: '',
      props: [],
      aggregatorScheme: {
        name: '',
        start_time: null,
        end_time: null,
        attributes: [],
        slugs: [],
        report_theme: '',
        privacy_policy: {},
        client: {},
        profile_fit_calculators: [],
        player_facing_report_layout: {},
        user_facing_report_layout: {},
        properties: [],
        members: [],
        custom_fields: [],
      },
      csvSettings: {
        initialValue: null,
        newValue: null,
      },
      layout: {
        user_facing_report_layout: {
          id: null,
          name: null
        },
        player_facing_report_layout: {
          id: null,
          name: null
        }
      },
      layouts: {},
      clientChanged: false,
      memberError: null,
      localStartTime: null,
      localEndTime: null,
      checked: false,
    };
  },
  computed: {
    customFieldError() {
      return this.aggregator.custom_fields.length > 0 ? this.aggregator.custom_fields.some((field) => !field.export_key && field.export_key === '') : false;
    },
    slugformatError() {
      return this.aggregator.slugs.some((slug) => slug.includes(' '));
    },
  },
  methods: {
    addSlug(slug) {
      this.aggregator.slugs.push(slug);
      this.newSlug = null;
    },
    removeSlug(index) {
      this.aggregator.slugs.splice(index, 1);
    },
    modifyLayouts(layouts) {
      this.aggregator.layouts = layouts;
    },
    setMsg(msg) {
      this.resultMessage = msg;
    },
    rerender() {
      this.render++;
    },
    checkProps() {
      this.props = [];
      this.aggregator.properties.forEach((property) => {
        this.props.push(property.key);
      });
    },
    checkMemberErrors() {
      let errorMsg = null;
      const { members } = this.aggregator;
      const regMemberIndex = members.findIndex((member) => member.entity_type === 'register');
      const firstVideoMemebrIdx = members.findIndex((member) => member.entity_type === 'video');
      const firstProjectMemebrIdx = members.findIndex((member) => member.entity_type === 'project');
      const firstCardIdx = members.findIndex((member) => member.entity_type === 'play_card');
      switch (true) {
        // Aggregator should contain exactly one registration member
        case regMemberIndex === -1:
          errorMsg = this.$t('aggregator.no_reg_member_error');
          break;
        // Aggregator should contain at least one project member
        case firstProjectMemebrIdx === -1:
          errorMsg = this.$t('aggregator.no_project_member_error');
          break;
        case members.filter((member) => member.entity_type === 'register').length > 1:
          errorMsg = this.$t('aggregator.multiple_reg_members_error');
          break;
        // Project or video members should not be placed before registration
        case (regMemberIndex > -1 && firstVideoMemebrIdx > -1)
          && (regMemberIndex > firstVideoMemebrIdx):
          errorMsg = this.$t('aggregator.video_before_reg_error');
          break;
        case (regMemberIndex > -1 && firstProjectMemebrIdx > -1)
          && (regMemberIndex > firstProjectMemebrIdx):
          errorMsg = this.$t('aggregator.game_before_reg_error');
          break;
        // All non-first projects should be preceded by 1 card (even if preceded by video member)
        case (firstProjectMemebrIdx > -1 && members.some((member, index) => member.entity_type === 'project'
          && (index > firstProjectMemebrIdx) && members[index - 1].entity_type !== 'play_card')):
          errorMsg = this.$t('aggregator.no_card_before_non-first_project_error');
          break;
        // The first project must be preceded by a card if there is video before the project
        // OR the first project can only be preceded by a card or registration
        case (firstProjectMemebrIdx > -1 && members[firstProjectMemebrIdx - 1].entity_type !== 'register'
          && members[firstProjectMemebrIdx - 1].entity_type !== 'play_card'):
          errorMsg = this.$t('aggregator.card_or_reg_before_first-project_rule');
          break;
        // An aggregator can only contain 1 identical game even if the levelpack is different
        case (this.checkIdenticalMembers('project')):
          errorMsg = this.$t('aggregator.same_games_error');
          break;
        // A video can only be included once in the process
        case (this.checkIdenticalMembers('video')):
          errorMsg = this.$t('aggregator.same_videos_error');
          break;
        // The same play card cannot be used twice in the same process
        case (this.checkIdenticalMembers('play_card')):
          errorMsg = this.$t('aggregator.same_cards_error');
          break;
        // Registration must be preceded by at least 1 play card
        case (firstCardIdx === -1 || (firstCardIdx > -1 && (regMemberIndex < firstCardIdx))):
          errorMsg = this.$t('aggregator.no_card_before_reg_error');
          break;
        default:
          break;
      }
      this.memberError = errorMsg;
    },
    checkIdenticalMembers(type) {
      const idList = this.aggregator.members.filter((member) => member.entity_type === type)
        .map((m) => (m.entity ? m.entity.id : m.entity_id));
      return idList.length !== new Set(idList).size;
      // TODO: check game id-s of projects
    },
    setupEditor() {
      this.checkProps();

      setTimeout(() => {
        if (this.aggregator.members.length === 0) {
          this.$refs.memberSelector.addMember();
        }

        if (this.aggregator.profile_fit_calculators.length === 0) {
          this.$refs.calculatorSelector.addCalculator();
        }
      }, 300);

      if (this.props.includes('redirection_link')) {
        this.redirectionLink = this.aggregator.properties[
          this.props.indexOf('redirection_link')
        ].value;
      }

      this.checked = true;
    },
    getDefaultLang() {
      let defaultLang = this.langs[0].name;
      this.langs.forEach((lang) => {
        if (lang.code === this.selectedLang) {
          defaultLang = lang.name;
        }
      });

      return defaultLang;
    },
    selectLang(lang) {
      this.selectedLang = lang.code;
      this.rerender();
    },
    setUserMailTemplate(selected) {
      const template = this.props.indexOf('mail-template-report-notification') >= 0
        ? this.aggregator.properties[this.props.indexOf('mail-template-report-notification')]
          .value
        : null;

      if (template) {
        template[this.selectedLang] = selected.id;
      }
    },
    getDefaultUserTemplate() {
      let template;

      if (this.props.indexOf('mail-template-report-notification') >= 0) {
        template = this.aggregator.properties[
          this.props.indexOf('mail-template-report-notification')
        ].value;
      } else {
        this.aggregator.properties.push({
          key: 'mail-template-report-notification',
          value: {},
        });
        this.props.push('mail-template-report-notification');
      }

      let userTemplate = '';

      if (template) {
        this.emailTemplates.forEach((item) => {
          if (template[this.selectedLang] === item.id) {
            userTemplate = item.name;
          }
        });
      }

      return userTemplate;
    },
    setPlayerMailTemplate(selected) {
      const template = this.props.indexOf('mail-template-report-player-notification') >= 0
        ? this.aggregator.properties[
          this.props.indexOf('mail-template-report-player-notification')
        ].value
        : null;

      if (template) {
        template[this.selectedLang] = selected.id;
      }
    },
    getDefaultPlayerTemplate() {
      let template;

      if (this.props.indexOf('mail-template-report-player-notification') >= 0) {
        template = this.aggregator.properties[
          this.props.indexOf('mail-template-report-player-notification')
        ].value;
      } else {
        this.aggregator.properties.push({
          key: 'mail-template-report-player-notification',
          value: {},
        });
        this.props.push('mail-template-report-player-notification');
      }

      let playerTemplate = '';

      if (template) {
        this.emailTemplates.forEach((item) => {
          if (template[this.selectedLang] === item.id) {
            playerTemplate = item.name;
          }
        });
      }

      return playerTemplate;
    },
    getDefaultValue(prop, key) {
      if (
        (key === 'client'
          || key === 'player_facing_report_layout'
          || key === 'user_facing_report_layout')
        && prop
        && prop.name
      ) {
        return prop.name;
      } if (key === 'privacy_policy' && prop && (prop.name || prop.id)) {
        return prop.name || `(Id: ${prop.id})`;
      }

      return '';
    },
    sendAction(action) {
      this.$emit('sendAction', action);
    },
    preCheck() {
      if (
        this.aggregator.properties.length !== 0
        && this.aggregator.properties[0].key === 'languages'
        && this.aggregator.properties[0].value
        && this.aggregator.properties[0].value.length !== 0
      ) {
        this.aggregator.properties[0].value.forEach((lang) => {
          this.selectedLanguages[lang] = true;
        });
      }

      if (this.aggregator.attributes.length !== 0) {
        this.aggregator.attributes.forEach((attr) => {
          this.checkboxes[attr] = true;
        });
      }
    },
    setLocalDates() {
      const startTime = new Date(this.aggregator.start_time);
      const startTimeOffsetInMS = new Date(startTime).getTimezoneOffset() * 60000;
      this.localStartTime = this.aggregator.start_time
        ? startTime.setTime(startTime.getTime() - startTimeOffsetInMS) : null;

      const endtTime = new Date(this.aggregator.end_time);
      const endTimeOffsetInMS = new Date(endtTime).getTimezoneOffset() * 60000;
      this.localEndTime = this.aggregator.end_time
        ? endtTime.setTime(endtTime.getTime() - endTimeOffsetInMS) : null;
    },
    selectPolicy(policy) {
      this.aggregator.privacy_policy_id = policy.id;
    },
    selectClient(client) {
      if (this.aggregator.client_id !== client.id) {
        this.clientChanged = true;
        this.aggregator.client_id = client.id;
      } else {
        this.clientChanged = false;
      }
    },
    playerFacing(layout) {
      this.aggregator.player_facing_report_layout_id = layout.id;
      this.layout.player_facing_report_layout.id = layout.id;
      this.layout.player_facing_report_layout.name = layout.name;
    },
    userFacing(layout) {
      this.aggregator.user_facing_report_layout_id = layout.id;
      this.layout.user_facing_report_layout.id = layout.id;
      this.layout.user_facing_report_layout.name = layout.name;
    },
    setAggregator(aggregatorId) {
      this.deleteValues();
      this.resultMessage = '';
      this.aggregatorId = aggregatorId;

      api
        .getAggregator(aggregatorId)
        .then((res) => {
          this.aggregator = res.data;
          const csvSettingsProp = this.aggregator.properties.filter((prop) => prop.key === 'csv-settings');
          if (csvSettingsProp.length > 0) {
            // Prettify csv settings JSON
            this.csvSettings.initialValue = JSON.stringify(csvSettingsProp[0].value, undefined, 4);
            this.csvSettings.newValue = this.csvSettings.initialValue;
          }
          const savedAggregatorData = sessionStorage.getItem('aggregator');
          if (savedAggregatorData) {
            const savedData = JSON.parse(savedAggregatorData);
            if (savedData.aggregator.id == res.data.id) {
              this.aggregator = savedData.aggregator;
              if (savedData.layout) {
                this.aggregator.player_facing_report_layout = savedData.layout.player_facing_report_layout;
                this.aggregator.user_facing_report_layout = savedData.layout.user_facing_report_layout;
              }
              if (savedData.layouts) {
                this.aggregator.layouts = savedData.layouts;
              }
            }
          }

          this.setLocalDates();
          this.preCheck();
          this.setupEditor();
          this.checkMemberErrors();
        })
        .catch((err) => {
          if (err.response !== undefined) {
            this.handleErrors(err.response);
          } else {
            this.handleErrors(err);
          }
        });
    },
    setAttributes() {
      const checkboxes = [];
      Object.entries(this.checkboxes).forEach(([key, value]) => {
        if (value) {
          checkboxes.push(key);
        }
      });
      this.aggregator.attributes = checkboxes;
    },
    setProperty(propertyName, propertyValue) {
      if (this.props.includes(propertyName)) {
        const index = this.props.indexOf(propertyName);
        if (propertyValue) {
          this.aggregator.properties[
            this.props.indexOf(propertyName)
          ].value = propertyValue;
        } else {
          // remove property if value is set empty
          this.aggregator.properties.splice(index, 1);
        }
      } else if (!this.props.includes(propertyName)) {
        if (propertyValue) {
          const newProperty = {
            key: propertyName,
            value: propertyValue,
          };

          this.aggregator.properties.push(newProperty);
        } else {
          const currentProps = this.aggregator.properties.map((prop) => prop.key);
          const index = currentProps.indexOf(propertyName);
          // remove property if value is set empty
          this.aggregator.properties.splice(index, 1);
        }
      }
    },
    createAggregator() {
      this.layouts = this.aggregator.layouts;
      const dataToStore = {
        aggregator: this.aggregator,
        layout: this.layout,
        layouts: this.layouts,
      };
      sessionStorage.setItem('aggregator', JSON.stringify(dataToStore));
      
      api
        .addAggregator(this.aggregator)
        .then((res) => {
          this.setMsg(this.$t('result_message.aggregator_added'));
          sessionStorage.removeItem('aggregator');
          setTimeout(() => this.$router.push(`/aggregator/${res.data.id}`), 2500);
        })
        .catch((err) => {
          if (err.response !== undefined) {
            this.handleErrors(err.response);
          } else {
            this.handleErrors(err);
          }

          this.checkProps();
        });
    },
    editAggregator() {
      this.layouts = this.aggregator.layouts;
      // layouts field in patch request requires a different structure
      this.aggregator.layouts = this.aggregator.layouts.map((layout) => ({
        layout_id: layout.layout.id,
        translations: layout.translations,
      }));

      this.setAttributes();
      this.errorHappened = false;

      if (this.redirectionLink || (!this.redirectionLink && this.props.includes('redirection_link'))) {
        this.setProperty('redirection_link', this.redirectionLink);
      }

      if (this.csvSettings.newValue !== this.csvSettings.initialValue) {
        try {
          const valuetoSet = this.csvSettings.newValue
            ? JSON.parse(this.csvSettings.newValue) : null;
          this.setProperty('csv-settings', valuetoSet);
        } catch (error) {
          this.handleErrors(error);
          return;
        }
      }

      this.aggregator.start_time = this.localStartTime ? moment(this.localStartTime).utc().format() : null;

      this.aggregator.end_time = this.localEndTime ? moment(this.localEndTime).utc().format() : null;
      this.aggregator.profile_fit_calculator_ids = [];
      if (this.aggregator.profile_fit_calculators[0].id) {
        this.aggregator.profile_fit_calculators.forEach((calc) => {
          if (calc.id) {
            this.aggregator.profile_fit_calculator_ids.push(calc.id);
          }
        });
      }

      this.aggregator.members.forEach((member, i) => {
        if (this.aggregator.members[i].entity) {
          this.aggregator.members[i].entity_id = this.aggregator.members[i].entity.id;
          delete this.aggregator.members[i].entity;
        }
      });

      let templateMissing = false;
      if (this.aggregator.attributes.includes('PLAYERS_GET_REPORT')) {
        templateMissing = true;

        this.aggregator.properties.forEach((prop) => {
          if (
            prop.key === 'mail-template-report-player-notification'
            || prop.key === 'mail-template-report-notification'
          ) {
            this.langs.forEach((lang) => {
              if (Object.keys(prop.value).includes(lang.code)) {
                templateMissing = false;
              }
            });
          }
        });
      }

      if (templateMissing) {
        this.setMsg(this.$t('result_message.email_template_missing'));
        return;
      }
      const dataToStore = {
        aggregator: this.aggregator,
        layout: this.layout,
        layouts: this.layouts,
      };
      sessionStorage.setItem('aggregator', JSON.stringify(dataToStore));

      api
        .editAggregator(this.aggregatorId, this.aggregator)
        .then(() => {
          this.setMsg(this.$t('result_message.aggregator_edited'));
          sessionStorage.removeItem('aggregator');
          setTimeout(() => window.location.reload(), 2500);
        })
        .catch((err) => {
          if (err.response !== undefined) {
            this.handleErrors(err.response);
          } else {
            this.handleErrors(err);
          }

          this.checkProps();
        });
    },
    updateMembers(updatedMembers) {
      this.aggregator.members = updatedMembers;
      this.checkMemberErrors();
    },
    updateLanguages(updatedLangs) {
      this.setProperty('languages', updatedLangs);
    },
    updateCustomfields(fields) {
      this.aggregator.custom_fields = fields;
    },
    moveProjects() {
      let movedProjects = 0;
      const projectMembers = this.aggregator.members.filter((member) => member.entity_type === 'project');
      projectMembers.forEach((member) => {
        if (member.entity_type === 'project') {
          const id = member.entity ? member.entity.id : member.entity_id;
          api
            .getProject(id)
            .then((res) => {
              const clientId = this.aggregator.client_id
                ? this.aggregator.client_id
                : this.aggregator.client.id;

              const projectId = res.data.id;
              const project = res.data;
              project.client_id = clientId;

              api
                .editProject(projectId, project)
                .then(() => {
                  // eslint-disable-next-line space-unary-ops
                  movedProjects ++;
                  if (projectMembers.length === movedProjects) {
                    if (!this.errorHappened) {
                      this.clientChanged = false;
                      this.saveAggregator();
                    }
                  }
                })
                .catch((err) => {
                  if (err.response !== undefined) {
                    this.handleErrors(err.response);
                  } else {
                    this.handleErrors(err);
                  }
                });
            })
            .catch((err) => {
              if (err.response !== undefined) {
                this.handleErrors(err.response);
              } else {
                this.handleErrors(err);
              }
            });
        }
      });
    },
    saveAggregator() {
      if (this.selectedAction === 'edit') {
        if (this.clientChanged) this.moveProjects();
        else this.editAggregator();
      } else if (this.selectedAction === 'add') {
        this.createAggregator();
      }
    },
    successEdit() {
      if (this.selectedAction === 'edit') {
        this.editAggregator();
      } else if (this.selectedAction === 'add') {
        this.setMsg(this.$t('result_message.aggregator_added'));
      }
    },
    deleteValues() {
      this.aggregator = null;
      this.redirectionLink = '';
      this.props = [];
      this.checkboxes = {
        INVITE_ONLY: false,
        PLAYERS_GET_REPORT: false,
        ANONYMOUS: false,
        ANONYMOUS_ASK_EMAIL: false,
        ALLOW_EXPERIMENTAL_GAME: false,
      };
      this.selectedLanguages = {
        en: false,
        hu: false,
        cs: false,
        de: false,
        fr: false,
        it: false,
        tr: false,
        es_ES: false,
        es_MX: false,
        nb_NO: false,
        pt_PT: false,
        ko: false,
        pl: false,
        ar: false,
      };
    },
    selectAction(action) {
      if (!action) {
        this.deleteValues();
        this.hideResult();
      } else if (action === 'add') {
        this.aggregator = structuredClone(this.aggregatorScheme);
        this.setupEditor();
      }

      this.selectedAction = action;
      this.sendAction(action);
    },
    newAggregator() {
      this.$router.push('/new-aggregator');
    },
  },
  mounted() {
    const settingsData = JSON.parse(localStorage.getItem("settings"));
    const allowexperimental = settingsData?.allowexperimental;
    
    if (this.$route.params.aggregatorId) {
      this.setAggregator(Number(this.$route.params.aggregatorId));
      this.selectAction('edit');
    }
    if (this.$route.name === 'NewAggregator') {
      this.selectAction('add');
      if (allowexperimental) {
        this.checkboxes.ALLOW_EXPERIMENTAL_GAME = true;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.row {
  display: flex;
  align-items: baseline;

  h3,
  .full-width {
    flex: 100%;
  }

  label {
    display: flex;
    align-items: center;
    margin-top: 8px;

    .label-text {
      font-weight: 400;
      color: #3c4866;
      margin-right: 10px;
      margin-bottom: 0;

      &.name {
        font-weight: bold;
      }
    }

    .smaller {
      width: 70px;
    }

    textarea {
      height: 70px;
      width: 250px;
      margin-top: 0;
      resize: both;
    }
  }

  textarea {
    padding: 10px 12px;
    width: 100%;
    max-width: 1000px;
  }

  &:not(:first-child) {
    margin-top: 0.8rem;
  }

  .custom-control.custom-checkbox {
    margin-right: 30px;
  }

  .form-group,
  textarea {
    margin-right: 30px;
    margin-bottom: 0;
  }

  .form-group input,
  textarea {
    height: 43px;
    border: 1px solid #cad1d7;
    border-radius: 0.25rem;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.5;
    color: #3c4866;

    &::placeholder {
      color: #8898aa;
      opacity: 0.8;
    }
  }

  .edit-aggregator {
    display: flex;
  }

  .aggregator-row {
    display: flex;
    flex: 100%;
  }
}

.selection-panel {
  .aggregator .row {
    &:not(:first-child) button {
      margin-top: 0.8rem;
    }

    button:last-child {
      margin-right: 0;
    }
  }

  hr {
    position: absolute;
    width: 100%;
    margin-top: 0.75rem;
    margin-left: 0;
  }
}

.display-name {
  display: flex;
  margin-left: 50px;
}

.results {
  margin-left: 50px;
}

.client-errors p {
  color: red;
  margin-top: 10px;
}

.aggregator-save {
  display:flex;
  flex-direction: column;
  gap: 5px;
}

.slug-input {
  margin-bottom: 0;
  margin-right: 30px;
}

.slug {
  overflow: auto;
  margin-right: 1rem !important;
  display: flex;
  align-items: center;
}

.slug-name {
  font-weight: bold;
  margin: 0;
  margin-right: 50px;
  font-size: 1rem !important;
}

.delete-item {
  cursor: pointer;
  height: 1.2rem;
  margin: 0 1rem 0 0.2rem;
}

.add-item {
  cursor: pointer;
  height: 1.5rem;
  margin-right: 2rem;
  border-radius: 50%;
  transition: box-shadow 0.3s;

  &:hover {
    box-shadow: 0 0 5px 5px rgba(75, 75, 75, 0.219);
  }
  &.disabled {
    opacity: 0.7;
  }
}

</style>

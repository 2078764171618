<template>
    <div class="result-table">
        <div class="pmcalc_results">
            <div class="report-module row">
                <base-button @click="navigateToNewPMC">{{ $t('profile-match-calculator.new') }}</base-button>
            </div>
            <h3>{{ $t('profile-match-calculator.list') }}</h3>
            <LoadingRing v-if="loading" class="pagination-wrapper"></LoadingRing>
            <div v-if="pmCalculatorList" class="grid-container">
                <div class="grid-item-group" v-for="(items, groupName) in groupedPMCalculatorList" :key="groupName">
                    <h4>{{ groupName }}</h4>
                    <div v-if="groupName === 'generator'">
                        <div v-for="(subclass, subclassIndex) in items" :key="subclassIndex">
                            <h5>{{ getClass(subclass) }}</h5>
                            <div v-for="(item, itemIndex) in subclass" :key="itemIndex" class="grid-item"
                                @click="navigateToLink(item)">
                                <p>
                                    {{ getItem(item) }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div class="grid-item" v-for="(item, itemIndex) in items" :key="itemIndex"
                            @click="navigateToLink(item)">
                            <p>
                                {{ getItem(item) }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Message v-if="resultMessage !== ''" :classes="''" :message="resultMessage"
                @hideResult="resultMessage = ''">
            </Message>
        </div>
    </div>

</template>
<script>
import api from '../common/api';
import sharedMethods from '../mixins/sharedMethods';
import Message from '../components/Message.vue';
import LoadingRing from '../components/base-components/LoadingRing';

export default {
    components: {
        Message,
        LoadingRing,
    },
    mixins: [sharedMethods],
    data() {
        return {
            loading: false,
            pmCalculatorList: null,
        };
    },
    computed: {
        groupedPMCalculatorList() {
            const grouped = {};
            this.pmCalculatorList.forEach(pmcalculator => {
                const [mainGroup, subGroup, fileName] = pmcalculator.split('/');
                if (mainGroup === "generator") {
                    if (!grouped[mainGroup]) {
                        grouped[mainGroup] = {};
                    }
                    if (!grouped[mainGroup][subGroup]) {
                        grouped[mainGroup][subGroup] = [];
                    }
                    grouped[mainGroup][subGroup].push(pmcalculator);
                } else {
                    if (!grouped[mainGroup]) {
                        grouped[mainGroup] = [];
                    }
                    grouped[mainGroup].push(pmcalculator);
                }
            });
            return grouped;
        }
    },
    methods: {
        getClass(subclass) {
            if (subclass && subclass.length > 0) {
                const parts = subclass[0].split('/');
                return parts.length > 1 ? parts[1] : null;
            }
            return null;
        },

        getItem(item) {
            const parts = item.split('/');
            for (let i = 0; i < parts.length; i++) {
                if (parts[i].endsWith('.json') || parts[i].endsWith('.pickle')) {
                    return parts[i];
                }
            }
            return null;
        },
        navigateToLink(item) {
            this.$router.push({ name: 'ProfileMatchCalculatorPage', params: { pmcalculator: item } });
        },
        navigateToNewPMC() {
            this.$router.push({ name: 'NewProfileMatchCalculatorPage' });
        },
        listPMCalculatorPickle() {
            api
                .listPMCalculatorPickle()
                .then((res) => {
                    this.pmCalculatorList.push(...res.data);
                })
                .catch((err) => {
                    if (err.response !== undefined) {
                        this.handleErrors(err.response);
                    } else {
                        this.handleErrors(err);
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        listPMCalculator() {
            this.loading = true;
            api
                .listPMCalculatorJson()
                .then((res) => {
                    this.pmCalculatorList = res.data;
                    this.listPMCalculatorPickle();
                })
                .catch((err) => {
                    if (err.response !== undefined) {
                        this.handleErrors(err.response);
                    } else {
                        this.handleErrors(err);
                    }
                });
        },
    },
    mounted() {
        this.listPMCalculator();
    },
};
</script>
<style lang="scss">
.pmcalc_results {
    padding: 0px 3px;
    text-align: center;
    font-weight: 400;
    color: #3c4866;
    margin: 1rem;

    h3 {
        margin-bottom: 2rem;
    }

    h4 {
        font-weight: 600;
        margin-bottom: 2rem;
    }

    h5 {
        margin: 1rem;
    }
}

.grid-container {
    display: inline-flex;
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr;

    .grid-item {
        padding: 8px 10px;
        margin: 2px 0;
        line-height: 2;
        border-bottom: 1px solid lightgray;
        background-color: #ffffffd0;
        transition: box-shadow 0.3s;
        cursor: pointer;

        p {
            color: #5e72e4;
            margin: 0 !important;
        }

        p:hover {
            font-weight: 400;
            color: #3d57ee;
        }
    }

    .grid-item:hover {
        box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
        background-color: #ffffffd1;
        margin: 2px;
    }
}

@media only screen and (max-width: 1210px) {
    .grid-container {
        display: inline-grid;
        grid-template-columns: 1fr;
    }
}
</style>
<template>
  <div v-if="auditlogs && user" class="result top_margin">
    <div class="pagination-wrapper">
      <p class="email"><router-link :to="`/user/${this.$route.params.userId}`">{{ `${user.email}` }}</router-link> - {{ $t('audit-log.title') }}</p>
    </div>
    <div v-if="auditlogs.length == 0" class="pagination-wrapper">
      <p class="empty">{{ $t('audit-log.empty') }}</p>
    </div>
    <div v-if="auditlogs && pagination.total > pagination.size" class="pagination-wrapper">
      <BasePagination class="justify-content-center" :total="pagination.total" :value="pagination.page"
                :per-page="pagination.size" @input="setPage" />
    </div>
    <LoadingRing v-if="loading" class="pagination-wrapper"></LoadingRing>
    <div v-if="auditlogs && auditlogs.length != 0">
      <table class="audit-log-list">
        <thead>
          <tr>
            <th>{{ $t('audit-log.timestamp') }}</th>
            <th>{{ $t('audit-log.ip-address') }}</th>
            <th>{{ $t('audit-log.message') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(auditlog, i) in auditlogs" :key="auditlog.id" class="player">
            <td>{{ `${auditlog.timestamp}` | moment }}</td>
            <td>{{ `${auditlog.ip_address}` }}</td>
            <td>{{ `${auditlog.log}` }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import Filters from '../mixins/filters';
import api from '../common/api';
import sharedMethods from '../mixins/sharedMethods';
import BasePagination from "../components/base-components/BasePagination";
import LoadingRing from '../components/base-components/LoadingRing';

export default {
  data() {
    return {
      loading: false,
      user: null,
      auditlogs: null,
      pagination: {
        page: 1,
        total: 0,
        size: 35,
      },
    };
  },
  components: {
    BasePagination,
    LoadingRing,
  },
  computed: {
    pages() {
      return Math.ceil(this.pagination.total / this.pagination.size);
    },
  },
  mixins: [Filters, sharedMethods],
  methods: {
    setPage(page) {
      if (this.pagination.page !== page) {
        this.pagination.page = page;
        this.getAuditLogs(page);
      }
    },
    getAuditLogs(page) {
      this.loading = true;
      api
        .getAuditLogs(this.$route.params.userId, page, this.pagination.size)
        .then((res) => {
          this.auditlogs = res.data.items;
          this.pagination.total = res.data.total;
          this.pagination.page = page;
        })
        .catch((err) => {
          if (err.response !== undefined) {
            this.handleErrors(err.response);
          } else {
            this.handleErrors(err);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getUser(userId) {
      api.getUser(userId)
        .then((res) => {
          this.user = res.data;
        })
        .catch((err) => {
          if (err.response !== undefined) {
            this.handleErrors(err.response);
          } else {
            this.handleErrors(err);
          }
        });
    },
  },
  mounted() {
    if (this.$route.params.userId) {
      this.getUser(this.$route.params.userId);
      this.getAuditLogs(1);
    }
  },
};
</script>
<style scoped lang="scss">
.email {
  font-size: 1.75rem;
}
.empty {
  font-size: 1.1rem;
}
.top_margin {
  margin-top: 30px;
}

.audit-log-list {
  margin: 20px auto;
  text-align: center;
  width: 80%;

  tbody tr td {
    padding: 10px;
  }
}
</style>

<template>
  <div class="result-table">
    <base-button @click="newProject()">
      {{ $t('add_project') }}
    </base-button>
    <div class="results">
      <table v-if="projects">
        <thead>
          <tr>
            <th>
              {{ $t('result_table.projects.id') }}
            </th>
            <th>
              {{ $t('result_table.projects.name') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(obj, i) in projects" :key="i">
            <td :key="obj.id">{{ obj.id || '-' }}</td>
            <td>
              <router-link :to="`/project/${obj.id}`">
                {{ obj.name || '-' }}
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import api from '../common/api';

export default {
  data() {
    return {
      projectDataSize: 0,
      projects: [],
    };
  },
  mounted() {
    if (this.projectDataSize === 0) {
      this.getProjectList(1);
    }
  },
  methods: {
    newProject() {
      this.$router.push('/new-project');
    },
    getProjectList(page) {
      api
        .listProjects(page)
        .then((res) => {
          this.projectDataSize = res.data.total / 100;
          res.data.items.forEach((item) => {
            this.projects.unshift({
              id: item.id,
              name: `${item.id} - ${item.name}`,
            });
          });
          if (page < this.projectDataSize) {
            page++;
            this.getProjectList(page);
          }
        })
        .catch((err) => {
          if (err.response !== undefined) {
            this.handleErrors(err.response);
          } else {
            this.handleErrors(err);
          }
        });
    },
  },
};
</script>

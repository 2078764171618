<template>
  <div v-if="allLangs" class="policy">
    <div class="policy-options row">
      <Dropdown
        :options="allLangs"
        @selected="setCurrentLang"
        :defaultValue="allLangs[0].name"
        :disabled="false"
        name="lang"
        :maxItem="allLangs.length"
        :language="true" 
        :placeholder="$t('select_lang')"
      />

      <base-button
        @click="$router.push('/privacy-policies')">
        {{ $t('back') }}
      </base-button>
    </div>

    <div v-if="policy" class="policy-options row">
      <Dropdown
        :options="clients"
        @selected="setPolicyClient"
        :defaultValue="policy.client && policy.client.id ? policy.client.name : null"
        :disabled="false"
        name="clients"
        :maxItem="clients.length"
        :placeholder="$t('select_client')"
      />
      <label for="name">
      <p class="label-text">{{ $t('name') }}:</p>
        <base-input
          class="name-input"
          :placeholder="$t('name')"
          type="text"
          v-model="policy.name"
        ></base-input>
      </label>

      <label for="small-change" v-if="policy.id">
        <p class="label-text">
          {{ $t('small_change') }}:
        </p>

        <base-checkbox
          name="small-change"
          v-model="smallChange"
        ></base-checkbox>
      </label>

      <base-button
        v-if="policy.id"
        @click="showConfirmation = true">
        {{ $t('edit') }}
      </base-button>

      <base-button
        v-if="$route.name === 'New Privacy Policy'"
        @click="addPolicy"
      >
        {{ $t('add') }}
      </base-button>
    </div>
    <hr />
    <Message
      v-if="resultMessage !== ''"
      :classes="''"
      :message="resultMessage"
      @hideResult="resultMessage = ''"
    >
    </Message>
    <Confirmation
      v-if="showConfirmation"
      :userManagement="false"
      :confirmationNeeded="true"
      :confirmMessage="confirmMessage"
      selected="add-policy"
      @hideConfirmation="showConfirmation = false"
      @editPolicy="editPolicy"
    />

    <ckeditor
      v-if="policy && currentLang"
      class="text-editor"
      v-model="policy.translations.content[currentLang]"
      :config="editorConfig"
    ></ckeditor>
  </div>
</template>

<script>
import api from '../common/api';
import sharedMethods from '../mixins/sharedMethods';
import Dropdown from '../components/Dropdown.vue';
import Message from '../components/Message.vue';

export default {
  components: {
    Dropdown,
    Message,
  },
  mixins: [sharedMethods],
  data() {
    return {
      clientDataSize: 0,
      clients: [],
      policy: null,
      newPolicy: {
        name: null,
        client_id: null,
        translations: {
          content: {},
        },
      },
      smallChange: false,
      policyClient: null,
      currentLang: 'en',
      editorConfig: {
        extraPlugins: 'justify',
        resize_dir: 'both',
        resize_minWidth: 300,
        width: 800,
        height: 350,
        entities_latin: false,
        entities_greek: false,
      },
      showConfirmation: false,
      confirmMessage: this.$t('confirm_message.edit_policy'),
    };
  },
  computed: {
    allLangs() {
      return this.$store.state.admin.langs;
    },
  },
  watch: {
    allLangs() {
      this.allLangs.forEach((element) => {
        this.$set(this.newPolicy.translations.content, `${element.code}`, null);
      });
      this.policy = this.newPolicy;
    },
  },
  methods: {
    setCurrentLang(lang) {
      this.currentLang = lang.code;
    },
    getPolicy(policyId) {
      api.getPolicy(policyId)
        .then((res) => {
          this.policy = res.data;
        })
        .catch((err) => {
          if (err.response !== undefined) {
            this.handleErrors(err.response);
          } else {
            this.handleErrors(err);
          }
        });
    },
    editPolicy() {
      api.editPolicy(this.smallChange, this.policy.id, this.policy)
        .then(() => {
          this.resultMessage = this.$t('result_message.policy_edited');
        })
        .catch((err) => {
          if (err.response !== undefined) {
            this.handleErrors(err.response);
          } else {
            this.handleErrors(err);
          }
        });
    },
    addPolicy() {
      api.addPolicy(this.policy)
        .then(() => {
          this.resultMessage = this.$t('result_message.policy_added');
        })
        .catch((err) => {
          if (err.response !== undefined) {
            this.handleErrors(err.response);
          } else {
            this.handleErrors(err);
          }
        });
    },
    setPolicyClient(client) {
      this.policy.client_id = client.id;
    },
    getClientList(page) {
      api
        .listClients(page, 1000)
        .then((res) => {
          this.clientDataSize = res.data.total / 100;
          res.data.items.forEach((item) => {
            this.clients.unshift(item);
          });
          if (page < this.clientDataSize) {
            page++;
            this.getClientList(page);
          }
        })
        .catch((err) => {
          if (err.response !== undefined) {
            this.handleErrors(err.response);
          } else {
            this.handleErrors(err);
          }
        });
    },
  },
  mounted() {
    if (this.clientDataSize === 0) {
      this.getClientList(1);
    }
    if (this.$route.params.id) {
      this.getPolicy(this.$route.params.id);
    } else if (!this.$route.params.id && this.allLangs) {
      this.allLangs.forEach((element) => {
        this.$set(this.newPolicy.translations.content, `${element.code}`, null);
      });
      this.policy = this.newPolicy;
    }
  },
};
</script>

<style lang="scss" scoped>
.policy {
  display: block;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.row {
  display: flex;
  align-items: baseline;

  label {
    margin: 0;
    display: flex;
    align-items: center;
    margin-top: 8px;
  }

  .edit-policy {
    display: flex;
  }
}

.text-editor {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
}
</style>

<template>
    <div class="user">
    </div>
  </template>

<script>
import Dropdown from './Dropdown.vue';
import api from '../common/api.js';
import Message from './Message.vue';

export default {
  components: {
    Dropdown,
    Message,
  },
  props: {
  },
  data() {
    return {
    };
  },
  filters: {
  },
  methods: {
  },
};
</script>

  <style lang="scss" scoped>
  $purple:#A245FF;
  $white:#FFFFFF;

  span.allow_csv {
    box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
    position: relative;
    width: 120px;
    height: 43px;
    border-radius:40px;
    overflow:hidden;
    border: 1.2px solid $purple;
    input {
      appearance: none;
      position: relative;
      width: 120px;
      height: 43px;
      border-radius:40px;
      background-color:$white;
      outline:none;
      font-family: "Open Sans", sans-serif;
      text-transform: uppercase;
      font-size: 0.9rem;
      font-weight: 600;
      letter-spacing: 0.025em;
      &:before, &:after {
        z-index:2;
        position: absolute;
        top:50%;
        transform:translateY(-50%);
        color:$purple;
      }
      &:before {
        content: 'ON';
        left:20px;
      }
      &:after {
        content: 'OFF';
        right:20px;
      }
    }
    label {
      z-index:1;
      position: absolute;
      bottom:10px;
      margin: 0;
    }
    &.allow_csv-2 {
      input {
        transition:background-color 0s .5s;
        &:before {
          color:$white;
        }
        &:after {
          color:$purple;
        }
        &:checked {
          background-color:$purple;
          &+label {
            background:$purple;
            animation: turn-on .5s ease-out;
            @keyframes turn-on {
              0% {
                left:100%;
              }
              100% {
                left:0%;
              }
            }
          }
        }
        &:not(:checked) {
          background:$white;
        &+label {
          background:$white;
          animation: turn-off .5s ease-out;
          @keyframes turn-off {
            0% {
              right:100%;
            }
            100% {
              right:0%;
            }
          }
        }
      }
    }
    label {
        top:0px;
        width: 120px;
        height: 43px;
        border-radius:40px;
        max-height: 43px;
      }
    }
  }

  .row {
    display: flex;
    align-items: baseline;

    h3,
    .full-width {
      flex: 100%;
    }

    label {
      display: flex;
      align-items: center;
      margin-top: 8px;

      .label-text {
        font-weight: 400;
        color: #3c4866;
        margin-right: 10px;
        margin-bottom: 0;

        &.name {
          font-weight: bold;
        }
      }

      .smaller {
        width: 70px;
      }

      textarea {
        height: 70px;
        width: 250px;
        margin-top: 0;
        resize: both;
      }
    }

    textarea {
      padding: 10px 12px;
    }

    &:not(:first-child) {
      margin-top: 0.8rem;
    }

    .custom-control.custom-checkbox {
      margin-right: 50px;
    }

    .form-group,
    textarea {
      margin-right: 50px;
      margin-bottom: 0;
    }

    .form-group input,
    textarea {
      height: 43px;
      border: 1px solid #cad1d7;
      border-radius: 0.25rem;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.5;
      color: #3c4866;

      &::placeholder {
        color: #8898aa;
        opacity: 0.8;
      }
    }

    .edit-client {
      display: flex;
    }

    .client-row {
      display: flex;
      flex: 100%;
    }
  }

  .result {
    font-weight: bold;
    margin: 0;
    margin-right: 50px;
  }

  .selection-panel {
    .client .row {
      &:not(:first-child) button {
        margin-top: 0.8rem;
      }

      button.logo-upload {
        margin-top: 0;
      }

      label.allow_csv {
        margin-top: 0.8rem;
        margin-right: 40px;
        margin-bottom: 0;
      }

      button.unset_logo {
        margin-right: 30px;
      }

      span.allow_csv:hover {
        box-shadow: 0 7px 14px rgba(50,50,93,.1), 0 3px 6px rgba(0,0,0,.08);;
        transform: translateY(-1px);
      }

      button:last-child {
        margin-right: 0;
      }
    }

    hr {
      position: absolute;
      width: 100%;
      margin-top: 0.75rem;
      margin-left: 0;
    }
  }

  .logo_url {
    margin-right: 30px;
  }
  </style>

/* eslint-disable no-plusplus */
import Vue from 'vue';
import Vuex from 'vuex';
import { adminState, adminActions, adminMutations } from './admin-vuex';
import { authState, authGetters, authActions, authMutations } from './auth-vuex';
import { userState, userActions, userMutations } from './user-vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  modules:{
    auth:{
      namespaced: true,
      state: authState,
      getters: authGetters,
      mutations: authMutations,
      actions: authActions
    },
    user:{
      namespaced: true,
      state: userState,
      mutations: userMutations,
      actions: userActions
    },
    admin: {
      namespaced: true,
      state: adminState,
      mutations: adminMutations,
      actions: adminActions
    }
  },
});

<!-- eslint-disable vuejs-accessibility/form-control-has-label -->
<template>
  <div class="result-table">
    <div class="results">
      <Message
        v-if="resultMessage !== ''"
        :classes="(selectedModuleType && (selectedModuleType.name === 'strength'
          || selectedModuleType.name === 'bar'|| selectedModuleType.name === 'polar'))
          || selectedOption === 'edit-report'
          || selectedOption === 'edit-aggregator' ? 'move-up' : ''
          || selectedOption === 'edit-client' ? 'move-up' : ''"
        :message="resultMessage"
        @hideResult="clearMessage">
      </Message>

      <Export
        v-if="responseData
          && responseData.length
          && exportUsers"
        :exportData="exportData"
        @exportTable="exportTable"
        @setMsg="setMsg"
      ></Export>

      <table
        v-if="selectedOption === 'add-layout'"
        class="full-size"
      >
        <thead>
          <tr>
            <th
              v-for="(header, i) in headers.layouts"
              :key="i"
            >
              {{ header }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(obj, i) in additionalLayouts"
            :key="i"
          >
            <td
              v-for="(prop, j) in obj"
              :key="j"
            >
              {{ prop }}
            </td>
          </tr>
        </tbody>
      </table>

       <table
        v-else-if="selectedOption === 'edit-report'
          && !selectedAction
          && reportLayouts"
      >
        <thead>
          <tr>
            <th
              v-for="(header, i) in headers.report_layouts"
              :key="i"
            >
              {{ header }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(obj, i) in reportLayouts"
            :key="i"
          >
            <td
              v-for="(prop, j) in obj"
              :key="j"
            >
              {{ prop || '-' }}
            </td>
          </tr>
        </tbody>
      </table>

      <table
        v-else-if="selectedOption === 'add-module'
          && reportModules[0] && showReportModuleList"
        class="full-size"
        :class="{
          'report-module': selectedModuleType,
          'hide': selectedModuleType
            && selectedModuleType.name
        }"
      >
        <thead>
          <tr>
            <th
              v-for="(header, i) in headers.modules"
              :key="i"
            >
              {{ header }}
            </th>
          </tr>
        </thead>
        <tbody
          v-for="(modules, i) in reportModules[0]"
          :key="i"
        >
          <tr
            v-for="(props, j) in modules"
            :key="j"
          >
            <td>
              {{ i }}
            </td>
            <td
              v-for="(value, k) in props"
              :key="k"
            >
              {{ value }}
            </td>
          </tr>
        </tbody>
      </table>

      <table
        v-else-if="selectedOption === 'add-kpi'
          && kpis"
        class="full-size"
        :class="{
          'hide': selectedAction === 'add'
            || selectedAction === 'edit'
            && selectedKpi
        }"
      >
        <thead>
          <tr>
            <th
              v-for="(header, i) in headers.kpis"
              :key="i"
            >
              {{ header }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(kpi, i) in filterKpi()"
            :key="i"
          >
            <td
              v-for="(value, j) in kpi"
              :key="j"
            >
              <span v-if="value === null">
                -
              </span>

              <span v-else-if="value === false">
                {{ $t('no') }}
              </span>

              <span v-else-if="value === true">
                {{ $t('yes') }}
              </span>

              <span v-else >
                {{ value }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>

      <table
        v-else-if="selectedOption === 'add-slug'"
        class="full-size"
      >
        <thead>
          <tr>
            <th
              v-for="(header, i) in headers.all_slug"
              :key="i"
            >
              {{ header }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(obj, i) in allSlug"
            :key="i"
          >
            <td
              v-for="(prop, j) in obj"
              :key="j"
            >
              {{ prop }}
            </td>
          </tr>
        </tbody>
      </table>

      <AggregatorList
          v-else-if="selectedOption === 'list-aggregators'
          && !selectedAction
          && aggregators"
          :aggregators="aggregators"
          :clients="clients"
          :headers="headers.aggregators"
          @setFilter="setFilter"
      ></AggregatorList>

      <ClientList
          v-else-if="selectedOption === 'get-client'
          && !selectedAction
          && clients"
          :clients="clients"
          :headers="headers.clients"
          @setFilter="setFilter"
      ></ClientList>

      <UserList
          v-else-if="selectedOption === 'get-user'
          && !selectedAction
          && users"
          :users="responseData"
          :clients="clients"
          :clientUsersHeaders="headers.get_user"
          :allUsersHeaders="headers.export_userlist"
          :responseData="responseData"
          :displayUsers="displayUsers"
          :userClients="userClients"
          :exportUsers="exportUsers"
          @selected="selected"
      ></UserList>

      <table
        v-else-if="selectedOption === 'edit-project'
          && !selectedAction
          && projects"
      >
        <thead>
          <tr>
            <th
              v-for="(header, i) in headers.projects"
              :key="i"
            >
              {{ header }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(obj, i) in projects"
            :key="i"
          >
            <td
              v-for="(prop, j) in obj"
              :key="j"
            >
              {{ prop || '-' }}
            </td>
          </tr>
        </tbody>
      </table>
      <!-- pagination -->
      <div class="pagination-wrapper">
        <ul class="pagination justify-content-center">
        <li
            v-for="p in pages" :key="p"
            :class="`page-item ${p === page ? 'active' : ''}`">
            <a class="page-link" @click="setPage(p)">{{p}}</a>
        </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>

import api from '../common/api';
import Export from './Export.vue';
import Message from './Message.vue';
import AggregatorList from './AggregatorList.vue';
import ClientList from './ClientList.vue';
import UserList from './UserList.vue';

export default {
  name: 'result-panel',
  components: {
    Export,
    Message,
    AggregatorList,
    ClientList,
    UserList,
  },
  props: {
    resultMessage: {
      required: true,
    },
    responseData: {
      required: true,
    },
    clientId: {
      required: true,
    },
    displayUsers: {
      required: true,
    },
    displayClient: {
      required: true,
    },
    exportUsers: {
      required: true,
    },
    additionalLayouts: {
      required: true,
    },
    allSlug: {
      required: true,
    },
    clientName: {
      required: true,
    },
    clients: {
      required: true,
    },
    reportModules: {
      required: true,
    },
    showReportModuleList: {
      required: false,
    },
    selectedModuleType: {
      required: true,
    },
    kpis: {
      required: true,
    },
    selectedAction: {
      required: true,
    },
    selectedKpi: {
      required: true,
    },
    reportLayouts: {
      required: true,
    },
    aggregators: {
      required: true,
    },
    projects: {
      required: true,
    },
    size: {
      required: true,
    },
    total: {
      required: true,
    },
    page: {
      required: true,
    },
  },
  data() {
    return {
      users: [],
      userClients: [],
      headers: this.$t('result_table'),
      exportData: [],
      cloneModalOpen: false,
      selectedAggregator: null,
    };
  },
  computed: {
    selectedOption() {
      return this.$store.state.admin.selectedOption;
    },
    pages() {
      return Math.ceil(this.total / this.size);
    },
  },
  methods: {
    setPage(page) {
      if (this.page !== page) this.$emit('setPage', page);
    },
    setFilter(filterParams) {
      // send aggregator filterparams
      this.$emit('filterData', filterParams);
    },
    handleErrors(err) {
      this.$emit('handleErrors', err);
    },
    setMsg(msg) {
      this.$emit('setMsg', msg);
    },
    job() {
      this.$emit('job');
    },
    hideResult() {
      this.$emit('hideResult', this.selectedOption);
    },
    clearMessage() {
      this.$emit('clearMsg');
    },
    selected() {
      this.$emit('selected');
    },
    filterKpi() {
      const kpis = structuredClone(this.kpis);
      kpis.forEach((kpi) => {
        delete kpi.name;
      });
      return kpis;
    },
    exportTable(table_id, separator = ';') {
      // Select rows from table_id
      const rows = document.querySelectorAll(`table#${table_id} tr`);
      // Construct csv
      const csv = [];
      for (let i = 0; i < rows.length; i++) {
        const row = [];
        const cols = rows[i].querySelectorAll('td, th');
        for (let j = 0; j < cols.length; j++) {
          // Clean innertext to remove multiple spaces and jumpline (break csv)
          let data = cols[j].innerText.replace(/(\s\s)/gm, ' ');
          // Escape double-quote with double-double-quote
          data = data.replace(/"/g, '""');
          // Push escaped string
          row.push(data);
        }
        csv.push(row);
      }
      this.exportData = csv;
    },
    selectClient(client) {
      this.clone.client = client.id;
    },
    closeCloneModal() {
      this.selectedAggregator = null;
      this.cloneModalOpen = false;
    },
    openCloneModal(assessment) {
      this.selectedAggregator = assessment;
      this.cloneModalOpen = true;
    },
  },
};
</script>

<style lang="scss">

.results {
  padding: 0 3px;
  text-align: center;
  font-weight: 400;
  color: #3C4866;

  .select-box {
    height: 20px;
    width: 20px;
    margin-bottom: 0;
    margin-right: 10px;
  }

  table:not(.inner-table) {
    display: inline-block;

    &.full-size {
      margin-top: 10px;

      &.hide {
        display: none;
      }
    }

    th {
      position: -webkit-sticky;
      position: sticky;
      top: -1px;
      background: #F8F7FA;
    }
  }

  input[type="checkbox"] {
    display: block;
  }

  table, th, td {
  border-collapse: collapse;
  border-spacing: 0;
}

  th {
    padding: 10px 20px;
    border-bottom: 1px solid lightgray;
  }

  td {
    padding: 8px 4px;
    line-height: 2;
    border-bottom: 1px solid lightgray;
  }

  .inner-table {
    width: 100%;

    td:last-child {
      border: none;
    }
  }

  table#export td {
    white-space: pre-line;
  }

  table {
    max-width: 100%;
    button, button:hover {
      color: #A245FF;
      border: 1px solid #A245FF;
      border-radius: 40px;
      max-height: 43px;
    }
  }

  // Hide textarea before ckeditor loaded
  .pagination-wrapper {
    display: flex;
    justify-content: center;
    .pagination {
      width: 80vw;
      flex-wrap: wrap;
    }
  }
  .text-editor {
    textarea {
      display: none;
    }

    div[id^=cke_editor] {
      margin-left: auto;
      margin-right: auto;
      width: 50%;
    }
  }
}
</style>

<template>
  <div class="aggregator-options row members">
    <div>
      <div v-for="(item, i) in languages" :key="`lang-${item.code}-${render}`" class="entity">
        <div class="label-text lang">{{ $t(`lang.${item.code}`, { langCode: item.code }) }}:</div>
        <div class="btns">
          <base-button class="btn-up"
            :disabled="i === 0"
            @click="moveUp(languages, i, afterReorder)">
            ^
          </base-button>

          <base-button
            class="btn-down"
            :disabled="i === languages.length - 1"
            @click="moveDown(languages, i, afterReorder)"
          >
            ^
          </base-button>
        </div>
        <base-button class="remove"
          :disabled="languages.length === 0"
          @click="removeLanguage(item, i)">
          X
        </base-button>
      </div>
      <div>
        <Dropdown
          :options="selectableLangs"
          @selected="setLang"
          :defaultValue="$t('select_lang')"
          :disabled="false"
          name="lang"
          :maxItem="selectableLangs.length"
          :placeholder="$t('aggregator.select_entity_type')"
          :language="true" 
        />
      </div>
    </div>
  </div>
</template>

<script>
import sharedMethods from '../mixins/sharedMethods';
import Dropdown from './Dropdown.vue';

export default {
  components: {
    Dropdown,
  },
  mixins: [sharedMethods],
  props: {
    aggregator: {
      required: true,
    },
    aggregatorProps: {
      required: true,
    },
    allLangs: {
      required: true,
    },
  },
  data() {
    return {
      render: 0,
      languages: [],
      selectedLanguages: {
        en: false,
        hu: false,
        cs: false,
        de: false,
        fr: false,
        it: false,
        tr: false,
        es_ES: false,
        es_MX: false,
        nb_NO: false,
        pt_PT: false,
        ko: false,
        pl: false,
        ar: false,
      },
      selectedOption: null,
    };
  },
  methods: {
    rerender() {
      this.render++;
    },
    setLang(lang) {
      this.languages.push(lang);
      this.selectedOption = lang;
      this.selectedLanguages[lang.code] = true;
      this.sendToParent();
    },
    removeLanguage(lang, i) {
      this.languages.splice(i, 1);
      this.selectedLanguages[lang.code] = false;
      this.sendToParent();
    },
    afterReorder() {
      this.rerender();
      this.sendToParent();
    },
    sendToParent() {
      const payload = JSON.parse(JSON.stringify(this.languages.map((l) => l.code)));
      this.$emit('languagesUpdated', payload.length > 0 ? payload : null);
    },
  },
  computed: {
    selectableLangs() {
      if (this.aggregator) {
        const notSelected = this.allLangs.filter((lang) => !this.selectedLanguages[lang.code]);
        return notSelected;
      }
      return this.allLangs;
    },
  },
  mounted() {
    if (this.aggregatorProps.includes('languages')) {
      const aggregatorLangs = this.aggregator.properties.find((prop) => prop.key === 'languages').value;
      if (aggregatorLangs && aggregatorLangs.length > 0) {
        aggregatorLangs.forEach((lang) => {
          this.selectedLanguages[lang] = true;
        });
        this.languages = aggregatorLangs.map((l) => this.allLangs
          .filter((lang) => lang.code === l)[0]);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.label-text {
    font-weight: 400;
    color: #3c4866;
    margin-right: 10px;
    margin-bottom: 0;
    &.lang {
      min-width: 200px;
      margin: 0.8rem, 0.8rem, 0, 0;
    }
}

.row.members {
  position: relative;
  display: flex;
  width: 1030px;
  margin-right: 10px;
  padding-bottom: 5px;

  .entity {
    display: flex;
    align-items: center;

    .btns {
      height: 43px;

      button {
        display: block;
        height: 22px;
        padding: 1px 6px;
        border-radius: 40px 40px 0 0;
        box-shadow: none;
      }

      .btn-up:disabled {
        transform: none;
      }

      .btn-down {
        margin-top: 0 !important;
        transform: rotate(180deg);

        &:hover {
          transform: rotate(180deg) translateY(-1px);

          &:disabled {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  &:not(:first-child) {
    margin-top: 0.8rem;
  }
}
.selection-panel {
  .aggregator-options.row {
    &:not(:first-child) button {
      margin-top: 0.8rem;
    }

    button:last-child {
      margin-right: 0;
    }
  }
}
</style>

<template>
    <div class="search">
        <div class="player_name">
            <div class="row2">
                <h3 class="search_h3">{{ `${name || $t('anonymus')}` }}</h3>
                <h5 class="uuid">P-{{ `${playerId}` }}</h5>
            </div>
            <div class="row2">
                <p class="result">{{ `${email || $t('anonymus')}` }}</p>
            </div>
        </div>
        <div v-if="pagination.total > pagination.size" class="pagination-wrapper">
            <ul class="pagination justify-content-center">
                <li v-for="p in pages" :key="p" :class="`page-item ${p === pagination.page ? 'active' : ''}`">
                    <a class="page-link" @click="setPage(p)">{{ p }}</a>
                </li>
            </ul>
        </div>
        <div v-for="(data, i) in data" v-if="data" :key="data.id" class="player">
            <div class="row2 player_name">
                <router-link class="aggregator-router" :to="`/aggregator/${data.aggregator.id}/candidates`">
                    {{ `${data.aggregator.name}` }} ({{ `${data.aggregator.id}` }})</router-link>
                <base-button class="raw_button"
                @click="setState(data.aggregator.id)"
                :to="`/${playerId}/state/${data.aggregator.id}`">
                    {{ $t('game.state') }}</base-button>
                <base-button v-if="data.report && data.report.raw_report_url" class="aggregator_raw_button"
                    @click="RawAggregatorPush(data.aggregator.id, playerId)">
                    {{ $t('player.agg_raw_report') }}
                </base-button>
                <p class="report_generated" v-if="data.invitations[0].gameplays.length !== 0 && !data.report">
                    {{ $t('player.report_generated') }}
                </p>
                <img class="report_state" v-if="data.report && data.report.state === 'error'" v-tooltip="data.report.error"
                    src="img/report/warning-icon-error.svg">
                <img class="report_state" v-if="data.report && data.report.state === 'not-ready'"
                    v-tooltip="$t('player.not ready')" src="img/report/warning-icon-not-ready.svg">
                <img class="report_state" v-if="data.report && data.report.state === 'warning'"
                    v-tooltip="$t('player.warning')" src="img/report/warning-icon-warning.svg">
                <div class="report-container" v-if="data.report && data.report.state !== 'error'">
                    <base-button v-on:click="toggleMenu(i)" v-if="data.report_links[0][1]" class="report_button">
                        {{ $t('player.report') }}
                    </base-button>

                    <ul class="report-menu" v-if="selectedButton === i">
                        <li v-for="j in data.report_links" :key="j.toString()">
                            <span v-if="j[0] === 'player'" v-on:click="navigateToPage(j[1])"
                                class="report_button report_link">
                                {{ $t('player.candidate_report') }}
                            </span>
                            <span v-else-if="j[0] === 'user'" v-on:click="navigateToPage(j[1])"
                                class="report_button report_link">
                                {{ $t('player.full_report') }}
                            </span>
                            <span v-else v-on:click="navigateToPage(j[1])" class="report_button report_link">
                                {{ `${j[0]}` }}
                            </span>
                        </li>
                    </ul>
                </div>
                <base-button v-if="data.report"
                    @click="prepareRegenerate(data.aggregator.id, playerId)"
                    class="report_button regenerate" type="primary" textColor="primary" outline icon="ni ni-atom">
                    {{ $t('regenarate') }}
                </base-button>
            </div>
            <div v-if="data.invitations" v-for="(inv, i) in data.invitations" :key="inv.id">
                <div class="gameplay" v-for="(gameplay, i) in inv.gameplays" :key="gameplay.id">
                    <div class="row2">
                        <router-link class="result project_id" :to="`/project/${gameplay.project.id}`">({{ `${gameplay.project.id}`
                        }})</router-link>
                        <p class="result project_id" v-tooltip="$t('copy_link')" @click="copyCode(gameplay.prefixed_uuid)">
                            {{ `${gameplay.prefixed_uuid}` }}</p>
                        <i
                            v-if="gameplay.invalid"
                            class="fa fa-ban invalid-icon result" 
                            v-tooltip="$t('invalid')"></i>
                        <base-button class="raw_button" @click="RawDataPush(gameplay.prefixed_uuid)">
                            Raw Data
                        </base-button>
                        <base-button
                            v-if="!gameplay.invalid"
                            type="warning" outline
                            class="raw_button invalidate" @click="prepareToInvalidate(gameplay.prefixed_uuid)">
                            {{$t('invalidate')}}
                        </base-button>
                    </div>
                    <div class="row2">
                        <p class="label-text">{{ $t('gameplay.start_time') }}: </p>
                        <p class="result">{{ `${gameplay.created_at}` | moment }}</p>
                    </div>
                    <div class="row2">
                        <p class="label-text">{{ $t('gameplay.end_time') }}: </p>
                        <p class="result">{{ `${gameplay.end_time}` | moment }}</p>
                        <img class="report_state" v-if="gameplay.autoclosed === true" v-tooltip="'Autoclosed'"
                            src="img/report/hourglass.png">
                    </div>
                    <div class="row2">
                        <p class="label-text">{{ $t('invitation_code') }}:</p>
                        <p class="result" v-tooltip="$t('copy_link')" @click="copyCode(inv.invitation_code)">
                            {{ `${inv.invitation_code || $t('unknown')}` }}</p>
                    </div>
                    <hr class="raw_hr" />
                </div>
                <div class="row2 gameplay" v-if="inv.created_at && !inv.gameplays.length">
                    <p class="label-text">{{ $t('player.created_at') }}:</p>
                    <p class="result">{{
                        `${inv.created_at}` | moment }}</p>
                </div>
                <div class="row2 gameplay" v-if="inv.invitation_code && !inv.gameplays.length">
                    <p class="label-text">{{ $t('invitation_code') }}:</p>
                    <p class="result" v-tooltip="$t('copy_link')" @click="copyCode(inv.invitation_code)">{{
                        `${inv.invitation_code}` }}</p>
                </div>
                <hr v-if="inv.invitation_code && !inv.gameplays.length" class="raw_hr" />
            </div>
        </div>
        <Message v-if="resultMessage !== ''" :classes="''" :message="resultMessage" @hideResult="resultMessage = ''">
        </Message>
         <modal :show.sync="showInvalidateModal" @close="closeInvalidationDialog">
            <h5 slot="header" class="modal-title" id="modal-title-default">{{$t('invalidate_modal_title')}}</h5>
            {{$t('invalidate_modal_text')}}
            <br>
            {{toInvalidate}}
            <template slot="footer">
                <base-button type="warning" class="invalidate" outline @click="invalidateGameplay"> {{$t('invalidate')}}</base-button>
                <base-button type="secondary" class="ml-auto" @click="closeInvalidationDialog">{{$t('cancel')}}
                </base-button>
            </template>
        </modal>
        <modal :show.sync="showRegenerateModal" @close="closeRegenerateModal">
            <h5 slot="header" class="modal-title" id="modal-title-default">{{$t('regenerate_modal_title')}}</h5>
            {{$t('regenerate_modal_text')}}
            <br>
            <template slot="footer">
                <base-button class="regenerate" type="primary" textColor="primary" outline
                    @click="regenerateReport">
                    {{ $t('regenarate') }}
                </base-button>
                <base-button type="secondary" class="ml-auto" @click="closeRegenerateModal">{{$t('cancel')}}
                </base-button>
            </template>
        </modal>
    </div>
</template>
<script>
import api from '../common/api';
import Filters from '../mixins/filters';
import Message from '../components/Message.vue';
import Modal from '../components/base-components/Modal.vue';

export default {
    mixins: [Filters],
    components: {
        Message,
        Modal,
    },
    data() {
        return {
            name: 'Anonymus',
            id: '',
            email: '',
            playerId: null,
            data: [],
            pagination: {
                page: 1,
                total: 0,
                size: 5,
            },
            resultMessage: '',
            showMenu: false,
            savedReportButton: null,
            selectedButton: null,
            toInvalidate: null,
            showInvalidateModal: false,
            showRegenerateModal: false,
            toRegenerate: {},
        };
    },
    computed: {
        pages() {
            return Math.ceil(this.pagination.total / this.pagination.size);
        },
    },
    methods: {
        setState(aggregatorId) {
            this.$router.push(`${this.playerId}/state/${aggregatorId}`);
        },
        toggleMenu(index) {
            if (this.selectedButton === index) {
                this.selectedButton = null;
            } else {
                this.selectedButton = index;
            }
        },
        copyCode(code) {
            navigator.clipboard.writeText(code);
        },
        navigateToPage(link) {
            window.open(link, "_blank");
        },
        RawDataPush(gameplayId) {
            this.$router.push(`/gameplay/${gameplayId}/raw-data/`);
        },
        RawAggregatorPush(aggregatorId, playerId) {
            this.$router.push(`/aggregator/${aggregatorId}/player/${playerId}/raw-report`);
        },
        setPage(page) {
            if (this.pagination.page !== page) {
                this.pagination.page = page;
                this.getPlayer(this.playerId, page, this.pagination.size);
            }
        },
        getPlayer(playerId, page, size) {
            api.getPlayer(playerId, page, size)
                .then((res) => {
                    this.data = null;
                    this.data = res.data.items;
                    if (this.data[0].player.name) {
                        this.name = this.data[0].player.name;
                    }
                    if (this.data[0].player.id) {
                        this.id = this.data[0].player.id;
                    }
                    if (this.data[0].player.email) {
                        this.email = this.data[0].player.email;
                    }
                    this.pagination.total = res.data.total;
                    this.pagination.page = page;
                    this.resultMessage = '';
                })
                .catch((err) => {
                    if (err.response !== undefined) {
                        this.resultMessage = err.response;
                    } else {
                        this.resultMessage = err;
                    }
                });
        },
        prepareToInvalidate(gameplayId) {
            this.toInvalidate = gameplayId;
            this.showInvalidateModal = true;
        },
        closeInvalidationDialog() {
            this.toInvalidate = null;
            this.showInvalidateModal = false;
        },
        invalidateGameplay() {
            api.invalidateGameplay(this.toInvalidate).then(
                this.resultMessage = 'Gameplay invalidated successfully',
                this.toInvalidate = null,
                this.showInvalidateModal = false,
            )
            .catch((err) => {
                if (err.response !== undefined) {
                    this.resultMessage = err.response;
                } else {
                    this.resultMessage = err;
                }
            });
        },
        prepareRegenerate(aggregatorId, playerId) {
            this.showRegenerateModal = true;
            this.toRegenerate.aggregatorId = aggregatorId;
            this.toRegenerate.playerId = playerId;
        },
        closeRegenerateModal() {
            this.showRegenerateModal = false,
            this.toRegenerate = {};
        },
        regenerateReport() {
            api.forceRegenerateReport(this.toRegenerate.aggregatorId, this.toRegenerate.playerId)
            .then(
                this.resultMessage = 'Report is being generated',
                 this.toRegenerate = {},
                this.showRegenerateModal = false,
            )
            .catch((err) => {
                if (err.response !== undefined) {
                    this.resultMessage = err.response;
                } else {
                    this.resultMessage = err;
                }
            });
        }
    },
    mounted() {
        if (this.$route.params.playerId) {
            this.playerId = this.$route.params.playerId;
            this.getPlayer(this.playerId, this.pagination.page, this.pagination.size);
        }
    },
};
</script>
<style lang="scss">
.aggregator_raw_button {
    left: 0;
    margin-left: 0 !important;
    margin-right: 20px !important;
    height: 27px;
    padding: 2px 10px !important;
    vertical-align: middle !important;
}

.invalidate {
    color: #fb6340!important;
    &:hover {
        color: white !important;
    }
}

.regenerate {
    &:hover {
        color: white !important;
    }
}

.invalid-icon {
    color: #fb6340!important;
}

.report_link {
    white-space: nowrap;
    font-size: 0.95rem;
    margin-left: 0 !important;
}

.report-container {
    position: relative;
}

.report-menu {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    transform: translate3d(80px, 30px, 0px);
    z-index: 2;
    color: #a245ff;
    font-weight: 500;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #a245ff;
    border-radius: .35rem;

    li {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    li:hover {
        background-color: #cfcfcf;
        cursor: pointer;

    }
}

.report_generated {
    margin-left: 20px;
    margin-bottom: 0;
    font-weight: 500;
}

.report_button {
    left: 0;
    height: 27px;
    margin-left: 80px;
    padding: 2px 20px !important;
    vertical-align: middle !important;
    z-index: 2;
}

.report_state {
    margin-left: 10px;
    margin-right: 20px;
    height: 23px;
}

.player_name {
    margin-bottom: 20px;

    ul {
        padding: 0;
    }

    li {
        list-style-type: none;
    }
}

.gameplay {
    margin-top: 3px;
    margin-left: 10px;
}

.raw_button {
    left: 0;
    margin-left: 100px !important;
    margin-right: 20px !important;
    height: 27px;
    padding: 2px 10px !important;
    vertical-align: middle !important;
}

.raw_hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.project_id {
    color: #525f7f;
    margin-right: 10px;
    font-weight: 600 !important;
    font-size: 17px;
}

.aggregator-router {
    font-size: 18px;
    color: black;
    font-weight: 600;
    text-decoration: underline;
}
</style>
  
<template>
  <div
    class="report-options row modules"
  >
    <div
      v-if="selectedAction === 'add'
        && newLayout"
    >
      <div
        v-for="(module, i) in newLayout.modules"
        :key="i"
        class="module"
      >
        <Dropdown
          :options="modules"
          @selected="saveModuleType"
          @index="selectModuleType(i)"
          :defaultValue="getDefaultModuleType(newLayout.modules[i].module_type, i)"
          :disabled="false"
          name="modules"
          :maxItem="modules.length"
          :placeholder="$t('report_editor.select_module_type')"
          :key="`moduleType-${i}-${render}`"
        />

        <Dropdown
          v-if="selectedModuleTypes[i]"
          :options="reportModules[0][selectedModuleTypes[i]]"
          @selected="setModule"
          @index="selectModule(i)"
          :defaultValue="getDefaultModule(newLayout.modules[i].module_id, i)"
          :disabled="false"
          :name="`modules-${i}`"
          :maxItem="reportModules[0][selectedModuleTypes[i]].length"
          :placeholder="$t('report_editor.select_module')"
          :key="`modules-${i}-${render}`"
        />

        <div
          class="btns"
        >
          <base-button
            class="btn-up"
            :disabled="i === 0"
            @click="decreaseOrder(i)"
          >
            {{ $t('^') }}
          </base-button>

          <base-button
            class="btn-down"
            :disabled="i === newLayout.modules.length - 1"
            @click="increaseOrder(i)"
          >
            {{ $t('^') }}
          </base-button>
        </div>

        <base-button
          class="remove"
          :disabled="newLayout.modules.length === 1"
          @click="removeModule(i)"
        >
          X
        </base-button>

        <base-button
          v-if="i === 0"
          class="add-module"
          @click.prevent="addModule()"
        >
          {{ $t('report_editor.add_module') }}
        </base-button>
      </div>
    </div>

    <div
      v-else-if="selectedAction === 'edit'
        && layout"
    >
      <div
        v-for="(module, i) in layout.modules"
        :key="i"
        class="module"
      >
        <Dropdown
          :options="modules"
          @selected="saveModuleType"
          @index="selectModuleType(i)"
          :defaultValue="getDefaultModuleType(layout.modules[i].module_type, i)"
          :disabled="false"
          name="modules"
          :maxItem="modules.length"
          :placeholder="$t('report_editor.select_module_type')"
          :key="`moduleType-${i}-${render}`"
        />

        <Dropdown
          v-if="selectedModuleTypes[i]"
          :options="reportModules[0][selectedModuleTypes[i]]"
          @selected="setModule"
          @index="selectModule(i)"
          :defaultValue="getDefaultModule(layout.modules[i].module_id, i)"
          :disabled="false"
          :name="`modules-${i}`"
          :maxItem="reportModules[0][selectedModuleTypes[i]].length"
          :placeholder="$t('report_editor.select_module')"
          :key="`modules-${i}-${render}`"
        />

        <div
          class="btns"
        >
          <base-button
            class="btn-up"
            :disabled="i === 0"
            @click="decreaseOrder(i)"
          >
            {{ $t('^') }}
          </base-button>

          <base-button
            class="btn-down"
            :disabled="i === layout.modules.length - 1"
            @click="increaseOrder(i)"
          >
            {{ $t('^') }}
          </base-button>
        </div>

        <base-button
          class="remove"
          :disabled="layout.modules.length === 1"
          @click="removeModule(i)"
        >
          X
        </base-button>

        <base-button
          v-if="i === 0"
          class="add-module"
          @click.prevent="addModule()"
        >
          {{ $t('report_editor.add_module') }}
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import Dropdown from "./Dropdown.vue";

export default {
  components: {
    Dropdown
  },
  props: {
    reportLayouts: {
      required: true
    },
    layout: {
      required: true
    },
    newLayout: {
      required: true
    },
    reportModules: {
      required: true
    },
    selectedAction: {
      required: true
    },
    moduleTypes: {
      required: true
    },
  },
  data() {
    return {
      render: 0,
      selectedModuleTypes: [],
      selectedModuleName: '',
      selectedModuleId: null,
    }
  },
  computed: {
    modules() {
      let modules = [];
      this.moduleTypes.forEach((module, i) => {
        modules.push({
          id: i,
          name: module
        });
      })
      return modules;
    },
  },
  methods: {
    rerender() {
      this.render++;
    },
    handleErrors(err) {
      this.$emit('handleErrors', err);
    },
    setModule(selected) {
      this.selectedModuleId = selected.id;
    },
    selectModule(i) {
      if (
        this.selectedAction === 'add'
        && this.newLayout
      ) {
        this.newLayout.modules[i].module_type = this.selectedModuleTypes[i];
        this.newLayout.modules[i].module_id = this.selectedModuleId;
      } else if (
        this.selectedAction === 'edit'
        && this.layout
      ) {
        this.layout.modules[i].module_type = this.selectedModuleTypes[i];
        this.layout.modules[i].module_id = this.selectedModuleId;
      }
    },
    removeModule(i) {
      if (
        this.selectedAction === 'add'
        && this.newLayout.modules.length > 1
      ) {
        this.newLayout.modules.splice(i, 1);
        this.selectedModuleTypes.splice(i, 1);
        this.newLayout.modules.forEach(module => {
          if ( module.order > i ) {
            module.order--;
          }
        })
        this.rerender();
      } else if (
        this.selectedAction === 'edit'
        && this.layout.modules.length > 1
      ) {
        this.layout.modules.splice(i, 1);
        this.selectedModuleTypes.splice(i, 1);
        this.layout.modules.forEach(module => {
          if ( module.order > i ) {
            module.order--;
          }
        })
        this.rerender();
      }
    },
    addModule() {
      if ( this.selectedAction === 'add' ) {
        let order = this.newLayout.modules.length;
        this.newLayout.modules.push({
          module_id: null,
          module_type: '',
          order: order
        })
      } else if ( this.selectedAction === 'edit' ) {
        let order = this.layout.modules.length;
        this.layout.modules.push({
          module_id: null,
          module_type: '',
          order: order
        })
      }
    },
    saveModuleType(type) {
      this.selectedModuleName = type.name;
    },
    selectModuleType(i) {
      this.selectedModuleTypes[i] = this.selectedModuleName;
      this.rerender();
    },
    getDefaultModuleType(moduleType, i) {
      if ( !this.selectedModuleName ) {
        this.selectedModuleTypes[i] = moduleType;
      }
      return this.selectedModuleTypes[i];
    },
    getDefaultModule(moduleId, i) {
      let arr = this.reportModules[0][this.selectedModuleTypes[i]].filter(obj => {
        return obj.id === moduleId;
      }).map(module => module.name);

      return arr[0] || '';
    },
    decreaseOrder(i) {
      if ( this.selectedAction === 'add' ) {
        this.newLayout.modules[i].order--;
        this.newLayout.modules[i - 1].order++;
      } else if ( this.selectedAction === 'edit' ) {
        this.layout.modules[i].order--;
        this.layout.modules[i - 1].order++;
      }

      let moduleType = this.selectedModuleTypes.splice(i, 1)[0];
      this.selectedModuleTypes.splice(i - 1, 0, moduleType);

      this.sortByOrder();
      this.rerender();
    },
    increaseOrder(i) {
      if ( this.selectedAction === 'add' ) {
        this.newLayout.modules[i].order++;
        this.newLayout.modules[i + 1].order--;
      } else if ( this.selectedAction === 'edit' ) {
        this.layout.modules[i].order++;
        this.layout.modules[i + 1].order--;
      }

      let moduleType = this.selectedModuleTypes.splice(i, 1)[0];
      this.selectedModuleTypes.splice(i + 1, 0, moduleType);

      this.sortByOrder();
      this.rerender();
    },
    sortByOrder() {
      let modules;
      if ( this.selectedAction === 'add' ) {
        modules = this.newLayout.modules;
      } else if ( this.selectedAction === 'edit' ) {
        modules = this.layout.modules;
      }
      let sortable = [];
      modules.forEach(module => {
        sortable.push([
          module.module_id,
          module.module_type,
          module.order
        ]);
      })
      sortable.sort((a, b) => {
        return a[2] - b[2];
      });
      if ( this.selectedAction === 'add' ) {
        this.newLayout.modules = [];
        sortable.forEach((module, i) => {
          this.newLayout.modules.push({
            module_id: module[0],
            module_type: module[1],
            order: module[2]
          })
        })
      } else if ( this.selectedAction === 'edit' ) {
        this.layout.modules = [];
        sortable.forEach((module, i) => {
          this.layout.modules.push({
            module_id: module[0],
            module_type: module[1],
            order: module[2]
          })
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.row.modules {
  position: relative;
  display: flex;
  width: 1030px;
  margin-right: 10px;
  padding-bottom: 5px;

  .module {
    display: flex;

    .btns {
      height: 43px;

      button {
        display: block;
        height: 22px;
        padding: 1px 6px;
        border-radius: 40px 40px 0 0;
        box-shadow: none;
      }

      .btn-up:disabled {
        transform: none;
      }

      .btn-down {
        margin-top: 0 !important;
        transform: rotate(180deg);

        &:hover {
          transform: rotate(180deg) translateY(-1px);

          &:disabled {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  label {
    margin: 0;
    display: flex;
    align-items: center;
    margin-top: 0.8rem;

    .label-text {
      font-weight: 400;
      color: #3C4866;
      margin-right: 10px;
      margin-bottom: 0;
    }

    .form-group {
      margin-top: 0;

      &.smaller {
        width: 60px;
      }
    }
  }

  &:not(:first-child) {
    margin-top: 0.8rem;
  }

  .form-group {
    margin-top: 0.8rem;
    margin-right: 30px;
    margin-bottom: 0;
  }

  .form-group input {
    height: 43px;
    border: 1px solid #CAD1D7;
    border-radius: 0.25rem;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.5;
    color: #3C4866;

    &::placeholder {
      color: #8898AA;
      opacity: 0.8;
    }
  }
}

.selection-panel {
  .report-options.row {
    &:not(:first-child) button {
      margin-top: 0.8rem;
    }

    button:last-child {
      margin-right: 0;
    }
  }
}
</style>

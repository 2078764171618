<template>
  <div
    class="selection-panel"
    :style="[
      check()
        ? { 'margin-top': '0.5em' }
        : ''
    ]"
  >

    <div
      class="client"
      :class="{
        'hide': !selected
          || projectManagement.map((p) => p.text).includes(selected)
          || layoutManagement.includes(selected)
          || translations.includes(selected)
          || selected === 'edit-aggregator'
          || selected === 'edit-client'
      }"
    >
      <div class="client-options"
        v-if="selected === 'get-user'"
      >
        <Dropdown
          :options="clients"
          @selected="showClient"
          :disabled="false"
          name="clients"
          :maxItem="clients.length"
          :placeholder="$t('select_client')"
          :key="`client-${key}`"
        />

        <base-button
        :class="{ 'clicked': displayUsers }"
        >
          {{ $t('show') }}
        </base-button>

        <base-button
          id="all-users"
          :class="{ 'clicked': exportUsers }"
          @click="showClient('export')"
        >
          {{ $t('all_users') }}
        </base-button>
      </div>
    </div>

    <div
      class="project"
      :class="{
        'hide': !selected
          || selected === 'edit-aggregator'
          || selected === 'edit-client'
          || selected === 'edit-project'
          || (!projectManagement.map((p) => p.text).includes(selected)
          && selected !== 'add-module'
          && selected !== 'add-kpi')
      }"
    >
      <div class="project-options">
        <base-button
          v-if="selected === 'add-module'"
          @click="selectAction('add')"
        >
        {{ $t('report_editor.add_module') }}
        </base-button>

        <KPI
          v-else-if="selected === 'add-kpi'"
          :key="`kpi-${key}`"
          :kpis="kpis"
          :allLang="allLang"
          :langs="langs"
          @handleErrors="handleErrors"
          @sendAction="sendAction"
          @sendKpi="sendKpi"
          @refreshKpis="$emit('refreshKpis')"
        />
      </div>
    </div>

    <div
      v-if="displayClient
        && selected
        && !projectManagement.map((p) => p.text).includes(selected)"
      class="result-table"
    >
      <div class="results">
        <table
          v-if="clientResponseData
            && clientResponseData.length
            && clientName"
          class="client-details"
        >
          <tr>
            <th
              v-for="(header, i) in headers.get_client"
              :key="i"
            >
              {{ header }}
            </th>
          </tr>
          <tr>
            <td
              v-for="(obj, j) in clientResponseData"
              :key="j"
            >
              {{ obj[Object.keys(obj)[0]] }}
            </td>
            <td
              v-if="onlyParent[1]
                && onlyParent[1].top_parent !== null"
            >
              {{ onlyParent[1].top_parent.id }}
            </td>
            <td v-else>-</td>
            <td
              v-if="onlyParent[1]
                && onlyParent[1].top_parent !== null"
            >
              {{ onlyParent[1].top_parent.name }}
            </td>
            <td v-else>-</td>
          </tr>
        </table>
      </div>
    </div>

    <div
      v-if="responseData"
      class="client users"
      :class="{
        'hide': !selected
          || projectManagement.map((p) => p.text).includes(selected)
      }"
    >
    <div class="first-row">
      <div class="user-options">
        <h4
          v-if="exportUsers"
          class="list-title"
          v-html="$t('all_users')"
        ></h4>
      </div>
    </div>
    <div class="second-row">
      <div class="user-options">
        <base-input
          class="email-input"
          placeholder="name@example.com"
          name="email"
          type="email"
          v-model="email"
        ></base-input>

        <base-button
          :disabled="!responseData
            || exportUsers"
          @click="confirm('add-user')"
        >
          {{ $t('add_user') }}
        </base-button>

        <base-button
          :disabled="!responseData
            || responseData.length === 0
            || !displayUsers
            || !activation"
          @click="confirm('re/deactivate')"
        >
          {{ $t('re/deactivate') }}
        </base-button>
        <Dropdown
          :options="dashboardLanguages"
          :disabled="false"
          name="dashboardlangs"
          @selected="selectLanguage"
          :maxItem="dashboardLanguages.length"
          :placeholder="$t('select_lang')"
          :language="true"
        />
        <base-button
         :disabled="!responseData
            || responseData.length === 0
            || !displayUsers
            || !activation
            || !selectedLang"
          @click="setUserlang">
          {{$t("set-language")}}
        </base-button>

        <base-button
          :class="{ 'clicked': allSelected }"
          :disabled="!responseData
            || responseData.length === 0
            || !displayUsers"
          @click.prevent="selectAll()"
        >
          <base-checkbox
            id="check-all"
            @input="selectAll()"
          >
            {{ $t('select_all') }}
          </base-checkbox>
        </base-button>
      </div>
    </div>
    </div>

    <ReportLayout
      v-if="selected === 'edit-report'"
      :allLang="allLang"
      :langs="langs"
      :reportLayouts="reportLayouts"
      :reportModules="reportModules"
      :moduleTypes="moduleTypes"
      @handleErrors="handleErrors"
      @setMsg="setMsg"
      @hideResult="hideResult"
      @sendLang="sendLang"
      @sendAction="sendAction"
      @confirm="confirm"
      @sendLayoutId="sendLayoutId"
    />

    <Aggregator
      v-if="selected === 'edit-aggregator' || selected === 'list-aggregators'"
      :langs="langs"
      :aggregators="aggregators"
      :clients="clients"
      :policies="policies"
      :reportLayouts="reportLayouts"
      :projects="projects"
      :notMemberProjects="notMemberProjects"
      :playCards="playCards"
      :videoCards="videoCards"
      :calculators="calculators"
      :emailTemplates="emailTemplates"
      @handleErrors="handleErrors"
      @sendAction="sendAction"
      @setMsg="setMsg"
      @hideResult="hideResult"
    />

    <Client
      v-if="selected === 'edit-client' || selected === 'get-client'"
      :clients="clients"
      @handleErrors="handleErrors"
      @sendAction="sendAction"
      @setMsg="setMsg"
      @hideResult="hideResult"
    />

    <User
      v-if="selected === 'edit-user' || selected === 'get-user'"
    ></User>

    <Project
      v-if="selected === 'edit-project'"
      ref="project"
      :projects="projects"
      :clients="clients"
      :games="games"
      @handleErrors="handleErrors"
      @sendAction="sendAction"
      @setMsg="setMsg"
      @hideResult="hideResult"
      @confirm="confirm"
      @hideConfirmation="hideConfirmation"
    />

    <hr
      v-if="clientName
        && responseData"
      :class="{
        'hide': !selected
          || projectManagement.map((p) => p.text).includes(selected)
      }"
    >

    <Confirmation
      v-if="showConfirmation"
      :userManagement="userManagement"
      :confirmationNeeded="confirmationNeeded"
      :confirmMessage="confirmMessage"
      :selected="selected"
      @hideConfirmation="hideConfirmation"
      @disable="disable"
      @addUser="addUser"
      @editPolicy="editPolicy"
      @editProject="editProject"
    />
  </div>
</template>

<script>
import Dropdown from './Dropdown.vue';
import Confirmation from './Confirmation.vue';
import KPI from './KPI.vue';
import ReportLayout from './ReportLayout.vue';
import Aggregator from './Aggregator.vue';
import Project from './Project.vue';
import Client from './Client.vue';
import User from './User.vue';
import api from '../common/api';

export default {
  components: {
    Dropdown,
    Confirmation,
    KPI,
    ReportLayout,
    Aggregator,
    Project,
    Client,
    User,
  },
  props: {
    parents: {
      required: true,
    },
    responseData: {
      required: true,
    },
    clientResponseData: {
      required: true,
    },
    selectedOption: {
      required: true,
    },
    allSelected: {
      required: true,
    },
    userManagement: {
      required: true,
    },
    projectManagement: {
      required: true,
    },
    layoutManagement: {
      required: true,
    },
    translations: {
      required: true,
    },
    reportLayouts: {
      required: true,
    },
    assessments: {
      required: true,
    },
    clients: {
      required: true,
    },
    displayUsers: {
      required: true,
    },
    displayClient: {
      required: true,
    },
    exportUsers: {
      required: true,
    },
    clientName: {
      required: true,
    },
    moduleTypes: {
      required: true,
    },
    reportModules: {
      required: true,
    },
    kpis: {
      required: true,
    },
    activation: {
      required: true,
    },
    videoCards: {
      required: true,
    },
    aggregators: {
      required: true,
    },
    projects: {
      required: true,
    },
    notMemberProjects: {
      required: true,
    },
    calculators: {
      required: true,
    },
    emailTemplates: {
      required: true,
    },
    games: {
      required: true,
    },
    users: {
      required: true,
    },
  },
  data() {
    return {
      langs: [],
      allLang: {
        en: '',
        fr: '',
        hu: '',
        cs: '',
        de: '',
        it: '',
        tr: '',
        es_ES: '',
        es_MX: '',
        nb_NO: '',
        pt_PT: '',
        ko: '',
        pl: '',
        ar: '',
      },
      selectedLang: null,
      choices: [],
      managements: [
        'user_manage',
        'project_manage',
        'report_manage',
        'translations',
      ],
      selected: null,
      headers: this.$t('result_table'),
      email: '',
      confirmMessage: '',
      showConfirmation: false,
      confirmationNeeded: null,
      key: 0,
    };
  },
  computed: {
    onlyParent() {
      return this.clientResponseData.splice(4, 2);
    },
    dashboardLanguages() {
      const langs = ['en', 'hu', 'de', 'fr'];
      return langs.map((lang) => (
        {
          name: this.$t(`lang.${lang}`, { langCode: lang }),
          code: lang,
        }));
    },
  },
  methods: {
    setMsg(msg) {
      this.$emit('setMsg', msg);
    },
    hideConfirmation() {
      this.showConfirmation = false;
    },
    sendAction(action) {
      this.$emit('sendAction', action);
    },
    sendLang(lang) {
      this.$emit('getLang', lang);
    },
    sendKpi(kpi) {
      this.$emit('sendKpi', kpi);
    },
    handleErrors(err) {
      this.$emit('handleErrors', err);
    },
    hideResult(selected) {
      this.$emit('hideResult', selected);
    },
    setClientName(clientName) {
      this.$emit('setClientName', clientName);
    },
    sendAssessmentId(assessment) {
      this.$emit('getAssessmentId', assessment.prefixed_id);
    },
    sendLayoutId(layout) {
      this.$emit('getLayoutId', layout.id, layout.name);
    },
    selectLanguage(lang) {
      this.selectedLang = lang.code;
    },
    async showClient(selected) {
      if (selected === 'export') {
        this.showTable('export');
        return;
      }
      this.showConfirmation = false;
      this.setClientName(selected.name);
      await this.$emit('job', 'get-client');
      this.$emit('job', 'get-user');
    },
    check() {
      this.selected = this.selectedOption;
      return this.selected;
    },
    containes(i) {
      for (const choice in this.choices[i]) {
        if (this.choices[i][choice].option === this.selected) {
          return true;
        }
      }
      return false;
    },
    selectAll() {
      const checkbox = document.querySelector('#check-all input');
      checkbox.checked = !checkbox.checked;
      this.$emit('selectAll');
    },
    confirm(job) {
      this.confirmationNeeded = {
        deactivate: [],
        reactivate: [],
      };
      switch (job) {
        case 're/deactivate': {
          this.selectBoxes = document.querySelectorAll('.select-box');
          this.selectBoxes.forEach((box, i) => {
            if (
              box.checked
              && this.responseData[i].disabled
            ) {
              const userId = this.responseData[i].id;
              this.confirmationNeeded.reactivate.push(userId);
            } else if (
              box.checked
              && !this.responseData[i].disabled
            ) {
              const userId = this.responseData[i].id;
              this.confirmationNeeded.deactivate.push(userId);
            }
          });
          if (
            this.confirmationNeeded.reactivate.length
            && this.confirmationNeeded.deactivate.length
          ) {
            this.confirmMessage = this.$t('confirm_message.re/deactivate', { reactivateIds: this.confirmationNeeded.reactivate, deactivateIds: this.confirmationNeeded.deactivate });
          } else if (
            this.confirmationNeeded.reactivate.length
            && !this.confirmationNeeded.deactivate.length
          ) {
            this.confirmMessage = this.$t('confirm_message.reactivate', { reactivateIds: this.confirmationNeeded.reactivate });
          } else if (
            this.confirmationNeeded.deactivate.length
            && !this.confirmationNeeded.reactivate.length
          ) {
            this.confirmMessage = this.$t('confirm_message.deactivate', { deactivateIds: this.confirmationNeeded.deactivate });
          }
          this.showConfirmation = false;
          if (
            this.confirmationNeeded.deactivate.length
            || this.confirmationNeeded.reactivate.length
          ) {
            this.showConfirmation = true;
          }
          break;
        }
        case 'add-user': {
          this.showConfirmation = false;
          this.confirmMessage = this.$t('confirm_message.add_user', { user: this.email, client: this.clientName });
          this.showConfirmation = true;
          break;
        }
        case 'edit-policy': {
          this.showConfirmation = false;
          this.confirmMessage = this.$t('confirm_message.edit_policy');
          this.showConfirmation = true;
          break;
        }
        case 'edit-card': {
          this.showConfirmation = false;
          this.confirmMessage = this.$t('confirm_message.edit_card');
          this.showConfirmation = true;
          break;
        }
        case 'edit-project': {
          this.showConfirmation = false;
          this.confirmMessage = this.$t('confirm_message.edit_project');
          this.showConfirmation = true;
          break;
        }
        default:
          break;
      }
    },
    disable() {
      this.selectBoxes.forEach((box, i) => {
        if (
          box.checked
          && this.responseData[i].disabled
        ) {
          const userId = this.responseData[i].id;
          this.$emit('job', 'reactivate-user', userId);
        } else if (
          box.checked
          && !this.responseData[i].disabled
        ) {
          const userId = this.responseData[i].id;
          this.$emit('job', 'deactivate-user', userId);
        }
      });
      this.showConfirmation = false;
    },
    setUserlang() {
      this.selectBoxes = document.querySelectorAll('.select-box');
      this.selectBoxes.forEach((box, i) => {
        if (box.checked && this.responseData[i].settings.language !== this.selectedLang) {
          const userId = this.responseData[i].id;
          this.$emit('setUserlang', userId, this.selectedLang);
        }
      });
    },
    showTable(table) {
      if (
        table === 'client'
        || table === 'users'
      ) {
        const checkbox = document.querySelector(`#check-${table} input`);
        checkbox.checked = !checkbox.checked;
      } else if (table === 'export') {
        if (this.allSelected) {
          this.selectAll();
        }
      }
      this.$emit('showTable', table);
    },
    addUser() {
      this.$emit('setEmail', this.email);
      this.$emit('job', 'add-user');
      this.email = '';
      this.showConfirmation = false;
    },
    editPolicy() {
      this.$emit('editPolicy');
    },
    editProject() {
      this.$refs.project.sendRequest();
    },
  },
  mounted() {
    Object.keys(this.$t('lang')).forEach((key, i) => {
      const name = this.$t(`lang.${key}`, { langCode: key });
      this.langs.push({
        id: i,
        name,
        code: key,
      });
    });

    this.managements.forEach(() => {
      this.choices.push([]);
    });

    setTimeout(() => {
      this.userManagement.forEach((option, i) => {
        this.choices[0].push({
          id: i,
          name: this.$t(option.text),
          option: option.text,
          route: option.route ? option.route : null,
        });
      });
      this.projectManagement.forEach((option, i) => {
        this.choices[1].push({
          id: i,
          name: this.$t(option.text),
          option: option.text,
          route: option.route ? option.route : null,
        });
      });
      this.layoutManagement.forEach((option, i) => {
        this.choices[2].push({
          id: i,
          name: this.$t(option),
          option,
        });
      });
      this.translations.forEach((option, i) => {
        this.choices[3].push({
          id: i,
          name: this.$t(option),
          option,
        });
      });
    }, 100);
  },
};
</script>

<style lang="scss">
.selection-panel {
  .wrap {
    display: flex;
    margin-bottom: 20px;
    margin-left: 40px;

    .managements {
      .titles {
        display: block;
        margin-right: 40px;
        width: max-content;
      }

      .selected {
        color: #A245FF;
        font-weight: bold;
        text-decoration: underline;
      }

      .unselected {
        color: #525F7F;
      }
    }
  }

  .client,
  .project,
  .policy,
  .play-card,
  .report,
  .aggregator,
  .project {
    display: block;
    margin-top: 2rem;
    margin-bottom: 2rem;

    &.users {
      width: fit-content;
      margin-left: auto;

      .first-row {
        margin-bottom: 15px;
      }
    }

    .client-options,
    .user-options,
    .project-options,
    [class*="-options"].row {
      display: flex;

      .email-input{
        margin-bottom: 0;
        margin-right: 30px;
      }

      &:not(.user-options) {
        margin-left: 45px;
      }

      .list-title {
        position: absolute;
        margin-top: 5px;
        left: 50px;
        font-weight: bold;
      }

      button {
        color: #A245FF;
        border: 1px solid #A245FF;
        border-radius: 40px;
        margin-right: 30px;
        max-height: 43px;
      }

      #all-users {
        margin-left: auto;
      }
    }
  }

  .result-table {
    .results {
      text-align: center;
      font-weight: 400;
      color: #3C4866;

      .message {
        font-weight: 500;
      }

      table:not(.inner-table) {
        display: inline-block;
      }

      table {
        th {
          padding: 10px 20px;
          border-bottom: 1px solid lightgray;
        }

        td {
          padding: 10px;
          border-bottom: 1px solid lightgray;
        }
      }
    }
  }

  .hide {
    display: none;
  }

  hr {
    width: 90%;
    margin-top: 0;
    margin-bottom: 10px;
    margin-left: 5%;
  }

  .confirmation {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;

    p {
      margin-top: 16px;
      max-width: 900px;
      color: #3C4866;
      font-weight: 500;
    }

    button {
      color: #A245FF;
      border: 1px solid #A245FF;
      border-radius: 40px;
      margin-left: 20px;
      max-height: 43px;
    }
  }

  .clicked {
    color: white !important;
    background: linear-gradient(81.57deg,#0d86fe 12.9%,#b504ff 87.1%);
  }
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"confirmation"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.confirmMessage)}}),(_vm.confirmationNeeded
      && (_vm.confirmationNeeded.deactivate && _vm.confirmationNeeded.deactivate.length
      || _vm.confirmationNeeded.reactivate && _vm.confirmationNeeded.reactivate.length))?_c('base-button',{on:{"click":function($event){return _vm.disable()}}},[_vm._v(" "+_vm._s(_vm.$t('yes'))+" ")]):(_vm.confirmationNeeded
      && _vm.selected === 'add-policy')?_c('base-button',{on:{"click":function($event){return _vm.editPolicy()}}},[_vm._v(" "+_vm._s(_vm.$t('yes'))+" ")]):(_vm.confirmationNeeded
      && _vm.selected === 'add-playcard')?_c('base-button',{on:{"click":function($event){return _vm.editPlayCard()}}},[_vm._v(" "+_vm._s(_vm.$t('yes'))+" ")]):(_vm.confirmationNeeded
      && _vm.selected === 'edit-project')?_c('base-button',{on:{"click":function($event){return _vm.editProject()}}},[_vm._v(" "+_vm._s(_vm.$t('yes'))+" ")]):(_vm.userManagement && _vm.userManagement.map(item=> item.text).includes(_vm.selected))?_c('base-button',{on:{"click":function($event){return _vm.addUser()}}},[_vm._v(" "+_vm._s(_vm.$t('yes'))+" ")]):(_vm.confirmationNeeded
      && _vm.selected === 'general')?_c('base-button',{on:{"click":_vm.yesClicked}},[_vm._v(" "+_vm._s(_vm.$t('yes'))+" ")]):_vm._e(),_c('base-button',{on:{"click":function($event){return _vm.hideConfirmation()}}},[_vm._v(" "+_vm._s(_vm.$t('no'))+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
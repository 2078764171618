<template>
  <div class="player" v-if="player && active === 'invitation_code'">
    <div class="row2">
      <h3 class="search_h3">{{ `${player.player.name}` }}</h3>
      <router-link class="uuid" :to="`/player/${player.player.uuid}`">P-{{ `${player.player.uuid}` }}</router-link>
    </div>
    <p class="email" v-tooltip=" $t('copy_link')" @click="copyEmail(player.player.email)">{{ `${player.player.email}` }}</p>
    <div class="row2">
      <p class="label-text">{{ $t('invitation_code') }}:</p>
      <p class="result">{{ `${player.invitation_code}` }}</p>
    </div>
    <div class="row2">
      <p class="label-text">{{ $t('invitation_search.recruitment') }}: </p>
      <router-link class="result" :to="`/aggregator/${player.aggregator.id}`">{{ `${player.aggregator.name}`
      }} ({{ `${player.aggregator.id}` }})</router-link>
    </div>
    <div class="row2">
      <p class="label-text">{{ $t('invitation_search.project') }}: </p>
      <router-link class="result" :to="`/project/${player.project.id}`">{{ `${player.project.name}` }} ({{
        `${player.project.id}` }})</router-link>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    player: {
      required: true,
    },
    active: {
      required: true,
    },
  },
  methods: {
    isActive(tabName) {
      const clipboardData = navigator.clipboard.getData('text/plain');
      return !clipboardData || this.active !== tabName;
    },
    copyEmail(email) {
      navigator.clipboard.writeText(email);
    },
  },
};
</script>

<template>
  <div class="manage" id="myScrollElement">
    <SelectPanel
      ref="selectPanel"
      :userManagement="userManagement"
      :projectManagement="projectManagement"
      :layoutManagement="layoutManagement"
      :translations="translations"
      :responseData="responseData"
      :clientResponseData="clientResponseData"
      :selectedOption="selectedOption"
      :allSelected="allSelected"
      :reportLayouts="reportLayouts"
      :assessments="assessments"
      :clients="clients"
      :users="users"
      :parents="parents"
      :displayUsers="displayUsers"
      :displayClient="displayClient"
      :exportUsers="exportUsers"
      :clientName="clientName"
      :moduleTypes="moduleTypes"
      :reportModules="reportModules"
      :kpis="kpis"
      :activation="activation"
      :videoCards="videoCards"
      :aggregators="aggregators"
      :projects="projects"
      :notMemberProjects="notMemberProjects"
      :calculators="calculators"
      :emailTemplates="emailTemplates"
      :games="games"
      @getSelectedOption="getSelectedOption"
      @getAssessmentId="getAssessmentId"
      @getLayoutId="getLayoutId"
      @loadModuleList="loadModuleList"
      @hideResult="hideResult"
      @showResult="showResult"
      @job="job"
      @setUserlang="setUserlang"
      @setEmail="setEmail"
      @setSlug="setSlug"
      @showTable="showTable"
      @unselect="unselect"
      @selectAll="selectAll"
      @setClientName="setClientName"
      @handleErrors="handleErrors"
      @sendAction="setAction"
      @sendKpi="setKpi"
      @getLang="getLang"
      @setMsg="setMsg"
      @refreshKpis="getKpiList(1)"
    />

    <ResultPanel
      v-if="resultVisibility && $store.state.user.user_lang"
      ref="resultPanel"
      :resultMessage="resultMessage"
      :responseData="responseData"
      :clientId="clientId"
      :displayUsers="displayUsers"
      :displayClient="displayClient"
      :exportUsers="exportUsers"
      :selectedOption="selectedOption"
      :additionalLayouts="additionalLayouts"
      :allSlug="allSlug"
      :clientName="clientName"
      :clients="clients"
      :users="users"
      :reportModules="reportModules"
      :selectedModuleType="selectedModuleType"
      :kpis="kpis"
      :selectedAction="selectedAction"
      :selectedKpi="selectedKpi"
      :reportLayouts="reportLayouts"
      :aggregators="aggregators"
      :projects="projects"
      :showReportModuleList="showReportModuleList"
      :total="pagination.total"
      :size="pagination.size"
      :page="pagination.page"
      :activation="activation"
      @showTable="showTable"
      @filterData="filterData"
      @setPage="setPage"
      @handleErrors="handleErrors"
      @selected="selected"
      @hideResult="hideResult"
      @showResult="showResult"
      @job="job"
      @setMsg="setMsg"
      @clearMsg="resultMessage = ''"
    />
  </div>
</template>

<script>
import api from '../common/api.js';
import ResultPanel from './ResultPanel.vue';
import SelectPanel from './SelectPanel.vue';

export default {
  name: 'Manage',
  components: {
    ResultPanel,
    SelectPanel,
  },
  data() {
    return {
      clientResponseData: null,
      responseData: null,
      previousUserList: null,
      selectBoxes: null,
      allSelected: false,
      userDisable: false,
      clientId: '',
      assessmentId: '',
      layout: {},
      userEmail: '',
      slug: '',
      resultMessage: '',
      activation: false,
      userManagement: [
        { text: 'get-user', route: '/users' },
        { text: 'get-client', route: '/clients' },
      ],
      projectManagement: [
        { text: 'add-slug' },
        { text: 'add-layout' },
        { text: 'list-aggregators', route: '/aggregators' },
        { text: 'edit-project', route: '/projects' },
      ],
      layoutManagement: [
        'add-module',
        'edit-report',
      ],
      translations: [
        'add-playcard',
        'add-policy',
        'add-kpi',
      ],
      customField: [
        'add-custom-field',
      ],
      moduleTypes: [
        'radar',
        'polar',
        'bar',
        'strength',
        'warning',
        'text',
        'quiz',
      ],
      reportModuleDataSize: 0,
      reportModules: [{}],
      showReportModuleList: false,
      kpiDataSize: 0,
      kpis: [],
      layoutDataSize: 0,
      reportLayouts: [],
      additionalLayoutDataSize: 0,
      additionalLayouts: [],
      assessmentDataSize: 0,
      assessments: [],
      allSlugDataSize: 0,
      allSlug: [],
      clientDataSize: 0,
      clients: [],
      userDataSize: 0,
      users: [],
      videoCards: [],
      aggregatorDataSize: 0,
      aggregators: [],
      parents: [],
      projectDataSize: 0,
      projects: [],
      notMemberProjectDataSize: 0,
      notMemberProjects: [],
      calculatorDataSize: 0,
      calculators: [],
      emailTemplateDataSize: 0,
      emailTemplates: [],
      gameDataSize: 0,
      games: [],
      displayUsers: true,
      displayClient: false,
      exportUsers: false,
      clientName: '',
      selectedModuleType: null,
      selectedAction: null,
      selectedKpi: null,
      selectedCard: null,
      selectedLang: null,
      pagination: {
        page: 1,
        total: 0,
        size: 20,
      },
    };
  },
  computed: {
    selectedOption() {
      return this.$store.state.admin.selectedOption;
    },
    resultVisibility() {
      return this.$store.state.admin.resultVisibility;
    },
  },
  methods: {
    setPage(page) {
      if (this.$route.name === 'Aggregators') this.getAggregatorList(page);
      if (this.$route.name === 'Clients') this.getClientList(page);
      if (this.$route.name === 'Home' && this.selectedOption === 'add-layout') this.getAdditionalLayoutList(page);
    },
    filterData(params) {
      if (this.$route.name === 'Aggregators') this.getAggregatorList(1, params);
      if (this.$route.name === 'Clients') this.getClientList(1, params);
    },
    getLang(lang) {
      this.selectedLang = lang;
    },
    setAction(action) {
      this.selectedAction = action;
    },
    setKpi(kpi) {
      this.selectedKpi = kpi;
    },
    setMsg(msg) {
      this.showResult();
      this.resultMessage = msg;
    },
    setClientName(clientName) {
      this.clientName = clientName;
    },
    resetInputData() {
      this.clientId = '';
      this.userEmail = '';
    },
    setEmail(email) {
      this.userEmail = email;
    },
    setSlug(slug) {
      this.slug = slug;
    },
    showTable(table) {
      switch (table) {
        case 'users': {
          this.exportUsers = false;
          this.displayUsers = !this.displayUsers;
          break;
        }
        case 'export': {
          this.displayUsers = false;
          this.exportUsers = true;
          this.listUsers();
          break;
        }
        case 'client': {
          this.displayClient = !this.displayClient;
          break;
        }
      }
    },
    async listUsers() {
      this.responseData = [];
      await this.getUserList(1);
      this.exportUsers = true;
    },
    hideResult(selected) {
      if (
        (this.userManagement.map((p) => p.text).includes(selected)
        && !this.projectManagement.map((p) => p.text).includes(selected))
        && !this.layoutManagement.includes(selected)
        && !this.translations.includes(selected)
        && !this.exportUsers
      ) {
        this.$store.commit('admin/setResultVisibility', false);
      }
      if (!selected) {
        this.assessmentId = '';
        this.layout = {};
        this.$refs.selectPanel.showConfirmation = false;
        this.$store.commit('admin/setResultVisibility', false);
      } else if (
        this.resultMessage
        && !this.userManagement.includes(selected)
        && selected !== 'reactivate-user'
        && selected !== 'deactivate-user'
      ) {
        this.$store.commit('admin/setResultVisibility', false);
      }
      this.resultMessage = '';
    },
    showResult() {
      this.$store.commit('admin/setResultVisibility', true);
    },
    showResultPanel(option) {
      if (
        this.userManagement.map((p) => p.text).includes(option)
        || this.projectManagement.map((p) => p.text).includes(option)
        || this.layoutManagement.includes(option)
        || this.translations.includes(option)
      ) {
        this.showResult();
      } else {
        this.hideResult(this.selectedOption);
      }
    },
    getSelectedOption(option) {
      this.$store.commit('admin/setSelectedOption', option);
      this.showResultPanel(option);
    },
    getClientId() {
      const client = document.querySelector('.dropdown-input');
      setTimeout(() => {
        for (const i in this.clients) {
          if (this.clients[i].name === client.value) {
            this.clientId = this.clients[i].id;
          }
        }
      }, 100);
    },
    getAssessmentId(id) {
      this.assessmentId = id;
    },
    unselect() {
      this.allSelected = false;
    },
    getLayoutId(id, name) {
      this.layout.name = name;
      this.layout.id = id;
    },
    selected() {
      let unselected = false;
      this.activation = false;
      this.selectBoxes = document.querySelectorAll('.select-box');
      this.selectBoxes.forEach((box) => {
        if (!box.checked) {
          unselected = true;
        } else if (box.checked) {
          this.activation = true;
        }
      });
      const checkbox = document.querySelector('#check-all input');
      checkbox.checked = !unselected;
      this.allSelected = !unselected;
    },
    selectAll() {
      this.allSelected = !this.allSelected;
      this.activation = !!this.allSelected;
      this.selectBoxes = document.querySelectorAll('.select-box');
      this.selectBoxes.forEach((box) => {
        box.checked = !!this.allSelected;
      });
    },
    formatDate(key, data) {
      if (
        (key === 'created_at'
        || key === 'last_login_at'
        || key === 'last_seen')
        && data[key] !== null
      ) {
        data[key] = data[key].replace('T', ' ');
        return data[key];
      }
    },
    async job(selected, userId) {
      this.hideResult(selected || this.selectedOption);
      switch (selected || this.selectedOption) {
        case 'add-user': {
          this.getClientId();
          // Wait for the client id
          await new Promise((resolve) => {
            setTimeout(resolve, 100);
          });
          const params = {
            email: this.userEmail,
            client_ids: [this.clientId],
            disabled: this.userDisable,
          };
          await api.addUser(params)
            .then(() => {
              this.resultMessage = this.$t('result_message.user_added', { user: this.userEmail });
            })
            .catch(async (err) => {
              const clients = [];
              let userId;
              // 409 status means that user already exist
              if (
                err.response
              && err.response.status === 409
              ) {
                this.previousUserList.forEach((user) => {
                  if (user.email === params.email) {
                    userId = user.id;
                    user.clients.forEach((client) => {
                      clients.push(client.id);
                    });
                  }
                });
                // Add the actual client for the user if it's exist
                if (!clients.includes(this.clientId)) {
                  clients.push(this.clientId);
                  await api.patchUser(userId, { client_ids: clients })
                    .then(() => {
                      this.resultMessage = this.$t('result_message.user_added', { user: this.userEmail });
                    })
                    .catch((err) => {
                      if (err.response !== undefined) {
                        this.handleErrors(err.response);
                      } else {
                        this.handleErrors(err);
                      }
                    });
                } else if (err.response !== undefined) {
                  this.handleErrors(err.response);
                } else {
                  this.handleErrors(err);
                }
              } else if (err.response !== undefined) {
                this.handleErrors(err.response);
              } else {
                this.handleErrors(err);
              }
            });
          break;
        }
        case 'reactivate-user': {
          await api.patchUser(userId, { disabled: false })
            .then(() => {
              this.resultMessage = this.$t('result_message.user_re/deactivated');
              this.responseData.forEach((user) => {
                if (user.id === userId) {
                  user.disabled = false;
                }
              });
            })
            .catch((err) => {
              if (err.response !== undefined) {
                this.handleErrors(err.response);
              } else {
                this.handleErrors(err);
              }
            });
          break;
        }
        case 'deactivate-user': {
          await api.deactivateUser(userId)
            .then(() => {
              this.resultMessage = this.$t('result_message.user_re/deactivated');
              this.responseData.forEach((user) => {
                if (user.id === userId) {
                  user.disabled = true;
                }
              });
            })
            .catch((err) => {
              if (err.response !== undefined) {
                this.handleErrors(err.response);
              } else {
                this.handleErrors(err);
              }
            });
          break;
        }
        case 'get-user': {
          this.exportUsers = false;
          this.displayUsers = true;
          this.previousUserList = JSON.parse(JSON.stringify(this.responseData));
          this.responseData = [];
          this.getClientId();
          // Wait for the client id
          await new Promise((resolve) => {
            setTimeout(resolve, 100);
          });
          await api.getUsers(this.clientId)
            .then((res) => {
              if (res.data.items.length === 0) {
                this.resultMessage = this.$t('result_message.no_user');
              } else {
                this.responseData = res.data.items;
              }
            })
            .catch((err) => {
              if (err.response !== undefined) {
                this.handleErrors(err.response);
              } else {
                this.handleErrors(err);
              }
            });
          break;
        }
        case 'get-client': {
          this.getClientId();
          // Wait for the client id
          await new Promise((resolve) => {
            setTimeout(resolve, 100);
          });
          await api.getClient(this.clientId)
            .then((res) => {
              this.clientResponseData = [];
              for (const key in res.data) {
                this.formatDate(key, res.data);
                this.clientResponseData.push({
                  [key]: res.data[key],
                });
              }
            })
            .catch((err) => {
              if (err.response !== undefined) {
                this.handleErrors(err.response);
              } else {
                this.handleErrors(err);
              }
            });
          break;
        }
      }
      if (selected !== 'get-client') {
        this.showResult();
      }
      this.resetInputData();
    },
    setUserlang(userId, lang) {
      api.patchUser(userId, { settings: { language: lang } })
        .then(() => {
          this.resultMessage = this.$t('result_message.userlang_set');
          this.responseData.forEach((user) => {
            if (user.id === userId) {
              user.settings.language = lang;
            }
          });
        })
        .catch((err) => {
          if (err.response !== undefined) {
            this.handleErrors(err.response);
          } else {
            this.handleErrors(err);
          }
        });
    },
    handleErrors(error) {
      console.log(error);
      if (
        !error.status
        && error.message
      ) {
        this.resultMessage = error.message;
      } else {
        switch (error.status) {
          case 400: {
            this.resultMessage = error.data.detail;
            break;
          }
          case 401: {
            this.resultMessage = error.data.detail;
            break;
          }
          case 403: {
            this.resultMessage = error.data.detail;
            break;
          }
          case 404: {
            this.resultMessage = `API ${error.data.detail}` || `URL ${error.statusText}`;
            break;
          }
          case 409: {
            this.resultMessage = error.data.detail;
            break;
          }
          case 422: {
            this.resultMessage = `${(error.data.detail[0].loc[1] || error.data.detail[0].loc[0])} ${error.data.detail[0].msg}`;
            break;
          }
          case 502: {
            this.resultMessage = 'API not available';
            break;
          }
          default: {
            this.resultMessage = this.$t('result_message.default_error');
            break;
          }
        }
      }
      this.showResult();
    },
    getReportLayoutList(page) {
      api.listReportLayouts(page)
        .then((res) => {
          this.layoutDataSize = res.data.total / 5000;
          res.data.items.forEach((item) => {
            this.reportLayouts.unshift({
              id: item.id,
              name: `${item.id} - ${item.name}`,
            });
          });
          if (page < this.layoutDataSize) {
            page++;
            this.getReportLayoutList(page);
          }
        })
        .catch((err) => {
          if (err.response !== undefined) {
            this.handleErrors(err.response);
          } else {
            this.handleErrors(err);
          }
        });
    },
    getAdditionalLayoutList(page) {
      this.additionalLayouts = [];
      api.listAdditionalLayouts(page, this.pagination.size)
        .then((res) => {
          this.pagination.total = res.data.total;
          this.pagination.page = page;
          res.data.items.forEach((item) => {
            this.additionalLayouts.unshift({
              id: item.assessment.id,
              name: item.assessment.name,
              layout_id: item.layout.id,
              layout_name: item.layout.name,
            });
          });
        })
        .catch((err) => {
          if (err.response !== undefined) {
            this.handleErrors(err.response);
          } else {
            this.handleErrors(err);
          }
        });
    },
    getSlugList(page) {
      api.listSlugs(page)
        .then((res) => {
          this.allSlugDataSize = res.data.total / 5000;
          res.data.items.forEach((item) => {
            this.allSlug.unshift({
              id: item.assessment.id,
              name: item.assessment.name,
              slug: item.slug,
            });
          });
          if (page < this.allSlugDataSize) {
            page++;
            this.getSlugList(page);
          }
        })
        .catch((err) => {
          if (err.response !== undefined) {
            this.handleErrors(err.response);
          } else {
            this.handleErrors(err);
          }
        });
    },
    getAssessmentList(page) {
      api.listAssessments(page)
        .then((res) => {
          this.assessmentDataSize = res.data.total / 5000;
          res.data.items.forEach((item) => {
            if (!item.prefixed_id.includes('P')) {
              this.assessments.unshift({
                id: item.id,
                name: `${item.id} - ${item.name}`,
                prefixed_id: item.prefixed_id,
              });
            }
          });
          if (page < this.assessmentDataSize) {
            page++;
            this.getAssessmentList(page);
          }
        })
        .catch((err) => {
          if (err.response !== undefined) {
            this.handleErrors(err.response);
          } else {
            this.handleErrors(err);
          }
        });
    },

    getUserList(page) {
      api.listUsers(page)
        .then((res) => {
          this.userDataSize = res.data.total / 100;
          res.data.items.forEach((item) => {
            this.responseData.unshift(item);
          });
          if (page < this.userDataSize) {
            page++;
            this.getUserList(page);
          } else {
            this.showResult();
          }
        })
        .catch((err) => {
          if (err.response !== undefined) {
            this.handleErrors(err.response);
          } else {
            this.handleErrors(err);
          }
        });
    },
    getKpiList(page) {
      api.listKpis(page)
        .then((res) => {
          this.kpiDataSize = res.data.total / 100;
          res.data.items.forEach((item) => {
            const displayName = item.name
              ? `${item.identifier} - ${item.name}`
              : item.identifier;
            this.kpis.unshift({
              id: item.id,
              name: displayName,
              identifier: item.identifier,
              order: item.order,
              excluded: item.excluded,
              obsolete: item.obsolete,
            });
          });
          if (page < this.kpiDataSize) {
            page++;
            this.getKpiList(page);
          }
        })
        .catch((err) => {
          if (err.response !== undefined) {
            this.handleErrors(err.response);
          } else {
            this.handleErrors(err);
          }
        });
    },
    getReportModuleList(page, moduleType) {
      api.listReportModules(page, moduleType, 100)
        .then((res) => {
          this.reportModuleDataSize = res.data.total / 100;
          res.data.items.forEach((item) => {
            this.reportModules[0][moduleType].unshift({
              id: item.id,
              name: item.name,
            });
          });
          if (page < this.reportModuleDataSize) {
            page++;
            this.getReportModuleList(page, moduleType);
          } else {
            this.showReportModuleList = true;
          }
        })
        .catch((err) => {
          if (err.response !== undefined) {
            this.handleErrors(err.response);
          } else {
            this.handleErrors(err);
          }
        });
    },
    loadModuleList() {
      this.reportModules = [{}];
      this.moduleTypes.forEach((moduleType) => {
        this.reportModules[0] = Object.assign(this.reportModules[0], { [moduleType]: [] });
        this.getReportModuleList(1, moduleType);
      });
    },
    getVideoCardsList(page) {
      api.listVideoCards(page)
        .then((res) => {
          res.data.items.forEach((item) => {
            this.videoCards.unshift({
              id: item.id,
              displayName: `${item.id} - ${item.name}`,
              name: item.name,
            });
          });
        })
        .catch((err) => {
          if (err.response !== undefined) {
            this.handleErrors(err.response);
          } else {
            this.handleErrors(err);
          }
        });
    },
    getClientList(page, params) {
      if (this.$route.name === 'Clients') {
        this.clients = [];
        api.listClients(page, this.pagination.size, params)
          .then((res) => {
            res.data.items.forEach((item) => {
              this.clients.unshift({
                name: item.name,
                id: item.id,
                slug: item.slug,
                logo_url: item.logo_url,
                parent: item.parent,
              });
            });
            this.pagination.total = res.data.total;
            this.pagination.page = page;
          })
          .catch((err) => {
            if (err.response !== undefined) {
              this.handleErrors(err.response);
            } else {
              this.handleErrors(err);
            }
          }).finally(() => {
            const scrollElement = document.getElementById('myScrollElement');
            if (scrollElement) {
              scrollElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
          });
      } else {
        api.listClients(page, 1000)
          .then((res) => {
            this.clientDataSize = res.data.total / 100;
            res.data.items.forEach((item) => {
              this.clients.unshift(item);
            });
            if (page < this.clientDataSize) {
              page++;
              this.getClientList(page);
            }
          })
          .catch((err) => {
            if (err.response !== undefined) {
              this.handleErrors(err.response);
            } else {
              this.handleErrors(err);
            }
          });
      }
    },
    getAggregatorList(page, params) {
      this.aggregators = [];
      api.listAggregators(page, this.pagination.size, params)
        .then((res) => {
          res.data.items.forEach((item) => {
            this.aggregators.unshift({
              id: item.id,
              name: `${item.id} - ${item.name}`,
              members: item.members.filter((member) => member.entity_type === 'project'),
            });
          });
          this.pagination.total = res.data.total;
          this.pagination.page = page;
        })
        .catch((err) => {
          if (err.response !== undefined) {
            this.handleErrors(err.response);
          } else {
            this.handleErrors(err);
          }
        });
    },
    getProjectList(page) {
      api.listProjects(page)
        .then((res) => {
          this.projectDataSize = res.data.total / 100;
          res.data.items.forEach((item) => {
            this.projects.unshift({
              id: item.id,
              name: `${item.id} - ${item.name}`,
            });
          });
          if (page < this.projectDataSize) {
            page++;
            this.getProjectList(page);
          }
        })
        .catch((err) => {
          if (err.response !== undefined) {
            this.handleErrors(err.response);
          } else {
            this.handleErrors(err);
          }
        });
    },
    getNotMemberProjectList(page) {
      api.listProjects(page, true)
        .then((res) => {
          this.notMemberProjectDataSize = res.data.total / 100;
          res.data.items.forEach((item) => {
            this.notMemberProjects.unshift({
              id: item.id,
              name: `${item.id} - ${item.name}`,
            });
          });
          if (page < this.notMemberProjectDataSize) {
            page++;
            this.getNotMemberProjectList(page);
          }
        })
        .catch((err) => {
          if (err.response !== undefined) {
            this.handleErrors(err.response);
          } else {
            this.handleErrors(err);
          }
        });
    },
    getCalculatorList(page) {
      api.listCalculators(page)
        .then((res) => {
          this.calculatorDataSize = res.data.total / 5000;
          res.data.items.forEach((item) => {
            this.calculators.unshift({
              id: item.id,
              name: `${item.id} - ${item.name}`,
            });
          });
          if (page < this.calculatorDataSize) {
            page++;
            this.getCalculatorList(page);
          }
        })
        .catch((err) => {
          if (err.response !== undefined) {
            this.handleErrors(err.response);
          } else {
            this.handleErrors(err);
          }
        });
    },
    getEmailTemplateList(page) {
      api.listEmailTemplates(page)
        .then((res) => {
          this.emailTemplateDataSize = res.data.total / 5000;
          res.data.items.forEach((item) => {
            this.emailTemplates.unshift({
              id: item.id,
              name: item.name,
            });
          });
          if (page < this.emailTemplateDataSize) {
            page++;
            this.getEmailTemplateList(page);
          }
        })
        .catch((err) => {
          if (err.response !== undefined) {
            this.handleErrors(err.response);
          } else {
            this.handleErrors(err);
          }
        });
    },
    getGameList(page) {
      api.listGames(page)
        .then((res) => {
          this.gameDataSize = res.data.total / 100;
          res.data.items.forEach((item) => {
            this.games.unshift({
              id: item.id,
              name: `${item.name} (${item.identifier})`,
            });
          });
          if (page < this.gameDataSize) {
            page++;
            this.getGameList(page);
          }
        })
        .catch((err) => {
          if (err.response !== undefined) {
            this.handleErrors(err.response);
          } else {
            this.handleErrors(err);
          }
        });
    },
    getData(option) {
      switch (true) {
        case option === 'get-user' || option === 'get-client' || option === 'edit-client':
          if (this.clientDataSize === 0) {
            this.getClientList(1);
          }
          break;
        case option === 'add-slug':
          if (this.assessmentDataSize === 0) {
            this.getAssessmentList(1);
          }
          if (this.allSlugDataSize === 0) {
            this.getSlugList(1);
          }
          break;
        case option === 'add-layout':
          if (this.assessmentDataSize === 0) {
            this.getAssessmentList(1);
          }
          if (this.additionalLayoutDataSize === 0) {
            this.getAdditionalLayoutList(this.pagination.page);
          }
          if (this.layoutDataSize === 0) {
            this.getReportLayoutList(1);
          }
          break;
        case option === 'list-aggregators':
          if (this.aggregatorDataSize === 0) {
            this.getAggregatorList(1);
          }
          if (this.clientDataSize === 0) {
            this.getClientList(1);
          }
          break;
        case option === 'edit-aggregator':
          // edit a specific aggregator or create new
          if (this.notMemberProjectDataSize === 0) {
            this.getNotMemberProjectList(1);
          }
          if (this.calculatorDataSize === 0) {
            this.getCalculatorList(1);
          }
          if (this.emailTemplateDataSize === 0) {
            this.getEmailTemplateList(1);
          }
          if (this.playCardDataSize === 0) {
            this.getPlayCardList(1);
          }
          this.getVideoCardsList(1);
          if (this.layoutDataSize === 0) {
            this.getReportLayoutList(1);
          }
          if (this.policyDataSize === 0) {
            this.getPolicyList(1);
          }
          if (this.projectDataSize === 0) {
            this.getProjectList(1);
          }
          if (this.clientDataSize === 0) {
            this.getClientList(1);
          }
          break;
        case option === 'edit-project':
          if (this.projectDataSize === 0) {
            this.getProjectList(1);
          }
          // edit a specific project, or create new
          if (this.gameDataSize === 0) {
            this.getGameList(1);
          }
          if (this.clientDataSize === 0) {
            this.getClientList(1);
          }
          break;
        case option === 'add-module':
          if (this.reportModuleDataSize === 0) {
            this.loadModuleList();
          }
          if (this.kpiDataSize === 0) {
            this.getKpiList(1);
          }
          break;
        case option === 'edit-report':
          if (this.layoutDataSize === 0) {
            this.getReportLayoutList(1);
          }
          if (this.reportModuleDataSize === 0) {
            this.loadModuleList();
          }
          break;
        case option === 'add-policy':
          if (this.clientDataSize === 0) {
            this.getClientList(1);
          }
          if (this.policyDataSize === 0) {
            this.getPolicyList(1);
          }
          break;
        case option === 'add-kpi':
          if (this.kpiDataSize === 0) {
            this.getKpiList(1);
          }
          break;
        default:
          break;
      }
      this.showResultPanel(option);
    },
  },
  watch: {
    selectedOption(newValue) {
      this.$store.commit('admin/setResultVisibility', false);
      if (newValue) {
        this.getData(newValue);
      }
    },
  },
  mounted() {
    if (this.selectedOption) {
      this.getData(this.selectedOption);
    } else {
      if (this.$route.params.aggregatorId || this.$route.name === 'NewAggregator') {
        this.getSelectedOption('edit-aggregator');
      }
      if (this.$route.name === 'Aggregators') {
        this.getSelectedOption('list-aggregators');
      }
      if (this.$route.params.clientId || this.$route.name === 'NewClient') {
        this.getSelectedOption('edit-client');
      }
      if (this.$route.name === 'Clients') {
        this.getSelectedOption('get-client');
      }
      if (this.$route.name === 'ClientPage') {
        this.getSelectedOption('edit-client');
      }
      if (this.$route.name === 'Users') {
        this.getSelectedOption('get-user');
      }
      if (this.$route.params.userId || this.$route.name === 'NewUser') {
        this.getSelectedOption('edit-user');
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.manage {
  width: 100%;
  position: relative;
  margin-right: 30px;
  margin-left: 30px;
}
</style>

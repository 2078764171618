<template>
  <div v-if="customfield" class="wrapper">
    <div class="row">
      <label for="field-type">
        <p class="label-text">{{$t('custom_field.field-type')}}:</p>
        <Dropdown
          :options="fieldTypes"
          @selected="setType"
          :defaultValue="customfield.field_type ?
            fieldTypes.filter((type) => type.id === customfield.field_type)[0].name : null"
          :disabled="false"
          name="fieldType"
          :maxItem="fieldTypes.length"
          :placeholder="$t('select_type')"
        />
      </label>
        <label for="name">
          <p class="label-text">{{$t('name')}}:</p>
          <base-input
            class="name-input"
            :placeholder="$t('name')"
            name="name"
            type="text"
            v-model="customfield.name"
          ></base-input>
        </label>
    </div>
    <div class="row"><h3>{{$t('custom_field.translations')}}</h3></div>
      <div class="row">
        <Dropdown
          :options="allLangs"
          @selected="setCurrentLang"
          :defaultValue="allLangs[0].name"
          :disabled="false"
          name="lang"
          :maxItem="allLangs.length"
          :language="true" 
          :placeholder="$t('select_lang')"
        />
    </div>
  <div class="row">
    <label for="label">
      <p class="label-text">{{$t('custom_field.label')}}:</p>
      <textarea
        class="name-input"
        placeholder="label"
        name="label"
        cols="30" rows="3"
        v-model="customfield.translations.label[currentLang]"
      ></textarea>
    </label>
  </div>
  <div v-if="customfield.field_type === 'select' && customfield.translations.selections" class="row members">
    <div class="row"><h4>{{ $t('custom_field.selections')}}</h4></div>
    <div v-if="customfield.translations.selections[currentLang].length >0">
      <div v-for="(item, i) in customfield.translations.selections[currentLang]"
      :key="`option-${i}-${render}`" class="entity">
      <label :for="`option-${i}-${currentLang}`">
        <base-input
          class="name-input"
          :class="customfield.translations.selections[currentLang][i] === '' ? 'field-error' : ''"
          :name="`option-${i}-${currentLang}`"
          type="text"
          v-model="customfield.translations.selections[currentLang][i]"
        ></base-input>
      </label>
      <div class="btns">
        <base-button class="btn-up" :disabled="i === 0"
          @click="moveUp(customfield.translations.selections[currentLang], i, rerender)">
            ^
        </base-button>

        <base-button
          class="btn-down"
          :disabled="i === customfield.translations.selections[currentLang].length - 1"
          @click="moveDown(customfield.translations.selections[currentLang], i, rerender)"
        >
          ^
        </base-button>
      </div>
      <base-button class="remove"
        :disabled="customfield.translations.selections[currentLang] === 0"
        @click="removeOption(i)">
        X
      </base-button>
    </div>
    </div>
    <base-button class="remove"
      :disabled="customfield.translations.selections[currentLang] === 0"
      @click="addOption()">
      {{$t('custom_field.add-option')}}
    </base-button>

  </div>
<div v-if="customfield.field_type === 'select' && customfield.translations.selections"
  class="options">
  <div class="row"><h4>{{ $t('custom_field.options')}}</h4></div>
  <div class="row">
      <label for="min-selection">
          <p class="label-text">{{ $t(`custom_field.min-selection`) }}:</p>
          <base-input
            class="smaller"
            :class="minSelectionError ? 'field-error' : ''"
            name="min-selection"
            type="number"
            min="0"
            v-model.number="options['min-selection']"
          ></base-input>
        </label>
      <label for="max-selection">
          <p class="label-text">{{ $t(`custom_field.max-selection`) }}:</p>
          <base-input
            class="smaller"
            :class="minSelectionError ? 'field-error' : ''"
            name="min-selection"
            type="number"
            min="0"
            v-model.number="options['max-selection']"
          ></base-input>
        </label>

        <label for="allow-other">
          <p class="label-text">
            {{ $t(`custom_field.allow-other`) }}:
          </p>

          <base-checkbox
            name="alow-other"
            v-model="options['allow-other']"
          ></base-checkbox>
        </label>
  </div>
</div>
    <Message
      v-if="resultMessage !== ''"
      :classes="''"
      :message="resultMessage"
      @hideResult="resultMessage = ''">
    </Message>
    <div class="client-errors">
      <p v-if="minSelectionError">{{$t('custom_field.min-selection-error')}}</p>
      <p v-if="selectionsError.length > 0 && showClientErrors">
        {{$t('custom_field.selections-error')}}: {{selectionsError.toString()}}
         </p>
      <p v-if="emptyAnswer.length > 0 && showClientErrors">
        {{$t('custom_field.empty-answer-error')}}: {{emptyAnswer.toString()}}
      </p>
      <p v-if="allEmpty">{{$t('custom_field.all-empty')}}</p>
      <p v-if="selectionLengthError && showClientErrors">
        {{$t('custom_field.selection-length-error')}}
      </p>
    </div>
    <base-button
      v-if="$route.name === 'NewCustomField'"
      @click="createCustomField">
      {{ $t('add') }}
    </base-button>
      <base-button v-else
        @click="editCustomField">
      {{ $t('edit') }}
    </base-button>
  </div>
</template>

<script>
import sharedMethods from '../mixins/sharedMethods';
import api from '../common/api';
import Dropdown from '../components/Dropdown.vue';
import Message from '../components/Message.vue';

export default {
  components: {
    Dropdown,
    Message,
  },
  mixins: [sharedMethods],
  data() {
    return {
      customfield: null,
      fieldTypes: [
        { id: 'bool', name: 'checkbox' },
        { id: 'phone', name: 'phone' },
        { id: 'text', name: 'text' },
        { id: 'select', name: 'select' },
        { id: 'date', name: 'date' },
      ],
      currentLang: 'en',
      customfieldSchema: {
        field_type: null,
        name: null,
        translations: {
          label: {},
          selections: {},
        },
      },
      options: {
        'min-selection': 0,
        'max-selection': 0,
        'allow-other': false,
      },
      selections: {},
      render: 0,
      showClientErrors: false,
    };
  },
  methods: {
    getCustomField(id) {
      api
        .getCustomField(id)
        .then((res) => {
          this.customfield = res.data;
        })
        .catch((err) => {
          if (err.response !== undefined) {
            this.handleErrors(err.response);
          } else {
            this.handleErrors(err);
          }
        });
    },
    createCustomField() {
      if (!this.clientErrors) {
        this.showClientErrors = false;
        this.setOptions();
        api.addCustomField(this.customfield)
          .then(() => {
            this.setMsg(this.$t('result_message.customfield_added'));
            setTimeout(() => this.$router.push('/custom-fields'), 2500);
          })
          .catch((err) => {
            if (err.response !== undefined) {
              this.handleErrors(err.response);
            } else {
              this.handleErrors(err);
            }
          });
      } else {
        this.showClientErrors = true;
      }
    },
    editCustomField() {
      if (!this.clientErrors) {
        this.showClientErrors = false;
        this.setOptions();
        api.editCustomField(this.customfield.id, this.customfield)
          .then(() => {
            this.setMsg(this.$t('result_message.customfield_edited'));
            setTimeout(() => this.$router.push('/custom-fields'), 2500);
          })
          .catch((err) => {
            if (err.response !== undefined) {
              this.handleErrors(err.response);
            } else {
              this.handleErrors(err);
            }
          });
      } else {
        this.showClientErrors = true;
      }
    },

    setType(type) {
      if (!this.customfield.field_type || !this.customfield.field_type !== type.id) {
        this.customfield.field_type = type.id;
        this.handleSelectionsProperty();
      }
    },
    setOptions() {
      if (this.customfield.field_type === 'select') {
        this.customfield.options = this.options;
      } else {
        this.customfield.options = null;
      }
    },
    handleSelectionsProperty() {
      if (this.customfield.field_type === 'select') {
        if (!Object.keys(this.selections).length) {
          this.allLangs.forEach((element) => {
            this.$set(this.customfield.translations.selections, `${element.code}`, []);
          });
        }
      } else if (this.customfield.field_type !== 'select' && this.customfield.translations.selections) {
        // when select-type field and its aswers were created before, delete all
        this.customfield.translations.selections = {};
      }
    },
    setCurrentLang(lang) {
      this.currentLang = lang.code;
    },
    addOption() {
      this.customfield.translations.selections[this.currentLang].push('');
      this.rerender();
    },
    removeOption(i) {
      this.customfield.translations.selections[this.currentLang].splice(i, 1);
      this.rerender();
    },
    rerender() {
      this.render++;
    },
  },
  computed: {
    allLangs() {
      return this.$store.state.admin.langs;
    },
    minSelectionError() {
      // check if min-selection is greater than max-selection
      return this.customfield.field_type === 'select' && Number(this.options['max-selection']) < Number(this.options['min-selection']);
    },
    selectionsError() {
      // check if any locale contains question (label) without answer options
      // or answers without question
      const errors = [];
      if (this.customfield.field_type === 'select' && this.customfield.translations.selections) {
        const texts = this.customfield.translations;
        this.allLangs.forEach((l) => {
          if ((texts.selections[l.code].length === 0 && texts.label[l.code])
            || (!texts.label[l.code] && texts.selections[l.code].length > 0)) {
            errors.push(l.code);
          }
        });
      }
      return errors;
    },
    emptyAnswer() {
      // check which locale contain empty answer(s)
      const empty = [];
      if (this.customfield.field_type === 'select' && this.customfield.translations.selections) {
        const texts = this.customfield.translations;
        this.allLangs.forEach((l) => {
          if (texts.selections[l.code].length > 0) {
            const found = texts.selections[l.code].find((text) => text.length === 0);
            if (found === '') empty.push(l.code);
          }
        });
      }
      return empty;
    },
    allEmpty() {
      // check if any answers provided
      let allEmpty = true;
      if (this.customfield.field_type === 'select' && this.customfield.translations.selections) {
        const texts = this.customfield.translations;
        this.allLangs.forEach((l) => {
          if (texts.selections[l.code] && texts.selections[l.code].length > 0) {
            allEmpty = false;
          }
        });
      }
      return allEmpty && this.customfield.field_type === 'select';
    },
    selectionLengthError() {
      let error = false;
      // check if min-selection is greater than the number of answers
      // at least on locale should contain greater number of selection options
      if (this.customfield.field_type === 'select' && this.customfield.translations.selections) {
        const selectionTexts = Object.values(this.customfield.translations.selections);
        const found = selectionTexts.length > 0 && selectionTexts
          .find((selections) => selections && selections.length >= Number(this.options['min-selection']));
        error = !found;
      }
      return error;
    },
    clientErrors() {
      return this.minSelectionError || this.selectionsError.length > 0
      || this.emptyAnswer.length > 0 || this.allEmpty || this.selectionLengthError;
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.getCustomField(this.$route.params.id);
    } else {
      this.customfield = this.customfieldSchema;
    }
  },
};
</script>
<style scoped lang="scss">
.row {
  position: relative;
  display: flex;
  width: 1030px;
  margin-right: 10px;
  padding-bottom: 5px;

  &:not(:first-child) {
    margin-top: 0.8rem;
  }

  label {
    margin: 0;
    display: flex;
    align-items: center;
    margin-top: 0.8rem;
  }
}

.client-errors p {
  color: red;
  font-weight: 500;
}

textarea {
  height: 70px;
  width: 250px;
  margin-top: 0;
  resize: both;
}
</style>

export const adminState = {
  selectedOption: null,
  langs: null,
  resultVisibility: false,
  moduleTypes: [
    'radar',
    'polar',
    'bar',
    'strength',
    'warning',
    'text',
    'quiz',
  ],
};

export const adminGetters = {
  moduleTypes(state) {
    return state.moduleTypes;
  },
};

export const adminMutations = {
  setSelectedOption(state, option) {
    state.selectedOption = option;
  },
  setLangs(state, langs) {
    state.langs = langs;
  },
  setResultVisibility(state, value) {
    state.resultVisibility = value;
  },
};

export const adminActions = {};

<template>
  <div v-if="reportWarning && allLangs" class="wrapper report_warning">
    <div class="row">
      <label for="id">
        <p class="label-text">ID:</p>
        <p class="result">{{ `${reportWarning.id}` }}</p>
      </label>
      <label for="id">
        <p class="label-text">identifier:</p>
        <p class="result">{{ `${reportWarning.identifier}` }}</p>
      </label>
    </div>
    <div class="row">
      <Dropdown
        :options="allLangs"
        @selected="setCurrentLang"
        :defaultValue="allLangs[0].name"
        :disabled="false"
        name="lang"
        :maxItem="allLangs.length"
        :language="true" 
        :placeholder="$t('select_lang')"
      />

    </div>
    <div>
      <label for="obsolete" class="warning-message" v-if="reportWarning">
        <p class="label-text">
          {{$t('obsolete') }}:
          <base-checkbox
            class=obsolete-box
            name="obsolete"
            v-model="reportWarning.obsolete"
          ></base-checkbox>
        </p>
      </label>
    </div>
    <div class="row">
      <base-button
        class="report-button"
        @click="$router.push('/report-warnings')">
        {{ $t('back') }}
      </base-button>
      <base-button 
        class="report-button" v-if="$route.name === 'NewReportWarning'" @click="addReportWarning">
        {{ $t('add') }}
      </base-button>
      <base-button 
        class="report-button" v-else @click="editReportWarning">
        {{ $t('edit') }}
      </base-button>
    </div>

    <hr />
    <Message
      v-if="resultMessage !== ''"
      :classes="''"
      :message="resultMessage"
      @hideResult="resultMessage = ''"
    >
    </Message>
    <div>
      <label for="message" class="warning-message">
        <p class="label-text">{{ $t('warning-message') }}:</p>
        <textarea
          v-model="reportWarning.translations.message[currentLang]"
          cols="60">
        </textarea>
      </label>
    </div>
  </div>
</template>

<script>
import sharedMethods from '../mixins/sharedMethods';
import api from '../common/api';
import Dropdown from '../components/Dropdown.vue';
import Message from '../components/Message.vue';

export default {
  components: {
    Dropdown,
    Message,
  },
  mixins: [sharedMethods],
  data() {
    return {
      reportWarning: null,
      currentLang: 'en',
      newWarning: {
        obsolete: false,
        translations: {
          message: {},
        },
      },
      editorConfig: {
        extraPlugins: 'justify',
        resize_dir: 'both',
        resize_minWidth: 300,
        width: 800,
        height: 280,
        entities_latin: false,
        entities_greek: false,
      },
    };
  },
  computed: {
    allLangs() {
      return this.$store.state.admin.langs;
    },
  },
  watch: {
    allLangs() {
      this.allLangs.forEach((element) => {
        this.$set(this.newWarning.translations.message, `${element.code}`, null);
      });
      this.reportWarning = this.newWarning;
    },
  },
  methods: {
    getwarning(id) {
      api
        .getReportWarning(id)
        .then((res) => {
          this.reportWarning = res.data;
        })
        .catch((err) => {
          if (err.response !== undefined) {
            this.handleErrors(err.response);
          } else {
            this.handleErrors(err);
          }
        });
    },
    addReportWarning() {
      api
        .addReportWarning(this.newWarning)
        .then(() => {
          this.setMsg(this.$t('result_message.warning_added'));
        })
        .catch((err) => {
          if (err.response !== undefined) {
            this.handleErrors(err.response);
          } else {
            this.handleErrors(err);
          }
        });
    },
    editReportWarning() {
      api
        .editReportWarning(this.reportWarning.id, this.reportWarning)
        .then(() => {
          this.setMsg(this.$t('result_message.warning_edited'));
        })
        .catch((err) => {
          if (err.response !== undefined) {
            this.handleErrors(err.response);
          } else {
            this.handleErrors(err);
          }
        });
    },
    setCurrentLang(lang) {
      this.currentLang = lang.code;
    },
    rerender() {
      this.render++;
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.getwarning(this.$route.params.id);
    } else if (!this.$route.params.id && this.allLangs) {
      this.allLangs.forEach((element) => {
        this.$set(this.newWarning.translations.message, `${element.code}`, null);
      });
      this.reportWarning = this.newWarning;
    }
  },
};
</script>
<style scoped lang="scss">
.report_warning {
  margin-top: 2rem;
}
.row {
  position: relative;
  display: flex;
  width: 1030px;
  margin-right: 10px;
  padding-bottom: 5px;

  &:not(:first-child) {
    margin-top: 0.8rem;
  }

  label {
    margin: 0;
    display: flex;
    align-items: center;
  }
}

.result {
  font-weight: bold;
  margin: 0;
  margin-right: 50px;
}

.report-button {
  margin-right: 1rem !important;
}

.warning-message {
   textarea {
    margin-bottom: 0.8rem;
    resize: both;
    min-width: 350px;
  }
  .label-text {
    margin: 0.75rem 0;
    font-weight: 600 !important;
    display: flex;
  }
  .obsolete-box {
    margin-right: 135px;
    margin-left: 20px;
  }
}
</style>

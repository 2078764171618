<template>
  <header class="header-global">
    <base-nav class="navbar-main bg-default" id="navbar-main" type="default" expand effect="light">
      <div slot="brand" class="navbar-brand" @click="navigateHome">
        <img :src="logoSrc" alt="logo" />
      </div>

      <div class="row" slot="content-header" slot-scope="{ closeMenu }">
        <div class="col-6 collapse-brand">
          <div @click="navigateHome">
            <img src="/img/brand/bg-logo-white.png" alt="Benchmark.games logo" loading="lazy" />
          </div>
        </div>
        <div class="col-6 collapse-close">
          <close-button @click="closeMenu"></close-button>
        </div>
      </div>

      <ul class="navbar-nav navbar-nav-hover align-items-lg-center ml-lg-auto">
        <base-dropdown class="nav-item" v-for="(manage, i) in managements" :key="i">
          <a role="button" slot="title" href="#" class="nav-link" data-toggle="dropdown">
            <span class="nav-link-inner--text">{{ $t(manage.name) }}</span>
          </a>
          <div class="dropdown-item" v-for="(option, i) in manage.options" :key="i" @click="navigate(option)">
            {{ $t(option.name || option) }}
          </div>
        </base-dropdown>
        <div slot="brand" class="navbar-brand navbar-search" @click="navigateHome">
          <img class="logo" src="img/search.png" alt="logo" />
        </div>
      </ul>
    </base-nav>
  </header>
</template>
<script>
import BaseNav from '@/components/base-components/BaseNav.vue';
import CloseButton from '@/components/base-components/CloseButton.vue';
import BaseDropdown from '@/components/base-components/BaseDropdown.vue';

export default {
  components: {
    BaseNav,
    CloseButton,
    BaseDropdown,
  },

  data() {
    return {
      managements: [
        {
          name: 'user_manage',
          options: [
            { name: 'get-user', route: '/users' },
            { name: 'get-client', route: '/clients' },
          ],
        },
        {
          name: 'project_manage',
          options: [
            { name: 'add-slug' },
            { name: 'add-layout' },
            { name: 'list-aggregators', route: '/aggregators' },
            { name: 'edit-project', route: '/projects' },
          ],
        },
        {
          name: 'report_manage',
          options: [
            { name: 'add-module', route: '/modules' },
            'edit-report',
          ],
        },
        {
          name: 'translations',
          options: [
            { name: 'add-playcard', route: '/playcards' },
            { name: 'add-policy', route: '/privacy-policies' },
            'add-kpi',
            { name: 'custom-fields', route: '/custom-fields' },
            { name: 'add-video', route: '/videocards' },
            { name: 'report-warnings', route: '/report-warnings' },
          ],
        },
        {
          name: 'other',
          options: [
            { name: 'add-game', route: '/games' },
            { name: 'profile-calculators', route: '/profile-calculators'},
            { name: 'benchmarks', route: '/benchmarks'},
            { name: 'profile-match-calculator-list', route: '/pm-calculator-list'},
            { name: 'status', route: '/status'},
          ],
        },
      ],
    };
  },

  props: {
    dark: {
      type: Boolean,
      required: false,
      deafult: false,
    },
  },
  computed: {
    logoSrc() {
      const settingsData = JSON.parse(localStorage.getItem("settings"));
      const environment = settingsData?.environment;
      
      if (environment === "production") {
        return "/img/brand/benchmark-games.png";
      } else if (environment === "staging") {
        return "/img/brand/benchmark-games-staging.png";
      } else {
        return "/img/brand/benchmark-games-dev.png";
      }
    },
  },
  methods: {
    navigateHome() {
      this.$store.commit('admin/setSelectedOption', null);
      if (this.$route.path !== '/' || Object.keys(this.$route.query).length > 0) {
        this.$router.replace({ path: '/' });
      }
    },
    navigate(option) {
      this.$store.commit('admin/setSelectedOption', option.name || option.name === null ? option.name : option);
      if (option.route && this.$route.path !== option.route) {
        this.$router.push(option.route);
      } else if (!option.route && this.$route.name !== 'Home') {
        this.$router.push('/');
      } else if (Object.keys(this.$route.query).length > 0) {
        this.$router.replace({ path: '/' });
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.navbar-main-transparent {
  background-color: transparent !important;
}

.navbar-main-dark {
  // background-color: #2E3548 !important;
  background-color: transparent !important;
}

.navbar-search {
  margin-left: 30px;
  display: inline-block;
  position: relative;
}

.logo {
  transition: filter 0.3s ease;

  &:hover {
    filter: drop-shadow(0px 0px 10px rgb(77, 77, 77));
  }
}

.navbar-brand {
  margin-bottom: 10px;
  margin-left: 10px;
}

#navbar-main {
  cursor: pointer;
  z-index: 9999;

  .navbar-img {
    max-width: 221px;
    width: auto;
    height: auto;
  }

  .navbar-nav {
    .nav-link {
      color: #525F7F !important;
      transition: box-shadow 0.3s ease;

      &:hover {
        box-shadow: 0 11px 5px -5px rgba(0, 0, 0, 0.3);
      }
    }

    .nav-item {
      .nav-link-inner--text {
        // color: white;
      }

      .nav-link-inner--text:hover {
        // color: rgba(255,255,255,0.7);
      }
    }
  }
}
</style>
<style scoped>
.navbar-dark .navbar-nav .nav-link,
.navbar-dark .navbar-nav .show>.nav-link,
.navbar-dark .navbar-nav .active>.nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: black;
}
</style>

<template>
  <div v-if="levelpack" class="wrapper">
    <div class="row">
      <label for="identifier">
        <p class="label-text">{{ $t('identifier') }}:</p>
        <base-input class="name-input" :placeholder="$t('identifier')" :name="'identifier'" type="text"
          v-model="levelpack.identifier"></base-input>
      </label>
      <label for="name">
        <p class="label-text">{{ $t('name') }}:</p>
        <base-input class="name-input" :placeholder="$t('name')" :name="'name'" type="text"
          v-model="levelpack.name"></base-input>
      </label>
      <label for="state">
        <p class="label-text">{{ $t('game.state') }}:</p>
        <Dropdown :options="state" @selected="setState" :defaultValue="levelpack.state" />
      </label>
    </div>
    <hr />
    <div class="json_setting row">
      <h3>Levelpack content</h3>
      <textarea name="content" id="lvlSettings" cols="30" rows="10" v-model="lvlSettings.newValue">
                  </textarea>
    </div>
    <base-button v-if="$route.name === 'NewLevelPack'" @click="addLevelPack">
      {{ $t('add') }}
    </base-button>
    <base-button v-else @click="editLevelPack">
      {{ $t('edit') }}
    </base-button>
    <base-button @click="backGame">
      {{ $t('back') }}
    </base-button>
    <Message v-if="resultMessage !== ''" :classes="''" :message="resultMessage" @hideResult="resultMessage = ''">
    </Message>
  </div>
</template>

<script>
import sharedMethods from '../mixins/sharedMethods';
import api from '../common/api';
import Dropdown from '../components/Dropdown.vue';
import Message from '../components/Message.vue';

export default {
  components: {
    Dropdown,
    Message,
  },
  mixins: [sharedMethods],
  data() {
    return {
      levelpack: null,
      state: [
        { id: 'stable', name: 'stable' },
        { id: 'obsolete', name: 'obsolete' },
      ],
      levelpackSchema: {
        content: [],
        name: null,
        state: null,
        identifier: null,
      },
      lvlSettings: {
        initialValue: null,
        newValue: null,
      },
      valid: false,
    };
  },
  methods: {
    backGame() {
      this.$router.push(`/game/${this.$route.params.gameId}`);
    },
    counterPush(editorsave) {
      const timeoutSeconds = 4;
      let countDown = timeoutSeconds;

      const message = `${this.$t(editorsave)}`;
      this.setMsg(message);

      const intervalId = setInterval(() => {
        countDown--;
        if (countDown > 0) {
          this.setMsg(`${message} ... ${countDown}`);
        } else {
          clearInterval(intervalId);
          this.$router.push(`/game/${this.$route.params.gameId}`);
        }
      }, 1000);
    },
    formatJSON(json) {
      if (!json) return '';

      return JSON.stringify(json, null, 2)
        .replace(/\\|"(\[|\{|\]|\}),?/g, '$1')
        .replace(/([^\\]),"/g, '$1, "');
    },
    parseJSON(jsonString) {
      try {
        return JSON.parse(jsonString);
      } catch (e) {
        console.error('parseJSON error:', e);
        this.setMsg('parseJSON error:' + e);
        return null;
      }
    },
    getLevelPack(levelpacksId, gameId) {
      api.getLevelPack(levelpacksId, gameId)
        .then((res) => {
          this.levelpack = res.data;
          const lvlSettingsProp = this.levelpack.content;
          if (Object.keys(lvlSettingsProp).length > 0) {
            // Prettify csv settings JSON
            this.lvlSettings.initialValue = this.formatJSON(lvlSettingsProp);
            this.lvlSettings.newValue = this.lvlSettings.initialValue;
          }
        })
        .catch((err) => {
          if (err.response !== undefined) {
            this.handleErrors(err.response);
          } else {
            this.handleErrors(err);
          }
        });
    },
    editLevelPack() {
      if (this.lvlSettings.newValue !== this.lvlSettings.initialValue) {
        try {
          const valueToSet = this.lvlSettings.newValue;
          const parsedValue = this.parseJSON(valueToSet);
          if (parsedValue !== null) {
            this.levelpack.content = parsedValue;
            api.editLevelPack(this.$route.params.gameId, this.$route.params.levelpackId, this.levelpack)
              .then(() => {
                this.counterPush('result_message.levelpack_edited');
              })
              .catch((err) => {
                if (err.response !== undefined) {
                  this.handleErrors(err.response);
                } else {
                  this.handleErrors(err);
                }
              });
          }
        } catch (error) {
          this.handleErrors(error);
        }
      }
    },
    setState(state) {
      if (!this.levelpack.state || !this.levelpack.state !== state.id) {
        this.levelpack.state = state.id;
      }
    },
    addLevelPack() {
      if (this.lvlSettings.newValue !== this.lvlSettings.initialValue) {
        try {
          const valueToSet = this.lvlSettings.newValue;
          const parsedValue = this.parseJSON(valueToSet);
          if (parsedValue !== null) {
            this.levelpack.content = parsedValue;

            api.addLevelPack(this.$route.params.gameId, this.levelpack)
              .then(() => {
                this.counterPush('result_message.levelpack_added');
              })
              .catch((err) => {
                if (err.response !== undefined) {
                  this.handleErrors(err.response);
                } else {
                  this.handleErrors(err);
                }
              });
          }
        } catch (error) {
          this.handleErrors(error);
        }
      }
    },
  },
  mounted() {
    if (this.$route.params.levelpackId) {
      this.getLevelPack(this.$route.params.levelpackId, this.$route.params.gameId);
    } else {
      this.levelpack = this.levelpackSchema;
    }
  },
};
</script>
<style scoped lang="scss">
.row {
  position: relative;
  display: flex;
  width: 1030px;
  margin-right: 10px;
  padding-bottom: 5px;

  &:not(:first-child) {
    margin-top: 0.8rem;
  }

  label {
    margin: 0;
    display: flex;
    align-items: center;
    margin-top: 0.8rem;
  }
}

textarea {
  height: 430px;
  width: 430px;
  margin-top: 0;
  resize: both;
}

.result {
  font-weight: bold;
  margin: 0;
  margin-right: 50px;
}

.json_setting {
  position: relative;
  display: inherit;
}

button {
  margin-top: 20px;
  margin-left: 20px;
}
</style>
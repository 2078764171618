<template>
    <div class="wrapper top_margin" v-if="statusData">
        <h2 class="title">{{ $t('status_page.status_title') }}</h2>
        <hr />
        <div class="row">
            <label for="asid">
                <p class="label-text">Node:</p>
                <p class="result" v-if="statusData.node">{{ `${statusData.node.id}` }} ({{
                    `${statusData.node.type}` }})</p>
            </label>
        </div>
        <div class="row">
            <label for="asid">
                <p class="label-text">{{ $t('status_page.last_update') }}:</p>
                <p class="result" v-if="statusData.now">{{ `${statusData.now}` }}</p>
            </label>
        </div>
        <h3 class="some_raw">{{ $t('status_page.generic_data') }}</h3>
        <table>
            <thead>
                <tr>
                    <th>ID</th>
                    <th>{{ $t('status_page.type') }}</th>
                    <th>{{ $t('status_page.title') }}</th>
                    <th>{{ $t('status_page.value') }}</th>
                    <th>{{ $t('status_page.status') }}</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>D-02</td>
                    <td>
                        <h5>{{ $t('status_page.database') }}</h5>
                    </td>
                    <td>
                        <h5 class="td_title">{{ $t('status_page.backend_data') }}</h5>
                    </td>
                    <td>
                        <h5>{{ statusData.backend_db_ok ? 'connected' : 'UNKNOWN' }}</h5>
                    </td>
                    <td v-if="statusData.backend_db_ok" :data-cache="statusData.backend_db_ok" class="value">
                        <h5>{{ statusData.backend_db_ok ? 'OK' : 'NOT OK' }}</h5>
                    </td>
                </tr>
                <tr>
                    <td>D-03</td>
                    <td>
                        <h5>{{ $t('status_page.database') }}</h5>
                    </td>
                    <td>
                        <h5 class="td_title">{{ $t('status_page.game_event_data') }}</h5>
                    </td>
                    <td>
                        <h5>{{ statusData.event_db_ok ? 'connected' : 'UNKNOWN' }}</h5>
                    </td>
                    <td v-if="statusData.event_db_ok" :data-cache="statusData.event_db_ok" class="value">
                        <h5>{{ statusData.event_db_ok ? 'OK' : 'NOT OK' }}</h5>
                    </td>
                </tr>
                <tr>
                    <td>D-04</td>
                    <td>
                        <h5>{{ $t('status_page.database') }}</h5>
                    </td>
                    <td>
                        <h5 class="td_title">{{ $t('status_page.cache') }}</h5>
                    </td>
                    <td>
                        <h5>{{ statusData.cache_ok ? 'connected' : 'UNKNOWN' }}</h5>
                    </td>
                    <td v-if="statusData.cache_ok" :data-cache="statusData.cache_ok" class="value">
                        <h5>{{ statusData.cache_ok ? 'OK' : 'NOT OK' }}</h5>
                    </td>
                </tr>
                <tr>
                    <td>P-02</td>
                    <td>
                        <h5>{{ $t('status_page.perfomance') }}</h5>
                    </td>
                    <td>
                        <h5 class="td_title">{{ $t('status_page.last_gp_created') }}</h5>
                    </td>
                    <td>
                        <h5>{{ `${statusData.last_gp_age}` }}</h5>
                    </td>
                    <td v-if="getStatusText" :data-cache="getStatusColor" class="value">
                        <h5>{{ getStatusText }}</h5>
                    </td>
                </tr>
                <tr>
                    <td>P-03</td>
                    <td>
                        <h5>{{ $t('status_page.perfomance') }}</h5>
                    </td>
                    <td>
                        <h5 class="td_title">{{ $t('status_page.aggregated_report_six') }}</h5>
                    </td>
                    <td>
                        <h5></h5>
                    </td>
                    <td v-if="statusData.backend_db_ok" :data-cache="statusData.backend_db_ok" class="value">
                        <h5>{{ statusData.backend_db_ok ? 'OK' : 'NOT OK' }}</h5>
                    </td>
                </tr>
                <tr>
                    <td>P-03</td>
                    <td>
                        <h5>{{ $t('status_page.perfomance') }}</h5>
                    </td>
                    <td>
                        <h5 class="td_title">{{ $t('status_page.heartbeat_age') }}</h5>
                    </td>
                    <td>
                        <h5 v-if="statusData.heartbeat_data && statusData.heartbeat_data.current_age">{{
                            `${statusData.heartbeat_data.current_age}` }}</h5>
                    </td>
                    <td v-if="statusData.heartbeat_ok" :data-cache="statusData.heartbeat_ok" class="value">
                        <h5>{{ statusData.heartbeat_ok ? 'OK' : 'NOT OK' }}</h5>
                    </td>
                </tr>
            </tbody>
        </table>
        <h3 class="some_raw">{{ $t('status_page.some_raw_metrics') }}</h3>
        <table>
            <tbody>
                <tr>
                    <td>
                        <h5 class="td_title">{{ $t('status_page.gp_today') }}</h5>
                    </td>
                    <td>
                        <h5>{{ `${statusData.today_gp_count}` }}</h5>
                    </td>
                </tr>
                <tr>
                    <td>
                        <h5 class="td_title">{{ $t('status_page.gp_open') }}</h5>
                    </td>
                    <td>
                        <h5>{{ `${statusData.open_gp_count}` }}</h5>
                    </td>
                </tr>
                <tr>
                    <td>
                        <h5 class="td_title">{{ $t('status_page.background_jobs') }}</h5>
                    </td>
                    <td>
                        <h5>{{ `${statusData.background_jobs}` }}</h5>
                    </td>
                </tr>
                <tr>
                    <td>
                        <h5 class="td_title">{{ $t('status_page.deploy_commit') }}</h5>
                    </td>
                    <td>
                        <h5>{{ `${statusData.git_commit}` }}</h5>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import api from '../common/api';


export default {
    data() {
        return {
            statusData: [],
        };
    },
    computed: {
        getStatusColor() {
            if (this.statusData && (this.statusData.last_gp_age < this.statusData.gp_age_thresh_min)) {
                return 'green';
            } else if (this.statusData && (this.statusData.last_gp_age < this.statusData.gp_age_thresh_max)) {
                return 'yellow';
            } else {
                return 'red';
            }
        },
        getStatusText() {
            if (this.statusData && (this.statusData.last_gp_age < this.statusData.gp_age_thresh_max)) {
                return 'OK';
            } else {
                return 'NOT OK';
            }
        },
    },
    mounted() {
        this.fetchData();
    },
    methods: {
        fetchData() {
            api.getStatus()
                .then((res) => {
                    this.statusData = res.data;
                })
                .catch((err) => {
                    if (err.response !== undefined) {
                        this.handleErrors(err.response.data);
                    } else {
                        this.handleErrors(err);
                    }
                });
        },
    },
};
</script>
<style scoped lang="scss">
.title {
    margin-top: 2rem;
}

.value {
    $green-color: rgba(0, 211, 0, 0.712);
    $red-color: rgba(226, 0, 0, 0.507);
    $yellow-color: rgba(226, 211, 0, 0.507);

    &[data-cache="true"] {
        background-color: $green-color;
    }

    &[data-cache="false"] {
        background-color: $red-color;
    }

    &[data-cache="yellow"] {
        background-color: $yellow-color;
    }

    &[data-cache="green"] {
        background-color: $green-color;
    }

    &[data-cache="red"] {
        background-color: $red-color;
    }
}

table {

    thead {
        text-align: center;
        height: 3rem;

        tr {
            border-bottom: 1px solid rgba(0, 0, 0, 0.459);
        }
    }

    tbody tr td {
        margin: 10px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.164);

        .td_title {
            font-weight: 500;
        }
    }
}

table h5 {
    margin: 0.75rem 2rem;
}

.some_raw {
    margin-top: 2rem;
}

@media (max-width: 991px) {
    .wrapper {
        margin-left: 0 !important;
    }
}
</style>
<template>
  <div class="result-table">
    <base-button @click="showAll=!showAll">
      {{ showAll ? $t('hide') : $t('show_all') }}
    </base-button>
    <div class="results">
      <h3>{{ $route.name }}</h3>
      <div class="table-container">
        <div class="games-table">
          <h5>{{ $t('game.active-game') }}</h5>
          <table v-if="activeGames">
            <thead>
              <tr>
                <th>Id</th>
                <th>
                  {{ $t('name') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="game in activeGames" :key="game.id">
                <td>
                  {{ game.id }}
                </td>
                <td>
                  <a v-if="game.identifier === 'quiz'" :href="quizEditorUrl" target="_blank">
                    {{ game.name }}
                  </a>
                  <router-link v-if="game.identifier !== 'quiz'" :to="`/game/${game.id}`">
                    {{ game.name }}
                  </router-link>

                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="games-table" v-if="showAll && obsoleteGames.length > 0">
          <h5>{{ $t('game.obsolete-game') }}</h5>
          <table v-if="obsoleteGames">
            <thead>
              <tr>
                <th>Id</th>
                <th>
                  {{ $t('name') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="game in obsoleteGames" :key="game.id">
                <td>
                  {{ game.id }}
                </td>
                <td>
                  <router-link :to="`/game/${game.id}`">
                    {{ game.name }}
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <Message v-if="resultMessage !== ''" :classes="''" :message="resultMessage" @hideResult="resultMessage = ''">
      </Message>
    </div>
  </div>
</template>
<script>
import api from '../common/api';
import sharedMethods from '../mixins/sharedMethods';
import Message from '../components/Message.vue';
import { QUIZ_EDITOR } from '../common/constants';

export default {
  components: {
    Message,
  },
  mixins: [sharedMethods],
  data() {
    return {
      activeGames: null,
      obsoleteGames: null,
      showAll: false,
      quizEditorUrl: QUIZ_EDITOR,
    };
  },
  mounted() {
    api.listGames(1)
      .then((res) => {
        this.activeGames = res.data.items;
      })
      .catch((err) => {
        if (err.response !== undefined) {
          this.handleErrors(err.response);
        } else {
          this.handleErrors(err);
        }
      });
    api.listGames(1, 'obsolete')
      .then((res) => {
        this.obsoleteGames = res.data.items;
      })
      .catch((err) => {
        if (err.response !== undefined) {
          this.handleErrors(err.response);
        } else {
          this.handleErrors(err);
        }
      });
  },
};
</script>
<style lang="scss">
.table-container {
  white-space: nowrap;

  .games-table {
    width: auto !important;
    display: inline-block;
    padding: 1em;
    vertical-align: top;
    margin: 0 50px;
  }
}

button {
  margin-right: 0 !important;
}
</style>

<template>
  <div class="userTable">
    <!-- Users of client -->
    <table v-if="responseData
      && responseData.length
      && displayUsers" class="user-table">
      <thead>
        <tr v-if="users[0]">
          <th v-for="(header, i) in clientUsersHeaders" :key="`client-user-${i}`">
            {{ header }}
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(user) in responseData" :key="user.id">
          <td>
            {{ user.id }}
          </td>
          <td>
            <router-link :to="`/user/${user.id}`">
              {{ user.email }}
            </router-link>
          </td>
          <td>
            {{ user.disabled ? $t('yes') : $t('no') }}
          </td>
          <td>
            {{ user.settings && user.settings.language ? user.settings.language : '-' }}
          </td>
          <td>
            {{ user.created_at | moment }}
          </td>
          <td>
            {{ user.last_login_at | moment }}
          </td>
          <td>
            {{ user.last_seen | moment }}
          </td>

          <td v-if="user.clients.length > 0">
            <table class="inner-table">
              <tr v-for="(client, j) in user.clients" :key="`user-client-id-${j}`">
                <td>
                  {{ client.id }}
                </td>
              </tr>
            </table>
          </td>
          <td v-if="user.clients.length > 0">
            <table class="inner-table">
              <tr v-for="(client, k) in user.clients" :key="`user-client-name-${k}`">
                <td>
                  {{ client.name }}
                </td>
              </tr>
            </table>
          </td>
          <td>
            <input name="disable" type="checkbox" true-value="true" false-value="false" class="select-box"
              @change="selected()">
          </td>
        </tr>
      </tbody>
    </table>

    <!-- All users, exportable table  -->
    <table v-if="responseData
      && responseData.length
      && exportUsers" id="export">
      <thead>
        <tr>
          <th v-for="(header, i) in allUsersHeaders" :key="`all-users-${i}`">
            {{ header }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(user) in responseData" :key="`export-user${user.id}`">
          <td>
            {{ user.id }}
          </td>
          <td>
            <router-link :to="`/user/${user.id}`">
              {{ user.email }}
            </router-link>
          </td>
          <td>
            {{ user.disabled ? $t('yes') : $t('no') }}
          </td>
          <td>
            {{ user.settings && user.settings.language ? user.settings.language : '-' }}
          </td>
          <td>
            {{ clientValue(user.clients, "id") }}
          </td>
          <td>
            {{ clientValue(user.clients, "name") }}
          </td>
          <td>
            {{ clientValue(user.clients, "id", "parent") }}
          </td>
          <td>
            {{ clientValue(user.clients, "name", "parent") }}
          </td>
          <td>
            {{ clientValue(user.clients, "id", "top_parent") }}
          </td>
          <td>
            {{ clientValue(user.clients, "name", "top_parent") }}
          </td>
          <td>
            {{ user.account_service_id || '-' }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

import Filters from '../mixins/filters';

export default {
  components: {
  },
  mixins: [Filters],
  props: {
    userClients: {
      required: true,
    },
    responseData: {
      required: true,
    },
    displayUsers: {
      required: true,
    },
    clientUsersHeaders: {
      required: true,
    },
    clients: {
      required: true,
    },
    users: {
      required: true,
    },
    exportUsers: {
      required: true,
    },
    allUsersHeaders: {
      required: true,
    },
  },
  methods: {
    selected() {
      this.$emit('selected');
    },
    clientValue(clients, key, parent) {
      let value = '';
      if (
        parent
        && clients
      ) {
        clients.forEach((client, i) => {
          if (
            client[parent]
            && i > 0
          ) {
            value = value.concat('\r\n', client[parent][key]);
          } else if (client[parent]) {
            value = value.concat(client[parent][key]);
          } else if (
            !client[parent]
            && i > 0
          ) {
            value = value.concat('\r\n', '-');
          } else if (!client[parent]) {
            value = value.concat('-');
          }
        });
      } else if (clients) {
        clients.forEach((client, i) => {
          if (i > 0) {
            value = value.concat('\r\n', client[key]);
          } else {
            value = value.concat(client[key]);
          }
        });
      }

      return value;
    },
  },
};
</script>

<style lang="scss">
.userTable {
  margin-bottom: 60px;
}
</style>

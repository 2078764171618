<template>
    <div v-if="benchmark" class="benchmark">
        <h3 v-if="selectedAction === 'edit'" class="calculator_title">{{ $t('benchmark.editor') }}</h3>
        <h3 v-if="selectedAction === 'add'" class="calculator_title">{{ $t('benchmark.creator') }}</h3>
        <div class="row">
            <label v-if="selectedAction == 'edit'" class="id" for="identifier">
                <p class="label-text">{{ $t('ID') }}: {{ `${benchmark.id}` }}</p>
            </label>
            <label for="name">
                <p class="label-text">{{ $t('name') }}:</p>
                <base-input class="name-input" :placeholder="$t('name')" :name="'name'" type="text"
                    v-model="benchmark.name"></base-input>
            </label>
        </div>
        <hr />
        <div class="row3">
            <p class="label-text">{{ $t('benchmark.KPI') }}:</p>
        </div>
        <div class="kpis" v-if="benchmarkKPI" v-for="(kpi, i) in benchmarkKPI" :key="`${renderer}_${i}`">
            <Dropdown v-if="benchmark.kpis" class="kpiDropdown" :options="formattedKpiList"
                @selected="kpiTypesChange(i, $event)" :defaultValue="kpiDefault(i)" :disabled="false" name="kpi"
                :maxItem="kpiList.length" :placeholder="$t('select_kpi_type')" />
            <base-input class="value-input" :placeholder="$t('benchmark.min_value')" :name="'min_value'" type="text"
                v-model="kpi.min_value">
            </base-input>
            <base-input class="value-input" :placeholder="$t('benchmark.max_value')" :name="'max_value'" type="text"
                v-model="kpi.max_value">
            </base-input>
            <base-button class="remove" v-if="i > 0" :disabled="benchmarkKPI.length === 0" @click="removeKPI(i)">
                X
            </base-button>
            <base-button v-if="i === 0" class="add-member" @click="addKPI(i)">
                {{ $t('report_editor.add_kpi') }}
            </base-button>
        </div>
        <base-button v-if="selectedAction === 'edit'" @click="editBenchmark">
            {{ $t('edit') }}
        </base-button>
        <base-button v-if="selectedAction === 'add'" @click="addBenchmark">
            {{ $t('add') }}
        </base-button>
        <div class="ring_massage">
            <Message v-if="responseMsg !== ''" :classes="''" :message="responseMsg" @hideResult="responseMsg = ''">
            </Message>
            <LoadingRing v-if="loading" class="pagination-wrapper"></LoadingRing>
        </div>
    </div>
</template>
<script>
import api from '../common/api.js';
import LoadingRing from '../components/base-components/LoadingRing';
import Dropdown from '../components/Dropdown.vue';
import Message from '../components/Message.vue';
import sharedMethods from '../mixins/sharedMethods';

export default {
    components: {
        Dropdown,
        Message,
        LoadingRing,

    },
    mixins: [sharedMethods],
    data() {
        return {
            renderer: 0,
            loading: false,
            responseMsg: '',
            benchmark: null,
            selectedAction: null,
            currentLang: 'en',
            benchmarkKPI: [],
            kpiList: [],
            currentPage: 1,
            benchmarkSchema: {
                name: null,
                kpis: [],
                benchmarkKPI: {
                    kpi: [],
                    max_value: null,
                    min_value: null,
                },
            },
        }
    },
    computed: {
        formattedKpiList() {
            if (this.kpiList) {
                return this.kpiList.map((kpi) => ({
                    ...kpi,
                    displayName: `${kpi.name} (${kpi.identifier})`,
                }));
            }
        },
    },
    methods: {
        setMsg(msg) {
            this.responseMsg = msg;
        },
        counterPush(editorsave) {
            const timeoutSeconds = 3;
            let countDown = timeoutSeconds;

            const message = `${this.$t(editorsave)}`;
            this.setMsg(message);

            const intervalId = setInterval(() => {
                countDown--;
                if (countDown < 0) {
                    clearInterval(intervalId);
                    this.$router.push('/benchmarks');
                }
            }, 1000);
        },
        addKPI() {
            const order = this.benchmarkKPI.length + 1;
            this.benchmarkKPI.push({
                kpi: [],
                max_value: null,
                min_value: null,
            });
        },
        removeKPI(i) {
            this.benchmarkKPI.splice(i, 1);
        },
        kpiDefault(i) {
            if ((i || i == 0) && this.benchmarkKPI[i].kpi.identifier !== undefined) {
                return this.benchmarkKPI[i].kpi.name + " (" + this.benchmarkKPI[i].kpi.identifier + ")";
            }
        },
        kpiTypesChange(index, newKpi) {
            this.benchmarkKPI[index].kpi = newKpi;
            this.renderer++;
        },
        getBenchmark() {
            api
                .getBenchmark(this.$route.params.benchmarkId)
                .then((res) => {
                    this.benchmark = res.data;
                    this.benchmarkKPI = res.data.kpis;
                })
                .catch((err) => {
                    if (err.response !== undefined) {
                        this.handleErrors(err.response);
                    } else {
                        this.handleErrors(err);
                    }
                });
        },
        addBenchmark() {
            const addBenchmark = {
                name: this.benchmark.name,
                kpis: this.benchmarkKPI.map((kpi) => ({
                    min_value: kpi.min_value,
                    max_value: kpi.max_value,
                    kpi_id: kpi.kpi.id,
                })),
            };
            if (this.isValidBenchmark(addBenchmark)) {
                this.loading = true;
                api
                    .addBenchmark(addBenchmark)
                    .then(() => {
                        this.counterPush('result_message.benchmark_added');
                    })
                    .catch((err) => {
                        if (err.response !== undefined) {
                            this.handleErrors(err.response);
                        } else {
                            this.handleErrors(err);
                        }
                        this.loading = false;
                    });
            } else {
                this.setMsg(`${this.$t('result_message.data_incomplet')}`);
            }
        },
        editBenchmark() {
            const editedBenchmark = {
                name: this.benchmark.name,
                kpis: this.benchmarkKPI.map((kpi) => ({
                    min_value: kpi.min_value,
                    max_value: kpi.max_value,
                    kpi_id: kpi.kpi.id,
                })),
            };
            if (this.isValidBenchmark(editedBenchmark)) {
                this.loading = true;
                api
                    .editBenchmark(this.$route.params.benchmarkId, editedBenchmark)
                    .then(() => {
                        this.counterPush('result_message.benchmark_edited');
                    })
                    .catch((err) => {
                        if (err.response !== undefined) {
                            this.handleErrors(err.response);
                        } else {
                            this.handleErrors(err);
                        }
                        this.loading = false;
                    });
            } else {
                this.setMsg(`${this.$t('result_message.data_incomplet')}`);
            }
        },
        isValidBenchmark(benchmark) {
            const nonEmptyKPIsExist = Array.isArray(benchmark.kpis);
            const nonEmptyKPIidValuesExist = benchmark.kpis.every(kpi => kpi.kpi_id !== undefined && kpi.min_value !== null && kpi.max_value !== null);

            if (benchmark.name && benchmark.kpis.length !== 0 && nonEmptyKPIsExist && nonEmptyKPIidValuesExist) {
                return true;
            }
            return false;
        },
        async getKpiList() {
            api
                .listKpis(this.currentPage)
                .then((res) => {
                    const activeKpis = res.data.items.filter((kpi) => !kpi.obsolete);
                    this.kpiList.push(...activeKpis);
                    if (res.data.page >= res.data.pages) {
                        return;
                    } else {
                        this.currentPage = this.currentPage + 1;
                        this.getKpiList();
                    }
                })
                .catch((err) => {
                    if (err.response !== undefined) {
                        this.handleErrors(err.response);
                    } else {
                        this.handleErrors(err);
                    }
                });
        },
    },
    mounted() {
        this.getKpiList();
        if (this.$route.params.benchmarkId) {
            this.getBenchmark();
            this.selectedAction = "edit";
        } else {
            this.benchmark = this.benchmarkSchema;
            this.benchmarkKPI.push({
                kpi: [],
                max_value: null,
                min_value: null,
            });
            this.selectedAction = "add";
        }
    }
}
</script>
<style lang="scss">
.benchmark {
    margin: 31px 30px 25px 40px !important;

    .kpiDropdown {
        width: 25rem;
    }

    .kpis {
        display: flex;

        .dropdown::after {
            margin-left: 370px !important;
        }

        .dropdown-content {
            width: 370px !important;
        }
    }

    .value-input {
        width: 4rem;
        margin-right: 1rem;
    }

    .calculator_title {
        margin-bottom: 2rem;
    }

    .row label {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .id {
        margin-right: 20px;
    }

    .row3 {
        display: flex;
        align-items: flex-start;
        flex-direction: column;

        label {
            margin-bottom: 1.7rem;

            p {
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.7;
                color: #3c4866;
            }
        }

        .label-text {
            margin-bottom: 5px !important;
        }
    }

    .ring_massage {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}
</style>
<template>
  <div class="report-module">
    <div class="row">

      <!-- Module type selector for new modules -->
      <Dropdown
        v-if="!$route.params.type"
        class="add"
        :options="moduleTypes.map((module, i) =>({id: i, name: module}))"
        @selected="setModuleType"
        :disabled="false"
        name="modules"
        :maxItem="moduleTypes.length"
        :placeholder="$t('report_editor.select_module_type')"
        :key="`module`"
      />

      <base-button
        v-if="selectedAction"
        @click="$router.push('/modules')"
      >
        {{ $t('back') }}
      </base-button>
    </div>

    <!-- NEW MODULE -->
    <div v-if="editableModule">
      <RadarModule
        v-if="moduleType === 'radar'"
        :moduleType="moduleType"
        :kpis="kpis"
        :module="editableModule"
        :allLangs="allLangs"
        @saveModule="saveModule"
        :selectedAction="selectedAction"/>
      <PolarModule
        v-else-if="moduleType === 'polar'"
        :moduleType="moduleType"
        :kpis="kpis"
        :module="editableModule"
        :allLangs="allLangs"
        @saveModule="saveModule"
        :selectedAction="selectedAction"/>
        <BarchartModule
          v-else-if="moduleType === 'bar'"
          :moduleType="moduleType"
          :kpis="kpis"
          :module="editableModule"
          :allLangs="allLangs"
          @saveModule="saveModule"
          :selectedAction="selectedAction"
        />
        <StrengthModule
          v-else-if="moduleType === 'strength'"
          :moduleType="moduleType"
          :module="editableModule"
          :allLangs="allLangs"
          @saveModule="saveModule"
          :selectedAction="selectedAction"
        />
        <WarningsModule
          v-else-if="moduleType === 'warning'"
          :moduleType="moduleType"
          :module="editableModule"
          :allLangs="allLangs"
          @saveModule="saveModule"
          :selectedAction="selectedAction"
        />
        <TextModule
          v-else-if="moduleType === 'text'"
          :moduleType="moduleType"
          :module="editableModule"
          :allLangs="allLangs"
          @saveModule="saveModule"
          :selectedAction="selectedAction"
        />
        <QuizModule
          v-else-if="moduleType === 'quiz'"
          :moduleType="moduleType"
          :module="editableModule"
          :allLangs="allLangs"
          @saveModule="saveModule"
          :selectedAction="selectedAction"
        />
    </div>
    <hr>
    <message
      v-if="resultMessage !== ''"
      :classes="''"
      :message="resultMessage"
      @hideResult="resultMessage = ''">
    ></message>
  </div>
</template>

<script>
import Dropdown from '../components/Dropdown.vue';
import api from '../common/api.js';
import sharedMethods from '../mixins/sharedMethods';
import Message from '../components/Message.vue';
import RadarModule from '../components/moduleTypes/RadarModule.vue';
import PolarModule from '../components/moduleTypes/PolarModule.vue';
import BarchartModule from '../components/moduleTypes/BarchartModule.vue';
import StrengthModule from '../components/moduleTypes/StrengthModule.vue';
import WarningsModule from '../components/moduleTypes/WarningsModule.vue';
import TextModule from '../components/moduleTypes/TextModule.vue';
import QuizModule from '../components/moduleTypes/QuizModule.vue';

export default {
  components: {
    RadarModule,
    PolarModule,
    BarchartModule,
    StrengthModule,
    WarningsModule,
    TextModule,
    QuizModule,
    Dropdown,
    Message,
  },
  mixins: [sharedMethods],
  data() {
    return {
      kpiDataSize: 0,
      kpis: [],
      key: 0,
      render: 0,
      selectedAction: this.$route.params.id ? 'edit' : 'add',
      moduleType: this.$route.params.type ? this.$route.params.type : null,
      selectedLang: 'en',
      name: '',
      modes: [
        { id: 0, name: '', value: 'chart' },
        { id: 1, name: 'Text only', value: 'text' },
        { id: 2, name: 'Both', value: 'both' },
      ],
      editableModule: null,
      strengthParams: {
        strength_count: null,
        weakness_count: null,
        mid_low_score: null,
        mid_score: null,
        mid_high_score: null,
        high_score: null,
        translations: {
          title: {},
          strengths_title: {},
          strengths_text: {},
          weaknesses_title: {},
          weaknesses_text: {},
          other_kpis_title: {},
          other_kpis_text: {},
        },
      },
      barParams: {
        mid_low_score: null,
        mid_score: null,
        mid_high_score: null,
        high_score: null,
        kpis: [
          { kpi_id: '' },
          { kpi_id: '' },
          { kpi_id: '' },
        ],
        display_parts: 'both',
        display_count: null,
        with_icons: false,
        translations: {
          title: {},
        },
      },
      polarParams: {
        kpis: [
          { kpi_id: '' },
          { kpi_id: '' },
          { kpi_id: '' },
        ],
        translations: {
          title: {},
        },
      },
      radarParams: {
        x_kpi_id: null,
        x_low: null,
        x_high: null,
        y_kpi_id: null,
        y_low: null,
        y_high: null,
        display_parts: 'both',
        translations: {
          title: {},
          description: {},
          low_low_name: {},
          low_low: {},
          low_high_name: {},
          low_high: {},
          high_low_name: {},
          high_low: {},
          high_high_name: {},
          high_high: {},
          average_name: {},
          average: {},
        },
      },
      kpiId: '',
      newKpi: null,
      translations: {
        title: {},
        text: {},
      },
    };
  },
  computed: {
    moduleTypes() {
      return this.$store.state.admin.moduleTypes;
    },
    allLangs() {
      return this.$store.state.admin.langs;
    },
    modules() {
      const modules = [];
      this.moduleTypes.forEach((module, i) => {
        modules.push({
          id: i,
          name: module,
        });
      });
      return modules;
    },
  },
  methods: {
    saveModule(moduleType, module) {
      const payload = this.checkEmpty(module);
      if (this.selectedAction === 'add') {
        this.addModule(moduleType, payload);
      } else {
        this.editModule(moduleType, payload);
      }
    },
    checkEmpty(payload) {
      const module = Object.entries(payload);
      const corrected = module.map(([key, value]) => {
        if (value === '') value = null;
        return [key, value];
      });
      return Object.fromEntries(corrected);
    },
    rerender() {
      this.render++;
    },
    setDefaultLang() {
      this.$children.forEach((child, i) => {
        if (child.$el.className === 'dropdown lang') {
          this.$children[i].setDefault('English');
        }
      });
      this.selectedLang = 'en';
    },
    setModuleType(type) {
      this.moduleType = type.name;
      if (!this.$route.params.id) {
        this.setParams();
      }
    },
    getKpiList(page) {
      api.listKpis(page)
        .then((res) => {
          this.kpiDataSize = res.data.total / 100;
          res.data.items.forEach((item) => {
            if (!item.obsolete) {
              const displayName = item.name
                ? `${item.identifier} - ${item.name}`
                : item.identifier;
              this.kpis.unshift({
                id: item.id,
                name: displayName,
                identifier: item.identifier,
                order: item.order,
                excluded: item.excluded,
                obsolete: item.obsolete,
              });
            }
          });
          if (page < this.kpiDataSize) {
            page++;
            this.getKpiList(page);
          }
        })
        .catch((err) => {
          if (err.response !== undefined) {
            this.handleErrors(err.response);
          } else {
            this.handleErrors(err);
          }
        });
    },
    selectLang(lang) {
      this.selectedLang = lang.code;
    },
    getModule() {
      api.getModule(this.$route.params.type, this.$route.params.id)
        .then((res) => {
          this.editableModule = res.data;
          this.rerender();
          setTimeout(() => {
            this.setDefaultLang();
          }, 200);
        })
        .catch((err) => {
          if (err.response !== undefined) {
            this.handleErrors(err.response);
          } else {
            this.handleErrors(err);
          }
        });
    },
    editModule(moduleType, module) {
      // patch endpoint requires different data structure
      if (module.x_kpi) {
        delete module.x_kpi;
      }
      if (module.y_kpi) {
        delete module.y_kpi;
      }

      if (module.kpis) {
        module.kpis = module.kpis.map((kpi) => ({
          kpi_id: kpi.id ? kpi.id : kpi.kpi.id,
        }));
      }

      api.editModule(moduleType, module.id, module)
        .then(() => {
          this.setMsg(this.$t('result_message.module_edited'));
          // setTimeout(() => window.location.reload(), 2500);
        })
        .catch((err) => {
          if (err.response !== undefined) {
            this.handleErrors(err.response);
          } else {
            this.handleErrors(err);
          }
        });
    },
    setParams() {
      let params;
      if (
        this.moduleType === 'text'
        || this.moduleType === 'warning' || this.moduleType === 'quiz'
      ) {
        params = {
          name: this.name,
          translations: this.translations,
        };
      } else if (this.moduleType === 'strength') {
        params = {
          name: this.name,
          strength_count: this.strengthParams.strength_count,
          weakness_count: this.strengthParams.weakness_count,
          mid_low_score: this.strengthParams.mid_low_score,
          mid_score: this.strengthParams.mid_score,
          mid_high_score: this.strengthParams.mid_high_score,
          high_score: this.strengthParams.high_score,
          translations: this.strengthParams.translations,
        };
      } else if (this.moduleType === 'bar') {
        params = {
          name: this.name,
          mid_low_score: this.barParams.mid_low_score,
          mid_score: this.barParams.mid_score,
          mid_high_score: this.barParams.mid_high_score,
          high_score: this.barParams.high_score,
          kpis: this.barParams.kpis,
          display_parts: this.barParams.display_parts,
          display_count: this.barParams.display_count,
          with_icons: this.barParams.with_icons,
          translations: this.barParams.translations,
        };
      } else if (this.moduleType === 'polar') {
        params = {
          name: this.name,
          kpis: this.polarParams.kpis,
          translations: this.polarParams.translations,
        };
      } else if (this.moduleType === 'radar') {
        params = {
          name: this.name,
          x_kpi_id: this.radarParams.x_kpi_id,
          x_high: this.radarParams.x_high,
          x_low: this.radarParams.x_low,
          y_kpi_id: this.radarParams.y_kpi_id,
          y_high: this.radarParams.y_high,
          y_low: this.radarParams.y_low,
          display_parts: this.radarParams.display_parts,
          translations: this.radarParams.translations,
        };
      }
      this.editableModule = params;
      this.allLangs.forEach((element) => {
        Object.keys(this.editableModule.translations).forEach((key) => {
          this.$set(this.editableModule.translations[key], `${element.code}`, null);
        });
      });
    },
    addModule(moduleType, module) {
      if (module.kpis) {
        module.kpis = module.kpis.filter((kpi) => kpi.kpi_id !== '');
      }
      api.addModule(moduleType, module)
        .then((res) => {
          this.setMsg(this.$t('result_message.module_added'));
          setTimeout(() => this.$router.push(`/module/${moduleType}/${res.data.id}`), 2500);
        })
        .catch((err) => {
          if (err.response !== undefined) {
            this.handleErrors(err.response);
          } else {
            this.handleErrors(err);
          }
        });
    },
  },
  beforeMount() {
    if (this.$route.params.id) {
      this.getModule();
    }

    if (this.kpiDataSize === 0) {
      this.getKpiList(1);
    }
  },
};
</script>

<style lang="scss" scoped>
.row {
  display: flex;
  align-items: center;

  .first-row {
    margin-top: 0.8rem;
    margin-right: 30px;
    margin-bottom: 0;
  }

  label {
    display: flex;
    margin: 0;
    align-items: center;
    margin-top: 0.8rem;

    .label-text {
      font-weight: 400;
      color: #3C4866;
      margin-right: 10px;
      margin-bottom: 0;
    }

    .form-group {
      margin-top: 0;

      &.smaller {
        width: 60px;
      }
    }

    textarea {
      height: 70px;
      width: 250px;
      margin-top: 0;
      resize: both;
    }
  }

  &:not(:first-child) {
    margin-top: 0.8rem;
  }

  .form-group, textarea {
    margin-top: 0.8rem;
    margin-right: 30px;
    margin-bottom: 0;
  }

  .form-group input, textarea {
    height: 43px;
    border: 1px solid #CAD1D7;
    border-radius: 0.25rem;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.5;
    color: #3C4866;

    &::placeholder {
      color: #8898AA;
      opacity: 0.8;
    }
  }

  textarea {
    height: 70px;
    width: 250px;
    padding: 10px 12px;
    resize: both;
  }

  &.strength {
    .strength-flex-small {
      flex: 35%;
    }

    .strength-flex-large {
      flex: 60%;
    }
  }

  &.radar {
    .radar-flex-small {
      flex: 35%;
    }

    .radar-flex-large {
      flex: 60%;
    }

    .flex-full {
      flex: 100%;
    }
  }

  &.translations {
    margin-right: 10px;
  }
}

.selection-panel {
  .report-module .row {
    &:not(:first-child) button {
      margin-top: 0.8rem;
    }

    button:last-child {
      margin-right: 0;
    }
  }

  hr {
    position: absolute;
    width: 100%;
    margin-left: -45px;
  }
}
</style>

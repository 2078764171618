<template>
  <div class="msg-box" :class="classes">
    <p class="message" v-html="message"></p>
    <a class="close-msg" href="javascript:void(0)" @click="$emit('hideResult')"></a>
  </div>
</template>
<script>
export default {
  props: {
    classes: {
      required: true,
    },
    message: {
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
  .msg-box {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;

    &.move-up {
      transform: translate(0, -65px);
    }

    .message {
      margin-bottom: 0;
      font-weight: 500;
      line-height: 1.5;
    }

    .close-msg {
      height: 36px;
      margin-left: 30px;
      margin-top: -12px;

      &::after {
        content: "\00d7";
        font-size: xx-large;
      }
    }
  }
</style>

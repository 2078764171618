<template>
  <div v-if="videocard && allLangs" class="wrapper">
     <h3>{{videocard.name  || $route.name}}</h3>
    <div class="row">
      <Dropdown
        :options="allLangs"
        @selected="setCurrentLang"
        :defaultValue="allLangs[0].name"
        :disabled="false"
        name="lang"
        :maxItem="allLangs.length"
        :placeholder="$t('select_lang')"
        :language="true" 
      />

      <base-button
        @click="$router.push('/videocards')">
        {{ $t('back') }}
      </base-button>
    </div>
    <div class="policy-options row">
      <label for="name">
        <p class="label-text">{{ $t('name') }}:</p>
        <base-input
          class="name-input"
          :placeholder="$t('name')"
          name="name"
          type="text"
          v-model="videocard.name"
        ></base-input>
      </label>
      <label for="time-limit">
          <p class="label-text">
            {{ $t(`time-limit`) }}:
            <br>
            <span class="min"
              :class="videocard.time_limit < 10 ? 'error' : ''">
              * {{$t('time-limit-min')}} 
            </span>
          </p>
          <base-input
            class="time-limit"
            name="time-limit"
            type="number"
            min="10"
            :valid="videocard.time_limit > 9"
            v-model.number="videocard.time_limit"
          ></base-input>
      </label>

      <base-button
        v-if="$route.name === 'New Video Card'"
        @click="addVideoCard"
        :disabled="videocard.time_limit < 10">
        {{ $t('add') }}
      </base-button>
      <base-button v-else
        @click="showConfirmation = true"
        :disabled="videocard.time_limit < 10">
        {{ $t('edit') }}
      </base-button>
    </div>
    <hr v-if="showConfirmation || resultMessage !== ''"/>
    <Message
      v-if="resultMessage !== ''"
      :classes="''"
      :message="resultMessage"
      @hideResult="resultMessage = ''"
    >
    </Message>
    <Confirmation
      v-if="showConfirmation"
      :userManagement="false"
      :confirmationNeeded="true"
      :confirmMessage="confirmMessage"
      selected="general"
      @hideConfirmation="showConfirmation = false"
      @onYesClicked="editVideoCard"
    />
    <hr />
    <div v-if="videocard && currentLang">
      <div class="row">
        <label for="question"  class="video-question">
        <p class="label-text">{{ $t('question') }}:</p>
        <textarea
          v-model="videocard.translations.question[currentLang]"
          cols="30">
        </textarea>
      </label>
      </div>
      <div class="row">
        <p class="label-text video" v-html="$t('video-text')"></p>
        <ckeditor
          class="text-editor"
          v-model="videocard.translations.text[currentLang]"
          :config="editorConfig"
        >
        </ckeditor>
      </div>
    </div>
  </div>
</template>

<script>
import sharedMethods from '../mixins/sharedMethods';
import api from '../common/api';
import Dropdown from '../components/Dropdown.vue';
import Message from '../components/Message.vue';

export default {
  components: {
    Dropdown,
    Message,
  },
  mixins: [sharedMethods],
  data() {
    return {
      videocard: null,
      currentLang: 'en',
      newCard: {
        name: null,
        translations: {
          question: {},
          text: {},
        },
        time_limit: 60,
      },
      editorConfig: {
        extraPlugins: 'justify',
        resize_dir: 'both',
        resize_minWidth: 300,
        width: 800,
        height: 280,
        entities_latin: false,
        entities_greek: false,
      },
      showConfirmation: false,
      confirmMessage: this.$t('confirm_message.edit_video_card'),
    };
  },
  computed: {
    allLangs() {
      return this.$store.state.admin.langs;
    },
  },
  watch: {
    allLangs() {
      this.allLangs.forEach((element) => {
        this.$set(this.newCard.translations.question, `${element.code}`, null);
        this.$set(this.newCard.translations.text, `${element.code}`, null);
      });
      this.videocard = this.newCard;
    },
  },
  methods: {
    getplaycard(id) {
      api
        .getVideoCard(id)
        .then((res) => {
          this.videocard = res.data;
        })
        .catch((err) => {
          if (err.response !== undefined) {
            this.handleErrors(err.response);
          } else {
            this.handleErrors(err);
          }
        });
    },
    addVideoCard() {
      api
        .addVideoCard(this.newCard)
        .then(() => {
          this.setMsg(this.$t('result_message.video_card_added'));
        })
        .catch((err) => {
          if (err.response !== undefined) {
            this.handleErrors(err.response);
          } else {
            this.handleErrors(err);
          }
        });
    },
    editVideoCard() {
      api
        .editVideoCard(this.videocard.id, this.videocard)
        .then(() => {
          this.setMsg(this.$t('result_message.video_card_edited'));
        })
        .catch((err) => {
          if (err.response !== undefined) {
            this.handleErrors(err.response);
          } else {
            this.handleErrors(err);
          }
        });
    },
    setCurrentLang(lang) {
      this.currentLang = lang.code;
    },
    rerender() {
      this.render++;
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.getplaycard(this.$route.params.id);
    } else if (!this.$route.params.id && this.allLangs) {
      this.allLangs.forEach((element) => {
        this.$set(this.newCard.translations.text, `${element.code}`, null);
      });
      this.videocard = this.newCard;
    }
  },
};
</script>
<style scoped lang="scss">
.row {
  position: relative;
  display: flex;
  width: 1030px;
  margin-right: 10px;
  padding-bottom: 5px;

  &:not(:first-child) {
    margin-top: 0.8rem;
  }

  label {
    margin: 0;
    display: flex;
    align-items: center;
  }

  .min.error {
    color: red;
  }
}

.text-editor {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
}

.video-question {
   textarea {
    margin-bottom: 0.8rem;
    resize: both;
    min-width: 350px;
  }
  .label-text {
    font-weight: 600 !important;
  }
}

.label-text.video {
  font-weight: 600;
  color: #3c4866;
}

.time-limit {
  width: 120px;
}
</style>

<template>
  <div class="aggregator-options row members">
    <div v-if="(selectedAction === 'edit' || selectedAction === 'add') && aggregator">
      <div v-for="(member, i) in updatedMembers" :key="i" class="entity">
        <Dropdown
          :options="entityTypes"
          @selected="saveEntityType"
          @index="selectEntityType(i)"
          :defaultValue="getDefaultEntityType(updatedMembers[i].entity_type, i)"
          :disabled="false"
          name="entityTypes"
          :maxItem="entityTypes.length"
          :placeholder="$t('aggregator.select_entity_type')"
          :key="`entityType-${i}`"
        />

        <Dropdown
          v-if="selectedEntityTypes[i]"
          :options="
            selectedEntityTypes[i] === 'project'
              ? notMemberProjects
              : entities[selectedEntityTypes[i]]
          "
          @selected="setEntity"
          @index="selectEntity(i)"
          :defaultValue="getDefaultEntity(updatedMembers[i], i)"
          :disabled="false"
          :name="`entity-${i}`"
          :maxItem="
            selectedEntityTypes[i] === 'project'
              ? notMemberProjects.length
              : entities[selectedEntityTypes[i]].length
          "
          :placeholder="$t('aggregator.select_entity')"
          :key="`entities-${i}-${render}`"
        />

        <div class="btns">
          <base-button class="btn-up" :disabled="i === 0"
            @click="moveUp(updatedMembers, i, afterReorder, [i])">
            ^
          </base-button>

          <base-button
            class="btn-down"
            :disabled="i === updatedMembers.length - 1"
            @click="moveDown(updatedMembers, i, afterReorder, [i])"
          >
            ^
          </base-button>
        </div>

        <base-button
          class="remove"
          :disabled="updatedMembers.length === 1"
          @click="removeMember(i)"
        >
          X
        </base-button>

        <base-button v-if="i === 0" class="add-member" @click.prevent="addMember()">
          {{ $t('aggregator.add_member') }}
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import sharedMethods from '../mixins/sharedMethods';
import Dropdown from './Dropdown.vue';

export default {
  components: {
    Dropdown,
  },
  mixins: [sharedMethods],
  props: {
    selectedAction: {
      required: true,
    },
    aggregator: {
      required: true,
    },
    projects: {
      required: true,
    },
    notMemberProjects: {
      required: true,
    },
    playCards: {
      required: true,
    },
    videoCards: {
      required: true,
    },
    registerCards: {
      required: true,
    },
  },
  data() {
    return {
      render: 0,
      selectedEntityTypes: [],
      selectedEntityName: '',
      selectedEntityId: null,
      entityTypes: [
        { id: 0, name: 'project' },
        { id: 1, name: 'play_card' },
        { id: 2, name: 'register' },
        { id: 3, name: 'video' },
      ],
      entities: {
        project: this.projects,
        play_card: this.playCards,
        register: this.registerCards,
        video: this.videoCards,
      },
      updatedMembers: [],
    };
  },
  methods: {
    rerender() {
      this.render++;
    },
    handleErrors(err) {
      this.$emit('handleErrors', err);
    },
    setEntity(entity) {
      this.selectedEntityId = entity.id;
    },
    selectEntity(i) {
      this.updatedMembers[i].entity_type = this.selectedEntityTypes[i];
      this.updatedMembers[i].entity_id = this.selectedEntityId;
      delete this.updatedMembers[i].entity;
      this.sendToParent();
    },
    removeMember(i) {
      this.updatedMembers.splice(i, 1);
      this.selectedEntityTypes.splice(i, 1);
      this.sendToParent();
    },
    addMember() {
      this.updatedMembers.push({
        entity_id: null,
        entity_type: '',
      });
      this.sendToParent();
    },
    saveEntityType(type) {
      this.selectedEntityName = type.name;
    },
    selectEntityType(i) {
      this.selectedEntityTypes[i] = this.selectedEntityName;
      this.rerender();
    },
    getDefaultEntityType(entityType, i) {
      if (!this.selectedEntityName) {
        this.selectedEntityTypes[i] = entityType;
      }

      return this.selectedEntityTypes[i];
    },
    getDefaultEntity(member, i) {
      const entityId = member.entity ? member.entity.id : member.entity_id;
      const arr = this.entities[this.selectedEntityTypes[i]]
        .filter((obj) => obj.id === entityId)
        .map((entity) => entity.name);

      return arr[0] || '';
    },
    afterReorder(i) {
      const entityType = this.selectedEntityTypes.splice(i, 1)[0];
      this.selectedEntityTypes.splice(i - 1, 0, entityType);
      this.sendToParent();
    },
    sendToParent() {
      const payload = JSON.parse(JSON.stringify(this.updatedMembers));
      this.$emit('membersUpdated', payload);
    },
  },
  mounted() {
    this.selectedEntityName = '';
    this.updatedMembers = JSON.parse(JSON.stringify(this.aggregator.members));
  },
};
</script>

<style lang="scss" scoped>
.row.members {
  position: relative;
  display: flex;
  width: 1030px;
  margin-right: 10px;
  padding-bottom: 5px;

  .entity {
    display: flex;

    .btns {
      height: 43px;

      button {
        display: block;
        height: 22px;
        padding: 1px 6px;
        border-radius: 40px 40px 0 0;
        box-shadow: none;
      }

      .btn-up:disabled {
        transform: none;
      }

      .btn-down {
        margin-top: 0 !important;
        transform: rotate(180deg);

        &:hover {
          transform: rotate(180deg) translateY(-1px);

          &:disabled {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  label {
    margin: 0;
    display: flex;
    align-items: center;
    margin-top: 0.8rem;

    .label-text {
      font-weight: 400;
      color: #3c4866;
      margin-right: 10px;
      margin-bottom: 0;
    }

    .form-group {
      margin-top: 0;

      &.smaller {
        width: 60px;
      }
    }
  }

  &:not(:first-child) {
    margin-top: 0.8rem;
  }

  .form-group {
    margin-top: 0.8rem;
    margin-right: 30px;
    margin-bottom: 0;
  }

  .form-group input {
    height: 43px;
    border: 1px solid #cad1d7;
    border-radius: 0.25rem;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.5;
    color: #3c4866;

    &::placeholder {
      color: #8898aa;
      opacity: 0.8;
    }
  }
}

.selection-panel {
  .aggregator-options.row {
    &:not(:first-child) button {
      margin-top: 0.8rem;
    }

    button:last-child {
      margin-right: 0;
    }
  }
}
</style>

<template>
    <div class="pmcc-new-style">
        <div>
            <h3 class="pmcctitle">{{ $t('profile-match-calculator.title-new') }}</h3>
        </div>
        <div class="pmConfigUpload">
            <div class="pmConfigVersionData upload_label">
                <label class="pmcalculatorconfig_name" for="customInput">
                    <span class="label-text">{{ $t('profile-match-calculator.folder') }}:</span>
                    <base-input class="name-input" :placeholder="$t('profile-match-calculator.folder')" :name="'name'"
                        type="text" v-model="pmcalculatorFolder"></base-input>
                </label>
                <label for="upload">
                    <span class="label-text">{{ $t('profile-match-calculator.file_upload') }}:</span>
                    <span v-if="selectedFileName" class="upload_file_name">{{ selectedFileName }}</span>
                    <input type="file" ref="fileInput" @change="openFileInput" accept=".json" style="display: none;">
                    <base-button @click="$refs.fileInput.click()">
                        {{ $t('profile-match-calculator.file') }}
                    </base-button>
                </label>
                <LoadingRing v-if="uploadLoading" class="button-delete loading-download"></LoadingRing>
                <p v-if="uploadSuccess" class="button-delete loading-download pagination-wrapper">
                    {{ $t('profile-match-calculator.upload_success') }}
                </p>
                <label v-if="!uploadLoading && !uploadSuccess" for="upload">
                    <base-button @click="uploadFile" :disabled="!selectedFile && !pmcalculatorFolder">
                        {{ $t('profile-match-calculator.upload_file') }}
                    </base-button>
                </label>
                <Message v-if="resultMessage !== ''" :classes="''" :message="resultMessage"
                    @hideResult="resultMessage = ''">
                </Message>
            </div>
        </div>
    </div>
</template>
<script>
import Filters from '../mixins/filters';
import api from '../common/api';
import sharedMethods from '../mixins/sharedMethods';
import Message from '../components/Message.vue';
import LoadingRing from '../components/base-components/LoadingRing';

export default {
    components: {
        Message,
        LoadingRing,
    },
    mixins: [sharedMethods, Filters],
    data() {
        return {
            loading: false,
            selectedFile: null,
            selectedFileName: '',
            downloadLoading: false,
            uploadSuccess: false,
            uploadLoading: false,
            pmcalculatorFolder: '',
        };
    },
    methods: {
        openFileInput(event) {
            const file = event.target.files[0];
            if (file) {
                this.selectedFileName = file.name;
                this.selectedFile = file;
            }
        },
        uploadFile() {
            if (!this.selectedFile) {
                return;
            }
            this.uploadLoading = true;
            const formData = new FormData();
            formData.append("file", this.selectedFile);
            var pm_evaluator = this.pmcalculatorFolder;

            api
                .postPMCalcConfigUpload(pm_evaluator, formData)
                .then((res) => {

                })
                .catch((err) => {
                    if (err.response !== undefined) {
                        this.handleErrors(err.response);
                    } else {
                        this.handleErrors(err);
                    }
                }).finally(() => {
                    this.uploadSuccess = true;
                    this.uploadLoading = false;
                    setTimeout(() => this.$router.push(`/pm-calculator-list`), 2500);
                });
        },
    },
};
</script>
<style lang="scss">
.pmcc-new-style {
    .pmcctitle {
        font-weight: 500;
        text-align: center;
        margin: 0 !important;
    }

    .pmConfigVersionData {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        margin: 1rem 0;
        padding-top: 1rem;
        border-radius: 5px;
        background-color: white;
        box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);

        .pmcalculatorconfig_name {
            margin-top: 1rem;
            display: flex;
            align-items: baseline;
        }

        label {
            margin-bottom: 0.7rem;
            margin-left: 1rem;

            span {
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.7;
                color: #3c4866;
                margin-bottom: 5px;
            }

            .label-text {
                margin: 0 10px;
                font-weight: 500;
            }

            .label-text-json {
                cursor: pointer;
                font-weight: 600;

                &:hover {
                    padding: 2px;
                    text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
                }
            }
        }
    }

    .button-delete {
        margin-right: 1rem !important;
    }


    button.confirm {
        color: white !important;
        background: linear-gradient(81.57deg, #0d86fe 12.9%, #b504ff 87.1%);
    }

    .loading-download {
        display: inline-block;
        vertical-align: middle;
        border: 1px solid transparent;
        padding: 0 1.25rem;
    }

    .upload_label {
        padding: 1rem;

        .upload_file_name {
            margin-right: 1rem;
        }
    }
}
</style>
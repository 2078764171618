<template>
    <div class="raw_div">
        <LoadingRing v-if="loading" class="pagination-wrapper"></LoadingRing>
        <pre v-if ="!loading && rawData">{{ `${rawData}` }}</pre>
    </div>
</template>
<script>
import api from '../common/api';
import LoadingRing from '../components/base-components/LoadingRing';

export default {
    components: {
        LoadingRing,
    },
    data() {
        return {
            rawData: null,
            url: '',
            playerId: null,
            aggregatorId: null,
            loading: true,
        };
    },
    methods: {
        getRawReport(playerId, aggregatorId) {
            api.getRawReport(playerId, aggregatorId)
                .then((res) => {
                    this.rawData = JSON.stringify(res.data, null, "\t");
                })
                .catch((err) => {
                    if (err.response !== undefined) {
                        this.resultMessage = err.response;
                    } else {
                        this.resultMessage = err;
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
    mounted() {
        if (this.$route.params.playerId !== "null" && this.$route.params.aggregatorId !== "null") {
            this.playerId = this.$route.params.playerId;
            this.aggregatorId = this.$route.params.aggregatorId;
            this.getRawReport(this.playerId, this.aggregatorId);
        }
    },
};
</script>
<style>
.raw_div {
    margin: 25px;
}
</style>
<template>
    <div v-if="benchmarkList" class="benchmarks">
        <div class="title_button">
            <h3>{{ $t('benchmark.list') }}</h3>
            <base-button @click="setNew">
                {{ $t('add') }}
            </base-button>
        </div>
        <table>
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Name</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(benchmark, i) in benchmarkList" :key="benchmark.id">
                    <td :key="benchmark.id">{{ benchmark.id || '-' }}</td>
                    <td>
                        <h5 @click="setBenchmark(benchmark.id)">{{ `${benchmark.name}` }}</h5>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import api from '../common/api.js';
import sharedMethods from '../mixins/sharedMethods';

export default {
    data() {
        return {
            benchmarkList: null,
        };
    },
    mixins: [sharedMethods],
    methods: {
        setBenchmark(id) {
            this.$router.push(`/benchmark/${id}`);
        },
        setNew() {
            this.$router.push(`/new-benchmark/`);
        },
        listBenchmarks(page) {
            api
                .listBenchmarks(page)
                .then((res) => {
                    this.benchmarkList = res.data.items;
                })
                .catch((err) => {
                    if (err.response !== undefined) {
                        this.handleErrors(err.response);
                    } else {
                        this.handleErrors(err);
                    }
                });
        }
    },
    mounted() {
        this.listBenchmarks(1);
    }
}
</script>
<style lang="scss">
.benchmarks {
    margin: 1.4rem;

    hr {
        margin: 0.5rem;
        width: 40rem !important;
    }

    table {
        margin-left: auto;
        margin-right: auto;

        thead{
            text-align: center;
            height: 3rem;

            tr {
                border-bottom: 1px solid rgba(0, 0, 0, 0.459);
            }
        }

        tbody tr td{
            margin: 10px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.164);
        }
    }

    table h5 {
        margin: 0.75rem 2rem;
        cursor: pointer;
    }

    table h5:hover {
        color: #1885FF;
    }

    .title_button {
        display: flex;
        margin-bottom: 20px;

        button {
            margin-left: 20px;
        }
    }
}
</style>
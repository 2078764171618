<template>
    <div v-if="players && active === 'gameplay'">
        <div class="player" v-for="(player, i) in players" :key="player.id">
            <div class="row2">
                <h4><router-link :to="`/project/${player.project.id}`">({{ `${player.project.id}` }}) </router-link></h4>
                <h4>{{ `${player.gameplay.prefixed_uuid}` }}</h4>
                <base-button class="search_gp_raw" @click="RawDataPush(player.gameplay.prefixed_uuid)">
                    Raw Data
                </base-button>
            </div>
            <div v-if="player.gameplay.start_time" class="row2">
                <p class="label-text">{{ $t('gameplay.start_time') }}: </p>
                <p class="result">{{ `${player.gameplay.start_time}` | moment }}</p>
            </div>
            <div v-else-if="player.gameplay.created_at" class="row2">
                <p class="label-text">{{ $t('gameplay.created_at') }}: </p>
                <p class="result">{{ `${player.gameplay.created_at}` | moment }}</p>
            </div>
            <div class="row2">
                <p class="label-text">{{ $t('gameplay.end_time') }}: </p>
                <p v-if="player.gameplay.end_time" class="result">{{ `${player.gameplay.end_time}` | moment }}</p>
                <p v-else class="result">{{ $t('unset') }}</p>
            </div>
            <div class="row2">
                <p class="label-text">{{ $t('invitation_code') }}: </p>
                <p class="result" v-tooltip="$t('copy_link')" @click="copyCode(player.invitation.invitation_code)">{{
                    `${player.invitation.invitation_code}` }}</p>
            </div>
            <div class="row2">
                <p class="label-text">{{ $t('gameplay.recruitment') }}: </p>
                <router-link class="result" :to="`/aggregator/${player.aggregator.id}/candidates`">{{ `${player.aggregator.name}`
                }} ({{ `${player.aggregator.id}` }})</router-link>
            </div>
            <div class="row2">
                <p class="label-text">{{ $t('gameplay.player') }}: </p>
                <router-link class="uuid" :to="`/player/${player.player.uuid}`">P-{{ `${player.player.uuid}`
                }}</router-link>
            </div>
        </div>
    </div>
</template>
<script>
import Filters from '../mixins/filters';

export default {
    mixins: [Filters],
    props: {
        players: {
            required: true,
        },
        active: {
            required: true,
        },
    },
    methods: {
        RawDataPush(gameplayId) {
            this.$router.push(`/gameplay/${gameplayId}/raw-data/`);
        },
        copyCode(invitation_code) {
            navigator.clipboard.writeText(invitation_code);
        },
    },
};
</script>
<template>
  <div v-if="moduleType && editableModule" class="report-module">
    <div class="row">
      <!-- NAME -->
      <label for="name">
        <p class="label-text">
          {{ $t(`report_editor.name`) }}:
        </p>
        <base-input
          class="name-input flex-full"
          :placeholder="$t('report_editor.name')"
          name="name"
          type="text"
          v-model="editableModule.name"
        ></base-input>
      </label>

       <label for="weakness_count">
        <p class="label-text">
          {{ $t(`report_editor.weakness_count`) }}:
        </p>
        <base-input
          class="smaller"
          name="weakness_count"
          type="number"
          v-model.number="editableModule.weakness_count"
        ></base-input>
      </label>

      <label for="strength_count">
        <p class="label-text">
          {{ $t(`report_editor.strength_count`) }}:
        </p>
        <base-input
          class="smaller"
          name="strength_count"
          type="number"
          v-model.number="editableModule.strength_count"
        ></base-input>
      </label>

        <label
        v-for="(key, i) in Object.keys(editableModule)
        .filter((key) => key !== 'kpis' && key.includes('score'))"
        :key="`bar-${i}`"
        :for="key"
        >
        <p
          v-if=" key.includes('score')"
          class="label-text"
        >
          {{ $t(`report_editor.${key}`) }}:
        </p>

        <base-input
          v-if="key.includes('score')"
          class="smaller"
          :class="`${key}-input`"
          :name="key"
          type="number"
          v-model.number="editableModule[key]"
        ></base-input>
      </label>
      </div>
      <div class="row translations strength">
        <div class="strength-flex-small">
      <Dropdown
        class="lang"
        style="margin-bottom: 0"
        :options="allLangs"
        @selected="selectLang"
        :disabled="false"
        name="lang"
        :maxItem="allLangs.length"
        :placeholder="$t('select_lang')"
        key="lang"
        :language="true"
      />
      </div>

    <div class="strength-flex-large">
      <label for="text">
      <p class="label-text">
        {{ $t(`report_editor.title`) }}:
      </p>
      <base-input
        v-if="selectedLang"
        class="title-input"
        :placeholder="$t('report_editor.title')"
        name="title"
        type="text"
        v-model="editableModule.translations.title[selectedLang]"
      ></base-input>
      </label>
    </div>

      <!-- STRENGTHS -->
    <div class="strength-flex-small">
      <label for="strengths_title">
        <p class="label-text">
          {{ $t('report_editor.strengths_title') }}:
        </p>
        <base-input
          class="strengths_title-input"
          name="strengths_title"
          :placeholder="selectedLang"
          type="text"
          v-model="editableModule.translations.strengths_title[selectedLang]"
        ></base-input>
        </label>
    </div>

    <div class="strength-flex-large">
      <label for="strengths_text">
        <p class="label-text">
          {{ $t('report_editor.strengths_text') }}:
        </p>
        <textarea
          class="`strengths_text-input`"
          name="strengths_text"
          :placeholder="selectedLang"
          v-model="editableModule.translations.strengths_text[selectedLang]"
        ></textarea>
        </label>
    </div>

    <!-- WEAKNESSES -->
    <div class="strength-flex-small">
      <label for="weaknesses_title">
        <p class="label-text">
          {{ $t('report_editor.weaknesses_title') }}:
        </p>
        <base-input
          class="weaknesses_title-input"
          name="weaknesses_title"
          :placeholder="selectedLang"
          type="text"
          v-model="editableModule.translations.weaknesses_title[selectedLang]"
        ></base-input>
        </label>
    </div>

    <div class="strength-flex-large">
      <label for="weaknesses_text">
        <p class="label-text">
          {{ $t('report_editor.weaknesses_text') }}:
        </p>
        <textarea
          class="`weaknesses_text-input`"
          name="weaknesses_text"
          :placeholder="selectedLang"
          v-model="editableModule.translations.weaknesses_text[selectedLang]"
        ></textarea>
        </label>
    </div>

    <!-- OTHER KPIS -->
    <div class="strength-flex-small">
      <label for="other_kpis_title">
        <p class="label-text">
          {{ $t('report_editor.other_kpis_title') }}:
        </p>
        <base-input
          class="other_kpis_title-input"
          name="other_kpis_title"
          :placeholder="selectedLang"
          type="text"
          v-model="editableModule.translations.other_kpis_title[selectedLang]"
        ></base-input>
        </label>
    </div>

    <div class="strength-flex-large">
      <label for="other_kpis_text">
        <p class="label-text">
          {{ $t('report_editor.other_kpis_text') }}:
        </p>
        <textarea
          class="`other_kpis_text-input`"
          name="other_kpis_text"
          :placeholder="selectedLang"
          v-model="editableModule.translations.other_kpis_text[selectedLang]"
        ></textarea>
        </label>
    </div>
   </div>

    <hr>

    <div class="row">
    <!-- SAVE BUTTON -->
    <base-button @click="saveModule">
      {{ $t(`report_editor.${selectedAction}_module`) }}
    </base-button>
    </div>
  </div>
</template>

<script>

import Dropdown from '../Dropdown.vue';
import '../../assets/scss/modules.scss';
import BaseButton from '../base-components/BaseButton.vue';

export default {
  components: { Dropdown, BaseButton },
  data() {
    return {
      editableModule: null,
      selectedLang: null,
      newModule: {
        name: null,
        strength_count: null,
        weakness_count: null,
        mid_low_score: null,
        mid_score: null,
        mid_high_score: null,
        high_score: null,
        translations: {
          title: {},
          strengths_title: {},
          strengths_text: {},
          weaknesses_title: {},
          weaknesses_text: {},
          other_kpis_title: {},
          other_kpis_text: {},
        },
      },
    };
  },
  props: {
    moduleType: {
      required: true,
    },
    module: {
      required: true,
    },
    allLangs: {
      required: true,
    },
    selectedAction: {
      required: true,
    },
  },
  methods: {
    selectLang(lang) {
      this.selectedLang = lang.code;
    },
    saveModule() {
      this.$emit('saveModule', this.moduleType, this.editableModule);
    },
    setDefaultLang() {
      this.$children.forEach((child, i) => {
        if (child.$el.className === 'dropdown lang') {
          this.$children[i].setDefault('English');
        }
      });
      this.selectedLang = 'en';
    },
    setScoresAscending() {
      // Reorder scores to be in ascending order
      const module = JSON.parse(JSON.stringify(this.module));
      Object.keys(module).forEach((key) => {
        if (key.includes('score')) {
          delete module[key];
        }
      });
      module.mid_low_score = this.module.mid_low_score;
      module.mid_score = this.module.mid_score;
      module.mid_high_score = this.module.mid_high_score;
      module.high_score = this.module.high_score;
      this.editableModule = module;
    },
  },
  beforeMount() {
    this.setScoresAscending();
  },
  mounted() {
    this.setDefaultLang();
  },
};
</script>

<template>
  <div v-if="layouts">
    <div class="layout">
      <label for="additional_layout">
        <p class="label-text">{{ $t(`aggregator.additional_report_layout`) }}:</p>
        <Dropdown
          :options="reportLayouts"
          @selected="setReportLayout"
          :disabled="false"
          :key="render"
          name="additional_layout"
          :maxItem="reportLayouts.length"
          :placeholder="$t('select_additional_layout')"
        />
    </label>
    <base-button
      @click="saveAdditionalLayout"
      :disabled=!newAdditionalLayout.layout.id
    >
      {{ $t('add_layout') }}
    </base-button>
    </div>
    <div class="display-name" v-if="newAdditionalLayout.translations.display_name.en">
      <Dropdown
        :options="allLangs"
        @selected="setCurrentLang"
        :defaultValue="allLangs[0].name"
        :disabled="false"
        name="lang"
        :maxItem="allLangs.length"
        :language="true" 
        :placeholder="$t('select_lang')"
      />
      <label for="label">
        <p class="label-text">{{ $t('dashbaord_diplay_name') }}:</p>
        <base-input
          class="name-input"
          placeholder="label"
          name="additional-layout"
          v-model="newAdditionalLayout.translations.display_name[currentLang]"
        ></base-input>
      </label>
    </div>
    <Message
      v-if="resultMessage !== ''"
      :classes="''"
      :message="resultMessage"
      @hideResult="resultMessage = ''">
    </Message>
    <Dropdown
      :options="allLangs"
      @selected="setCurrentLang"
      :defaultValue="allLangs.filter((l) => l.code === currentLang)[0].name"
      :disabled="false"
      name="lang"
      :maxItem="allLangs.length"
      :placeholder="$t('select_lang')"
      :language="true" 
    />
    <div class="results" v-if="layouts && layouts.length">
      <table class="full-size">
        <thead>
          <tr>
            <th>Id</th>
            <th>{{ $t('name') }}</th>
            <th>{{ $t('dashbaord_diplay_name') }} ({{currentLang.toUpperCase()}})</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(layout, i) in layouts" :key="i">
            <td>{{ layout.layout.id }}</td>
            <td>{{ layout.layout.name }}</td>
            <td>{{ layout.translations.display_name[currentLang] }}
            </td>
            <td>
              <base-button @click="removeReportLayout(i)">
                {{ $t('remove_layout') }}
              </base-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import api from '../common/api.js';
import Message from './Message.vue';
import sharedMethods from '../mixins/sharedMethods';
import Dropdown from './Dropdown.vue';

export default {
  components: {
    Message,
    Dropdown,
  },
  mixins: [sharedMethods],
  data() {
    return {
      currentLang: 'en',
      newAdditionalLayout: {
        layout: {
          id: null,
          name: null,
        },
        translations: {
          display_name: {},
        },
      },
      // The display name of additional layout is used only on dashboard. (within Reports dropdown)
      // Dashbaord supports only these languages:
      additionalLayoutDefaultName: {
        en: 'Detailed report',
        hu: 'Részletes riport',
        fr: 'Rapport détaillé',
        de: 'Detailbericht',
      },
      layouts: null,
      render: 0,
    };
  },
  props: {
    assessmentAdditionalLayouts: {
      required: true,
    },
    reportLayouts: {
      required: true,
    },
    getDefaultValue: {
      required: true,
    },
    assessmentId: {
      required: true,
    },
  },
  computed: {
    userLang() {
      return this.$store.state.user.user_lang;
    },
    allLangs() {
      return this.$store.state.admin.langs;
    },
  },
  methods: {
    setCurrentLang(lang) {
      this.currentLang = lang.code;
    },
    resetLayout() {
      this.newAdditionalLayout = {
        layout: {
          id: null,
          name: null,
        },
        translations: {
          display_name: {},
        },
      };
    },
    saveAdditionalLayout() {
      this.layouts.push(this.newAdditionalLayout);
      this.resetLayout();
      this.$emit('layoutModified', this.layouts);
    },
    removeReportLayout(index) {
      this.layouts.splice(index, 1);
      this.$emit('layoutModified', this.layouts);
    },
    setReportLayout(layout) {
      this.newAdditionalLayout.layout = layout;
      this.allLangs.forEach((element) => {
        this.$set(this.newAdditionalLayout.translations.display_name, `${element.code}`, this.additionalLayoutDefaultName[element.code] || '');
      });
    },
    rerender() {
      this.render++;
    },
  },
  mounted() {
    this.layouts = this.assessmentAdditionalLayouts;
  },
};
</script>
<style scoped>
.display-name {
  display: flex;
  margin-left: 50px;
}

.results {
  margin-left: 50px;
}

.layout {
  display: flex;
  align-items: center;
}

.row label {
    display: flex;
    align-items: center;
    margin-top: 8px;
}

.layout-name-lang:focus {
  outline-color: #a245ff;
  border: 2px solid #a245ff !important;
}
</style>

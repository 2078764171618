<template>
  <div class="project">
    <div class="project-options row">
      <base-button @click="$router.go(-1)">
        {{ $t('back') }}
      </base-button>
    </div>

    <div v-if="project" class="project-options row">
      <div class="project-row">
        <label
          v-for="(key, i) in Object.keys(project).filter(
            (key) => key === 'name' || key === 'client' || key === 'aggregator',
          )"
          :for="key"
        >
          <p class="label-text">{{ $t(`project.${key}`) }}:</p>

          <base-input
            v-if="key === 'name'"
            class="name-input"
            :placeholder="$t('name')"
            :name="key"
            type="text"
            v-model="project[key]"
          ></base-input>

          <Dropdown
            v-else-if="key === 'client'"
            :key="renderCounter"
            :options="clients"
            @selected="selectClient"
            :defaultValue="getDefaultValue(project.client, key)"
            :disabled="false"
            :name="key"
            :maxItem="clients.length"
            :placeholder="$t('select_client')"
            :clientid="true"
          />

          <p
            v-else-if="
              key === 'aggregator' &&
                project.aggregator &&
                project.aggregator.id &&
                project.aggregator.name
            "
            class="label-text aggregator"
          >
            {{ `(${project.aggregator.id}) ${project.aggregator.name}` }}
          </p>

          <p v-else-if="key === 'aggregator' && !project.aggregator" class="label-text aggregator">
            {{ $t('project.no_aggregator') }}
          </p>
        </label>
      </div>
    </div>

    <hr v-if="project" />

    <div v-if="project" class="project-options row">
      <div class="project-row">
        <label
          v-for="(key, i) in Object.keys(project)
            .filter((key) => key === 'game' || key === 'level_pack' || key === 'game_skin')
            .reverse()"
          :for="key"
        >
          <p
            v-if="
              key === 'game' ||
                (key === 'game_skin' && gameSkins.length > 0) ||
                (key === 'level_pack' && levelPacks.length > 0)
            "
            class="label-text"
          >
            {{ $t(`project.${key}`) }}:
          </p>

          <Dropdown
            v-if="key === 'game'"
            :options="games"
            @selected="selectGame"
            :defaultValue="getDefaultValue(project.game, key)"
            :disabled="false"
            :name="key"
            :maxItem="games.length"
            :placeholder="$t('select_game')"
          />

          <Dropdown
            v-else-if="key === 'game_skin' && gameSkins.length > 0"
            :options="gameSkins"
            @selected="selectSkin"
            :defaultValue="getDefaultValue(project.game_skin, key)"
            :disabled="false"
            :name="key"
            :maxItem="gameSkins.length"
            :placeholder="$t('select_skin')"
          />

          <Dropdown
            v-else-if="key === 'level_pack' && levelPacks.length > 0"
            :options="levelPacks"
            @selected="selectLevelPack"
            :defaultValue="getDefaultValue(project.level_pack, key)"
            :disabled="false"
            :name="key"
            :maxItem="levelPacks.length"
            :placeholder="$t('select_level_pack')"
          />
        </label>
      </div>
    </div>

    <hr v-if="project" />

    <div class="project-options row">
      <base-button @click="editProject">
        {{ $route.params.projectId ? $t('edit') : $t('add') }}
      </base-button>
      <Message
        v-if="resultMessage !== ''"
        classes=""
        :message="resultMessage"
        @hideResult="resultMessage = ''">
      </Message>
    </div>

    <hr v-if="project" />
    <Confirmation
      v-if="showConfirmDialog"
      selected="edit-project"
      :confirmationNeeded="true"
      :userManagement="false"
      :confirmMessage="$t('confirm_message.edit_project')"
      @hideConfirmation="resetClient"
      @editProject="sendRequest"
    />
  </div>
</template>

<script>
import Dropdown from './Dropdown.vue';
import api from '../common/api.js';
import sharedMethods from '../mixins/sharedMethods';
import Message from './Message.vue';

export default {
  mixins: [sharedMethods],
  components: {
    Dropdown, Message,
  },
  props: {
    clients: {
      required: true,
    },
    games: {
      required: true,
    },
  },
  data() {
    return {
      renderCounter: 0,
      selectedAction: null,
      projectId: null,
      project: null,
      originalClient: null,
      projectScheme: {
        name: '',
        level_pack: '',
        game_skin: '',
        client: null,
        game: null,
      },
      gameSkins: [],
      levelPackDataSize: 0,
      levelPacks: [],
      showConfirmDialog: false,
      allowExperimentalVersion: false,
    };
  },
  methods: {
    confirm() {
      this.showConfirmDialog = true;
    },
    hideResult() {
      this.$emit('hideResult', 'edit-project');
    },
    getDefaultValue(prop, key) {
      if ((key === 'client' || key === 'game') && prop && prop.name) {
        return prop.name;
      } if ((key === 'game_skin' || key === 'level_pack') && prop) {
        return prop;
      }

      return '';
    },
    getLists() {
      this.gameSkins = [];
      this.getGameSkinList();
      this.levelPacks = [];
      this.levelPackDataSize = 0;
      this.getLevelPackList(1);
    },
    selectClient(client) {
      this.project.client_id = client.id;
    },
    resetClient() {
      this.project.client_id = this.originalClient.id;
      // refresh client dropdown
      this.renderCounter++;
      this.showConfirmDialog = false;
    },
    selectGame(game) {
      this.project.game_id = game.id;
      this.project.game_skin = '';
      this.project.level_pack = '';

      this.getLists();
    },
    selectSkin(skin) {
      this.project.game_skin = skin.name;
    },
    selectLevelPack(levelPack) {
      this.project.level_pack = levelPack.name;
    },
    setProject(project) {
      this.hideResult();
      this.projectId = project;

      api
        .getProject(project)
        .then((res) => {
          this.project = res.data;
          // save original client id for reset
          this.originalClient = this.project.client;
          this.setAggregator();
        })
        .catch((err) => {
          if (err.response !== undefined) {
            this.handleErrors(err.response);
          } else {
            this.handleErrors(err);
          }
        });
    },
    getGameSkinList() {
      const gameId = this.project.game_id ? this.project.game_id : this.project.game.id;
      const allowVersion = this.allowExperimentalVersion;

      api
        .getGame(gameId, allowVersion)
        .then((res) => {
          Object.keys(res.data.skins).forEach((skin, i) => {
            this.gameSkins.push({
              id: i,
              name: skin,
            });
          });
        })
        .catch((err) => {
          if (err.response !== undefined) {
            this.handleErrors(err.response);
          } else {
            this.handleErrors(err);
          }
        });
    },
    setAggregator() {
      api
        .getAggregator(this.project.aggregator.id)
        .then((res) => {
          if (res.data.attributes.includes("ALLOW_EXPERIMENTAL_GAME")) {
            this.allowExperimentalVersion = true;
          }
        })
        .catch((err) => {
          if (err.response !== undefined) {
            this.handleErrors(err.response);
          } else {
            this.handleErrors(err);
          }
        }).finally(() => {
          if (this.project.game && this.project.game.id) {
            this.getLists();
          }
        });
    },
    getLevelPackList(page) {
      const gameId = this.project.game_id ? this.project.game_id : this.project.game.id;

      api
        .listLevelPacks(page, gameId)
        .then((res) => {
          this.levelPackDataSize = res.data.total / 100;
          res.data.items.forEach((item) => {
            this.levelPacks.unshift({
              id: item.id,
              identifier: item.identifier,
              name: item.name,
            });
          });
          if (page < this.levelPackDataSize) {
            page++;
            this.getLevelPackList(page, gameId);
          }
        })
        .catch((err) => {
          if (err.response !== undefined) {
            this.handleErrors(err.response);
          } else {
            this.handleErrors(err);
          }
        });
    },
    editProject() {
      if (
        this.project.aggregator
        && this.project.client_id
        && this.project.client.id !== this.project.client_id
      ) {
        this.confirm();
      } else {
        this.sendRequest();
      }
    },
    sendRequest() {
      if (this.$route.params.projectId) {
        api
          .editProject(this.projectId, this.project)
          .then((res) => {
            this.setMsg(this.$t('result_message.project_edited'));
            this.showConfirmDialog = false;
          })
          .catch((err) => {
            if (err.response !== undefined) {
              this.handleErrors(err.response);
            } else {
              this.handleErrors(err);
            }
          });
      } else {
        api
          .addProject(this.project)
          .then((res) => {
            this.setMsg(this.$t('result_message.project_added'));
          })
          .catch((err) => {
            if (err.response !== undefined) {
              this.handleErrors(err.response);
            } else {
              this.handleErrors(err);
            }
          });
      }
    },
  },
  mounted() {
    if (this.$route.params.projectId) {
      this.setProject(Number(this.$route.params.projectId));
    } else {
      this.project = structuredClone(this.projectScheme);
    }
  },
};
</script>

<style lang="scss" scoped>
.row {
  display: flex;
  align-items: baseline;

  h3,
  .full-width {
    flex: 100%;
  }

  label {
    display: flex;
    align-items: center;
    margin-top: 8px;
    margin-bottom: 0;

    .label-text {
      font-weight: 400;
      color: #3c4866;
      margin-right: 10px;
      margin-bottom: 0;

      &.aggregator {
        font-weight: bold;
        margin-top: 0;
        margin-right: 30px;
      }
    }

    .smaller {
      width: 70px;
    }

    textarea {
      height: 70px;
      width: 250px;
      margin-top: 0;
      resize: both;
    }
  }

  textarea {
    padding: 10px 12px;
  }

  &:not(:first-child) {
    margin-top: 0.8rem;
  }

  .custom-control.custom-checkbox {
    margin-right: 30px;
  }

  .form-group,
  textarea {
    margin-right: 30px;
    margin-bottom: 0;
  }

  .form-group input,
  textarea {
    height: 43px;
    border: 1px solid #cad1d7;
    border-radius: 0.25rem;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.5;
    color: #3c4866;

    &::placeholder {
      color: #8898aa;
      opacity: 0.8;
    }
  }

  .edit-project {
    display: flex;
  }

  .project-row {
    display: flex;
    flex: 100%;
  }
}

.selection-panel {
  .project .row {
    &:not(:first-child) button {
      margin-top: 0.8rem;
    }

    button:last-child {
      margin-right: 0;
    }
  }

  hr {
    position: absolute;
    width: 100%;
    margin-top: 0.75rem;
    margin-left: 0;
  }
}
</style>

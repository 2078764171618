<template>
  <div
    ref="kpiSelector"
    class="row kpis"
    :class="{
      'polar': editableKPIList
    }"
  >
    <div v-if="editableKPIList">
      <div
        v-for="(kpi, i) in editableKPIList"
        :key="`${render}_${i}`"
        class="kpi"
      >
        <Dropdown
          :options="kpis"
          @selected="setKpi"
          @index="selectKpi(i)"
          :defaultValue="getDefaultValue(kpi)"
          :disabled="false"
          :name="`kpis-${i}`"
          :maxItem="kpis.length"
          :placeholder="$t('report_editor.select_kpi')"
          :key="`kpis-${i}`"
        />

        <div class="btns">
          <base-button class="btn-up" :disabled="i === 0"
            @click="moveUp(editableKPIList, i, afterReorder, [i])">
            ^
          </base-button>

          <base-button
            class="btn-down"
            :disabled="i === editableKPIList.length - 1"
            @click="moveDown(editableKPIList, i, afterReorder, [i])"
          >
            ^
          </base-button>
        </div>

        <base-button
          class="remove"
          :disabled="editableKPIList.length === 1"
          @click="removeKpi(i)"
        >
          X
        </base-button>

        <base-button
          v-if="i === 0"
          class="add-kpi"
          @click.prevent="addKpi()"
        >
          {{ $t('report_editor.add_kpi') }}
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import sharedMethods from '../mixins/sharedMethods';
import Dropdown from './Dropdown.vue';

export default {
  components: {
    Dropdown,
  },
  data() {
    return {
      editableKPIList: null,
      selectedKpiId: null,
      render: 0,
    };
  },
  mixins: [sharedMethods],
  props: {
    kpis: {
      required: true,
    },
    editableModule: {
      required: true,
    },
  },
  methods: {
    rerender() {
      this.render++;
    },
    handleErrors(err) {
      this.$emit('handleErrors', err);
    },
    setKpi(kpi) {
      this.selectedKpiId = kpi.id;
      this.newKpi = {
        id: kpi.id,
        identifier: kpi.identifier,
        name: kpi.name,
      };
    },
    selectKpi(i) {
      let sameKpi = false;
      this.editableKPIList.forEach((kpi) => {
        if (kpi.kpi_id === this.selectedKpiId) {
          sameKpi = true;
        }
      });
      if (!sameKpi) {
        this.editableKPIList[i].kpi_id = this.selectedKpiId;
        this.editableKPIList[i].id = this.newKpi.id;
        this.editableKPIList[i].identifier = this.newKpi.identfitier;
        this.editableKPIList[i].name = this.newKpi.name;
      } else {
        // Do now allow selecting the same kpis multiple times
        this.$refs.kpiSelector.__vue__.$children.forEach((child, index) => {
          if (child.name === `kpis-${i}`) {
            setTimeout(() => {
              this.$refs.kpiSelector.__vue__.$children[index].emptyFilter();
            }, 300);
          }
        });
      }
      this.sendToParent();
    },
    getDefaultValue(kpi) {
      const result = this.kpis.filter((k) => k.id === kpi.id)[0];
      return result && result.name ? result.name : '';
    },
    removeKpi(i) {
      this.editableKPIList.splice(i, 1);
      this.sendToParent();
      this.rerender();
    },
    addKpi() {
      this.editableKPIList.push({ kpi_id: '' });
      this.sendToParent();
      const site = document.querySelector('#app');
      site.scrollTo(0, site.scrollHeight);
    },
    afterReorder() {
      this.sendToParent();
      this.rerender();
    },
    sendToParent() {
      const payload = JSON.parse(JSON.stringify(this.editableKPIList));
      this.$emit('kpisUpdated', payload);
    },
  },
  beforeMount() {
    this.editableKPIList = JSON.parse(JSON.stringify(this.editableModule.kpis));
  },
};
</script>

<style lang="scss" scoped>
.row.kpis {
  position: relative;
  display: flex;
  width: 1030px;
  margin-right: 10px;
  padding-bottom: 5px;

  .kpi {
    display: flex;

    .add-kpi {
      position: fixed;
      left: 650px;
    }

    .btns {
      height: 43px;
      margin-right: .8rem;

      button {
        display: block;
        height: 22px;
        padding: 1px 6px;
        border-radius: 40px 40px 0 0;
        box-shadow: none;
      }

      .btn-up:disabled {
        transform: none;
      }

      .btn-down {
        margin-top: 0 !important;
        transform: rotate(180deg);

        &:hover {
          transform: rotate(180deg) translateY(-1px);

          &:disabled {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  label {
    margin: 0;
    display: flex;
    align-items: center;
    margin-top: 0.8rem;

    .label-text {
      font-weight: 400;
      color: #3C4866;
      margin-right: 10px;
      margin-bottom: 0;
    }

    .form-group {
      margin-top: 0;

      &.smaller {
        width: 60px;
      }
    }
  }

  &:not(:first-child) {
    margin-top: 0.8rem;
  }

  .form-group {
    margin-top: 0.8rem;
    margin-right: 30px;
    margin-bottom: 0;
  }

  .form-group input {
    height: 43px;
    border: 1px solid #CAD1D7;
    border-radius: 0.25rem;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.5;
    color: #3C4866;

    &::placeholder {
      color: #8898AA;
      opacity: 0.8;
    }
  }
}

  .report-module .row {
    &:not(:first-child) button {
      margin-top: 0.8rem;
    }

    button:last-child {
      margin-right: 0;
    }
  }
</style>

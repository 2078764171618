import { RE_AUTHENTICATE } from '../common/constants';

export default {
  data() {
    return {
      resultMessage: '',
    };
  },
  methods: {
    handleErrors(error) {
      console.log(error);
      if (!error.status && error.message) {
        this.resultMessage = error.message;
      } else {
        switch (error.status) {
          case 400: {
            this.resultMessage = error.data.detail;
            break;
          }
          case 401: {
            this.resultMessage = error.data.detail;
            RE_AUTHENTICATE();
            break;
          }
          case 403: {
            this.resultMessage = error.data.detail;
            RE_AUTHENTICATE();
            break;
          }
          case 404: {
            this.resultMessage = `API ${error.data.detail}` || `URL ${error.statusText}`;
            break;
          }
          case 409: {
            this.resultMessage = error.data.detail;
            break;
          }
          case 422: {
            this.resultMessage = `${error.data.detail[0].loc[1] || error.data.detail[0].loc[0]} ${
              error.data.detail[0].msg
            }`;
            break;
          }
          case 502: {
            this.resultMessage = 'API not available';
            break;
          }
          default: {
            this.resultMessage = this.$t('result_message.default_error');
            break;
          }
        }
      }
    },
    hideResult() {
      this.resultMesssage = '';
    },
    setMsg(msg) {
      this.resultMessage = msg;
    },
    moveDown(obj, i, callback, callbackParams) {
      const toSwap = obj[i];
      obj[i] = obj[i + 1];
      obj[i + 1] = toSwap;
      if (callback) callback.apply(this, callbackParams);
    },
    moveUp(obj, i, callback, callbackParams) {
      const toSwap = obj[i];
      obj[i] = obj[i - 1];
      obj[i - 1] = toSwap;
      if (callback) callback.apply(this, callbackParams);
    },
  },
};

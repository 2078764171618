<template>
  <div class="aggregator-options row members">
    <div>
      <p>*{{$t('aggregator.export_key_info')}}</p>
      <div v-for="(item, i) in updatedFields"
        :key="`field-${item.field_type_id}-${render}-${i}`"
        class="entity">
        <label :for="`export-key-${i}`">
          <p v-if="i === 0" class="label-text">
            {{$t("aggregator.export_key")}}*:
          </p>
          <base-input
            :class="!item.export_key || item.export_key === '' ? 'field-error' : ''"
            class="name-input"
            :name="`export-key-${i}`"
            type="text"
            v-model="item.export_key"
          ></base-input>
        </label>
        <div class="label-text lang">
          {{ $t(`${item.field_type_id}- ${getFieldName(item.field_type_id)}`) }}
        </div>
        <label for="required">
          <p v-if="i === 0" class="label-text">
            {{ $t(`required`) }}:
          </p>

          <base-checkbox
            name="required"
            class="required"
            v-model="item.required"
          ></base-checkbox>
        </label>
        <div class="btns">
          <base-button
            @click="moveUp(updatedFields, i, afterReorder)"
            class="btn-up"
            :disabled="i === 0">
            ^
          </base-button>

          <base-button
            class="btn-down"
            :disabled="i === updatedFields.length - 1"
            @click="moveDown(updatedFields, i, afterReorder)"
          >
            ^
          </base-button>
        </div>
        <base-button class="remove"
          :disabled="updatedFields.length === 0"
          @click="removeLanguage(item, i)">
          X
        </base-button>
      </div>
      <div>
        <Dropdown
          :options="customfields"
          @selected="setField"
          :defaultValue="$t('select_custom_field')"
          :disabled="false"
          name="custom_field"
          :maxItem="customfields.length"
          :placeholder="$t('custom-fields')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import sharedMethods from '../mixins/sharedMethods';
import Dropdown from './Dropdown.vue';

export default {
  components: {
    Dropdown,
  },
  mixins: [sharedMethods],
  props: {
    aggregator: {
      required: true,
    },
    customfields: {
      required: true,
    },
  },
  data() {
    return {
      render: 0,
      selectedOption: null,
      updatedFields: [],
    };
  },
  methods: {
    rerender() {
      this.render++;
    },
    setField(option) {
      this.updatedFields.push({
        field_type_id: option.id,
        export_key: '',
      });
      this.selectedOption = option;
      this.sendToParent();
    },
    removeLanguage(lang, i) {
      this.updatedFields.splice(i, 1);
      this.sendToParent();
    },
    afterReorder() {
      this.rerender();
      this.sendToParent();
    },
    sendToParent() {
      this.$emit('update', this.updatedFields);
    },
    getFieldName(id) {
      const currentField = this.customfields.filter((field) => field.id === id)[0];
      return currentField ? currentField.name : '';
    },
  },
  mounted() {
    this.updatedFields = this.aggregator.custom_fields;
  },
};
</script>

<style lang="scss" scoped>
.entity {
  margin-bottom: 8px;
}
.label-text {
    font-weight: 400;
    color: #3c4866;
    margin-right: 10px;
    &.lang {
      min-width: 250px;
      margin: 0.8rem, 0.8rem, 0, 0;
    }
}

.required {
  min-width: 80px;
}
</style>

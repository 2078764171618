<template>
  <div v-if="moduleType && editableModule" class="report-module" :key="`report-module ${render}`">
    <div class="row">
      <!-- NAME -->
      <label for="name">
        <p class="label-text">
          {{ $t(`report_editor.name`) }}:
        </p>
        <base-input
          class="name-input flex-full"
          :placeholder="$t('report_editor.name')"
          name="name"
          type="text"
          v-model="editableModule.name"
        ></base-input>
      </label>
        <label
        v-for="(key, i) in Object.keys(editableModule)
        .filter((key) => key !== 'kpis' && key.includes('score'))"
        :key="`bar-${i}`"
        :for="key"
        >
        <p
          v-if=" key.includes('score')"
          class="label-text"
        >
          {{ $t(`report_editor.${key}`) }}:
        </p>

        <base-input
          v-if="key.includes('score')"
          class="smaller"
          :class="`${key}-input`"
          :name="key"
          type="number"
          v-model.number="editableModule[key]"
        ></base-input>
      </label>
      </div>
      <div class="row">

      <Dropdown
        class="lang"
        style="margin-bottom: 0"
        :options="allLangs"
        @selected="selectLang"
        :disabled="false"
        name="lang"
        :maxItem="allLangs.length"
        :placeholder="$t('select_lang')"
        key="lang"
        :language="true"
      />

      <label for="title">
      <p class="label-text">
        {{ $t(`report_editor.title`) }}:
      </p>
      <base-input
        v-if="selectedLang"
        class="title-input"
        :placeholder="$t('report_editor.title')"
        name="title"
        type="text"
        v-model="editableModule.translations.title[selectedLang]"
      ></base-input>
      </label>
    </div>
    <hr>

    <div class="row">
      <Dropdown
        :options="modes"
        @selected="getSelectMode"
        :defaultValue="getDefaultMode(editableModule)"
        :disabled="false"
        name="mode"
        :maxItem="modes.length"
        :selectedLang="selectedLang"
        :moduleType="moduleType"
        :placeholder="$t('report_editor.display_parts')"
        :key="`display_parts-${render}`"
      />

      <label for="display_count">
        <p class="label-text">
          {{ $t('report_editor.display_count') }}:
        </p>

        <base-input
          class="display_count-input smaller"
          name="display_count"
          type="number"
          v-model.number="editableModule.display_count"
        ></base-input>
      </label>

      <label for="with_icons" class="full-width">
        <p class="label-text">With icons:</p>

        <base-checkbox
          name="with_icons"
          v-model="editableModule.with_icons"
        ></base-checkbox>
      </label>
    </div>

    <hr>

    <CompetencySelector
      v-if="module"
      ref="kpiSelector"
      :editableModule="editableModule"
      :kpis="kpis"
      @kpisUpdated=setKPIList
    />
    <hr>

    <div class="row">
    <!-- SAVE BUTTON -->
    <base-button @click="saveModule">
      {{ $t(`report_editor.${selectedAction}_module`) }}
    </base-button>
    </div>
  </div>
</template>

<script>

import Dropdown from '../Dropdown.vue';
import CompetencySelector from '../CompetencySelector.vue';
import '../../assets/scss/modules.scss';
import BaseButton from '../base-components/BaseButton.vue';

export default {
  components: { Dropdown, BaseButton, CompetencySelector },
  data() {
    return {
      kpiId: null,
      newKpi: null,
      render: 0,
      editableModule: null,
      selectedLang: null,
      modes: [
        { id: 0, name: 'Bars only', value: 'chart' },
        { id: 1, name: 'Text only', value: 'text' },
        { id: 2, name: 'Both', value: 'both' },
      ],
      newModule: {
        name: null,
        mid_low_score: null,
        mid_score: null,
        mid_high_score: null,
        high_score: null,
        kpis: [
          { kpi_id: '' },
          { kpi_id: '' },
          { kpi_id: '' },
        ],
        display_parts: 'both',
        display_count: null,
        with_icons: false,
        translations: {
          title: {},
        },
      },
    };
  },
  props: {
    moduleType: {
      required: true,
    },
    module: {
      required: true,
    },
    kpis: {
      required: true,
    },
    allLangs: {
      required: true,
    },
    selectedAction: {
      required: true,
    },
  },
  methods: {
    rerender() {
      this.render++;
    },
    selectLang(lang) {
      this.selectedLang = lang.code;
    },
    saveModule() {
      this.$emit('saveModule', this.moduleType, this.editableModule);
    },
    setKPIList(kpis) {
      this.editableModule.kpis = kpis;
    },
    getDefaultMode(module) {
      if (
        module
        && module.display_parts
      ) {
        let defaultMode = '';
        this.modes.forEach((mode, i) => {
          if (mode.value === module.display_parts) {
            defaultMode = this.modes[i].name;
          }
        });
        return defaultMode;
      }
      return 'Both';
    },
    setDefaultLang() {
      this.$children.forEach((child, i) => {
        if (child.$el.className === 'dropdown lang') {
          this.$children[i].setDefault('English');
        }
      });
      this.selectedLang = 'en';
    },
    getSelectMode(mode) {
      this.editableModule.display_parts = mode.value;
    },
    setScoresAscending() {
      // Reorder scores to be in ascending order
      const module = JSON.parse(JSON.stringify(this.module));
      Object.keys(module).forEach((key) => {
        if (key.includes('score')) {
          delete module[key];
        }
      });
      module.mid_low_score = this.module.mid_low_score;
      module.mid_score = this.module.mid_score;
      module.mid_high_score = this.module.mid_high_score;
      module.high_score = this.module.high_score;
      this.editableModule = module;
    },
  },
  beforeMount() {
    this.setScoresAscending();
  },
  mounted() {
    this.setDefaultLang();
  },
};
</script>

<template>
  <div id="app" @scroll="checkScrollPos()">
    <router-view name="header"></router-view>
    <main>
      <div :class="$route.name !== 'error' ? 'app-wrapper' : ''">
        <router-view :key="$route.fullPath" />
      </div>
    </main>
    <router-view name="footer"></router-view>
    <base-button v-if="scrollPos > 100" class="scroll-top" @click="scrollTop()">
      ^
    </base-button>
    <Message
      v-if="resultMessage !== ''"
      :message="resultMessage"
      @hideResult="hideResult"
      classes="">
    </Message>
  </div>
</template>

<script>
import sharedMethods from './mixins/sharedMethods';
import Message from './components/Message.vue'; 

export default {
  components: {
    Message,
  },
  mixins: [sharedMethods],
  data() {
    return {
      scrollPos: null,
    };
  },
  methods: {
    checkScrollPos() {
      this.scrollPos = document.querySelector('#app').scrollTop;
    },
    scrollTop() {
      const site = document.querySelector('#app');
      site.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    },
    setFavicon() {
      const settingsData = JSON.parse(localStorage.getItem("settings"));
      const environment = settingsData?.environment;

      let iconPath;
      if (environment === "production") {
        iconPath="favicon.ico";
      } else if (environment === "staging") {
        iconPath="favicon-staging.ico";
      } else {
        iconPath="favicon-dev.ico";
      }
      const link = document.querySelector("#app-favicon");
      if (link) {
        link.href = iconPath;
      }
    },
  },
  mounted() {
    this.setFavicon();
    const langs = [];
    Object.keys(this.$t('lang')).forEach((key, i) => {
      const name = this.$t(`lang.${key}`, { langCode: key });
      langs.push({
        id: i,
        name,
        code: key,
      });
    });
    this.$store.commit('admin/setLangs', langs);
  },
};
</script>

<style lang="scss">
#app {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;

  button.scroll-top,
  button:hover.scroll-top {
    position: fixed;
    right: 30px;
    bottom: 30px;
    height: 40px;
    width: 40px;
    padding: 0;
    background-color: #ff7954;
    color: #ffffff;
    border: 2px solid #ffcf65;
    border-radius: 20px;
    font-size: large;
    font-weight: bold;
    opacity: 75%;
    z-index: 999999999999;
  }

  .app-wrapper {
    margin-right: 30px;
    margin-left: 30px;
    border: 1px solid #bbb;
    border-radius: 40px 40px 0 0;
    margin-top: 110px;
    background: #f8f7fa;
    min-height: 100vh;
    padding: 20px;

    button.btn-default {
      color: #a245ff;
      border: 1px solid #a245ff;
      margin-right: 30px;
      max-height: 43px;
    }
    button:not(.btn-down, .btn-up) {
      border-radius: 40px;
    }
  }
  hr {
    width: 100%;
  }

  .wrapper {
    margin-left: 45px;
  }

// General rules (TODO-remove these rules from individual component css)
.row {
  margin: unset !important;
  label {
    .label-text {
      font-weight: 400;
      color: #3c4866;
      margin-right: 10px;
      margin-bottom: 0;
    }

    .form-group {
      margin-top: 0;

      &.smaller {
        width: 60px;
      }
    }
  }

  &:not(:first-child) {
    margin-top: 0.8rem;

    label .dropdown {
      margin-bottom: 0;

      .dropdown-input {
        margin-top: 0 !important;
      }
    }

    .dropdown {
      margin-bottom: 10px;

      .dropdown-input {
        margin-top: 0.8rem;
      }
    }
  }

  .custom-control.custom-checkbox {
    margin-right: 30px;
    margin-top: -25px;
  }

  .form-group {
    margin-top: 0.8rem;
    margin-right: 30px;
    margin-bottom: 0;
  }

  .form-group input {
    height: 43px;
    border: 1px solid #cad1d7;
    border-radius: 0.25rem;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.5;
    color: #3c4866;

    &::placeholder {
      color: #8898aa;
      opacity: 0.8;
    }
  }
    .entity {
    display: flex;
    align-items: center;

    .btns {
      height: 43px;

      button {
        display: block;
        height: 22px;
        padding: 1px 6px;
        border-radius: 40px 40px 0 0;
        box-shadow: none;
      }

      .btn-up:disabled {
        transform: none;
      }

      .btn-down {
        margin-top: 0 !important;
        transform: rotate(180deg);

        &:hover {
          transform: rotate(180deg) translateY(-1px);

          &:disabled {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}
  .text-editor {
    textarea {
      display: none;
    }

    div[id^=cke_editor] {
      margin-left: auto;
      margin-right: auto;
      width: 50%;
    }
  }
  .client,
  .project,
  .policy,
  .play-card,
  .report,
  .aggregator,
  .project {
    display: block;
    margin-top: 2rem;
    margin-bottom: 2rem;

    &.users {
      width: fit-content;
      margin-left: auto;

      .first-row {
        margin-bottom: 15px;
      }
    }

    .client-options,
    .user-options,
    .project-options,
    [class*="-options"].row {
      display: flex;

      .email-input,
      .slug-input {
        margin-bottom: 0;
        margin-right: 30px;
      }

      &:not(.user-options) {
        margin-left: 45px;
      }

      .list-title {
        position: absolute;
        margin-top: 5px;
        left: 50px;
        font-weight: bold;
      }

      button {
        color: #A245FF;
        border: 1px solid #A245FF;
        border-radius: 40px;
        margin-right: 30px;
        max-height: 43px;
      }

      #all-users {
        margin-left: auto;
      }
    }
  }

  .field-error {
    border: 2px solid red;
  }
    textarea {
    background-color: field;
    border: 1px solid #cad1d7
  }
}

//V-Tooltip
.tooltip {
  display: block !important;
  z-index: 10000;

  .tooltip-inner {
    background: rgba(0, 0, 0, 0.644);
    color: white;
    border-radius: 16px;
    padding: 5px 10px 4px;
    white-space: nowrap;
    max-width: none;
  }

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: rgba(0, 0, 0, 0.644);
    z-index: 1;
  }

  &[x-placement^="top"] {
    margin-bottom: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="bottom"] {
    margin-top: 5px;

    .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="right"] {
    margin-left: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 5px 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^="left"] {
    margin-right: 5px;

    .tooltip-arrow {
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &.popover {
    $color: #f9f9f9;

    .popover-inner {
      background: $color;
      color: rgba(0, 0, 0, 0.644);
      padding: 24px;
      border-radius: 5px;
      box-shadow: 0 5px 30px rgba(black, .1);
    }

    .popover-arrow {
      border-color: $color;
    }
  }

  &[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition: opacity .45s, visibility .45s;
  }

  &[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity .35s;
  }
}
</style>

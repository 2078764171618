export const settings = (window.localStorage.getItem('settings')) ? JSON.parse(localStorage.getItem('settings')) : null;

const scopes = 'read:user+email+write:user+read:client+write:client+read:assessment+write:assessment+write:report+read:report+read:game+write:game';
const auth_params = `?client_id=admin-site&response_type=token&scope=${scopes}&redirect_uri=${window.location.origin}/auth-handler`;

export const AUTH_PATH = (settings === null) ? null : settings.auth_url + auth_params;
export const QUIZ_EDITOR =(settings === null) ? null : settings.quiz_editor;

export const SERVER_PATH = (settings === null) ? null : settings.api_url;
export const APIV2_PATH = (settings === null) ? null : settings.apiv2_url;
export const AS_API_PATH = (settings === null) ? null : settings.as_api_url;

export const CLIENT_ID = (settings === null) ? null : settings.gsheet_client_id;
export const API_KEY = (settings === null) ? null : settings.gsheet_api_key;
export const DISCOVERY_DOC = (settings === null) ? null : settings.gsheet_api_url;
export const SCOPES = (settings === null) ? null : settings.gsheet_scope;

export const RE_AUTHENTICATE = () => {
  const reAuthenticatedAt = localStorage.getItem('ReAuthenticated');
  const diff = new Date().getTime() - reAuthenticatedAt;

  if (!reAuthenticatedAt || (reAuthenticatedAt && diff > 5000)) {
    localStorage.removeItem('access_token');
    // remove access token before renewing it
    window.localStorage.setItem('reload_page', true);
    localStorage.setItem('ReAuthenticated', new Date().getTime());

    if (window.location.pathname !== '/auth-handler') {
      window.localStorage.setItem('path', window.location.pathname);
    }
    window.location.href = AUTH_PATH;
  }
};

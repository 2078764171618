<template>
  <div>
    <div class="client-list">
      <table>
        <thead>
          <tr>
            <th v-for="(header, i) in headers" :key="i">
              {{ header }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td></td>
            <td>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" @click="setFilter('client_name')"
                    ><i class="fa fa-search"></i
                  ></span>
                </div>
                <input
                  class="form-control"
                  :placeholder="$t('search')"
                  type="text"
                  v-model="filters.client_name"
                />
              </div>
            </td>
            <td></td>
            <td></td>
            <td>
              <div class="input-group">
                <Dropdown
                  :options="allClients"
                  @selected="(client) => { filters.parent_name = client.name; setFilter('parent_name'); }"
                  :disabled="false"
                  :name="parent_name"
                  :maxItem="allClients.length"
                  :placeholder="$t('select_client')"
                  :filterable="false"
                  v-model="filters.parent_name"
                />
              </div>
            </td>
            <td></td>
          </tr>
          <tr v-for="(client, i) in clients" :key="client.id || i">
            <td>{{ client.id || '-' }}</td>
            <td>
              <div class="client-name" @click="showClient(client.id)">
                {{ client.name || '-' }}
              </div>
            </td>
            <td>{{ client.slug || '-' }}</td>
            <td>{{ getParentId(client) || '-' }}</td>
            <td>{{ getParentName(client) || '-' }}</td>
            <td>
              <base-button
              v-if="client.logo_url"
              @click="openLogo(client.logo_url)">
                {{ $t('logo') }}
              </base-button>
              <span v-else>-</span>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="clients.length === 0">{{ $t('no-results') }}</div>
    </div>
  </div>
</template>

<script>
import Dropdown from './Dropdown.vue';
import api from '../common/api.js';

export default {
  components: {
    Dropdown,
  },
  props: {
    headers: {
      required: true,
    },
    clients: {
      required: true,
    },
  },
  data() {
    return {
      selectedClient: null,
      cloneModalOpen: false,
      parent_name: null,
      filters: {
        client_name: null,
      },
      allClients: [],
    };
  },
  mounted() {
    api.listClients(1, 1000)
      .then((res) => {
        this.allClients = res.data.items;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    getParentName(item) {
      return item.parent ? item.parent.name : '-';
    },
    getParentId(item) {
      return item.parent ? item.parent.id : '-';
    },
    openLogo(clients) {
      window.open(clients, '_blank');
    },
    setFilter(type) {
      const val = this.filters[type];
      Object.keys(this.filters).forEach((key) => {
        if (key !== type) this.filters[key] = null;
      });
      let filterParams = `&${type}=${val}`;
      if (val === '') filterParams = null;
      this.$emit('setFilter', filterParams);
    },
    showClient(clientId) {
      this.$store.commit('admin/setSelectedOption', 'edit-client');
      this.$router.push(`/client/${clientId}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.client-list {
  i {
    cursor: pointer;
  }
  .client-name {
    color: #5e72e4;
    text-decoration: none;
    background-color: transparent;
    cursor:pointer;
  }
}

.dropdown{
  margin-right: 0;
}
</style>

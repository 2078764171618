export const userState = {
   userinfo: null,
   userData: null,
   browser_lang: navigator.language || navigator.userLanguage,
   user_lang: null,
   fallback_locale: null,
   userLoading: true,
}
export const userMutations = {
    setUserinfo(state, data){
        state.userinfo=data;
        updateUserLoadingState(state);
    },
    setUserData(state, data) {
        state.userData=data;
    },
    setUserLang(state, data){
        state.user_lang=data;
        updateUserLoadingState(state);
    },
    setFallbackLocale(state, data){
        state.fallbackLocale=data;
    },
    setUserLoading(state, value) {
        state.userLoading = value;
    },
}

export const userActions = {

}

function updateUserLoadingState(state) {
    if (state.setUserinfo &&state.user_lang) {
        state.userLoading = true;
    } else {
        state.userLoading = false;
    }
}
<template>
  <modal
    :show="cloneModalOpen"
    modal-classes="modal-dialog-centered"
    body-classes="bg-secondary"
    footer-classes="bg-secondary"
    @close="close"
  >
    <h5 slot="header" class="modal-title" id="modal-title-default">
      {{
        selectedAggregator
          ? $t('cloning-aggregator', { aggregatorName: selectedAggregator.name })
          : ''
      }}
    </h5>

    <div class="form-group">
      <label for="name">
        <p class="label-text">{{ $t('aggregator.name') }}:</p>
        <base-input
          class="name-input"
          :placeholder="$t('name')"
          name="name"
          type="text"
          v-model="name"
        ></base-input>
        </label>
    <label for="client">
        <p class="label-text">{{ $t('aggregator.client') }}:</p>
        <Dropdown
          :options="clients"
          @selected="selectClient"
          defaultValue=""
          :disabled="false"
          :maxItem="clients.length"
          :placeholder="$t('select_client')"
          :clientid="true"
        />
      </label>
    </div>
    <Message
      v-if="resultMessage &&  resultMessage!== ''"
      :classes="''"
      :message="resultMessage"
      @hideResult="resultMessage = ''">
    </Message>

    <template slot="footer">
      <base-button class="ml-auto" @click="close">{{ $t('cancel') }} </base-button>
      <base-button @click="cloneAssessment" class="confirm":disabled="!name || !client" type="primary">{{
        $t('clone-aggregator')
      }}</base-button>
    </template>
  </modal>
</template>
<script>
import Modal from '@/components/base-components/Modal.vue';
import api from '../common/api';
import Dropdown from './Dropdown.vue';
import Message from './Message.vue';
import sharedMethods from '../mixins/sharedMethods';

export default {
  components: {
    Dropdown,
    Modal,
    Message,
  },
  mixins: [sharedMethods],
  props: {
    selectedAggregator: {
      required: true,
    },
    cloneModalOpen: {
      required: true,
    },
    clients: {
      required: true,
    },
  },
  data() {
    return {
      name: null,
      client: null,
      resultMessage: null,
    };
  },
  methods: {
    selectClient(client) {
      this.client = client.id;
    },
    close() {
      this.name = null;
      this.client = null;
      this.$emit('closeCloneModal');
    },
    cloneAssessment() {
      const payload = {
        name: this.name,
        client_id: this.client,
      };
      api
        .cloneAggregator(this.selectedAggregator.id, payload)
        .then((res) => {
          if (res.status === 201) {
            this.resultMessage = this.$t('clone-success');
            this.name = null;
            this.client = null;
            this.$emit('unselectAggregstor');
          }
        })
        .catch((err) => {
          this.handleErrors(err);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.modal {
  text-align:left;
  max-width: 100%;
  button,
  button:hover {
    color: #a245ff;
    border: 1px solid #a245ff;
    border-radius: 40px;
    margin-right: 30px;
    max-height: 43px;
  }
  button.confirm {
    color: white !important;
    background: linear-gradient(81.57deg, #0d86fe 12.9%, #b504ff 87.1%);
  }

  label {
    display:flex;
    align-items: center;
    .label-text {
        margin-right: 10px;
        color: #32325d;
        font-weight: 400;
        margin-bottom: 0px;
    }
  }
}
</style>

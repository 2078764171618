<template>
  <div class="client">
    <div class="client-options row">
      <base-button v-if="!selectedAction" @click="newClient()">
        {{ $t('add_client') }}
      </base-button>

      <base-button v-if="selectedAction" @click="goBack">
        {{ $t('back') }}
      </base-button>
    </div>
    <div
      v-if="client"
      class="client-options row"
    >
      <div class="client-row">
        <label for="id" v-if="selectedAction === 'edit'">
          <p class="label-text">{{ $t('client.id') }}:</p>
          <p class="result">{{ `${client.id}` }}</p>
        </label>

        <label for="name">
          <p class="label-text">{{ $t('client.name') }}:</p>
          <base-input
            class="name-input"
            :placeholder="$t('name')"
            :name="'name'"
            type="text"
            v-model="client.name"
          ></base-input>
        </label>

        <label for="parent">
          <p class="label-text">{{ $t('client.parent') }}:</p>
          <Dropdown
            :options="clients"
            @selected="selectParent"
            :defaultValue="getDefaultValue(client.parent, 'parent')"
            :disabled="false"
            :name="'parent'"
            :maxItem="clients.length"
            :placeholder="$t('select_client')"
            :key="parentUpdateCount"
          />
        </label>
      </div>

      <div class="client-row" v-if="selectedAction === 'edit'">
        <label for="created_at">
          <p class="label-text">{{ $t('client.created_at') }}:</p>
          <p class="result">{{ `${client.created_at}` | moment }}</p>
        </label>

        <label for="slug">
          <p class="label-text">{{ $t('client.slug') }}:</p>
          <p class="result">{{ `${client.slug}`}}</p>
        </label>
      </div>

      <div class="client-row" v-if="selectedAction === 'edit' || selectedAction === 'add'">
        <label for="logo_url" class="logo_url" v-if="selectedAction === 'edit'">
          <p class="label-text">{{ $t('client.logo') }}:</p>
          <img
          v-if="client.logo_url"
          :src="client.logo_url"
          style="max-width: 150px;"
          alt="Preview Image">
          <span v-else>-</span>
        </label>

        <label for="logo-upload" v-if="selectedAction === 'edit' || selectedAction === 'add'">
          <p class="label-text">{{ $t('client.logo_upload') }}:</p>
          <input type="file" ref="fileInput" @change="fileUpload"
            accept="image/*" style="display: none;">
          <base-button
          class="logo-upload"
          @click="$refs.fileInput.click()">{{ $t('client.choose_file') }}
          </base-button>
          <span v-if="imageFile">{{ imageFile.name }}</span>
          <img :src="imageUrl ? imageUrl : client.logo_url"
            style="max-width: 150px;" alt="Preview Image" v-if="imageUrl">
        </label>
      </div>
    </div>

    <hr v-if="client"/>

    <div
      v-if="client"
      class="client-options row"
    >
      <div class="client-row" v-if="selectedAction === 'edit' || selectedAction === 'add'">
        <base-button class="unset_logo" @click="unsetLogo" v-if="selectedAction === 'edit'">
          {{ $t('client.logo_unset') }}
        </base-button>

        <label class="allow_csv" for="allow_csv">
          <p class="label-text">{{ $t('client.allow_csv') }}:</p>
          <span class="allow_csv allow_csv-2">
            <input
              type="checkbox"
              id="allow_csv-2"
              v-model="client.allow_csv"
            />
            <label for="allow_csv-2"></label>
          </span>
        </label>

        <base-button class="top_parent" @click="topParent">
          {{ $t('client.unset_parent') }}
        </base-button>
      </div>
    </div>

    <hr v-if="client" />

    <div
      class="client-options row"
    >
      <base-button v-if="selectedAction === 'edit'" @click="editClient">
        {{ $t('edit') }}
      </base-button>

      <base-button v-if="selectedAction === 'add'" @click="editClient">
        {{ $t('add') }}
      </base-button>
      <Message
        v-if="responseMsg !== ''"
        :classes="''"
        :message="responseMsg"
        @hideResult="responseMsg = ''">
      </Message>
    </div>

    <hr v-if="client" />
  </div>
</template>

<script>
import Filters from '../mixins/filters';
import Dropdown from './Dropdown.vue';
import api from '../common/api.js';
import Message from './Message.vue';

export default {
  components: {
    Dropdown,
    Message,
  },
  props: {
    clients: {
      required: true,
    },
  },
  data() {
    return {
      parentUpdateCount: 0,
      errorHappened: false,
      selectedAction: null,
      clientId: null,
      client: null,
      clientScheme: {
        name: '',
        parent_id: null,
        logo: null,
        allow_csv: false,
      },
      clientChanged: false,
      responseMsg: '',
      imageFile: null,
      imageUrl: null,
    };
  },
  mixins: [Filters],
  methods: {
    fileUpload(event) {
      const file = event.target.files[0];
      const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.svg)$/i;

      if (!allowedExtensions.exec(file.name)) {
        this.setMsg(this.$t('result_message.logo_upload_invalid_type'));
        return;
      }
      if (file.size > 100 * 1024) {
        this.setMsg(this.$t('result_message.logo_upload_invalid_size'));
        return;
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.imageFile = file;
        this.imageUrl = reader.result;
        this.client.logo = this.imageFile;
      };
    },
    unsetLogo() {
      this.client.logo = null;
      this.setMsg(this.$t('result_message.unset_logo'));
    },
    topParent() {
      this.client.parent = null;
      this.client.parent_id = null;
      this.parentUpdateCount += 1;
      this.setMsg(this.$t('result_message.unset_parent_msg'));
    },
    setMsg(msg) {
      this.responseMsg = msg;
    },
    getDefaultValue(prop, key) {
      if (key === 'parent' && prop === null) {
        return null;
      }
      if (
        (key === 'client')
        && prop
        && prop.name
      ) {
        return prop.name;
      } if (prop && (prop.name || prop.id)) {
        return prop.name || `(Id: ${prop.id})`;
      }

      return '';
    },
    handleErrors(err) {
      this.errorHappened = true;
      this.$emit('handleErrors', err);
    },
    selectParent(client) {
      if (this.client.parent_id !== client.id) {
        this.client.parent_id = client.id;
      }
    },
    setClient(clientId) {
      this.deleteValues();
      this.responseMsg = '';
      this.clientId = clientId;

      api
        .getClient(clientId)
        .then((res) => {
          this.client = res.data;
        })
        .catch((err) => {
          if (err.response !== undefined) {
            this.handleErrors(err.response);
          } else {
            this.handleErrors(err);
          }
        });
    },
    editClient() {
      this.errorHappened = false;
      this.saveClient();
    },
    counterPush(editorsave) {
      const timeoutSeconds = 4;
      let countDown = timeoutSeconds;

      const message = `${this.$t(editorsave)}`;
      this.setMsg(message);

      const intervalId = setInterval(() => {
        countDown--;
        if (countDown > 0) {
          this.setMsg(`${message} ... ${countDown}`);
        } else {
          clearInterval(intervalId);
          this.$router.push('/clients');
        }
      }, 1000);
    },
    saveClient() {
      let formData;
      if (this.imageFile instanceof File) {
        formData = new FormData();
        formData.append('logo', this.imageFile);
      } if (this.client.logo === null) {
        formData = new FormData();
        formData.append('logo', '');
      }

      if (this.selectedAction === 'edit') {
        if (this.client.parent_id === undefined) {
          if (this.client.parent !== null) {
            this.client.parent_id = this.client.parent.id;
          } else {
            this.client.parent_id = null;
          }
        }
        const params = [this.clientId, this.client.name, this.client.parent_id, this.client.allow_csv];
        if (formData) params.push(formData);
        api
          .editClient(...params)
          .then(() => {
            this.counterPush('result_message.client_edited');
          })
          .catch((err) => {
            if (err.response !== undefined) {
              this.handleErrors(err.response);
            } else {
              this.handleErrors(err);
            }
          });
      } else if (this.selectedAction === 'add') {
        const params = [this.client.name, this.client.parent_id, this.client.allow_csv];
        if (formData) params.push(formData);
        api
          .addClient(...params)
          .then(() => {
            this.counterPush('result_message.client_added');
          })
          .catch((err) => {
            if (err.response !== undefined) {
              this.handleErrors(err.response);
            } else {
              this.handleErrors(err);
            }
          });
      }
    },
    successEdit() {
      if (this.selectedAction === 'edit') {
        this.editClient();
      } else if (this.selectedAction === 'add') {
        this.setMsg(this.$t('result_message.client_added'));
      }
    },
    deleteValues() {
      this.name = '';
      this.client = null;
      this.parent_id = null;
    },
    selectAction(action) {
      if (!action) {
        this.deleteValues();
        this.hideResult();
      } else if (action === 'add') {
        this.client = structuredClone(this.clientScheme);
        this.client.name = this.name;
      }

      this.selectedAction = action;
    },
    newClient() {
      this.$store.commit('admin/setSelectedOption', 'edit-client');
      this.$router.push('/new-client');
    },
    goBack() {
      this.$store.commit('admin/setSelectedOption', null);
      this.$router.go(-1);
    },
  },
  mounted() {
    if (this.$route.params.clientId) {
      this.setClient(Number(this.$route.params.clientId));
      this.selectAction('edit');
    }
    if (this.$route.name === 'NewClient') {
      this.selectAction('add');
    }
  },
};
</script>

<style lang="scss" scoped>
$purple:#A245FF;
$white:#FFFFFF;

span.allow_csv {
  box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
  position: relative;
  width: 120px;
  height: 43px;
  border-radius:40px;
  overflow:hidden;
  border: 1.2px solid $purple;
  input {
    appearance: none;
    position: relative;
    width: 120px;
    height: 43px;
    border-radius:40px;
    background-color:$white;
    outline:none;
    font-family: "Open Sans", sans-serif;
    text-transform: uppercase;
    font-size: 0.9rem;
    font-weight: 600;
    letter-spacing: 0.025em;
    &:before, &:after {
      z-index:2;
      position: absolute;
      top:50%;
      transform:translateY(-50%);
      color:$purple;
    }
    &:before {
      content: 'ON';
      left:20px;
    }
    &:after {
      content: 'OFF';
      right:20px;
    }
  }
  label {
    z-index:1;
    position: absolute;
    bottom:10px;
    margin: 0;
  }
  &.allow_csv-2 {
    input {
      transition:background-color 0s .5s;
      &:before {
        color:$white;
      }
      &:after {
        color:$purple;
      }
      &:checked {
        background-color:$purple;
        &+label {
          background:$purple;
          animation: turn-on .5s ease-out;
          @keyframes turn-on {
            0% {
              left:100%;
            }
            100% {
              left:0%;
            }
          }
        }
      }
      &:not(:checked) {
        background:$white;
      &+label {
        background:$white;
        animation: turn-off .5s ease-out;
        @keyframes turn-off {
          0% {
            right:100%;
          }
          100% {
            right:0%;
          }
        }
      }
    }
  }
  label {
      top:0px;
      width: 120px;
      height: 43px;
      border-radius:40px;
      max-height: 43px;
    }
  }
}

.row {
  display: flex;
  align-items: baseline;

  h3,
  .full-width {
    flex: 100%;
  }

  label {
    display: flex;
    align-items: center;
    margin-top: 8px;

    .label-text {
      font-weight: 400;
      color: #3c4866;
      margin-right: 10px;
      margin-bottom: 0;

      &.name {
        font-weight: bold;
      }
    }

    .smaller {
      width: 70px;
    }

    textarea {
      height: 70px;
      width: 250px;
      margin-top: 0;
      resize: both;
    }
  }

  textarea {
    padding: 10px 12px;
  }

  &:not(:first-child) {
    margin-top: 0.8rem;
  }

  .custom-control.custom-checkbox {
    margin-right: 50px;
  }

  .form-group,
  textarea {
    margin-right: 50px;
    margin-bottom: 0;
  }

  .form-group input,
  textarea {
    height: 43px;
    border: 1px solid #cad1d7;
    border-radius: 0.25rem;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.5;
    color: #3c4866;

    &::placeholder {
      color: #8898aa;
      opacity: 0.8;
    }
  }

  .edit-client {
    display: flex;
  }

  .client-row {
    display: flex;
    flex: 100%;
  }
}

.result {
  font-weight: bold;
  margin: 0;
  margin-right: 50px;
}

.selection-panel {
  .client .row {
    &:not(:first-child) button {
      margin-top: 0.8rem;
    }

    button.logo-upload {
      margin-top: 0;
    }

    label.allow_csv {
      margin-top: 0.8rem;
      margin-right: 40px;
      margin-bottom: 0;
    }

    button.unset_logo {
      margin-right: 30px;
    }

    span.allow_csv:hover {
      box-shadow: 0 7px 14px rgba(50,50,93,.1), 0 3px 6px rgba(0,0,0,.08);;
      transform: translateY(-1px);
    }

    button:last-child {
      margin-right: 0;
    }
  }

  hr {
    position: absolute;
    width: 100%;
    margin-top: 0.75rem;
    margin-left: 0;
  }
}

.logo_url {
  margin-right: 30px;
}
</style>

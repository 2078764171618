<template>
    <div class="wrapper">
        <div class="page-header">
            <Manage />
        </div>
    </div>
</template>

<script>
import Manage from '../components/Manage.vue';

export default {
  components: {
    Manage,
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  min-width: 1280px;
}
</style>

<template>
  <div class="wrapper">
    <Project
      ref="project"
      :clients="clients"
      :games="games"
      @handleErrors="handleErrors"
      @setMsg="setMsg"
    />
  </div>
</template>

<script>
import sharedMethods from '../mixins/sharedMethods';
import Project from '../components/Project.vue';
import api from '../common/api';

export default {
  components: { Project },
  mixins: [sharedMethods],
  data() {
    return {
      gameDataSize: 0,
      games: [],
      clientDataSize: 0,
      clients: [],
    };
  },
  methods: {
    getGameList(page) {
      api
        .listGames(page)
        .then((res) => {
          this.gameDataSize = res.data.total / 100;
          res.data.items.forEach((item) => {
            this.games.unshift({
              id: item.id,
              name: `${item.name} (${item.identifier})`,
            });
          });
          if (page < this.gameDataSize) {
            page++;
            this.getGameList(page);
          }
        })
        .catch((err) => {
          if (err.response !== undefined) {
            this.handleErrors(err.response);
          } else {
            this.handleErrors(err);
          }
        });
    },
    getClientList(page) {
      api
        .listClients(page, 1000)
        .then((res) => {
          this.clientDataSize = res.data.total / 100;
          res.data.items.forEach((item) => {
            this.clients.unshift(item);
          });
          if (page < this.clientDataSize) {
            page++;
            this.getClientList(page);
          }
        })
        .catch((err) => {
          if (err.response !== undefined) {
            this.handleErrors(err.response);
          } else {
            this.handleErrors(err);
          }
        });
    },
  },
  mounted() {
    if (this.gameDataSize === 0) {
      this.getGameList(1);
    }
    if (this.clientDataSize === 0) {
      this.getClientList(1);
    }
  },
};
</script>

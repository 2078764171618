<template>
  <div class="search">
    <h3 class="search_h3" ref="scrollElement">Search for:</h3>
    <div class="row">
      <p
        class="menu-text"
        v-for="item in menuItems"
        :key="item.value"
        @click="
          activeMenuItem = item.value;
          hideResultMessage();
        "
        :class="{ active: activeMenuItem === item.value }"
      >
        {{ item.label }}
      </p>
    </div>
    <hr />
    <div class="row" v-if="activeMenuItem === 'player'">
      <label class="search_label" for="name">
        <p class="label-text2">{{ $t("name") }}:</p>
        <base-input
          class="name-input"
          :placeholder="$t('name')"
          :name="'name'"
          type="text"
          v-model="searchParams.name"
        ></base-input>
      </label>
      <label class="search_label" for="email">
        <p class="label-text2">{{ $t("email") }}:</p>
        <base-input
          class="email-input"
          :placeholder="$t('email')"
          :name="'email'"
          type="text"
          v-model="searchParams.email"
        ></base-input>
      </label>
      <label class="search_label" for="uuid">
        <p class="label-text2">{{ $t("uuid") }}:</p>
        <base-input
          class="id-input"
          :placeholder="$t('uuid')"
          :name="'uuid'"
          type="text"
          v-model="searchParams.uuid"
        ></base-input>
      </label>
      <label class="search_label" for="external_id">
        <p class="label-text2">{{ $t("external_id") }}:</p>
        <base-input
          class="external_id-input"
          :placeholder="$t('external_id')"
          :name="'external_id'"
          type="text"
          v-model="searchParams.external_id"
        ></base-input>
      </label>
      <base-button class="search-button" @click="setPage(1)"
        >Search</base-button
      >
    </div>
    <div class="row" v-if="activeMenuItem === 'gameplay'">
      <label class="search_label" for="gameplay_id">
        <p class="label-text2">{{ $t("gameplay_id") }}:</p>
        <base-input
          class="gameplay_id-input"
          :placeholder="$t('gameplay_id')"
          :name="'gameplay_id'"
          type="text"
          v-model="searchParamsGameplay.gameplay_code"
        ></base-input>
      </label>
      <label class="search_label" for="dates">
        <p class="label-text2">{{ $t("dates") }}:</p>
        <div class="input-daterange datepicker">
          <div class="col">
            <base-input addon-left-icon="ni ni-calendar-grid-58">
              <flat-picker
                slot-scope="{ focus, blur }"
                @on-open="focus"
                @on-close="blur"
                :config="{ allowInput: true, mode: 'range' }"
                class="form-control datepicker"
                v-model="searchParamsGameplay.dates"
              >
              </flat-picker>
            </base-input>
          </div>
        </div>
      </label>
      <base-button class="search-button" @click="setGameplay(1)"
        >Search</base-button
      >
    </div>
    <div class="row" v-if="activeMenuItem === 'invitation_code'">
      <label class="search_label" for="invitation_code">
        <p class="label-text2">{{ $t("invitation_code") }}:</p>
        <base-input
          class="invitation_code-input"
          :placeholder="$t('invitation_code')"
          :name="'invitation_code'"
          type="text"
          v-model="searchParamsInvitation.invite_code"
        ></base-input>
      </label>
      <base-button class="search-button" @click="setInvitation()"
        >Search</base-button
      >
    </div>
    <hr />
    <SearchInvitation :player="invitation" :active="activeMenuItem">
    </SearchInvitation>
    <SearchGameplay :players="gameplay" :active="activeMenuItem">
    </SearchGameplay>
    <SearchPlayer :players="players" :active="activeMenuItem"> </SearchPlayer>
    <div
      v-if="activeMenuItem === 'player' && pagination.total > pagination.size"
      class="pagination-wrapper"
    >
      <ul class="pagination justify-content-center">
        <li
          v-for="p in pages"
          :key="p"
          :class="`page-item ${p === pagination.page ? 'active' : ''}`"
        >
          <a class="page-link" @click="setPage(p)">{{ p }}</a>
        </li>
      </ul>
    </div>
    <div
      v-if="
        activeMenuItem === 'gameplay' && paginationB.total > paginationB.size
      "
      class="pagination-wrapper"
    >
      <BasePagination
        class="justify-content-center"
        :total="paginationB.total"
        :value="paginationB.page"
        :per-page="paginationB.size"
        @input="setGameplay"
      />
    </div>
    <LoadingRing v-if="loading" class="pagination-wrapper"></LoadingRing>
    <Message
      v-if="resultMessage !== ''"
      :classes="''"
      :message="resultMessage"
      @hideResult="resultMessage = ''"
    >
    </Message>
  </div>
</template>
<script>
import api from "../common/api";
import SearchInvitation from "./SearchInvitation.vue";
import SearchGameplay from "./SearchGameplay.vue";
import SearchPlayer from "./SearchPlayer.vue";
import sharedMethods from "../mixins/sharedMethods";
import Message from "./Message.vue";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import BasePagination from "../components/base-components/BasePagination";
import LoadingRing from "../components/base-components/LoadingRing";
import moment from "moment-timezone";

export default {
  components: {
    SearchInvitation,
    Message,
    SearchGameplay,
    SearchPlayer,
    flatPicker,
    LoadingRing,
    BasePagination,
  },
  mixins: [sharedMethods],
  data() {
    return {
      menuItems: [
        { label: "Player", value: "player" },
        { label: "Gameplay", value: "gameplay" },
        { label: "Invitation code", value: "invitation_code" },
      ],
      activeMenuItem: "player",
      players: null,
      gameplay: null,
      invitation: null,
      loading: false,
      resultMessage: "",
      pagination: {
        page: 1,
        total: 0,
        size: 10,
      },
      paginationB: {
        page: 1,
        total: 0,
        size: 10,
      },
      searchParams: {
        name: "",
        external_id: "",
        uuid: "",
        email: "",
      },
      searchParamsGameplay: {
        gameplay_code: "",
        dates: "",
      },
      searchParamsInvitation: {
        invite_code: "",
      },
    };
  },
  computed: {
    pages() {
      return Math.ceil(this.pagination.total / this.pagination.size);
    },
    pagesB() {
      return Math.ceil(this.paginationB.total / this.paginationB.size);
    },
  },
  methods: {
    setPage(page) {
      const { name, external_id, uuid, email } = this.searchParams;
      const params = {
        name,
        external_id,
        uuid,
        email,
        page: this.pagination.page !== page || page !== 1 ? page : 1,
      };
      if (
        (name || external_id || uuid || email) &&
        (name !== this.$route.query.name ||
          external_id !== this.$route.query.external_id ||
          uuid !== this.$route.query.uuid ||
          email !== this.$route.query.email ||
          parseInt(params.page) != this.$route.query.page)
      ) {
        this.$router.replace({ query: params });
      }
    },
    setGameplay(page) {
      const { gameplay_code, dates } = this.searchParamsGameplay;
      let created_at_from = null;
      let created_at_to = null;

      if (dates) {
        const [startDate, endDate] = dates.split(" to ");
        if (startDate != "null" && endDate != "null") {
          created_at_from = startDate;
          created_at_to = endDate;
        }
      }
      if (created_at_to == undefined) {
        created_at_to = created_at_from;
      }

      const params = {
        gameplay_code,
        created_at_from,
        created_at_to,
        pageB: this.paginationB.page !== page || page !== 1 ? page : 1,
      };

      if (
        (gameplay_code || created_at_from || created_at_to) &&
        (gameplay_code !== this.$route.query.gameplay_code ||
          created_at_from !== this.$route.query.created_at_from ||
          created_at_to !== this.$route.query.created_at_to ||
          parseInt(params.pageB) != this.$route.query.pageB)
      ) {
        this.$router.replace({ query: params });
      }
    },
    setInvitation() {
      const { invite_code } = this.searchParamsInvitation;
      const params = {
        invite_code,
      };
      if (invite_code && invite_code !== this.$route.query.invite_code) {
        this.$router.replace({ query: params });
      }
    },
    getPlayer(name, external_id, uuid, email, page) {
      if (name || external_id || uuid || email) {
        this.loading = true;
        api
          .searchPlayer(
            name,
            external_id,
            uuid,
            email,
            page,
            this.pagination.size
          )
          .then((res) => {
            if (0 < res.data.total) {
              this.players = null;
              this.players = res.data.items;
              this.pagination.total = res.data.total;
              this.pagination.page = page;
              this.resultMessage = "";
            } else if (res.data.total == 0) {
              this.players = null;
              this.pagination.total = null;
              this.resultMessage = this.$t("no_match");
            } else {
              this.players = null;
              this.pagination.total = null;
              this.resultMessage = this.$t("too_much");
            }
          })
          .catch((err) => {
            if (err.response !== undefined) {
              this.players = null;
              this.resultMessage = err.response;
              this.handleErrors(err.response);
            } else {
              this.handleErrors(err);
            }
          })
          .finally(() => {
            this.loading = false;
            const scrollElement = this.$refs.scrollElement;
            scrollElement.scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          });
      } else {
        this.players = null;
        this.pagination.total = null;
        this.resultMessage = this.$t("nothing_title");
      }
    },
    getGameplay(uuid, created_at_from, created_at_to, page) {
    if (uuid || created_at_from || created_at_to) {
        this.loading = true;
        let fromDateUTC, toDateUTC;

        if (created_at_from || created_at_to) {
            const userTimezone = moment.tz.guess();
            const fromDateInUserTimezone = moment(created_at_from).tz(userTimezone);
            const toDateInUserTimezone = moment(created_at_to).tz(userTimezone);

            fromDateUTC = fromDateInUserTimezone.utc().format();
            toDateUTC = toDateInUserTimezone.endOf('day').utc().format();
        } else {
            fromDateUTC = null;
            toDateUTC = null;
        }

        api.searchGameplay(uuid, fromDateUTC, toDateUTC, page, this.paginationB.size)
            .then((res) => {
                if (res.data.total > 0) {
                    this.gameplay = res.data.items;
                    this.paginationB.total = res.data.total;
                    this.paginationB.page = page;
                    this.resultMessage = '';
                } else {
                    this.gameplay = null;
                    this.paginationB.total = 0;
                    this.resultMessage = this.$t('no_gameplay_found');
                }
            })
            .catch((err) => {
                if (err.response !== undefined) {
                    this.gameplay = null;
                    this.resultMessage = err.response.data.detail;
                } else {
                    this.resultMessage = err;
                }
            })
            .finally(() => {
                this.loading = false;
                const scrollElement = this.$refs.scrollElement;
                scrollElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
            });
    } else {
        this.gameplay = null;
        this.paginationB.total = 0;
        this.resultMessage = this.$t('no_search_criteria');
    }
},
    getInvites(invite) {
      this.loading = true;
      api
        .searchInvite(invite)
        .then((res) => {
          this.invitation = res.data;
          this.invitation.invitation_code = invite;
          this.resultMessage = "";
        })
        .catch((err) => {
          if (err.response !== undefined) {
            this.invitation = null;
            this.resultMessage = err.response.data.detail;
            this.handleErrors(err.response);
          } else {
            this.handleErrors(err);
          }
        })
        .finally(() => {
          this.loading = false;
          const scrollElement = this.$refs.scrollElement;
          scrollElement.scrollIntoView({ behavior: "smooth", block: "start" });
        });
    },
    hideResultMessage() {
      this.resultMessage = "";
    },
  },
  mounted() {
    const { name, external_id, uuid, email, page } = this.$route.query;
    if (name || external_id || uuid || email) {
      this.searchParams.name = name || "";
      this.searchParams.external_id = external_id || "";
      this.searchParams.uuid = uuid || "";
      this.searchParams.email = email || "";
      this.pagination.page = parseInt(page) || 1;
      this.activeMenuItem = "player";
      const parsedPage = parseInt(page);
      this.getPlayer(name, external_id, uuid, email, parsedPage);
    }

    const { gameplay_code, created_at_from, created_at_to, pageB } =
      this.$route.query;
    if (gameplay_code || created_at_from || created_at_to) {
      this.searchParamsGameplay.gameplay_code = gameplay_code;
      this.searchParamsGameplay.dates = `${created_at_from} to ${created_at_to}`;
      this.activeMenuItem = "gameplay";
      this.paginationB.page = parseInt(pageB) || 1;
      const parsedPage = parseInt(pageB);
      this.getGameplay(
        gameplay_code,
        created_at_from,
        created_at_to,
        parsedPage
      );
    }

    const { invite_code } = this.$route.query;
    if (invite_code) {
      this.activeMenuItem = "invitation_code";
      this.searchParamsInvitation.invite_code = invite_code;
      this.getInvites(invite_code);
    }
  },
};
</script>
<style lang="scss">
body {
  font-family: "Poppins", "Open Sans", sans-serif !important;
}

.pagination-wrapper {
  display: flex;
  justify-content: center;

  .pagination {
    width: 80vw;
    flex-wrap: wrap;
  }
}

.page-item {
  cursor: pointer;
}

.search_h3 {
  font-weight: 600 !important;
}

.menu-text {
  margin-top: 0.8rem;
  margin-left: 2rem;
  margin-right: 10rem;
  margin-bottom: -15px !important;
  font-weight: 600;

  &:hover {
    cursor: pointer;
    color: #9124ff;
  }

  &.active {
    color: #a245ff;
  }

  &.hovered {
    color: #9124ff;
  }
}

.search {
  margin-top: 30px;
  margin-left: 80px;
}

.search_label {
  display: flex !important;
  align-items: center !important;

  .label-text2 {
    font-weight: 400;
    color: #3c4866;
    margin-right: 10px;
    margin-bottom: 0;

    &.name {
      font-weight: bold;
    }
  }

  .invitation_code-input,
  .gameplay_id-input {
    width: 19rem;
  }

  .smaller {
    width: 70px;
  }

  textarea {
    height: 70px;
    width: 250px;
    margin-top: 0;
    resize: both;
  }
}

.player {
  position: relative;
  margin-left: -20px;
  padding: 20px;
  background-color: rgb(255, 255, 255);
  margin-bottom: 20px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
}

.search_gp_raw {
  position: absolute;
  top: 0;
  left: 100px;
  margin-top: 10px;
}

.row2 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: 0;
  margin-bottom: 0;

  h3 {
    margin-right: 15px;
    margin-bottom: 0;
  }

  h4 {
    margin-right: 15px;
    margin-bottom: 0;
    font-weight: 600;
  }

  .label-text {
    margin-right: 5px;
    margin-bottom: 3px;
  }

  .result {
    font-weight: 500;
    margin-bottom: 3px;
  }

  .uuid {
    color: #a245ff;
    text-decoration: underline;
    font-weight: 500;
    margin-bottom: 0;
  }
}

.email {
  font-weight: 600;
  width: max-content;
}

@media (max-width: 991px) {
  .search {
    margin-left: 10px;
    margin-top: 0;
  }

  .row {
    margin: 0;
    width: 100%;
    flex-direction: column;
    text-align: center;
  }

  .search-button {
    margin-top: 1.5rem;
  }

  .menu-text {
    margin-top: 1.2rem;
    margin-left: 2rem;
    margin-right: 1rem;
    margin-bottom: -15px !important;
  }
}
</style>

export const authState = {
  token: localStorage.getItem('access_token') || '',
  status: ''
};

export const authGetters = {
  isAuthenticated: state => { if (state.token) { return true } },
  authStatus: state => state.status,
};

export const authMutations = {
  AUTH_REQUEST: (state) => {
    state.status = 'loading'
  },
  AUTH_SUCCESS: (state, token) => {
    state.status = 'success'
    state.token = token
  },
  AUTH_ERROR: (state) => {
    state.status = 'error'
  },
  LOGOUT: (state) => {
    state.token = "";
  },
  setToken(state, token) {
    state.token=token;
  }
};

export const authActions = {
  AUTH_REQUEST({ commit }, params) {
    commit('AUTH_SUCCESS', params);
  }
};

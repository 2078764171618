<template>
    <div v-if="stateList" class="search">
        <div class="player_name">
            <div class="row2">
                <h3 class="search_h3">{{ `${name || $t('anonymus')}` }}</h3>
                <h5 class="uuid" @click="setPlayer(playerId)">P-{{ `${playerId}` }}</h5>
            </div>
            <div class="row2">
                <p class="result">{{ `${email || $t('anonymus')}` }}</p>
            </div>
            <div class="row2">
                <p class="result">Aggregator:</p>
                <router-link class="state_left" :to="`/aggregator/${aggregatorId}`">{{ `${aggregatorName}` }} ({{
                    `${aggregatorId}` }})</router-link>
            </div>
            <div class="row2">
                <p class="result">{{ $t('state.next_member') }}: </p>
                <p class="result state_left">{{ `${nextMember}` }}</p>
            </div>
        </div>
        <div class="side-container">
            <div class="side_a">
                <div v-for="(state, index) in stateList" :key="index" :class="[
                    'player',
                    { 'yellow_background': state.game && state.type === 'report_generated' }
                ]">
                    <div class="row2" v-if="state.type">
                        <p class="result project_id">{{ $t('state.type') }}:</p>
                        <p class="result project_id">{{ `${$t(`state.typeData.${state.type}`) || state.type}` }}</p>
                        <p class="result project_id game_start" v-if="state.activity === 'game_start'">({{
                            $t(`state.${state.activity}`) }})</p>
                        <p class="result project_id game_end" v-if="state.activity === 'game_end'">({{
                            $t(`state.${state.activity}`)
                        }})</p>
                    </div>
                    <div class="row2" v-if="state.game && state.type != 'report_generated'">
                        <p class="result project_id">{{ $t('state.game') }}:</p>
                        <p class="result project_id">{{
                            `${state.game}` }}</p>
                        <p v-if="state.gameplay" class="result project_id">{{
                            `(GP-${state.gameplay})` }}</p>
                    </div>
                    <div class="row2" v-if="state.game && state.type === 'report_generated'">
                        <p class="result project_id">{{ $t('state.game_report_generated') }}:</p>
                        <p class="result project_id">{{
                            `${state.game}` }}</p>
                        <p v-if="state.gameplay" class="result project_id">{{
                            `(GP-${state.gameplay})` }}</p>
                    </div>
                    <div class="row2" v-if="state.filename">
                        <p class="result project_id">{{ $t('state.filename') }}:</p>
                        <p class="result project_id">{{
                            `${state.filename}` }}</p>
                    </div>
                    <div class="row2 gameplay" v-if="state.level_pack">
                        <p class="label-text">{{ $t('state.level_pack') }}:</p>
                        <p class="result">{{
                            `${state.level_pack}` }}</p>
                    </div>
                    <div class="row2 gameplay" v-if="state.member">
                        <p class="label-text">{{ $t('state.member') }}:</p>
                        <p class="result">{{
                            `${state.member}` }}</p>
                    </div>
                    <div class="row2 gameplay" v-if="state.timestamp">
                        <p class="label-text">{{ $t('state.timestamp') }}:</p>
                        <p class="result">{{
                            `${state.timestamp}` | moment }}</p>
                    </div>
                    <div class="row2 gameplay" v-for="(value, key) in state" :key="key" v-if="state && !(['id', 'type', 'activity', 'game', 'gameplay', 'filename', 'level_pack', 'member', 'timestamp'].includes(key))">
                        <p class="label-text">{{ key }}:</p>
                        <p class="result">{{ value }}</p>
                    </div>
                </div>
            </div>
            <div class="side_b">
                <h2 class="timeline_center">{{ $t('state.timeline.name') }}</h2>
                <v-timeline class="center-timeline my-timeline">
                    <v-timeline-item v-for="(member, i) in members" :key="i" small bold :class="[
                        i % 2 === 0 ? 'align-left' : 'align-right',
                        nextMember - 1 >= i ? 'green-timeline' : (nextMember == null ? 'green-timeline' : 'red-timeline'),
                    ]">
                        <template v-slot:icon>
                            <div class="bolder">
                                {{ `${i}` }}
                            </div>
                        </template>
                        <div>{{ $t(`state.timeline.${member.entity_type}`) }}</div>
                        <div>{{ `${member.entity.name}` }} ({{ `${member.entity.id}` }})</div>
                    </v-timeline-item>
                </v-timeline>
            </div>
        </div>
    </div>
</template>
<script>
import Filters from '../mixins/filters';
import api from '../common/api';
import sharedMethods from '../mixins/sharedMethods';
import { VTimeline, VTimelineItem } from 'vuetify/lib';


export default {
    data() {
        return {
            playerId: null,
            name: null,
            email: null,
            stateList: null,
            nextMember: null,
            members: null,
            aggregatorId: null,
            aggregatorName: null,
        };
    },
    mixins: [Filters, sharedMethods],
    components: {
        VTimeline,
        VTimelineItem
    },
    methods: {
        setPlayer() {
            this.$router.push(`/player/${this.playerId}`);
        },
        getPlayerState(playerId, aggregatorId) {
            api.getPlayerState(playerId, aggregatorId)
                .then((res) => {
                    this.stateList = res.data.state;
                    this.nextMember = res.data.resolution.next_member;
                })
                .catch((err) => {
                    if (err.response !== undefined) {
                        this.resultMessage = err.response;
                    } else {
                        this.resultMessage = err;
                    }
                });
        },
        getPlayerData(playerId, page, size) {
            api.getPlayerData(playerId, page, size)
                .then((res) => {
                    this.name = res.data.name;
                    this.email = res.data.email;
                })
                .catch((err) => {
                    if (err.response !== undefined) {
                        this.resultMessage = err.response;
                    } else {
                        this.resultMessage = err;
                    }
                });
        },
        getAggregator(aggregatorId) {
            api.getAggregator(aggregatorId)
                .then((res) => {
                    this.aggregatorName = res.data.name;
                    this.members = res.data.members;
                })
                .catch((err) => {
                    if (err.response !== undefined) {
                        this.resultMessage = err.response;
                    } else {
                        this.resultMessage = err;
                    }
                });
        },
    },
    mounted() {
        if (this.$route.params) {
            this.playerId = this.$route.params.playerId;
            this.aggregatorId = this.$route.params.aggregatorId;
            this.getAggregator(this.aggregatorId);
            this.getPlayerData(this.playerId);
            this.getPlayerState(this.$route.params.playerId, this.$route.params.aggregatorId);
        }
    },
};
</script>
<style scoped lang="scss">
.uuid {
    cursor: pointer;
}

.game_start {
    color: rgb(0, 172, 0);
}

.game_end {
    color: rgb(172, 0, 0);
}

.side_a {
    width: 50%;
    float: left;
}

.side_b {
    width: 50%;
    float: right;
}

.side-container {
    display: flex;
    align-items: flex-start;
}

.center-timeline::before {
    left: 50%;
}

.center-timeline .align-left {
    text-align: left;
}

.center-timeline .align-right {
    text-align: right;
}

.green-timeline {
    color: green !important;
}

.red-timeline {
    color: red !important;
}

.bolder {
    font-weight: 600;
}

.timeline_center {
    text-align: center;
    margin-bottom: 20px;
    font-weight: 600;
}

.state_left {
    margin-left: 10px
}

.yellow_background {
    background: rgba(255, 255, 0, 0.226);
}


@media (max-width: 991px) {
    .search {
        margin-left: 10px;
        overflow: auto;
        white-space: pre-line;
    }

    .player {
        margin-left: 0;
    }

    .side-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .side_a {
        width: 100%;
        float: none;
    }

    .side_b {
        width: 100%;
        float: none;
    }
}
</style>
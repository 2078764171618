<template>
  <div class="kpi-editor">
    <div class="row">
      <base-button
        v-if="!selectedAction"
        @click="selectAction('add')"
      >
        {{ $t('report_editor.add_kpi') }}
      </base-button>

      <base-button
        v-if="!selectedAction"
        @click="selectAction('edit')"
      >
        {{ $t('report_editor.edit_kpi') }}
      </base-button>

      <base-input
        v-if="selectedAction === 'add'"
        class="identifier-input"
        name="identifier"
        :placeholder="$t('report_editor.identifier')"
        type="text"
        v-model="newKpi.identifier"
      ></base-input>

      <Dropdown
        v-else-if="selectedAction === 'edit'"
        class="edit"
        name="kpis"
        :options="kpis"
        :disabled="false"
        :maxItem="kpis.length"
        :placeholder="$t('report_editor.select_kpi')"
        :key="`kpis`"
        @selected="getKpi"
      />

      <base-button
        v-if="selectedAction"
        @click="selectAction(null)"
      >
        {{ $t('back') }}
      </base-button>
    </div>

    <div
      v-if="selectedAction === 'add' && newKpi"
      class="row"
    >
      <Dropdown
        class="lang"
        :options="langs"
        @selected="selectLang"
        :disabled="false"
        name="lang"
        :maxItem="langs.length"
        :language="true" 
        :placeholder="$t('select_lang')"
        :key="`lang`"
      />

      <label
        v-for="(key, i) in Object.keys(newKpi.translations).filter(key =>
          key === 'name'
          || key === 'description'
        )"
        :key="`kpi-${i}`"
        :for="key"
      >
        <p
          v-if="selectedLang"
          class="label-text"
        >
          {{ $t(`report_editor.${key}`) }}:
        </p>

        <base-input
          v-if="selectedLang
            && key === 'name'"
          :class="`${key}-input`"
          :name="key"
          :placeholder="selectedLang"
          type="text"
          v-model="newKpi.translations[key][selectedLang]"
        ></base-input>

        <textarea
          v-else-if="selectedLang"
          :class="`${key}-input`"
          :name="key"
          :placeholder="selectedLang"
          v-model="newKpi.translations[key][selectedLang]"
        ></textarea>
      </label>
    </div>

    <div
      v-else-if="selectedAction === 'edit'
        && selectedKpi"
      class="row"
    >
      <Dropdown
        class="lang"
        :options="langs"
        @selected="selectLang"
        :disabled="false"
        name="lang"
        :maxItem="langs.length"
        :placeholder="$t('select_lang')"
        :language="true" 
        :key="`lang`"
      />

      <label
        v-for="(key, i) in Object.keys(selectedKpi.translations).filter(key =>
          key === 'name'
          || key === 'description'
        )"
        :key="`kpi-${i}`"
        :for="key"
      >
        <p
          v-if="selectedLang"
          class="label-text"
          :class="{ name: key === 'name' }"
        >
          {{ $t(`report_editor.${key}`) }}:
        </p>

        <base-input
          v-if="selectedLang
            && key === 'name'"
          :class="`${key}-input`"
          :name="key"
          :placeholder="selectedLang"
          type="text"
          v-model="selectedKpi.translations[key][selectedLang]"
        ></base-input>

        <textarea
          v-else-if="selectedLang"
          :class="`${key}-input`"
          :name="key"
          :placeholder="selectedLang"
          v-model="selectedKpi.translations[key][selectedLang]"
        ></textarea>
      </label>
    </div>

    <hr
      v-if="selectedAction === 'add'
        || (selectedAction === 'edit'
        && selectedKpi)"
    >

    <div
      v-if="selectedAction === 'add'"
      class="row"
    >
      <label
        v-for="(key, i) in Object.keys(newKpi.translations).filter(key =>
          key.includes('eval')
        )"
        :key="`kpi-${i}`"
        :for="key"
      >
        <p
          v-if="selectedLang"
          class="label-text"
        >
          {{ $t(`report_editor.${key}`) }}:
        </p>

        <textarea
          v-if="selectedLang"
          :class="`${key}-input`"
          :name="key"
          :placeholder="selectedLang"
          v-model="newKpi.translations[key][selectedLang]"
        ></textarea>
      </label>
    </div>

    <div
      v-else-if="selectedAction === 'edit'
        && selectedKpi"
      class="row"
    >
      <label
        v-for="(key, i) in Object.keys(selectedKpi.translations).filter(key =>
          key.includes('eval')
        )"
        :key="`kpi-${i}`"
        :for="key"
      >
        <p
          v-if="selectedLang"
          class="label-text"
        >
          {{ $t(`report_editor.${key}`) }}:
        </p>

        <textarea
          v-if="selectedLang"
          :class="`${key}-input`"
          :name="key"
          :placeholder="selectedLang"
          v-model="selectedKpi.translations[key][selectedLang]"
        ></textarea>
      </label>
    </div>

    <hr
      v-if="selectedAction === 'add'
        || (selectedAction === 'edit'
        && selectedKpi)"
    >

    <div
      v-if="selectedAction === 'add'"
      class="row"
    >
      <label
        v-for="(key, i) in Object.keys(newKpi.translations).filter(key =>
          key.includes('adj')
        )"
        :key="`kpi-${i}`"
        :for="key"
      >
        <p
          v-if="selectedLang"
          class="label-text"
        >
          {{ $t(`report_editor.${key}`) }}:
        </p>

        <base-input
          v-if="selectedLang"
          :class="`${key}-input`"
          :name="key"
          :placeholder="selectedLang"
          type="text"
          v-model="newKpi.translations[key][selectedLang]"
        ></base-input>
      </label>
    </div>

    <div
      v-else-if="selectedAction === 'edit'
        && selectedKpi"
      class="row"
    >
      <label
        v-for="(key, i) in Object.keys(selectedKpi.translations).filter(key =>
          key.includes('adj')
        )"
        :key="`kpi-${i}`"
        :for="key"
      >
        <p
          v-if="selectedLang"
          class="label-text"
        >
          {{ $t(`report_editor.${key}`) }}:
        </p>

        <base-input
          v-if="selectedLang"
          :class="`${key}-input`"
          :name="key"
          :placeholder="selectedLang"
          type="text"
          v-model="selectedKpi.translations[key][selectedLang]"
        ></base-input>
      </label>
    </div>

    <hr
      v-if="selectedAction === 'add'
        || (selectedAction === 'edit'
        && selectedKpi)"
    >

    <div
      v-if="selectedAction === 'add'"
      class="row"
    >
      <label
        v-for="(key, i) in Object.keys(newKpi)"
        :key="`kpi-${i}`"
        :for="key"
      >
        <p
          v-if="key !== 'identifier'
            && key !== 'translations'"
          class="label-text"
        >
          {{ $t(`report_editor.${key}`) }}:
        </p>

        <base-checkbox
          v-if="key === 'excluded'
            || key === 'obsolete'"
          :class="`${key}-input`"
          :name="key"
          v-model="newKpi[key]"
        ></base-checkbox>

        <base-input
          v-if="key === 'order'"
          :class="`${key}-input`"
          class="smaller"
          :name="key"
          type="number"
          v-model.number="newKpi[key]"
        ></base-input>
      </label>
    </div>

    <div
      v-if="selectedAction === 'edit'
        && selectedKpi"
      class="row"
    >
      <label
        v-for="(key, i) in Object.keys(selectedKpi)"
        :key="`kpi-${i}`"
        :for="key"
      >
        <p
          v-if="key !== 'identifier'
            && key !== 'translations'
            && key !== 'id'
            && key !== 'name'"
          class="label-text"
        >
          {{ $t(`report_editor.${key}`) }}:
        </p>

        <base-checkbox
          v-if="key === 'excluded'
            || key === 'obsolete'"
          :class="`${key}-input`"
          :name="key"
          v-model="selectedKpi[key]"
        ></base-checkbox>

        <base-input
          v-if="key === 'order'"
          :class="`${key}-input`"
          class="smaller"
          :name="key"
          type="number"
          v-model.number="selectedKpi[key]"
        ></base-input>
      </label>
    </div>

    <hr
      v-if="selectedAction === 'add'
        || (selectedAction === 'edit'
        && selectedKpi)"
    >

    <div
      v-if="selectedAction === 'add'
        || (selectedAction === 'edit'
        && selectedKpi)"
      class="row"
    >
      <base-button
        v-if="selectedAction === 'add'"
        @click="addKpi()"
      >
        {{ $t('report_editor.add_kpi') }}
      </base-button>

      <base-button
        v-if="selectedAction === 'edit'"
        @click="editKpi()"
      >
        {{ $t('report_editor.edit_kpi') }}
      </base-button>
    </div>

    <hr
      v-if="selectedAction === 'add'
        || (selectedAction === 'edit'
        && selectedKpi)"
    >
  </div>
</template>

<script>
import Dropdown from "./Dropdown.vue";
import api from "../common/api.js";

export default {
  components: {
    Dropdown
  },
  props: {
    kpis: {
      required: true
    },
    allLang: {
      required: true,
    },
    langs: {
      required: true,
    },
  },
  data() {
    return {
      selectedAction: null,
      selectedKpi: null,
      selectedLang: null,
      kpiSchema: {
        identifier: '',
        order: null,
        excluded: false,
        obsolete: false,
        translations: {
          name: {},
          description: {},
          eval_low: {},
          eval_mid_low: {},
          eval_balanced: {},
          eval_mid_high: {},
          eval_high: {},
          low_adj: {},
          high_adj: {},
        },
      },
      newKpi: null,
    };
  },
  methods: {
    setDefaultLang() {
      this.$children.forEach((child, i) => {
        if ( child.$el.className === 'dropdown lang' ) {
          this.$children[i].setDefault('English');
        }
      })
      this.selectedLang = 'en';
    },
    handleErrors(err) {
      this.$emit('handleErrors', err);
    },
    selectLang(lang) {
      this.selectedLang = lang.code;
    },
    selectAction(action) {
      if ( !action ) {
        this.deleteValues();
      } else if ( action === 'add' ) {
        setTimeout(() => {
          this.setDefaultLang();
        }, 200);
      }
      this.selectedAction = action;
      this.$emit('sendAction', this.selectedAction);
    },
    deleteValues() {
      this.$children.forEach((child, i) => {
        if ( child.$el.className.includes('dropdown') ) {
          this.$children[i].emptyFilter();
        }
      })
      this.selectedLang = null;
      this.selectedKpi = null;
      this.$emit('sendKpi', this.selectedKpi);
      this.setupNewKpi();
    },
    getKpi(kpi) {
      api.getKpi(kpi.identifier)
      .then(res => {
        this.selectedKpi = res.data;
        this.$emit('sendKpi', this.selectedKpi);
        setTimeout(() => {
          this.setDefaultLang();
        }, 200);
      })
      .catch(err => {
        if ( err.response !== undefined ) {
          this.handleErrors(err.response);
        } else {
          this.handleErrors(err);
        }
      })
    },
    editKpi() {
      let selectedKpi = structuredClone(this.selectedKpi);
      let id = selectedKpi.identifier;
      delete selectedKpi.identifier;
      delete selectedKpi.id;

      api.editKpi(id, selectedKpi)
      .then(res => {
        let editedKpi = res.data;

        this.kpis.forEach((kpi, i) => {
          if ( kpi.name === editedKpi.identifier ) {
            editedKpi = {
              id: kpi.id,
              name: editedKpi.identifier,
              order: editedKpi.order,
              excluded: editedKpi.excluded,
              obsolete: editedKpi.obsolete
            }
            this.kpis[i] = editedKpi;
          }
        })
        this.deleteValues();
      })
      .catch(err => {
        if ( err.response !== undefined ) {
          this.handleErrors(err.response);
        } else {
          this.handleErrors(err);
        }
      })
    },
    addKpi() {
      api.addKpi(this.newKpi)
      .then(res => {
        let kpi = res.data;
        this.kpis.unshift({
          id: kpi.id,
          name: kpi.identifier,
          order: kpi.order,
          excluded: kpi.excluded,
          obsolete: kpi.obsolete
        });
        this.selectAction(null);
        this.$emit('refreshKpis');
      })
      .catch(err => {
        if ( err.response !== undefined ) {
          this.handleErrors(err.response);
        } else {
          this.handleErrors(err);
        }
      })
    },
    setupNewKpi() {
      this.newKpi = structuredClone(this.kpiSchema);
      Object.keys(this.newKpi.translations).forEach((key) => {
        this.newKpi.translations[key] = structuredClone(this.allLang);
      });
    }
  },
  mounted() {
    this.setupNewKpi();
  }
}
</script>

<style lang="scss" scoped>
.row {
  display: flex;
  align-items: center;

  label {
    margin: 0;
    display: flex;
    align-items: center;
    margin-top: 8px;

    &[for='eval_balanced'] {
      width: 100%;
    }

    .label-text {
      font-weight: 400;
      color: #3C4866;
      margin-right: 10px;
      margin-bottom: 0;

      &.name {
        font-weight: bold;
      }
    }

    .form-group {
      margin-top: 0;

      &.smaller {
        width: 60px;
      }
    }

    textarea {
      height: 70px;
      width: 250px;
      margin-top: 0;
      resize: both;
    }
  }

  textarea {
    padding: 10px 12px;
  }

  &:not(:first-child) {
    margin-top: 0.8rem;
  }

  .custom-control.custom-checkbox {
    margin-right: 30px;
  }

  .form-group, textarea {
    margin-top: 0.8rem;
    margin-right: 30px;
    margin-bottom: 0;

    &:first-child {
      margin-top: 0;
    }
  }

  .form-group input, textarea {
    height: 43px;
    border: 1px solid #CAD1D7;
    border-radius: 0.25rem;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.5;
    color: #3C4866;

    &::placeholder {
      color: #8898AA;
      opacity: 0.8;
    }
  }
}

.selection-panel {
  .project .project-options .row {
    &:not(:first-child) button {
      margin-top: 0.8rem;
    }

    button:last-child {
      margin-right: 0;
    }
  }

  hr {
    position: absolute;
    width: 100%;
    margin-top: 0.75rem;
    margin-left: -45px;
  }
}
</style>

<template>
  <div v-if="moduleType && editableModule" class="report-module" :key="`report-module ${render}`">
    <div class="row">
      <!-- NAME -->
      <label for="name" :style="{ flex: '100%' }">
        <base-input
          class="name-input flex-full"
          :placeholder="$t('report_editor.name')"
          name="name"
          type="text"
          v-model="editableModule.name"
        ></base-input>
      </label>

      <Dropdown
        class="lang"
        style="margin-bottom: 0"
        :options="allLangs"
        @selected="selectLang"
        :disabled="false"
        name="lang"
        :maxItem="allLangs.length"
        :placeholder="$t('select_lang')"
        key="lang"
        :language="true"
      />

      <base-input
        v-if="selectedLang"
        class="title-input"
        :placeholder="$t('report_editor.title')"
        name="title"
        type="text"
        v-model="editableModule.translations.title[selectedLang]"
      ></base-input>

    </div>

    <hr>

    <CompetencySelector
      v-if="module"
      ref="kpiSelector"
      :editableModule="editableModule"
      :kpis="kpis"
      @kpisUpdated=setKPIList
    />
    <hr>

    <div class="row">
    <!-- SAVE BUTTON -->
    <base-button @click="saveModule">
      {{ $t(`report_editor.${selectedAction}_module`) }}
    </base-button>
    </div>
  </div>
</template>

<script>

import Dropdown from '../Dropdown.vue';
import CompetencySelector from '../CompetencySelector.vue';
import '../../assets/scss/modules.scss';
import BaseButton from '../base-components/BaseButton.vue';

export default {
  components: { Dropdown, BaseButton, CompetencySelector },
  data() {
    return {
      kpiId: null,
      newKpi: null,
      render: 0,
      editableModule: null,
      selectedLang: null,
      newModule: {
        name: null,
        kpis: [
          { kpi_id: '' },
          { kpi_id: '' },
          { kpi_id: '' },
        ],
        translations: {
          title: {},
        },
      },
    };
  },
  props: {
    moduleType: {
      required: true,
    },
    module: {
      required: true,
    },
    kpis: {
      required: true,
    },
    allLangs: {
      required: true,
    },
    selectedAction: {
      required: true,
    },
  },
  methods: {
    rerender() {
      this.render++;
    },
    selectLang(lang) {
      this.selectedLang = lang.code;
    },
    saveModule() {
      this.$emit('saveModule', this.moduleType, this.editableModule);
    },
    setKPIList(kpis) {
      this.editableModule.kpis = kpis;
    },
    setDefaultLang() {
      this.$children.forEach((child, i) => {
        if (child.$el.className === 'dropdown lang') {
          this.$children[i].setDefault('English');
        }
      });
      this.selectedLang = 'en';
    },
  },
  beforeMount() {
    this.editableModule = JSON.parse(JSON.stringify(this.module));
  },
  mounted() {
    this.setDefaultLang();
  },
};
</script>
<template>
  <div class="result-table">
  <base-button @click="newCustomField()">
    {{ $t('add_custom_field') }}
  </base-button>
    <div class="results">
       <h3>{{$route.name}}</h3>
      <table v-if="customFields">
        <thead>
          <tr>
            <th>Id</th>
            <th>
              {{ $t('name') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="field in customFields" :key="field.id">
            <td>
              {{ field.id }}
            </td>
            <td>
              <router-link :to="`/custom-field/${field.id}`">
                {{ field.name}}
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
      <Message
        v-if="resultMessage !== ''"
        :classes="''"
        :message="resultMessage"
        @hideResult="resultMessage = ''">
      </Message>
    </div>
  </div>
</template>
<script>
import api from '../common/api';
import sharedMethods from '../mixins/sharedMethods';
import Message from '../components/Message.vue';

export default {
  components: {
    Message,
  },
  mixins: [sharedMethods],
  data() {
    return {
      customFields: null,
    };
  },
  methods: {
    newCustomField() {
      this.$router.push('/new-custom-field');
    },
  },
  mounted() {
    api.listCustomFields()
      .then((res) => {
        this.customFields = res.data.items;
      })
      .catch((err) => {
        if (err.response !== undefined) {
          this.handleErrors(err.response);
        } else {
          this.handleErrors(err);
        }
      });
  },
};
</script>

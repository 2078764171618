<template>
  <div class="result-table">
  <base-button @click="newAggregator()">
    {{ $t('add_aggregator') }}
  </base-button>
    <div class="results">
      <h3 ref="scrollElement">{{$route.name}}</h3>

      <!-- table -->
      <AggregatorList
        v-if="aggregators"
        :aggregators="aggregators"
        :clients="clients"
        :headers="$t('result_table').aggregators"
        @setFilter="filterData"
      ></AggregatorList>

      <!-- pagination -->
      <div class="pagination-wrapper">
        <ul class="pagination justify-content-center">
          <li
            v-for="p in pages"
            :key="p"
            :class="`page-item ${p === pagination.page ? 'active' : ''}`"
          >
            <a class="page-link" @click="setPage(p)">{{ p }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import AggregatorList from '../components/AggregatorList.vue';
import api from '../common/api';

export default {
  data() {
    return {
      aggregatorDataSize: 0,
      aggregators: [],
      clientDataSize: 0,
      clients: [],
      pagination: {
        page: 1,
        total: 0,
        size: 20,
      },
      searchParams: null,
      resultMessage: '',
      resultVisibility: false,
    };
  },
  components: {
    AggregatorList,
  },
  computed: {
    pages() {
      return Math.ceil(this.pagination.total / this.pagination.size);
    },
  },
  methods: {
    newAggregator() {
      this.$router.push('/new-aggregator');
    },
    setPage(page) {
      if (this.pagination.page !== page) {
        this.pagination.page = page;
        this.getAggregatorList(page);
      }
    },
    filterData(params) {
      this.searchParams = params;
      this.getAggregatorList(1);
    },
    getAggregatorList(page) {
      this.aggregators = [];
      api
        .listAggregators(page, this.pagination.size, this.searchParams)
        .then((res) => {
          res.data.items.forEach((item) => {
            this.aggregators.unshift({
              id: item.id,
              name: `${item.id} - ${item.name}`,
              members: item.members.filter((member) => member.entity_type === 'project'),
            });
          });
          this.pagination.total = res.data.total;
          this.pagination.page = page;
        })
        .catch((err) => {
          if (err.response !== undefined) {
            this.handleErrors(err.response);
          } else {
            this.handleErrors(err);
          }
        }).finally(() => {
          const scrollElement = this.$refs.scrollElement;
          scrollElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        });
    },
    getClientList(page) {
      api
        .listClients(page, 1000)
        .then((res) => {
          this.clientDataSize = res.data.total / 100;
          res.data.items.forEach((item) => {
            this.clients.unshift(item);
          });
          if (page < this.clientDataSize) {
            page++;
            this.getClientList(page);
          }
        })
        .catch((err) => {
          if (err.response !== undefined) {
            this.handleErrors(err.response);
          } else {
            this.handleErrors(err);
          }
        });
    },
    handleErrors(error) {
      console.log(error);
      if (!error.status && error.message) {
        this.resultMessage = error.message;
      } else {
        switch (error.status) {
          case 400: {
            this.resultMessage = error.data.detail;
            break;
          }
          case 401: {
            this.resultMessage = error.data.detail;
            break;
          }
          case 403: {
            this.resultMessage = error.data.detail;
            break;
          }
          case 404: {
            this.resultMessage = `API ${error.data.detail}` || `URL ${error.statusText}`;
            break;
          }
          case 409: {
            this.resultMessage = error.data.detail;
            break;
          }
          case 422: {
            this.resultMessage = `${error.data.detail[0].loc[1] || error.data.detail[0].loc[0]} ${
              error.data.detail[0].msg
            }`;
            break;
          }
          case 502: {
            this.resultMessage = 'API not available';
            break;
          }
          default: {
            this.resultMessage = this.$t('result_message.default_error');
            break;
          }
        }
      }
      this.showResult();
    },
    showResult() {
      this.resultVisibility = true;
      this.$store.commit('admin/setResultVisibility', this.resultVisibility);
    },
  },
  mounted() {
    if (this.aggregatorDataSize === 0) {
      this.getAggregatorList(1);
    }
    if (this.clientDataSize === 0) {
      this.getClientList(1);
    }
  },
};
</script>

<style lang="scss">
.results {
  padding: 0px 3px;
  text-align: center;
  font-weight: 400;
  color: #3c4866;

  .pagination-wrapper {
    margin-top: 2.5rem;
    display: flex;
    justify-content: center;
    .pagination {
      width: 80vw;
      flex-wrap: wrap;
    }
  }
  .text-editor {
    textarea {
      display: none;
    }

    div[id^='cke_editor'] {
      margin-left: auto;
      margin-right: auto;
      width: 50%;
    }
  }

  .select-box {
    height: 20px;
    width: 20px;
    margin-bottom: 0;
    margin-right: 10px;
  }

  table:not(.inner-table) {
    display: inline-block;

    &.full-size {
      margin-top: 10px;

      &.hide {
        display: none;
      }
    }

    th {
      position: -webkit-sticky;
      position: sticky;
      top: -1px;
      background: #f8f7fa;
    }
  }

  input[type='checkbox'] {
    display: block;
  }

  table,
  th,
  td {
    border-collapse: collapse;
    border-spacing: 0;
  }

  th {
    padding: 10px 20px;
    border-bottom: 1px solid lightgray;
  }

  td {
    padding: 8px 4px;
    line-height: 2;
    border-bottom: 1px solid lightgray;
  }

  .inner-table {
    width: 100%;

    td:last-child {
      border: none;
    }
  }

  table#export td {
    white-space: pre-line;
  }

  table {
    max-width: 100%;
    button,
    button:hover {
      color: #a245ff;
      border: 1px solid #a245ff;
      border-radius: 40px;
      margin-right: 30px;
      max-height: 43px;
    }
  }
}
</style>
<template>
    <div class="pmcc-style">
        <div>
            <h3 class="pmcctitle">{{ $t('profile-match-calculator.title') }}</h3>
            <h4 class="pmcctitle">{{ `${$route.params.pmcalculator}` }}</h4>
        </div>
        <div v-if="pmcconfigverions" class="pmccversion-container">
            <div v-for="(version, versionsIndex) in pmcconfigverions" :key="versionsIndex">
                <div class="pmccversion" :class="{ 'active': version.isActive }">
                    <button @click="setActiveVersion(version)">
                        {{ `${version.VersionId}` }} ({{ formatDate(version.LastModified) }})
                    </button>
                </div>
            </div>
            <div class="pmccversion file_button">
                <button @click="toggleUploadMenu()">
                    {{ $t('profile-match-calculator.file_upload') }}
                </button>
            </div>
        </div>
        <div v-if="pmcconfigversiondata">
            <div class="pmConfigVersionData">
                <label for="ETag">
                    <span class="label-text">{{ $t('profile-match-calculator.etag') }}:</span>
                    <span class="result">{{ `${pmcconfigversiondata.ETag}` }}</span>
                </label>
                <label for="DisplayName">
                    <span class="label-text">{{ $t('profile-match-calculator.displayname') }}:</span>
                    <span class="result">{{ `${pmcconfigversiondata.Owner.DisplayName}` }}</span>
                </label>
                <label for="ID">
                    <span class="label-text">{{ $t('id') }}:</span>
                    <span class="result">{{ `${pmcconfigversiondata.Owner.ID}` }}</span>
                </label>
                <label for="IsLatest">
                    <span class="label-text">{{ $t('profile-match-calculator.islatest') }}:</span>
                    <span class="result">{{ `${pmcconfigversiondata.IsLatest}` }}</span>
                </label>
                <label for="Key">
                    <span class="label-text">{{ $t('profile-match-calculator.key') }}:</span>
                    <span class="result">{{ `${pmcconfigversiondata.Key}` }}</span>
                </label>
                <label for="LastModified">
                    <span class="label-text">{{ $t('profile-match-calculator.lastmodified') }}:</span>
                    <span class="result">{{ `${pmcconfigversiondata.LastModified}` | moment }}</span>
                </label>
                <label for="Size">
                    <span class="label-text">{{ $t('profile-match-calculator.size') }}:</span>
                    <span class="result">{{ `${pmcconfigversiondata.Size}` }}</span>
                </label>
                <label for="StorageClass">
                    <span class="label-text">{{ $t('profile-match-calculator.storageclass') }}:</span>
                    <span class="result">{{ `${pmcconfigversiondata.StorageClass}` }}</span>
                </label>
                <label for="versionid">
                    <span class="label-text">{{ $t('profile-match-calculator.versionid') }}:</span>
                    <span class="result">{{ `${pmcconfigversiondata.VersionId}` }}</span>
                </label>
                <label for="Json">
                    <base-button class="button-delete" v-if="!pmcconfigJsonBoolean && jsonBoolean"
                        @click="toggleJsonData">
                        {{ $t('profile-match-calculator.json') }}
                    </base-button>
                    <base-button class="button-delete" v-if="pmcconfigJsonBoolean && jsonBoolean"
                        @click="toggleJsonData">
                        {{ $t('profile-match-calculator.jsonclose') }}
                    </base-button>
                    <base-button v-if="!downloadLoading" class="button-delete" @click="DownloadPMCalcConfigConfirm">
                        {{ $t('profile-match-calculator.download') }}
                    </base-button>
                    <LoadingRing v-if="downloadLoading" class="button-delete loading-download"></LoadingRing>
                    <base-button class="button-delete" @click="DeletePMCalcConfigConfirm">
                        {{ $t('profile-calculator.delete') }}
                    </base-button>
                    <div class="popup" v-if="showConfirmation">
                        <div class="popup-content">
                            <h2>
                                {{ $t('profile-match-calculator.warning') }}
                            </h2>
                            <p>{{ $t('profile-match-calculator.confirm_message') }}:</p>
                            <p class="confirmParam no-margin">
                                {{ $t('profile-match-calculator.config_name') }} -
                                {{ `${pmcconfigversiondata.Key}` }}
                            </p>
                            <p class="confirmParam more-margin">
                                {{ $t('profile-match-calculator.versionid') }} -
                                {{ `${pmcconfigversiondata.VersionId}` }}
                            </p>

                            <base-button v-if="!deleteLoading && !deleteSuccesful" class="confirm button-delete"
                                type="primary" @click="DeletePMCalcConfig">
                                {{ $t('profile-match-calculator.yes') }}
                            </base-button>
                            <base-button v-if="!deleteLoading && !deleteSuccesful" @click="DeletePMCalcConfigCancel">
                                {{ $t('profile-match-calculator.no') }}
                            </base-button>
                            <p v-if="deleteLoading" class="pagination-wrapper">
                                {{ $t('profile-match-calculator.delete-loading') }}
                            </p>
                            <LoadingRing v-if="deleteLoading" class="pagination-wrapper"></LoadingRing>
                            <p v-if="deleteSuccesful" class="pagination-wrapper">
                                {{ $t('profile-match-calculator.delete-success') }}
                            </p>
                        </div>
                    </div>
                </label>
            </div>
            <div class="pmConfigDataContainer" v-if="pmcconfigJson && pmcconfigJsonBoolean">
                <div class="pmConfigDataBox">
                    <pre>{{ `${pmcconfigJson}` }}</pre>
                </div>
            </div>
        </div>
        <div v-if="pmcconfigUpload" class="pmConfigUpload">
            <div class="pmConfigVersionData upload_label">
                <label for="ETag">
                    <span class="label-text">{{ $t('profile-match-calculator.file_upload') }}:</span>
                    <span v-if="selectedFileName" class="upload_file_name">{{ selectedFileName }}</span>
                    <input type="file" ref="fileInput" @change="openFileInput" accept=".json" style="display: none;">
                    <base-button @click="$refs.fileInput.click()">
                        {{ $t('profile-match-calculator.file') }}
                    </base-button>
                </label>
                <LoadingRing v-if="uploadLoading" class="button-delete loading-download"></LoadingRing>
                <p v-if="uploadSuccess" class="button-delete loading-download pagination-wrapper">
                    {{ $t('profile-match-calculator.upload_success') }}
                </p>
                <label v-if="!uploadLoading && !uploadSuccess" for="upload">
                    <base-button @click="uploadFile" :disabled="!selectedFile">
                        {{ $t('profile-match-calculator.upload_file') }}
                    </base-button>
                </label>
            </div>
        </div>
        <LoadingRing v-if="loading" class="pagination-wrapper"></LoadingRing>
        <Message v-if="resultMessage !== ''" :classes="''" :message="resultMessage" @hideResult="resultMessage = ''">
        </Message>
    </div>
</template>
<script>
import Filters from '../mixins/filters';
import api from '../common/api';
import sharedMethods from '../mixins/sharedMethods';
import Message from '../components/Message.vue';
import LoadingRing from '../components/base-components/LoadingRing';

export default {
    components: {
        Message,
        LoadingRing,
    },
    mixins: [sharedMethods, Filters],
    data() {
        return {
            activeVersion: null,
            loading: false,
            pmcconfigJson: null,
            pmcconfigJsonBoolean: false,
            pmcconfigversiondata: null,
            pmcconfigverions: null,
            pmcconfigUpload: false,
            selectedFile: null,
            selectedFileName: '',
            showConfirmation: false,
            deleteLoading: false,
            deleteSuccesful: false,
            jsonBoolean: false,
            downloadLoading: false,
            uploadSuccess: false,
            uploadLoading: false,
        };
    },
    computed: {
        activeVersionClass() {
            return {
                'active': this.activeVersion !== null
            };
        }
    },
    methods: {
        toggleJsonData() {
            this.pmcconfigJsonBoolean = !this.pmcconfigJsonBoolean;
            if (this.pmcconfigJsonBoolean) {
                this.getPMCalcConfig(this.$route.params.pmcalculator, this.pmcconfigversiondata);
            }
        },
        toggleUploadMenu() {
            this.pmcconfigUpload = !this.pmcconfigUpload;
            this.pmcconfigversiondata = false;
        },
        setActiveVersion(version) {
            this.pmcconfigverions.forEach(version => {
                version.isActive = false; // Minden menüpont inaktív lesz
            });
            version.isActive = true;
            this.activeVersion = version;
            this.pmcconfigUpload = false;
            this.pmcconfigJsonBoolean = false;
            this.pmcconfigversiondata = version;
        },
        formatDate(dateString) {
            const date = new Date(dateString);
            const formattedDate = date.toISOString().split('T')[0];
            return formattedDate;
        },
        openFileInput(event) {
            const file = event.target.files[0];
            if (file) {
                this.selectedFileName = file.name;
                this.selectedFile = file;
            }
        },
        uploadFile() {
            if (!this.selectedFile) {
                return;
            }
            this.uploadLoading = true;
            const formData = new FormData();
            formData.append("file", this.selectedFile);

            var routeParam = this.$route.params.pmcalculator;
            var remainingPath = routeParam.substring(0, routeParam.lastIndexOf('/'));

            api
                .postPMCalcConfigUpload(remainingPath, formData)
                .then((res) => {

                })
                .catch((err) => {
                    if (err.response !== undefined) {
                        this.handleErrors(err.response);
                    } else {
                        this.handleErrors(err);
                    }
                }).finally(() => {
                    this.uploadSuccess = true;
                    this.uploadLoading = false;
                    setTimeout(() => this.$router.push(`/pm-calculator-list`), 2500);
                });
        },
        DeletePMCalcConfigConfirm() {
            this.showConfirmation = true;
        },
        DeletePMCalcConfigCancel() {
            this.showConfirmation = false;
        },
        DeletePMCalcConfig() {
            this.deleteLoading = true;
            api
                .getPMCalcConfigDelete(this.pmcconfigversiondata.Key, this.pmcconfigversiondata.VersionId)
                .then((res) => { })
                .catch((err) => {
                    if (err.response !== undefined) {
                        this.handleErrors(err.response);
                    } else {
                        this.handleErrors(err);
                    }
                })
                .finally(() => {
                    this.deleteLoading = false;
                    this.deleteSuccesful = true;
                    setTimeout(() => this.$router.push(`/pm-calculator-list`), 2500);
                });
        },
        DownloadPMCalcConfigConfirm() {
            this.downloadLoading = true;
            api
                .getPMCalcConfigDownload(this.$route.params.pmcalculator, this.pmcconfigversiondata.VersionId)
                .then((res) => {
                    const jsonData = JSON.stringify(res.data);
                    const blob = new Blob([jsonData]);
                    const url = window.URL.createObjectURL(blob);

                    const fileNameParts = this.$route.params.pmcalculator.split('.');
                    const fileExtension = fileNameParts[fileNameParts.length - 1];

                    let fileType;
                    switch (fileExtension) {
                        case 'json':
                            fileType = 'application/json';
                            break;
                        case 'pickle':
                            fileType = 'application/octet-stream';
                            break;
                        default:
                            fileType = 'application/octet-stream';
                            break;
                    }

                    const link = document.createElement('a');
                    link.href = url;
                    link.download = this.$route.params.pmcalculator.split('/').pop();
                    link.type = fileType;
                    link.style.display = 'none';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(url);
                })
                .catch((err) => {
                    if (err.response !== undefined) {
                        this.handleErrors(err.response);
                    } else {
                        this.handleErrors(err);
                    }
                })
                .finally(() => {
                    this.downloadLoading = false;
                    this.downloadSuccesful = true;
                });
        },
        getPMCalcConfigVersions(filename) {
            this.loading = true;
            api
                .getPMCalcConfigVersions(filename)
                .then((res) => {
                    this.pmcconfigverions = res.data;
                    if (filename.includes('json')) {
                        this.jsonBoolean = true;
                    }
                })
                .catch((err) => {
                    if (err.response !== undefined) {
                        this.handleErrors(err.response);
                    } else {
                        this.handleErrors(err);
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        getPMCalcConfig(filename, version) {
            this.loading = true;
            this.pmcconfigJson = null;
            api
                .getPMCalcConfig(filename, version.VersionId)
                .then((res) => {
                    this.pmcconfigJson = JSON.stringify(res.data, null, 4);
                    this.pmcconfigJson = JSON.parse(this.pmcconfigJson);

                })
                .catch((err) => {
                    if (err.response !== undefined) {
                        this.handleErrors(err.response);
                    } else {
                        this.handleErrors(err);
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
    mounted() {
        this.getPMCalcConfigVersions(this.$route.params.pmcalculator);
    },
};
</script>
<style lang="scss">
.pmcc-style {
    .pmcctitle {
        font-weight: 500;
        text-align: center;
        margin: 0 !important;
    }

    .file_button {
        background-color: #ffffff !important;
        color: rgb(0, 0, 0) !important;
    }

    .pmccversion-container {
        display: flex;
        flex-wrap: wrap;
        margin: 1.5rem 0.5rem;

        .active {
            background-color: #7231b4 !important;
        }

        .pmccversion {
            padding: 0.6rem;
            border-radius: 5px;
            background-color: #a245ff;
            color: white;
            transition: box-shadow 0.3s;

            &:hover {
                background-color: #7231b4;
                box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
            }

            &:active {
                background-color: #421b69;
                box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
            }
        }
    }

    .pmConfigVersionData {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        margin: 1rem 0;
        padding-top: 1rem;
        border-radius: 5px;
        background-color: white;
        box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);

        label {
            margin-bottom: 0.7rem;
            margin-left: 1rem;

            span {
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.7;
                color: #3c4866;
                margin-bottom: 5px;
            }

            .label-text {
                margin: 0 10px;
                font-weight: 500;
            }

            .label-text-json {
                cursor: pointer;
                font-weight: 600;

                &:hover {
                    padding: 2px;
                    text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
                }
            }
        }
    }

    .button-delete {
        margin-right: 1rem !important;
    }

    .pmConfigDataContainer {
        display: flex;
        justify-content: center;
        height: auto;

        .pmConfigDataBox {
            width: 50rem;
            height: 30rem;
            overflow: auto;
            border: 1px solid #ccc;
            padding: 10px;
            background-color: #f9f9f9;
        }
    }

    .confirmParam {
        font-weight: 500;
    }

    .no-margin {
        margin: 0 !important;
    }

    .more-margin {
        margin-bottom: 1.4rem !important;
    }

    button.confirm {
        color: white !important;
        background: linear-gradient(81.57deg, #0d86fe 12.9%, #b504ff 87.1%);
    }

    .popup {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .popup-content {
            background-color: white;
            padding: 20px;
            color: #a245ff;
            border-radius: 40px;
            border-width: 3px;
            border-style: solid;
            border-color: #a245ff;
            box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);

            p {
                margin-bottom: 10px;
            }
        }
    }

    .loading-download {
        display: inline-block;
        vertical-align: middle;
        border: 1px solid transparent;
        padding: 0 1.25rem;
    }

    .upload_label {
        padding: 1rem;

        .upload_file_name {
            margin-right: 1rem;
        }
    }
}
</style>
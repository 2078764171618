<template>
  <div class="result-table rapper">
    <div class="report-module row">
      <base-button @click="newReportModule()">
        {{ $t('report_editor.add_module') }}
      </base-button>
    </div>
    <div class="results">
       <h3>{{$route.name}}</h3>
    <div class="report-module row">
      <!-- Module type selector -->
      <label for="type" :style="{ flex: '35%' }">
        <p class="label-text">
        {{ $t('report_editor.list_modules') }}:
        </p>
      <Dropdown
        v-if="!$route.params.type"
        class="add"
        :options="moduleTypes.map((module, i) =>({id: i, name: module}))"
        @selected="setModuleType"
        :disabled="false"
        name="modules"
        :maxItem="moduleTypes.length"
        :placeholder="$t('report_editor.select_module_type')"
        :key="`module`"
      />
      </label>
    </div>
        <!-- pagination -->
      <div v-if="pages > 1" class="pagination-wrapper">
        <ul class="pagination justify-content-center">
          <li
            v-for="p in pages"
            :key="p"
            :class="`page-item ${p === pagination.page ? 'active' : ''}`"
          >
            <a class="page-link" @click="setPage(p)">{{ p }}</a>
          </li>
        </ul>
      </div>
      <table v-if="reportModules.length > 0">
        <thead>
          <tr>
             <th>{{ $t('result_table.modules.module_type') }}</th>
             <th>{{ $t('result_table.modules.module_id') }}</th>
            <th>{{ $t('result_table.modules.module_name') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(module) in reportModules" :key="`${module.type}_${module.id}`">
            <td>
              {{ module.type }}
            </td>
            <td>
              {{ module.id }}
            </td>
            <td>
              <router-link :to="`/module/${module.type}/${module.id}`">
                {{ module.name !== '' ? module.name : '-'}}
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
      <Message
        v-if="resultMessage !== ''"
        :classes="''"
        :message="resultMessage"
        @hideResult="resultMessage = ''">
      </Message>
    </div>
  </div>
</template>
<script>
import api from '../common/api';
import Dropdown from '../components/Dropdown.vue';
import sharedMethods from '../mixins/sharedMethods';
import Message from '../components/Message.vue';

export default {
  components: {
    Message,
    Dropdown,
  },
  mixins: [sharedMethods],
  data() {
    return {
      reportModules: [],
      selectedModuleType: null,
      pagination: {
        page: 1,
        total: 0,
        size: 100,
      },
      moduleTypesListed: 0,
      setPage(page) {
        if (this.pagination.page !== page) {
          this.pagination.page = page;
          this.getModulesList(page, this.selectedModuleType);
        }
      },
    };
  },
  computed: {
    pages() {
      return Math.ceil(this.pagination.total / this.pagination.size);
    },
    moduleTypes() {
      return this.$store.state.admin.moduleTypes;
    },
  },
  methods: {
    newReportModule() {
      this.$router.push('/new-module');
    },
    setModuleType(module) {
      this.selectedModuleType = module.name;
    },
    getReportModuleList(page, moduleType) {
      api.listReportModules(page, moduleType, this.pagination.size)
        .then((res) => {
          this.reportModuleDataSize = res.data.total / this.pagination.size;
          res.data.items.forEach((item) => {
            this.reportModules.push({
              type: moduleType,
              id: item.id,
              name: item.name,
            });
          });
          if (page < this.reportModuleDataSize) {
            page++;
            this.getReportModuleList(page, moduleType);
          } else if (this.moduleTypesListed === this.moduleTypes.length) {
            this.showReportModuleList = true;
          } else {
            this.moduleTypesListed++;
          }
        })
        .catch((err) => {
          if (err.response !== undefined) {
            this.handleErrors(err.response);
          } else {
            this.handleErrors(err);
          }
        });
    },
  },
  watch: {
    selectedModuleType(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.reportModules = [];
        this.getReportModuleList(1, newValue);
      }
    },
  },
};
</script>

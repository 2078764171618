<template>
  <div v-if="user" class="wrapper top_margin">
    <div class="row">
      <label for="id">
        <p class="label-text">ID:</p>
        <p class="result">{{ `${user.id}` }}</p>
      </label>
      <label for="asid">
        <p class="label-text">ASID:</p>
        <p v-if="user.account_service_id" class="result">{{ `${user.account_service_id}` }}</p>
        <p v-else class="result">-</p>
      </label>
      <label for="email">
        <p class="label-text">{{ $t('email') }}:</p>
        <base-input class="name-input user_email" :placeholder="$t('email')" :name="'email'" type="text"
          v-model="user.email"></base-input>
      </label>
      <label for="is_active">
        <p class="label-text">{{ $t('user.is_active') }}:</p>
        <Checkbox class="disabled_check" :selectedCheck="!user.disabled" checkboxId="allow_csv" @update:selectedCheck="user.disabled = !$event">
        </Checkbox>
      </label>
      <label class="responsive" for="disabled">
        <p class="label-text">{{ $t('user.disabled') }}:</p>
        <p v-if="user.account_service_id && userAdmin" class="result">{{ `${ASuser.locked}` }}</p>
        <base-button class="disabled_button" v-if="user.account_service_id && userAdmin" @click="userUnlock">
          {{ $t('user.unlock') }}
        </base-button>
        <p v-if="user.account_service_id && !userAdmin" class="result">-</p>
      </label>
      <label for="clients">
        <p class="label-text">{{ $t('user.clients') }}:</p>
        <div class="user_clients " v-for="(client, index) in clients" :key="'client-' + index">
          <router-link class="result client_name" :to="`/client/${client.id}`">
            {{ client.name }}
          </router-link>
          <img class="client_cross" src="img/user/cancel.png" v-on:click="deleteClient(client.id)" />
        </div>
        <Dropdown :options="listClient" :maxItem="listClient.length" :placeholder="$t('select_client')"
          @selected="(selectedClient) => { newClients = selectedClient }" />
        <img class="client_add" src="img/user/plus.png" v-on:click="addNewClient" />
      </label>
    </div>
    <hr />
    <h3>
      {{ $t('user.settings') }}
    </h3>
    <div class="row">
      <label for="language">
        <p class="label-text">{{ $t('user.language') }}:</p>
        <Dropdown :options="dashboardLanguages" @selected="selectLanguage" :disabled="false" name="dashboardlangs"
          :defaultValue="getUserLanguage()" :maxItem="dashboardLanguages.length" :placeholder="$t('select_lang')"
          :language="true" />
      </label>
      <label class="responsive" for="is_administrator">
        <p class="label-text">{{ $t('user.is_administrator') }}:</p>
        <p class="result" v-if="user.account_service_id && userAdmin">{{ `${ASuser.admin}` }}</p>
        <Checkbox v-if="!user.account_service_id" :selectedCheck="user.is_administrator" checkboxId="is_administrator"
          @update:selectedCheck="user.is_administrator = $event"></Checkbox>
        <p class="result" v-if="user.account_service_id && !userAdmin">-</p>
      </label>
    </div>
    <hr />
    <h3>
      {{ $t('user.security') }}
    </h3>
    <div class="row">
      <label for="last_seen">
        <p class="label-text">{{ $t('user.has_password_set') }}:</p>
        <p class="result" v-if="user.account_service_id  && userAdmin">{{ `${ASuser.has_password_set}` }}</p>
        <p class="result" v-if="user.account_service_id  && !userAdmin">-</p>
        <p class="result" v-if="!user.account_service_id">{{ `${user.has_password_set}` }}</p>
      </label>
      <label for="last_seen">
        <p class="label-text">{{ $t('user.must_change_password') }}:</p>
        <p class="result" v-if="user.account_service_id  && userAdmin">{{ `${ASuser.password_change_required}` }}</p>
        <p class="result" v-if="user.account_service_id  && !userAdmin">-</p>
        <p class="result" v-if="!user.account_service_id">{{ `${user.must_change_password}` }}</p>
      </label>
      <label for="last_password_change">
        <p class="label-text">{{ $t('user.last_password_change') }}:</p>
        <p class="result" v-if="user.account_service_id  && userAdmin">{{ `${ASuser.last_password_change}` | moment }}</p>
        <p class="result" v-if="user.account_service_id  && !userAdmin">-</p>
        <p class="result" v-if="!user.account_service_id">{{ `${user.last_password_change}` | moment }}</p>
      </label>
      <label for="as_url" v-if="user.account_service_id  && userAdmin">
        <base-button v-tooltip:click="getTooltipContent()" :disabled="!asUserURL" @click="copyCode(asUserURL)">
          {{ $t('user.set_password') }}</base-button>
      </label>
    </div>
    <hr />
    <h3>
      {{ $t('user.date') }}
    </h3>
    <div class="row">
      <label for="created_at">
        <p class="label-text">{{ $t('user.created_at') }}:</p>
        <p class="result">{{ `${user.created_at}` | moment }}</p>
      </label>
      <label for="last_login_at">
        <p class="label-text">{{ $t('user.last_login_at') }}:</p>
        <p class="result">{{ `${user.last_login_at}` | moment }}</p>
      </label>
    </div>
    <div class="row">
      <base-button @click="editUser">
        {{ $t('edit') }}
      </base-button>
      <base-button @click="goBack">
        {{ $t('back') }}
      </base-button>
      <base-button @click="auditlogOpen">
        {{ $t('audit-log.open') }}
      </base-button>
    </div>
    <hr v-if="resultMessage !== ''" />
    <Message v-if="resultMessage !== ''" :classes="''" :message="resultMessage"
      @hideResult="resultMessage = ''; noneClient = false">
    </Message>
    <div class="nullClient_style" v-if="resultMessage !== '' && noneClient && !showConfirmation">
      <base-button @click="continueEditUser">
        {{ $t('yes') }}
      </base-button>
      <base-button class="button_no" @click="cancelEditUser">
        {{ $t('no') }}
      </base-button>
    </div>
    <hr />
    <h3>
      {{ $t('user.notifications') }}
    </h3>
    <div class="row">
      <label for="notifictations">
        <p class="label-text">{{ $t('user.email_notifications') }}:</p>
        <Dropdown :options="assessments" :maxItem="assessments.length" :placeholder="$t('select_assessment')"
          @selected="(selected) => { selectedAssessment = selected }" />
        <img alt="add" class="client_add" src="img/user/plus.png" @click="addNewNotification" />
      </label>
    </div>
    <div class="row" ref="scrollElement">
      <div class="user_clients " v-for="(notification, index) in emailNotifications" :key="'notification-' + index">
        <div class="result client_name">
          {{ `${notification.entity.id}-${notification.entity.name}` }}
        </div>
        <img class="client_cross" src="img/user/cancel.png" alt="cancel" @click="deleteNotification(notification.id)" />
      </div>
    </div>
    <hr />
  </div>
</template>
<script>
import Filters from '../mixins/filters';
import sharedMethods from '../mixins/sharedMethods';
import api from '../common/api';
import Dropdown from '../components/Dropdown.vue';
import Message from '../components/Message.vue';
import Checkbox from '../components/Checkbox.vue';

export default {
  components: {
    Message,
    Dropdown,
    Checkbox,
  },
  data() {
    return {
      user: null,
      userAdmin: true,
      ASuser: [],
      asUserURL: null,
      listClient: [],
      clients: [],
      noneClient: false,
      showConfirmation: false,
      newClients: [],
      selectedLang: null,
      emailNotifications: [],
      assessments: [],
      selectedAssessment: null,
    };
  },
  mixins: [sharedMethods, Filters],
  computed: {
    dashboardLanguages() {
      const langs = ['en', 'hu', 'de', 'fr'];
      return langs.map((lang) => (
        {
          name: this.$t(`lang.${lang}`, { langCode: lang }),
          code: lang,
        }));
    },
  },
  methods: {
    userUnlock() {
        api.postASUserUnlock(this.user.account_service_id)
          .then((res) => {
            this.setMsg(this.$t('user.unlock_message'));
            const scrollElement = this.$refs.scrollElement;
            scrollElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
          })
          .catch((err) => {
            if (err.response !== undefined) {
              this.handleErrors(err.response.data);
            } else {
              this.handleErrors(err);
            }
          });
    },
    getTooltipContent() {
      if (this.asUserURL) {
        return this.$t('copy_link');
      } else {
        return this.$t('user.disabled_tooltip_text');
      }
    },
    goBack() {
      this.$router.push('/users');
    },
    auditlogOpen() {
      this.$router.push(`/user/${this.$route.params.userId}/audit-log`);
    },
    selectLanguage(lang) {
      this.selectedLang = lang.code;
    },
    addNewClient() {
      const valid = this.clients.some((client) => client.id === this.newClients.id);
      if (!valid && this.newClients.id) {
        this.clients.push(this.newClients);
      }
    },
    deleteClient(clientId) {
      this.clients = this.clients.filter((client) => client.id !== clientId);
    },
    copyCode(code) {
      navigator.clipboard.writeText(code);
    },
    getUserLanguage() {
      if (this.user.settings && this.user.settings.language) {
        const userLanguage = this.user.settings.language;
        const languageObject = this.dashboardLanguages.find(
          (lang) => lang.code === userLanguage,
        );
        return languageObject.name;
      }
      return null;
    },
    getASUserInvitationLink() {
      if (this.user.account_service_id) {
        api.getASUserInvitationLink(this.user.account_service_id)
          .then((res) => {
            this.asUserURL = res.data.url;
            this.getASUser();
          })
          .catch((err) => {
            if (err.response !== undefined) {
              this.handleErrors(err.response.data);
            } else {
              this.handleErrors(err);
            }
            if (err.details = "Administrative access required") {
              this.userAdmin = false;
              this.resultMessage = this.$t('result_message.none_admin');
            }
          });
      }
    },
    getUser(userId) {
      return new Promise((resolve, reject) => {
        api.getUser(userId)
          .then((res) => {
            this.user = res.data;
            this.clients = res.data.clients;
            resolve();
          })
          .catch((err) => {
            if (err.response !== undefined) {
              this.handleErrors(err.response);
            } else {
              this.handleErrors(err);
            }
            reject(err);
          });
      });
    },
    getASUser() {
      if (this.user.account_service_id && this.userAdmin) {
        api.getASUser(this.user.account_service_id)
          .then((res) => {
            this.ASuser = res.data;
            if (this.ASuser) {
              if (this.ASuser.last_password_change) {
                this.ASuser.has_password_set = true;
              } else {
                this.ASuser.has_password_set = false;
              }
            }
          })
          .catch((err) => {
            if (err.response !== undefined) {
              this.handleErrors(err.response);
            } else {
              this.handleErrors(err);
            }
          });
      };
    },
    nullClient() {
      this.resultMessage = this.$t('user.null_client');
      this.noneClient = true;
    },
    continueEditUser() {
      this.showConfirmation = true;
      this.user.disabled = true;
      this.editUser();
    },
    cancelEditUser() {
      this.resultMessage = '';
    },
    counterPush(editorsave) {
      const timeoutSeconds = 4;
      let countDown = timeoutSeconds;

      const message = this.$t(editorsave);
      this.setMsg(message);

      const intervalId = setInterval(() => {
        countDown--;
        if (countDown > 0) {
          this.setMsg(`${message} ... ${countDown}`);
        } else {
          clearInterval(intervalId);
          this.$router.push('/users');
        }
      }, 1000);
    },
    editUser() {
      const userData = {
        email: this.user.email,
        client_ids: this.clients.map((client) => client.id),
        disabled: this.user.disabled,
        is_administrator: this.user.is_administrator,
        settings: {
          language: this.selectedLang ? this.selectedLang : this.user.settings.language,
        },
      };
      if (userData.client_ids.length === 0 && !userData.disabled && !this.user.is_active) {
        this.user.disabled = true;
        this.resultMessage = this.$t('result_message.user_disabled');
      } else if (userData.client_ids.length === 0 && this.user.is_active && !this.showConfirmation) {
        this.nullClient();
      } else if (userData.is_administrator && !this.user.is_active && userData.disabled) {
        this.user.is_administrator = false;
        this.resultMessage = this.$t('result_message.user_administrator');
      } else if (userData.is_administrator && this.user.is_active && userData.disabled) {
        this.user.is_administrator = false;
        this.resultMessage = this.$t('result_message.none_administrator');
      } else {
        this.resultMessage = '';
        api.patchUser(this.$route.params.userId, userData)
          .then((res) => {
            this.counterPush('result_message.user_edited');
          })
          .catch((err) => {
            if (err.response !== undefined) {
              this.handleErrors(err.response);
            } else {
              this.handleErrors(err);
            }
          });
      }
    },
    getUserNotifications() {
      api.getUserNotifications(this.user.id)
        .then((notfictionsResponse) => {
          this.emailNotifications = notfictionsResponse.data.items.filter((n) => n.notification_type === 'mail');
        })
        .catch((err) => {
          if (err.response !== undefined) {
            this.handleErrors(err.response);
          } else {
            this.handleErrors(err);
          }
        });
    },
    addNewNotification() {
      if (this.selectedAssessment) {
        api.createUserNotification(this.user.id, this.selectedAssessment.id, 'mail')
          .then(() => {
            this.getUserNotifications();
            this.setMsg('Notification created');
          })
          .catch((err) => {
            if (err.response !== undefined) {
              this.handleErrors(err.response);
            } else {
              this.handleErrors(err);
            }
          });
      }
    },
    deleteNotification(notificationId) {
      api.deleteUserNotification(notificationId)
        .then(() => {
          this.getUserNotifications();
          this.setMsg('Notification deleted');
        })
        .catch((err) => {
          if (err.response !== undefined) {
            this.handleErrors(err.response);
          } else {
            this.handleErrors(err);
          }
        });
    },
  },
  mounted() {
    if (this.$route.params.userId) {
      this.getUser(this.$route.params.userId)
        .then(() => {
          this.getASUserInvitationLink();
        });
      api.listClients(1, 1000)
        .then((res) => {
          this.listClient = res.data.items;
          this.getUserNotifications();
        })
        .catch((err) => {
          if (err.response !== undefined) {
            this.handleErrors(err.response);
          } else {
            this.handleErrors(err);
          }
        });
      api.listAssessments(1)
        .then((res) => {
          this.assessments = res.data.items.map((item) => ({ id: item.id, name: `${item.id}: ${item.name}` }));
        })
        .catch((err) => {
          if (err.response !== undefined) {
            this.handleErrors(err.response);
          } else {
            this.handleErrors(err);
          }
        });
    }
  },
};
</script>
<style scoped lang="scss">
.nullClient_style {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;

  button {
    margin-top: 12px;
  }

  .button_no {
    margin-left: 30px;
  }
}

.client_add {
  cursor: pointer;
  height: 1.5rem;
  margin-right: 2rem;
  border-radius: 50%;
  transition: box-shadow 0.3s;

  &:hover {
    box-shadow: 0 0 5px 5px rgba(75, 75, 75, 0.219);
  }
}

.client_cross {
  cursor: pointer;
  height: 1.2rem;
  margin: 0 1rem 0 0.2rem;
}

.add_client {
  display: flex;
  align-items: center;
  word-wrap: break-word;
}

.user_clients {
  overflow: auto;
  margin-right: 1rem !important;
  display: flex;
  align-items: center;
}

.break-word {
  word-wrap: break-word;
}

.top_margin {
  margin-top: 30px;
}

.user_email {
  width: 16rem;
}

.client_name {
  margin-right: 0 !important;
}

.row {
  position: relative;
  display: flex;
  width: 1030px;
  margin-right: 10px;
  padding-bottom: 5px;

  &:not(:first-child) {
    margin-top: 0.8rem;
  }

  button {
    margin: 20px 0 20px 20px;
  }

  label {
    margin: 0;
    display: flex;
    align-items: center;
    margin-top: 0.8rem;
  }
}

.result {
  font-weight: bold;
  margin: 0;
  margin-right: 50px;
  font-size: 1rem !important;
}

.table-container {
  max-height: 300px;
  width: auto;
  overflow-y: auto;

  td {
    padding-top: 10px;
    padding-right: 50px;
    padding-bottom: 10px;
  }

  thead {
    position: sticky;
    top: 0;
    background-color: #525f7f;
    color: white;

    tr th:hover {
      background-color: #394258;
      color: rgb(255, 255, 255);
    }
  }

  tbody tr {
    cursor: pointer;
  }

  tbody tr:hover {
    background-color: rgb(206, 211, 219);
  }

  .lvl_name {
    color: rgb(86, 86, 231);
  }

  .lvl_name:hover {
    color: rgb(122, 122, 236);
  }
}

.skins_lvl {
  margin: 0 !important;
  display: grid !important;
  margin-right: 100px !important;

  p {
    margin-bottom: 20px !important;
  }
}

.game-skins {
  list-style-type: none;
  padding: 0;

  li {
    margin-left: 30px;
  }
}

.disabled_check {
  margin-right: 50px;
}
.disabled_button {
  margin: 0 50px 0 0 !important;
}
</style>

<template>
  <div v-if="moduleType && editableModule" class="row radar">
    <!-- NAME -->
    <label for="name" :style="{ flex: '100%' }">
      <base-input
        class="name-input flex-full"
        :placeholder="$t('report_editor.name')"
        name="name"
        type="text"
        v-model="editableModule.name"
      ></base-input>
    </label>

    <!-- X_KPI -->
    <label for="x_kpi" :style="{ flex: '20%' }">
      <Dropdown
        :options="kpis"
        @selected="setKpi"
        @index="selectKpi(`x_kpi_id`)"
        :defaultValue="getDefaultKpi(editableModule, 'x_kpi')"
        :disabled="false"
        name="x_kpi"
        :maxItem="kpis.length"
        :placeholder="$t('report_editor.x_kpi_id')"
        :key="`x_kpi-${render}`"
      />
      </label>

      <label for="x_low" :style="{ flex: '35%' }">
        <p class="label-text">
        {{ $t(`report_editor.x_low`) }}:
        </p>
        <base-input
          class="x_low-input smaller"
          name="x_low"
          type="number"
          min="0"
          v-model.number="editableModule.x_low"
        ></base-input>
        </label>

      <label for="x_high" :style="{ flex: '35%' }">
          <p class="label-text">
            {{ $t(`report_editor.x_high`) }}:
          </p>
          <base-input
            class="x_high-input smaller"
            name="x_high"
            type="number"
            min="0"
            v-model.number="editableModule.x_high"
          ></base-input>
        </label>

    <!-- Y_KPI -->
      <label for="y_kpi" :style="{ flex: '20%' }">
        <Dropdown
          :options="kpis"
          @selected="setKpi"
          @index="selectKpi(`y_kpi_id`)"
          :defaultValue="getDefaultKpi(editableModule, 'y_kpi')"
          :disabled="false"
          name="y_kpi"
          :maxItem="kpis.length"
          :placeholder="$t('report_editor.y_kpi_id')"
          :key="`y_kpi-${render}`"
        />
        </label>

      <label for="y_low" :style="{ flex: '35%' }">
          <p class="label-text">
            {{ $t(`report_editor.y_low`) }}:
          </p>
          <base-input
            class="y_low-input smaller"
            name="y_low"
            type="number"
            min="0"
            v-model.number="editableModule.y_low"
          ></base-input>
        </label>

      <label for="y_high" :style="{ flex: '35%' }">
        <p class="label-text">
          {{ $t(`report_editor.y_high`) }}:
        </p>
        <base-input
          class="y_high-input smaller"
          name="y_high"
          type="number"
          min="0"
          v-model.number="editableModule.y_high"
        ></base-input>
      </label>

    <hr>

    <!-- TRANSLATIONS -->
    <div class="row radar">
      <div>
        <Dropdown
          class="lang"
          style="margin-bottom: 0"
          :options="allLangs"
          @selected="selectLang"
          :disabled="false"
          name="lang"
          :maxItem="allLangs.length"
          :placeholder="$t('select_lang')"
          key="lang"
          :language="true"
        />
      </div>

      <div
        v-for="(key, i) in Object.keys(editableModule.translations)"
        :key="i"
        :class="{
          'radar-flex-small': i % 2 === 0 && i > 1,
          'radar-flex-large': i % 2 !== 0 && i > 1
        }"
        :style="[
          key === 'description' ? { flex: '50%'} : ''
        ]"
      >
        <label
          :for="key"
        >
          <p
            v-if="selectedLang"
            class="label-text"
          >
            {{ $t(`report_editor.${key}`) }}:
          </p>

          <base-input
            v-if="!key.includes('text')
              && (key.includes('name')
              || key.includes('title'))
              && selectedLang"
            :class="`${key}-input`"
            :name="key"
            :placeholder="selectedLang"
            type="text"
            v-model="editableModule.translations[key][selectedLang]"
          ></base-input>

          <textarea
            v-else-if="selectedLang"
            :class="`${key}-input`"
            :name="key"
            :placeholder="selectedLang"
            v-model="editableModule.translations[key][selectedLang]"
          ></textarea>
        </label>
      </div>
    </div>

    <hr>

    <div class="row">
      <!-- DISPLAY PARTS -->
       <label for="display_parts">
        <p class="label-text">
          {{ $t('report_editor.display_parts') }}:
        </p>
        <Dropdown
          :options="modes"
          @selected="selectMode"
          :defaultValue="getDefaultMode(editableModule)"
          :disabled="false"
          name="mode"
          :maxItem="modes.length"
          :selectedLang="selectedLang"
          :moduleType="moduleType"
          :placeholder="$t('report_editor.display_parts')"
          :key="`display_parts-${render}`"
        />
       </label>

      <!-- SAVE BUTTON -->
      <base-button @click="saveModule">
        {{ $t(`report_editor.${selectedAction}_module`) }}
      </base-button>
    </div>
  </div>
</template>

<script>

import Dropdown from '../Dropdown.vue';
import '../../assets/scss/modules.scss';

export default {
  components: { Dropdown },
  data() {
    return {
      kpiId: null,
      newKpi: null,
      render: 0,
      editableModule: null,
      selectedLang: null,
      newModule: {
        x_kpi_id: null,
        x_low: null,
        x_high: null,
        y_kpi_id: null,
        y_low: null,
        y_high: null,
        display_parts: null,
        translations: {
          title: {},
          description: {},
          low_low_name: {},
          low_low: {},
          low_high_name: {},
          low_high: {},
          high_low_name: {},
          high_low: {},
          high_high_name: {},
          high_high: {},
          average_name: {},
          average: {},
        },
      },
      modes: [
        { id: 0, name: 'Chart only', value: 'chart' },
        { id: 1, name: 'Text only', value: 'text' },
        { id: 2, name: 'Both', value: 'both' },
      ],
    };
  },
  props: {
    moduleType: {
      required: true,
    },
    module: {
      required: true,
    },
    kpis: {
      required: true,
    },
    allLangs: {
      required: true,
    },
    selectedAction: {
      required: true,
    },
  },
  methods: {
    rerender() {
      this.render++;
    },
    selectLang(lang) {
      this.selectedLang = lang.code;
    },
    saveModule() {
      this.$emit('saveModule', this.moduleType, this.editableModule);
    },
    setKpi(kpi) {
      this.kpiId = kpi.id;
      this.newKpi = {
        id: kpi.id,
        identifier: kpi.identifier,
      };
    },
    selectMode(mode) {
      this.editableModule.display_parts = mode.value;
    },
    getDefaultMode(module) {
      if (module && module.display_parts) {
        let defaultMode = '';
        this.modes.forEach((mode, i) => {
          if (mode.value === module.display_parts) {
            defaultMode = this.modes[i].name;
          }
        });
        return defaultMode;
      }
      return 'Both';
    },
    getDefaultKpi(module, key) {
      if (module[key]) {
        const name = module[key].name ? ` - ${module[key].name}` : '';
        return `${module[key].identifier}${name}`;
      }
      return null;
    },
    selectKpi(kpiType) {
      if ((kpiType === 'x_kpi_id' && this.kpiId !== this.editableModule.y_kpi_id)
      || (kpiType === 'y_kpi_id' && this.kpiId !== this.editableModule.x_kpi_id)
      ) {
        this.editableModule[kpiType] = this.kpiId;
      } else {
        // Do now allow selecting the same kpis as X and Y kpi
        this.$children.forEach((child, index) => {
          if (
            (kpiType === 'x_kpi_id'
            && this.kpiId === this.editableModule.y_kpi_id
            && child.name === 'x_kpi')
            || (kpiType === 'y_kpi_id'
            && this.kpiId === this.editableModule.x_kpi_id
            && child.name === 'y_kpi')
          ) {
            this.editableModule[kpiType] = null;
            setTimeout(() => {
              this.$children[index].emptyFilter();
            }, 300);
          }
        });
      }
    },
    setDefaultLang() {
      this.$children.forEach((child, i) => {
        if (child.$el.className === 'dropdown lang') {
          this.$children[i].setDefault('English');
        }
      });
      this.selectedLang = 'en';
    },
  },
  beforeMount() {
    this.editableModule = JSON.parse(JSON.stringify(this.module));
  },
  mounted() {
    this.setDefaultLang();
  },
};
</script>
